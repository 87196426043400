import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ADMIN_REMARKS_FIELDS, AGGREGATED_ATTN_DECLARATION_FORM, DOWNLOAD_TEMPLATE_TASK_LIST, NEED_MORE_iNFOR_CONFIG, PROJECT_TYPE_MAP, SUBMIT_ACTIONS, } from '@shared/components/project-builder/consts/project-builder.const';
import * as moment from 'moment';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { ATTESTATION_FIELDS, PROJECT_ADMIN_REMARKS_FIELDS } from '@shared/components/project/project.constants';
import { Subject } from 'rxjs';
let SubProjectsAdditionComponent = class SubProjectsAdditionComponent {
    constructor(activatedRoute, renderer, formFactoryService, translateService, projectBuilderService, projectService, lookupService, storeService, modalService, uploadDocumentService, documentUploadInProgress, navigationSvc) {
        this.activatedRoute = activatedRoute;
        this.renderer = renderer;
        this.formFactoryService = formFactoryService;
        this.translateService = translateService;
        this.projectBuilderService = projectBuilderService;
        this.projectService = projectService;
        this.lookupService = lookupService;
        this.storeService = storeService;
        this.modalService = modalService;
        this.uploadDocumentService = uploadDocumentService;
        this.documentUploadInProgress = documentUploadInProgress;
        this.navigationSvc = navigationSvc;
        this.form = new FormGroup({});
        this.aggregatedAttnDeclarationModel = {};
        this.needMoreInfoModel = {};
        this.taskList = DOWNLOAD_TEMPLATE_TASK_LIST;
        this.downloadSelected = null;
        this.viewReady = false;
        this.apiCalled = false;
        this._destroy$ = new Subject();
    }
    ngOnInit() {
        const masterProject = history.state.masterProject;
        this.taskList = this.taskList = [{
                id: 1,
                name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
            }];
        this.projectBuilderService.updateProject = true;
        this.storeService.getOffsetMinStartDate().subscribe(prop => {
            this.minProjectStartDate = moment(prop.value);
        });
        const masterProjectId = this.activatedRoute.snapshot.params['masterProjectId'];
        if (masterProject) {
            this.setProjectBuilderModel(masterProject, true);
            this.viewReady = true;
        }
        else {
            this.getMaster(masterProjectId);
        }
        this.aggregatedAttnDeclarationFields = this.formFactoryService.configureForm(AGGREGATED_ATTN_DECLARATION_FORM);
        if (this.projectBuilderService.hasApproveAction()) {
            this.needMoreInfoFields = this.formFactoryService.configureForm(ADMIN_REMARKS_FIELDS);
        }
        this.translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
            this.taskList = [{
                    id: 1,
                    name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
                }];
        });
    }
    getMaster(masterProjectId) {
        this.viewReady = false;
        let model = {};
        this.projectService.getProjectDetails(+masterProjectId)
            .pipe(tap(data => model = Object.assign({}, data)), switchMap((data) => this.projectService.searchProjectAccount({
            accountId: data.accountId,
            masterProjectId,
            forAdditionOfProjectsToAggregation: true,
        }))).subscribe(result => {
            model = Object.assign({}, model, { projectsInAggregationId: result.projectsInAggregationId });
            this.projectsInAggregationId = result.projectsInAggregationId;
            this.setProjectBuilderModel(model);
            this.viewReady = true;
        }, () => this.back());
    }
    setProjectBuilderModel(result, skipReset = false) {
        this.projectBuilderService.model = result;
        this.projectBuilderService.model = this.checkCreditingPeriodDates(this.projectBuilderService.model);
        this.projectBuilderService.model.discardSubProjects = [];
        this.projectBuilderService.projectType = PROJECT_TYPE_MAP[result.type];
        this.projectBuilderService.addPermission(result.entityActionList);
        if (!skipReset) {
            this.projectBuilderService.model.subProjects = [];
        }
        if (this.projectBuilderService.model.subProjects && this.projectBuilderService.model.subProjects.length) {
            ATTESTATION_FIELDS.forEach(field => {
                this.aggregatedAttnDeclarationModel[field] = this.projectBuilderService.model.subProjects[0].activity[field] || false;
            });
            PROJECT_ADMIN_REMARKS_FIELDS.forEach(field => {
                this.needMoreInfoModel[field] = this.projectBuilderService.model.subProjects[0][field];
            });
        }
        this.needMoreInfoModel.adminPermisson = this.projectBuilderService.model.adminPermisson;
        setTimeout(() => (this.projectBuilderService.selectedCard = result.activity.activityType));
    }
    checkCreditingPeriodDates(model) {
        return Object.assign({}, model, { activity: Object.assign({}, model.activity, { _creditingPeriodStartDateVisible: !!model.activity.creditingPeriodStartDate, _creditingPeriodEndDateVisible: !!model.activity.creditingPeriodEndDate }) });
    }
    get model() {
        return this.projectBuilderService.model;
    }
    set model(val) {
        this.projectBuilderService.model = val;
    }
    addSubProject() {
        this.projectBuilderService.addSubProject();
    }
    uploadSubProjects() {
        if (!this.projectBuilderService.model.name) {
            this.displayNoSubProjectError('addNameAndDescriptionToUploadSubProjects');
        }
        else {
            const input = this.renderer.createElement('input');
            this.renderer.setAttribute(input, 'type', 'file');
            this.renderer.setStyle(input, 'display', 'none');
            this.renderer.listen(input, 'change', event => {
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append('file', file);
                formData.append('project', JSON.stringify({
                    id: this.model.id,
                    action: 'SAVE',
                    additionOfProjectsInAggregation: true,
                    projectsInAggregationId: this.projectBuilderService.model.projectsInAggregationId,
                    discardSubProjects: this.projectBuilderService.model.discardSubProjects
                }));
                const sub$ = this.projectService.uploadSubProjects(formData)
                    .subscribe(result => {
                    this.projectBuilderService.model = Object.assign({}, this.projectBuilderService.model, result.entity, { discardSubProjects: [] });
                    this.projectBuilderService.subProjectAdded$.next();
                    sub$.unsubscribe();
                });
            });
            this.renderer.appendChild(this.uploadContainer.nativeElement, input);
            input.click();
        }
    }
    downloadTemplate(ev) {
        const uri = this.translateService.currentLang === 'en' ? '/account-service/document/downloadTemplate/Projects.csv' : '/account-service/document/downloadTemplate/Projets.csv';
        this.uploadDocumentService.downloadDocument(`${environment.serviceUrl}${uri}`);
        //this.uploadDocumentService.downloadDocument(`${ environment.serviceUrl }/offset-service/project/downloadSubProjectUploadTemplate`);
        setTimeout(() => {
            this.downloadSelected = null;
            document.activeElement.blur();
        }, 0);
    }
    displayNoSubProjectError(message = 'atLeastTwoSubProjectRequired') {
        const dialogRef = this.modalService.open(ServiceMessageComponent, {
            messages: [
                {
                    message,
                },
            ],
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe(() => {
            dialogRef.unsubscribe();
        });
    }
    back() {
        this.navigationSvc.back();
    }
    setModel(result, save = false) {
        this.projectBuilderService.addPermission(result.entityActionList, result.status);
        this.model = result;
        this.projectBuilderService.isProjectPublicExistingValue = this.model.activity.isPublic;
        this.viewReady = true;
    }
    submit() {
        if (!this.projectBuilderService.model.subProjects.length) {
            this.showErrorMsg('noSubProjectsAdded');
            return;
        }
        const payload = this.createPayload();
        this.formFactoryService.markFormGroupDirty(this.form);
        if (this.form.valid) {
            this.apiCalled = false;
            this.projectBuilderService.validateFormsReq$.next();
            this.projectBuilderService.validateRequiredDocsRes$.pipe(filter(result => {
                if (!result) {
                    return;
                }
                if (result && !this.apiCalled) {
                    this.apiCalled = true;
                    return true;
                }
                else {
                    return false;
                }
            }), switchMap(() => this.projectService.submitProject(payload)))
                .subscribe(() => {
                this.back();
            });
        }
    }
    createPayload() {
        const payload = Object.assign({}, this.model, this.form.getRawValue());
        payload.activity.activityType = this.model.activity.activityType;
        payload.activity.id = this.model.activity.id;
        delete payload.activity.location.file;
        payload.additionOfProjectsInAggregation = true;
        payload.subProjects = payload.subProjects.map(subProject => (Object.assign({}, subProject, this.needMoreInfoModel, { activity: Object.assign({}, subProject.activity, this.aggregatedAttnDeclarationModel) })));
        return payload;
    }
    save() {
        if (!this.projectBuilderService.model.subProjects.length) {
            this.showErrorMsg('noSubProjectsAdded');
            return;
        }
        const payload = this.createPayload();
        if (!payload.name) {
            const dialogRef = this.modalService.open(ServiceMessageComponent, {
                messages: [
                    ...(!payload.name ? [{
                            message: 'subProjectNameAndRequired',
                        }] : []),
                ],
                type: "ERROR" /* ERROR */,
            }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe(() => {
                dialogRef.unsubscribe();
            });
        }
        else {
            if (this.model.entityActionList && this.model.entityActionList.length && this.model.entityActionList.some(action => action.workflowAction === 'EDIT')) {
                let actionLabel = 'EDIT';
                let message = 'confirmChangesMessageProject';
                if (!this.projectBuilderService.isProjectPublicExistingValue && payload.activity.isPublic) {
                    actionLabel = null;
                    message = 'projectPublicViewChangeConfirmation';
                }
                const dialogRef$ = this.projectBuilderService.openConfirmationModal(actionLabel, this.model.name, message).subscribe(result => {
                    if (result) {
                        this.saveProject(payload);
                        dialogRef$.unsubscribe();
                    }
                });
            }
            else {
                this.saveProject(payload);
            }
        }
    }
    saveProject(payload) {
        const valid = this.projectBuilderService.validateIncorrectValues(this.form, true);
        if (valid) {
            this.projectService.saveProject(payload)
                .subscribe(result => {
                this.setModel(result.entity, true);
            });
        }
    }
    showErrorMsg(errorMsg) {
        const dialogRef = this.modalService.open(ServiceMessageComponent, {
            messages: [{
                    message: errorMsg,
                }],
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe(() => {
            dialogRef.unsubscribe();
        });
    }
    openNeedMoreInfoPopUp() {
        const defaultValues = {
            id: this.model.id,
            action: 'NEED_MORE_INFO',
            additionOfProjectsInAggregation: true,
            projectsInAggregationId: this.model.projectsInAggregationId,
        };
        const sub$ = this.modalService.open(TableNewRowComponent, { form: NEED_MORE_iNFOR_CONFIG, defaultValues,
            actionType: 0 /* EDIT */ }).afterClosed().subscribe(data => {
            sub$.unsubscribe();
            if (data) {
                this.back();
            }
        });
    }
    triggerAction(action) {
        if (SUBMIT_ACTIONS.includes(action.workflowAction)) {
            this.submit();
            return;
        }
        else if (action.workflowAction === 'NEED_MORE_INFO') {
            this.openNeedMoreInfoPopUp();
            return;
        }
        else {
            const payload = {
                id: this.model.id,
                action: action.workflowAction,
                adminRemark: this.form.get('adminRemark') && this.form.get('adminRemark').value,
                discloseComment: this.form.get('discloseComment') && this.form.get('discloseComment').value,
                additionOfProjectsInAggregation: true,
                projectsInAggregationId: this.model.projectsInAggregationId,
            };
            let uri = 'review';
            if (['REJECT_SECOND', 'APPROVE_SECOND', 'REJECT_REJECTION', 'APPROVE_REJECTION'].includes(action.workflowAction)) {
                uri = 'review2';
            }
            const sub$ = this.projectBuilderService.openConfirmationModal(action.workflowAction, this.model.account.legalName)
                .pipe(filter(result => result), switchMap(() => this.projectService.projectWorkflowAction(payload, uri)))
                .subscribe(() => {
                sub$.unsubscribe();
                this.back();
            });
        }
    }
    ngOnDestroy() {
        this.projectBuilderService.clearData();
        this._destroy$.next();
        this._destroy$.complete();
    }
};
SubProjectsAdditionComponent = tslib_1.__decorate([
    UntilDestroy()
], SubProjectsAdditionComponent);
export { SubProjectsAdditionComponent };
