import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { Moment } from 'moment';
import {
  AGGREGATED_ATTN_DECLARATION_FORM,
  AGGREGATED_OFFSET_ACTIVITY_FORM,
  NEED_MORE_INFO_FORM,
  NEW_OFFSET_ACTIVITY_FORM,
  PROJECT_APPLICATION_TYPES,
  PROJECT_TYPES,
  PROJECT_TYPE_FORM,
  DISABLED_FIELDS_ON_REPORT_UPDATE
} from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';
import * as moment from 'moment';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { environment } from '@env/environment';
import { ProjectService } from '@shared/services/project.service';
import { FormService } from '@shared/services/form.service';
import { StoreService } from '@core/store/store.service';
import { cloneDeep } from 'lodash';
import { EDIT_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';

@Component({
  selector: 'app-project-activity-form',
  templateUrl: './project-activity-form.component.html',
  styleUrls: ['./project-activity-form.component.scss']
})
export class ProjectActivityFormComponent implements OnInit, AfterViewInit, OnDestroy {

  public form = new FormGroup({});
  public fields: FormlyFieldConfig[];
  public aggregatedAttnDeclarationfields: FormlyFieldConfig[];
  public cardInfo;
  public taskList = [{id:1, name: 'Download Template'}];
  public downloadSelected = null;

  public needMoreInfoFields: FormlyFieldConfig[];

  private _destroy$ = new Subject<any>();
  private activityAdded = false;
  private minProjectStartDate: Moment;
  private _mapFileId = 'mapFile';
  private _downloadLinkId = 'downloadLink';

  @ViewChild('UPLOAD', {static: false}) uploadContainer: ElementRef<any>;
  @ViewChild('ngForm', { static: false }) formEl: ElementRef;

  readonly container;

  constructor(
    private formFactoryService: FormFactoryService,
    private modalService: ModalService,
    public projectBuilderService: ProjectBuilderService,
    private translateService: TranslateService,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private uploadDocumentService: UploadDocumentService,
    private projectService: ProjectService,
    private renderer: Renderer2,
    private formService: FormService,
    private storeService: StoreService,
    ) {
      this.container = document.getElementsByClassName('inner-panel-2')[0];
    }

  ngOnInit() {
    this.activityAdded = !!this.projectBuilderService.getActivities().getValue().length;
    this.storeService.getOffsetMinStartDate().subscribe(
      prop => {
    this.minProjectStartDate = moment(prop.value);
    });

    this.cardInfo = this.projectBuilderService.selectedCard;
    this.taskList = [{
      id: 1,
      name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
    }];
    this.projectBuilderService.model.activity = {
      ...this.projectBuilderService.model.activity,
      location: this.projectBuilderService.model.activity.location || {},
      activityType: this.cardInfo,
      disabled: this.projectBuilderService.disabled,
      projectType: this.projectBuilderService.model.projectType || this.projectBuilderService.model.type
    };
    this.setFields();
    setTimeout(() => {
      this.projectBuilderService.updateControlsValueAndValidity();
    });
    this.validateFormOnSubmit();
    this.handleBrowseButton();
    this.disableFormIfApplicable();
    this.validateLatLong();
    this.projectBuilderService.renderDownloadKmlControl(this.projectBuilderService.model, this._mapFileId, this._downloadLinkId);
    this.formFactoryService.inputCallback.subscribe(field => {
      if (field.key === 'estimatedScenarioGhg') {
        this.validateGHGReductions();
      }
    });
    this.subscribeSaveEvent();
    this.radioChangeSubscription();
    this.updateOnLanguageChange();
    this.updateSubProjectCreditingPeriod();
  }



  public addActivity() {
    this.projectBuilderService.updateControlsValueAndValidity();
    this.formFactoryService.markFormGroupDirty(this.projectBuilderService.projectForm);
    if (this.projectBuilderService.projectForm.valid) {
      if ([PROJECT_TYPES.AGGREGATED, PROJECT_TYPES.MASTER_RECEIVER].includes(this.projectBuilderService.projectType) &&
          this.projectBuilderService.model.subProjects.length < 2) {
            this.displayNoSubProjectError();
      } else {
        this.projectBuilderService.addActivity(this.projectBuilderService.model);
        this.projectBuilderService.changeActivityView.next(true);
        this.activityAdded = true;
      }
    } else {
      this.formService.scrollToFirstInvalidControl(this.formEl, this.container);
    }
  }

  public modelChanged(ev) {
    this.checkForInvalidDate();
  }

  ngAfterViewInit() {
    this.disableOnUpdateFromReport();
  }

  public addSubProject() {
    if (!this.projectBuilderService.disabled) {
      this.projectBuilderService.addSubProject();
    }
  }

  public uploadSubProjects() {
    if (!this.projectBuilderService.disabled) {
      const projectNameField = this.translateService.currentLang === 'en' ? 'name' : 'name2';
      if (!this.projectBuilderService.model[projectNameField]) {
        this.displayNoSubProjectError('addNameAndDescriptionToUploadSubProjects');
      } else {
        this.projectBuilderService.uploadSubProjects(this.uploadContainer, this.renderer);
      }
    }
  }

  public downloadTemplate(ev) {
    const uri = this.translateService.currentLang === 'en' ? '/account-service/document/downloadTemplate/Projects.csv' : '/account-service/document/downloadTemplate/Projets.csv';
    this.uploadDocumentService.downloadDocument(`${ environment.serviceUrl }${uri}`);
    setTimeout(() => {
      this.downloadSelected = null;
      (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  public updateFormOnSubProjectUpdate() {
    this.validateGHGReductions();
  }

  private checkForInvalidDate() {
    const control = this.projectBuilderService.projectForm.get('activityStartDate');
    if (this.projectBuilderService.model.activity.activityStartDate && control && this.minProjectStartDate) {
      if (moment(this.projectBuilderService.model.activity.activityStartDate).isBefore(this.minProjectStartDate)) {
        control.markAsDirty();
        control.setErrors({ 'direct':  `${this.translateService.instant('COMMON.messageSection.startDateCannotBeLessThenJan2017')}
        ${this.minProjectStartDate.format('MMM D, YYYY')}` });
        return false;
      }
      return true;
    }
    return false;
  }

  private validateFormOnSubmit() {
    this.projectBuilderService.subscriptions$.add(this.projectBuilderService.validateFormsReq$
    .subscribe(() => {
      this.formFactoryService.markFormGroupDirty(this.projectBuilderService.projectForm);
      let valid = this.projectBuilderService.projectForm.valid;

      if (this.projectBuilderService.model.activity.location && this.projectBuilderService.model.activity.location.type === 'DECIMAL') {
        if(!this.projectBuilderService.validateDecimalLatLong(this.projectBuilderService.projectForm.get('location.latitudeDecimal') as FormControl, this.projectBuilderService.model.activity.location.latitudeDecimal)) {
          valid = false;
        }
        if(!this.projectBuilderService.validateDecimalLatLong(this.projectBuilderService.projectForm.get('location.longitudeDecimal') as FormControl, this.projectBuilderService.model.activity.location.longitudeDecimal)) {
          valid = false;
        }
      }
      if (!this.checkForInvalidDate()) {
        valid = false;
      }


      if ([PROJECT_TYPES.AGGREGATED, PROJECT_TYPES.MASTER_RECEIVER].includes(this.projectBuilderService.projectType) &&
        this.projectBuilderService.model.subProjects.length < 2 && valid) {
          valid = false;
          this.projectBuilderService.validateActivityFormsRes$.next(valid);
          this.displayNoSubProjectError();
          return;
        }

      if (!this.activityAdded && valid && this.projectBuilderService.showAddToSummary) {
        valid = false;
        this.projectBuilderService.validateActivityFormsRes$.next(valid);
        this.displayNoSubProjectError('activityNotAdded');
        return;
      }
        this.projectBuilderService.validateActivityFormsRes$.next(valid);
    }))
  }

  private getAddressLabel() {
    if (this.projectBuilderService.model.account) {
      const address = cloneDeep(this.projectBuilderService.model.account.authorizedOfficial.postalAddress);
      if (address) {
        address.province = this.translateService.instant(`COMMON.jurisdictionsList.${address.province}`);
        address.country = this.translateService.instant(`COMMON.countryList.${address.country}`);
        address.city = address.city ? address.city+',' : '';
        address.poBox = address.poBox ? address.poBox+' &nbsp' : '';
        return `${address.streetNumber} ${address.streetName}<br>${address.city} ${address.province}<br>${address.poBox}${address.postalCode}<br>${address.country}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  private setFields() {
    AGGREGATED_OFFSET_ACTIVITY_FORM[1].fieldGroup[0].label = this.getAddressLabel();
    NEW_OFFSET_ACTIVITY_FORM[1].fieldGroup[0]['options'] =  NEW_OFFSET_ACTIVITY_FORM[1].fieldGroup[0]['options'].map(option => {
      return {
        key: option.key,
        value: option.value,
        tooltip: option.tooltip,
        tooltipClass: option.tooltipClass
      }
    });

    this.fields = this.formFactoryService.configureForm(PROJECT_TYPE_FORM[this.projectBuilderService.projectType]);

    this.aggregatedAttnDeclarationfields = this.formFactoryService.configureForm(AGGREGATED_ATTN_DECLARATION_FORM);

    if (this.projectBuilderService.hasApproveAction()) {
      this.needMoreInfoFields = this.formFactoryService.configureForm(NEED_MORE_INFO_FORM);
    }
  }

  private disableOnUpdateFromReport(): void {
    if (this.updateProject) {
      DISABLED_FIELDS_ON_REPORT_UPDATE.forEach(key => this.projectBuilderService.projectForm.get(key).disable());
    }
  }

  private handleBrowseButton() {
    this.formFactoryService.buttonCallback
      .pipe(
        delay(100),
        takeUntil(this._destroy$)
      ).subscribe(result => {
        if (!result.field.subProject) {
          const doc = document.getElementById(this._mapFileId);
          if (doc) {
            doc.click();
          }
        }
      });

    this.formFactoryService.fileUpload
    .pipe(takeUntil(this._destroy$))
    .subscribe(({file, field}) => {
      this.projectBuilderService.renderKmlFileUpload(this.projectBuilderService.model, file, field, this._mapFileId, this._downloadLinkId);
    });
  }

  private displayNoSubProjectError(message = 'atLeastTwoSubProjectRequired') {
    const dialogRef = this.modalService.open(ServiceMessageComponent,
      {
        messages: [
          {
            message: message,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe(() => {
      dialogRef.unsubscribe();
    });
  }

  private disableFormIfApplicable() {
    if (this.projectBuilderService.checkForDisabledState()) {
      setTimeout(() => {
        this.projectBuilderService.projectForm.disable();
        this.formFactoryService.enableFieldByPermission(this.projectBuilderService.projectForm, 'activity.isPublic', [EDIT_PROJECT_PUBLIC_VISIBILITY]);
      });
    }
  }

  private validateLatLong() {
    this.formFactoryService.inputCallback
      .pipe(
        takeUntil(this._destroy$),
        filter(field => field.key === 'location.longitudeDecimal' || field.key === 'location.latitudeDecimal')
      )
      .subscribe(field => {
        const control = this.projectBuilderService.projectForm.get(field.key) as FormControl;
        this.projectBuilderService.validateDecimalLatLong(control, control.value);
      })
  }

  private validateGHGReductions() {
    const control = this.projectBuilderService.projectForm.get('estimatedScenarioGhg');
    if (this.projectBuilderService.model.activity.estimatedScenarioGhg <= 0) {
      control.markAsDirty();
      control.setErrors({ 'server-side': this.translateService.instant('cannotBeLessThenZero') });
    }
  }

  private subscribeSaveEvent() {
    this.projectBuilderService.subscriptions$.add(this.projectBuilderService.validateIncorrectValuesReq$.pipe(
    ).subscribe(() =>{
      let valid = this.projectBuilderService.validateIncorrectValues(this.projectBuilderService.projectForm, true);
      if (valid) {
        this.projectBuilderService.validateIncorrectValuesRes$.next(valid);
      }
    }))
  }

  private radioChangeSubscription() {
    this.formFactoryService.RadioCheangeCallback
    .pipe(takeUntil(this._destroy$))
    .subscribe(field => {
      if (field.key === 'sequestrationProject') {
        this.projectBuilderService.projectForm.get('quantificationMethod').setValue(null);
      }
    });
  }

  private updateOnLanguageChange() {
    this.translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.setFields();
      this.disableFormIfApplicable();
      this.taskList = [{
        id: 1,
        name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
      }];
    });
  }

  private updateSubProjectCreditingPeriod() {
    this.formFactoryService.onDateCheangeCallback.pipe(
      takeUntil(this._destroy$)
    ).subscribe(field => {
      if (field.key === 'creditingPeriodEndDate' && this.projectBuilderService.model.subProjects.length
         && this.projectBuilderService.model.type === PROJECT_APPLICATION_TYPES[PROJECT_TYPES.AGGREGATED]) {
        this.projectBuilderService.model.subProjects.forEach(sub => {
          sub.activity.creditingPeriodEndDate = this.projectBuilderService.model.activity.creditingPeriodEndDate;
        });
      }
    });
  }

  get updateProject(): boolean {
    return this.projectBuilderService.updateProject;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
