import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CFS_REGISTRATION_STEP_TYPE } from '../constants/registration-types.const';
import { StepAnchorDirective } from '../directive/step-anchor.directive';
import { validateStep } from '@shared/decorators/validate-step.decorator';
import { PERMISSION_PROPOSE_TRANSACTION } from '@module/submission-management/services/submission-management.service';
export const programIdKey = 'program_id';
export class StepsComponent {
    constructor(router, helperService, componentFactoryResolver, activatedRoute, registrationService, storeService, service, // todo
    agreementService, _location, cdRef, translateService, authService) {
        this.router = router;
        this.helperService = helperService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.activatedRoute = activatedRoute;
        this.registrationService = registrationService;
        this.storeService = storeService;
        this.service = service;
        this.agreementService = agreementService;
        this._location = _location;
        this.cdRef = cdRef;
        this.translateService = translateService;
        this.authService = authService;
        this._destroy$ = new Subject();
        this.currentStepLastEvent = new Subject();
        this.saveAsDraftEvent = new Subject();
        this.saveAndProceedEvent = new Subject();
        this.submitEvent = new Subject();
        this.submitButtonLabel = 'submit';
        this.useLayout = false;
        this.showBeforeNextButton = false;
        this.showNextButton = true;
        this.showBackButton = false;
        this.showCloseButton = false;
        this.showSaveAsDraftButton = false;
        this.activatedRoute.params.subscribe(params => {
            this.activatedRoute.data
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                if (data) {
                    data.entryComponentsMap && (this.entryComponentsMap = data.entryComponentsMap);
                    this.useLayout = data.useLayout;
                    this.applicationType = data.type;
                }
            });
            if (params.token) {
                this.registrationService.getCFRAccountDetails(params.token)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(response => {
                    sessionStorage.setItem(programIdKey, 'cfr');
                    this.authService.changeLang(this.translateService.currentLang, true)
                        .subscribe(() => {
                        this.initStepper(1);
                        if (response.accountSubTypeIds
                            && response.accountSubTypeIds.length > 0
                            && response.accountSubTypeIds[0] === 7) {
                            //vb
                            this.removeStepByType(CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG);
                        }
                        else {
                            //ps
                            this.removeStepByType(CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG);
                        }
                        this.getCurrentStep().inputData = response;
                        this.loadStep();
                    });
                }, (error) => {
                    this.router.navigate(this.helperService.getTranslatedPath('/'));
                });
            }
            if (params.id) {
                this.applicationId = params.id;
                this.service.getApplicationByType(this.applicationType, this.applicationId)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(data => {
                    this.service.applicationApprovalModel = data;
                });
            }
            if (params.agreementId) {
                this.applicationId = params.agreementId;
                this.agreementService.getAgreementById(this.applicationId)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(data => {
                    this.service.applicationApprovalModel = data;
                    this.ngOnInit();
                });
            }
        });
    }
    set content(content) {
        if (content) {
            this.appStepAnchor = content;
        }
        this.cdRef.detectChanges();
    }
    set content1(content1) {
        if (content1) {
            this.formContainer = content1;
        }
        this.cdRef.detectChanges();
    }
    ngOnInit() {
        if (this.getStepConfigFromRouter()) {
            if (history.state.data) {
                this.initStepper(0);
                this.loadStep();
            }
        }
        else {
            this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
        }
        if (this.storeService.user.hasPermission(PERMISSION_PROPOSE_TRANSACTION)) {
            this.submitButtonLabel = 'propose';
        }
        else {
            this.submitButtonLabel = 'submit';
        }
        if (this.applicationData) {
            this.submitButtonLabel = this.applicationData.type === 'CI_PATHWAY' ?
                `${this.submitButtonLabel}ButtonPathway` : `${this.submitButtonLabel}ButtonCI`;
        }
        this.registrationService.registrationType.next('cfr');
    }
    removeStepByType(type) {
        //vanilla
        let indexToRemove;
        this.configurations.steps.forEach((step, index) => {
            if (step.type === type) {
                indexToRemove = index;
                return false;
            }
        });
        this.configurations.steps.splice(indexToRemove, 1);
    }
    getStepConfigFromRouter() {
        return history.state.data || this.activatedRoute.snapshot.data.stepperConfig;
    }
    setInputData() {
        if (history.state.data && history.state.data.inputData) {
            if (!this.applicationData) {
                this.applicationData = history.state.data.inputData;
            }
            this.getCurrentStep().inputData = history.state.data.inputData;
        }
    }
    initStepper(fromStepIndex) {
        this.configurations = this.getStepConfigFromRouter();
        this.setProgressBar();
        this.resetProgressBarOnLangChange();
        this.step_title = this.configurations.step_title;
        this.page_title = this.configurations.page_title;
        this.currentStep = this.configurations.steps[fromStepIndex].id;
        this.updatePageSubTitle();
        this.updateBackNextButtons();
        this.setInputData();
    }
    loadStep() {
        this.translationLoading
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
            const factories = Array.from(this.componentFactoryResolver['_factories'].values());
            const factoryClass = factories.find((component) => component.componentType.key === this.getCurrentStep().type);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponentsMap[factoryClass.componentType.key]);
            setTimeout(() => {
                this.service.scrollEl = this.formContainer;
                const viewContainerRef = this.appStepAnchor.viewContainerRef;
                viewContainerRef.clear();
                const componentRef = viewContainerRef.createComponent(componentFactory);
                if (this.getCurrentStep().before_next) {
                    componentRef.instance.onSaveAndProceed = this.saveAndProceedEvent.asObservable();
                }
                if (this.getCurrentStep().saveAsDraft) {
                    componentRef.instance.onSaveAsDraft = this.saveAsDraftEvent.asObservable();
                }
                componentRef.instance.onSubmit = this.submitEvent.asObservable();
                if (this.getCurrentStep().inputData) {
                    componentRef.instance.inputData = /*Object.create(*/ this.getCurrentStep().inputData; //);
                }
                componentRef.instance.stepComplete.subscribe(event => {
                    if (this.getCurrentStep().updateStep) {
                        this.updateStep(event);
                    }
                    this.nextStep();
                });
            });
        });
    }
    getComponent() {
        this.loadStep();
    }
    back() {
        if (this.currentStep <= 1) {
            this.close();
        }
        else {
            this.progressBar[this.currentStep].completed = false;
            this.currentStep--;
            this.progressBar[this.currentStep].completed = false;
            this.loadStep();
            this.updatePageSubTitle();
            this.updateBackNextButtons();
        }
    }
    beforeNextStep() {
        this.saveAndProceedEvent.next();
    }
    nextStep() {
        this.progressBar[this.currentStepListId].completed = true;
        if (!this.isLastStep) {
            this.currentStep++;
            this.loadStep();
            this.updatePageSubTitle();
            this.updateBackNextButtons();
        }
        else {
            this._location.back();
        }
    }
    get isLastStep() {
        if (this.configurations) {
            return this.currentStepListId + 1 > this.configurations.steps.length - 1;
        }
        else {
            return false;
        }
    }
    get currentStepListId() {
        return this.configurations.steps.findIndex(step => step.id === this.currentStep);
    }
    updateStep(event) {
        this.configurations.steps = event;
    }
    goToStep($event) {
        this.currentStep = $event;
        this.loadStep();
        this.updatePageSubTitle();
        this.updateBackNextButtons();
    }
    updatePageSubTitle() {
        const currentStep = this.getCurrentStep();
        this.step_title = currentStep.step_title;
        this.page_sub_title = currentStep.type === CFS_REGISTRATION_STEP_TYPE.CFS_SECURE_SIGN_IN ? null : currentStep.page_sub_title;
    }
    updateBackNextButtons() {
        this.showBeforeNextButton = this.getCurrentStep().before_next;
        this.showNextButton = this.getCurrentStep().next;
        this.showBackButton = this.getCurrentStep().back;
        this.showCloseButton = this.getCurrentStep().close;
        this.showSaveAsDraftButton = this.getCurrentStep().saveAsDraft;
    }
    getCurrentStep() {
        return this.configurations.steps.find(step => step.id === this.currentStep);
    }
    close() {
        this._location.back();
    }
    saveAsDraft() {
        this.saveAsDraftEvent.next();
    }
    submit() {
        this.submitEvent.next();
    }
    resetProgressBarOnLangChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
            this.setProgressBar();
        });
    }
    setProgressBar() {
        this.progressBar = this.configurations.progress_bar_config.map(config => {
            return Object.assign({}, config, { name: this.translateService.instant(`REGISTRATION_PAGE.stepperSection.${config.name}`) });
        });
    }
    get translationLoading() {
        return this.authService.translationLoading$;
    }
    ngOnDestroy() {
        this.service.resetModel();
        this._destroy$.next();
        this._destroy$.complete();
    }
}
tslib_1.__decorate([
    validateStep()
], StepsComponent.prototype, "beforeNextStep", null);
tslib_1.__decorate([
    validateStep()
], StepsComponent.prototype, "saveAsDraft", null);
tslib_1.__decorate([
    validateStep()
], StepsComponent.prototype, "submit", null);
