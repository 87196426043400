import {  IFacility } from '@module/registration/registration.model';
import { Observable } from 'rxjs/internal/Observable';
import { TWorkflowAction } from './action.model';
import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';

export namespace IApp {
  export interface Property {
    id?: number;
    value?: string;
    component?: string;
  }

  export enum DocumentUploadTo {
    DOCUMENT_UPLOAD_ACCOUNT_SERVICE = '/account-service/document/account',
    DOCUMENT_UPLOAD_REGISTRATION_SERVICE = '/account-service/document',
    UPLOAD_OBPS_RESULT_REPORT_SERVICE = '/obps-service/credits/upload',
    UPLOAD_CRA_PAYMENT_REPORT_SERVICE = '/obps-service/transaction/upload',
    UPLOAD_CRA_REVERSE_REPORT_SERVICE = '/obps-service/transaction/uploadReversal',
    DOCUMENT_UPLOAD_ENTITY = '/account-service/document/entity',
    OBPS_UPLOAD_RESULTS = '/obps-service/fileUpload/obpsResults',
    UPLOAD_CASH_PAYMENT_REFUND= '/obps-service/payment-refund/uploadCashPaymentRefund',
  }

  export enum DocumentDownloadFrom {
    DOWNLOAD_PAYMENT_REFUND_TEMPLATE = '/obps-service/payment-refund/downloadPaymentRefundTemplate',
  }

  export interface IError {
    errorCode?: string;
    messages?: string[];
    errorId?: string;
    fieldMessages?: IFieldError[];
  }

  export interface IFieldError {
    field: string;
    message: string;
  }

  export interface ILookup {
    id?: any;
    name?: string;
    isNew?: boolean;
    code?: string;
    value?: string;
    accountTypeName?: string;
    [key: string]: any;
  }

  export interface ILookupFormData {
    value?: any;
    key?: string;
  }

  export interface IFacilityNamesLookup extends ILookup {
    certificateNumber?: string;
    accountSubTypeNames?: string;
    status?: string;
    subAccounts?: IFacilityNamesLookup[];
  }

  export interface IMenuItem {
    name: string;
    sortOrder?: number;
    path?: string;
    childMenus?: IMenuItem[];
    translatedName?: string;
    isActive?: boolean;
    urlMappings?: string[];
  }

  export interface IAddress {
    country?: string;
    id?: number;
    municipality?: string;
    province?: string;
    streetAddress?: string;
  }

  export interface IFacilityAuthorizedOfficial {
    email: string;
    name: string;
    telephone?: string;
    businessTelephone?: string;
    title?: string;
  }

  export interface IDocumentType {
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    code?: string;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    name?: string;
    version?: number;
  }

  export interface IDocumentProps {
    documentType?: IDocumentType;
    isPublic?: boolean;
  }

  export interface IAction {
    action?: string;
    enabled?: boolean;
    label?: string;
  }

  export interface IDocument {
    action?: NSDocument.ActionEnum;
    actionList?: IAction[];
    awsId?: string;
    comments?: string;
    contentType?: string;
    documentType?: IDocumentType;
    entityId?: number;
    entityType?: NSDocument.EntityTypeEnum;
    entityName?: string;
    id?: number;
    label?: string;
    lastModifiedDate?: string;
    lastModifiedDateStr?: string;
    name?: string;
    size?: number;
    status?: string;
    type?: string;
    isPublic?: boolean;
    _uploadStatus?: string;
    _placeholder?: boolean;
     signedUrl?: string;
  }

  export interface IDocumentUploadConfig {
    documentType?: boolean;
    entityName?: IApp.NSDocument.EntityTypeEnum;
    entityDetail?: string;
    comment?: boolean;
    statusColumn?: boolean;
    disablePaginator?: boolean;
    id?: number;
    uri?: string;
    listUri?: string;
    title?: string;
    showHeader?: boolean;
    documentTypeValue?: any;
    documentTypeCode?: string;
    documentTypeCodes?: string[];
    documentUploadConfigs?: IApp.IDocumentUploadConfig[];
    extraUploadDocuments?: IApp.IDocumentUploadConfig[];
    pendingSubmissionStatus?: boolean;
    preselectedDocumentType?: IApp.ILookup;
    historicData?: boolean;
    documents?: IApp.IDocument[];
    metaData?: any ;
    guidanceTextEntity?: string;
    replaceOTHERdocType?: any;
    overrideEntityName?: any;
    overrideId?: number;
    publicVisibility?: boolean;
    publicVisibilityEnabled?: boolean;
    secondaryEntityId?: number;
    secondaryEntityName?: string;
    supportingDocumentFilter?: Array<{id: number, entityName: string}>;
    entityVersionId?: number;
    baseUrl?: string;
    noOfRequiredDocs?: number;
  }

  export interface IPendingDocumentMetaData {
    sizeProperty?: string;
    prefix: string;
    title: string;
    dataLabel: string;
    data: string;
    status: string;
    uploadDocumentText: string;
    identificationDocuments: string;
    documentUploadConfig: IDocumentUploadConfig;
    documentsPage?: IDocumentsPage;
    disabled?: boolean;
    fileExtension?: string;

  }

  export namespace NSDocument {
    export type ActionEnum = 'APPROVE' | 'REJECT' | 'DELETE';
    export const ActionEnum = {
      APPROVE: 'APPROVE' as ActionEnum,
      REJECT: 'REJECT' as ActionEnum,
      DELETE: 'DELETE' as ActionEnum,
    };

    export type EntityTypeEnum =
      | 'ACCOUNT'
      | 'USER'
      | 'UNIT'
      | 'CREDIT_ACTION'
      | 'ADMIN'
      | 'ACCREDITATION_BODY'
      | 'SUB_CONTRACTING_ORGANIZATION'
      | 'APPLICATION'
      | 'VERIFICATION'
      | 'PROJECT_OFFSET'
      | 'PROJECTS_IN_AGGREGATION'
      | 'PROJECT_CANCELLATION'
      | 'PROJECT_TRANSFER_REQUEST'
      | 'SUBMISSIONS_OFFSET_MONITORING_REPORT'
      | 'SUBMISSIONS_OFFSET_PROJECT_REPORT'
      | 'SUBMISSIONS_OFFSET_GHG_REV_REPORT'
      | 'SUBMISSIONS_OFFSET_CORRECTED_REPORT'
      | 'SUBMISSIONS_OFFSET_NOT_START_DATE'
      | 'SUBMISSIONS_OFFSET_NOT_ERR_OMI'
      | 'SUBMISSIONS_OFFSET_NOT_CARRYOUT_PROJ_ACT'
      | 'SUBMISSIONS_OFFSET_NOT_GHG_REVERSAL'
      | 'CREDIT_CREATION_REPORT_ANNUAL'
      | 'CREDIT_CREATION_REPORT_ANNUAL_STEP1'
      | 'CREDIT_CREATION_REPORT_ANNUAL_STEP3'
      | 'CREDIT_CREATION_REPORT_ANNUAL_STEP4'
      | 'CREDIT_CREATION_REPORT_ANNUAL_STEP5'
      | 'SUBMISSIONS_OFFSET_NOT_CHANGE_LAND_OWNERSHIP'
      | 'NOTICE_OF_ERROR'
      | 'COMPLIANCE_REPORT'
      | 'COMPLIANCE_REPORT_STEP_1_2'
      | 'COMPLIANCE_REPORT_STEP_1_4'
      | 'COMPLIANCE_REPORT_STEP_5'
      | 'CREDIT_CREATION_REPORT_QUARTERLY_STEP_10'
      | 'CREDIT_CREATION_REPORT_ANNUAL_STEP_8'
      | 'COI_NOTICE'
      | 'VERIFICATION_REPORT'
      | 'CREDIT_ADJUSTMENT_REPORT'
      | 'PROJECT_OFFSET_RECEIVER'
      | 'COMPLIMENTRY_COMPLIANCE_REPORT';

    export const EntityTypeEnum = {
      ACCOUNT: 'ACCOUNT' as EntityTypeEnum,
      USER: 'USER' as EntityTypeEnum,
      UNIT: 'UNIT' as EntityTypeEnum,
      CREDIT_ACTION: 'CREDIT_ACTION' as EntityTypeEnum,
      ADMIN: 'ADMIN' as EntityTypeEnum,
      ACCREDITATION_BODY: 'ACCREDITATION_BODY' as EntityTypeEnum,
      SUB_CONTRACTING_ORGANIZATION: 'SUB_CONTRACTING_ORGANIZATION' as EntityTypeEnum,
      APPLICATION: 'APPLICATION' as EntityTypeEnum,
      VERIFICATION: 'VERIFICATION' as EntityTypeEnum,
      PROJECT_OFFSET: 'PROJECT_OFFSET' as EntityTypeEnum,
      PROJECT_CANCELLATION: 'PROJECT_CANCELLATION' as EntityTypeEnum,
      PROJECT_TRANSFER_REQUEST: 'PROJECT_TRANSFER_REQUEST' as EntityTypeEnum,
      SUBMISSIONS_OFFSET_MONITORING_REPORT: 'SUBMISSIONS_OFFSET_MONITORING_REPORT' as EntityTypeEnum,
      SUBMISSIONS_OFFSET_PROJECT_REPORT: 'SUBMISSIONS_OFFSET_PROJECT_REPORT' as EntityTypeEnum,
      SUBMISSIONS_OFFSET_CORRECTED_REPORT: 'SUBMISSIONS_OFFSET_CORRECTED_REPORT' as EntityTypeEnum,
      SUBMISSIONS_OFFSET_NOT_ERR_OMI: 'SUBMISSIONS_OFFSET_NOT_ERR_OMI' as EntityTypeEnum,
      NOTICE_OF_ERROR: 'NOTICE_OF_ERROR' as EntityTypeEnum,
      COMPLIMENTRY_COMPLIANCE_REPORT: 'COMPLIMENTRY_COMPLIANCE_REPORT' as EntityTypeEnum,
    };
  }

  export interface IEntityAction {
    enabled?: boolean;
    workflowAction?: TWorkflowAction;
    label?: string;
    action?: string;
    configAction?: string;
    custom?: boolean;
  }
  export interface IStandard {
    acronym?: string;
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    priority?: number;
    serialNoFormat?: string;
    standardName?: string;
    version?: number;
  }

  export interface IAccountType {
    code?: string;
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    enabled?: boolean;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    name?: string;
    standard?: IStandard;
    version?: number;
  }
  export interface IRegistrationType {
    code?: string;
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    enabled?: boolean;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    name?: string;
    version?: number;
  }

  export interface OkResponseAccountDto {
    code?: number;
    entity?: IAccount;
    entityList?: IAccount[];
    message?: string;
    monoList?: IAccount[];
    type?: string;
  }

  export interface IEntityResponse {
    code?: number;
    message?: string;
    type?: string;
  }

  export interface ILinkedAccountResponse extends IEntityResponse {
    entity?: ILinkedAccount;
    entityList?: ILinkedAccount[];
    monoList?: ILinkedAccount[];
  }

  export interface IAccountSubType {
    id?: number;
    name?: string;
  }

  export interface IAccountRegistrationType {
    id?: number;
    name?: string;
  }

  export interface IAccount {
    accountType?: IAccountType;
    accountId?: number;
    additionalLegalName?: string;
    address?: IAddress;
    authorizedUserId?: number;
    authorizedAccountRepresenttive?: IAccount;
    businessStructure?: IBusinessStructure;
    businessTelephone?: string;
    certificateNumber?: string;
    createdBy?: number;
    creationDate?: any;
    deleted?: boolean;
    email?: string;
    externalId?: number;
    facilityId?: number;
    firstName?: string;
    fullName?: string;
    id?: number;
    jurisdiction?: string;
    lastModifiedBy?: number;
    lastModifiedDate?: any;
    legalName?: string;
    masterAccount?: IAccount;
    naics?: string;
    persons?: IPerson[];
    phone?: string;
    phoneNumber?: string;
    registrationNumber?: string;
    status?: NSAccount.StatusEnum;
    termsAndConditions?: boolean;
    title?: string;
    transitionType?: NSAccount.TransitionTypeEnum;
    userType?: NSAccount.UserTypeEnum;
    version?: number;
    accoutSubtype?: IAccountSubType;
    registrationType?: IRegistrationType;
    accountSubTypes?: string;
    registrationId?: number;
    accountSubTypeActivity?: string;
    englishTradeName?: string;
    frenchTradeName?: string;
    orgBusinessPhone?: string;
    orgBusinessEmail?: string;
    contactPerson?: any;
    civicAddress?: any;
    postalAddress?: any;
  }
  export namespace NSAccount {
    export type StatusEnum =
      | 'NOT_SUBMITTED'
      | 'PENDING_REVIEW_ADMIN'
      | 'REJECTED'
      | 'ACTIVE'
      | 'CLOSED'
      | 'DISCARDED'
      | 'PENDING_KYC'
      | 'LOCKED';
    export const StatusEnum = {
      NOTSUBMITTED: 'NOT_SUBMITTED' as StatusEnum,
      PENDINGREVIEWADMIN: 'PENDING_REVIEW_ADMIN' as StatusEnum,
      REJECTED: 'REJECTED' as StatusEnum,
      ACTIVE: 'ACTIVE' as StatusEnum,
      CLOSED: 'CLOSED' as StatusEnum,
      DISCARDED: 'DISCARDED' as StatusEnum,
      LOCKED: 'LOCKED' as StatusEnum,
      PENDING_KYC: 'PENDING_KYC'  as StatusEnum,
    };
    export type TransitionTypeEnum =
      | 'ACCOUNT_SUBMITTED'
      | 'ACCOUNT_REJECTED'
      | 'ACCOUNT_DISCARDED'
      | 'ACCOUNT_CLOSED'
      | 'ACCOUNT_LOCKED'
      | 'NEED_MORE_INFO'
      | 'ACCOUNT_RECEIVED'
      | 'ACCOUNT_REOPENED'
      | 'ACCOUNT_UPDATE'
      | 'ACCOUNT_UNLOCKED'
      | 'ACCOUNT_APPROVED'
      | 'ACCOUNT_SAVED'
      | 'USER_VALID_LOGIN'
      | 'USER_INVALID_LOGIN'
      | 'USER_PASSWORD_UPDATE'
      | 'USER_UI_CREATE'
      | 'USER_API_CREATE'
      | 'USER_UPDATE'
      | 'USER_SUBMITTED'
      | 'USER_APPROVED'
      | 'USER_REJECTED'
      | 'USER_DISCARDED'
      | 'USER_ACTIVATED'
      | 'USER_DEACTIVATED'
      | 'USER_LOCKED'
      | 'USER_UNLOCKED'
      | 'USER_LOGOUT'
      | 'CREDIT_SUBMITTED'
      | 'CREDIT_APPROVED'
      | 'CREDIT_ISSUED'
      | 'CREDIT_REJECTED'
      | 'CREDIT_PENDING_CONFIRMATION'
      | 'CREDIT_DISCARDED'
      | 'CREDIT_ASSIGNED'
      | 'CREDIT_SUSPENDED'
      | 'CREDIT_DE_SUSPENDED'
      | 'CREDIT_REMITTED'
      | 'CREDIT_UNREMITTED'
      | 'CREDIT_REVOKED'
      | 'CREDIT_RETIRED'
      | 'CREDIT_VOLUNTARILY_CANCELLED'
      | 'OBLIGATION_SUBMITTED'
      | 'OBLIGATION_REJECTED'
      | 'TRANSFER_PEDNING_BUYER'
      | 'TRANSFER_PEDNING_ADMIN'
      | 'TRANSFER_INITIATED'
      | 'TRANSFER_APPROVED'
      | 'TRANSFER_REJECTED_ADMIN'
      | 'TRANSFER_REJECTED_BUYER'
      | 'TRANSFER_FINALIZED'
      | 'PAYMENT_RECEIVED'
      | 'PAYMENT_APPROVED'
      | 'PAYMENT_REJECTED'
      | 'MANUAL_REFUND'
      | 'PAYMENT_ERROR'
      | 'RETIREMENT_PENDING_APPROVAL'
      | 'RETIREMENT_APPROVED'
      | 'RETIREMENT_REJECTED'
      | 'RETIREMENT_REVERSED'
      | 'CANCELLATION_PENDING_APPROVAL'
      | 'CANCELLATION_APPROVED'
      | 'CANCELLATION_REJECTED'
      | 'CANCELLATION_REVERSED';
    export const TransitionTypeEnum = {
      ACCOUNTSUBMITTED: 'ACCOUNT_SUBMITTED' as TransitionTypeEnum,
      ACCOUNTREJECTED: 'ACCOUNT_REJECTED' as TransitionTypeEnum,
      ACCOUNTDISCARDED: 'ACCOUNT_DISCARDED' as TransitionTypeEnum,
      ACCOUNTCLOSED: 'ACCOUNT_CLOSED' as TransitionTypeEnum,
      ACCOUNTLOCKED: 'ACCOUNT_LOCKED' as TransitionTypeEnum,
      NEEDMOREINFO: 'NEED_MORE_INFO' as TransitionTypeEnum,
      ACCOUNTRECEIVED: 'ACCOUNT_RECEIVED' as TransitionTypeEnum,
      ACCOUNTREOPENED: 'ACCOUNT_REOPENED' as TransitionTypeEnum,
      ACCOUNTUPDATE: 'ACCOUNT_UPDATE' as TransitionTypeEnum,
      ACCOUNTUNLOCKED: 'ACCOUNT_UNLOCKED' as TransitionTypeEnum,
      ACCOUNTAPPROVED: 'ACCOUNT_APPROVED' as TransitionTypeEnum,
      ACCOUNTSAVED: 'ACCOUNT_SAVED' as TransitionTypeEnum,
      USERVALIDLOGIN: 'USER_VALID_LOGIN' as TransitionTypeEnum,
      USERINVALIDLOGIN: 'USER_INVALID_LOGIN' as TransitionTypeEnum,
      USERPASSWORDUPDATE: 'USER_PASSWORD_UPDATE' as TransitionTypeEnum,
      USERUICREATE: 'USER_UI_CREATE' as TransitionTypeEnum,
      USERAPICREATE: 'USER_API_CREATE' as TransitionTypeEnum,
      USERUPDATE: 'USER_UPDATE' as TransitionTypeEnum,
      USERSUBMITTED: 'USER_SUBMITTED' as TransitionTypeEnum,
      USERAPPROVED: 'USER_APPROVED' as TransitionTypeEnum,
      USERREJECTED: 'USER_REJECTED' as TransitionTypeEnum,
      USERDISCARDED: 'USER_DISCARDED' as TransitionTypeEnum,
      USERACTIVATED: 'USER_ACTIVATED' as TransitionTypeEnum,
      USERDEACTIVATED: 'USER_DEACTIVATED' as TransitionTypeEnum,
      USERLOCKED: 'USER_LOCKED' as TransitionTypeEnum,
      USERUNLOCKED: 'USER_UNLOCKED' as TransitionTypeEnum,
      USERLOGOUT: 'USER_LOGOUT' as TransitionTypeEnum,
      CREDITSUBMITTED: 'CREDIT_SUBMITTED' as TransitionTypeEnum,
      CREDITAPPROVED: 'CREDIT_APPROVED' as TransitionTypeEnum,
      CREDITISSUED: 'CREDIT_ISSUED' as TransitionTypeEnum,
      CREDITREJECTED: 'CREDIT_REJECTED' as TransitionTypeEnum,
      CREDITPENDINGCONFIRMATION: 'CREDIT_PENDING_CONFIRMATION' as TransitionTypeEnum,
      CREDITDISCARDED: 'CREDIT_DISCARDED' as TransitionTypeEnum,
      CREDITASSIGNED: 'CREDIT_ASSIGNED' as TransitionTypeEnum,
      CREDITSUSPENDED: 'CREDIT_SUSPENDED' as TransitionTypeEnum,
      CREDITDESUSPENDED: 'CREDIT_DE_SUSPENDED' as TransitionTypeEnum,
      CREDITREMITTED: 'CREDIT_REMITTED' as TransitionTypeEnum,
      CREDITUNREMITTED: 'CREDIT_UNREMITTED' as TransitionTypeEnum,
      CREDITREVOKED: 'CREDIT_REVOKED' as TransitionTypeEnum,
      CREDITRETIRED: 'CREDIT_RETIRED' as TransitionTypeEnum,
      CREDITVOLUNTARILYCANCELLED: 'CREDIT_VOLUNTARILY_CANCELLED' as TransitionTypeEnum,
      OBLIGATIONSUBMITTED: 'OBLIGATION_SUBMITTED' as TransitionTypeEnum,
      OBLIGATIONREJECTED: 'OBLIGATION_REJECTED' as TransitionTypeEnum,
      TRANSFERPEDNINGBUYER: 'TRANSFER_PEDNING_BUYER' as TransitionTypeEnum,
      TRANSFERPEDNINGADMIN: 'TRANSFER_PEDNING_ADMIN' as TransitionTypeEnum,
      TRANSFERINITIATED: 'TRANSFER_INITIATED' as TransitionTypeEnum,
      TRANSFERAPPROVED: 'TRANSFER_APPROVED' as TransitionTypeEnum,
      TRANSFERREJECTEDADMIN: 'TRANSFER_REJECTED_ADMIN' as TransitionTypeEnum,
      TRANSFERREJECTEDBUYER: 'TRANSFER_REJECTED_BUYER' as TransitionTypeEnum,
      TRANSFERFINALIZED: 'TRANSFER_FINALIZED' as TransitionTypeEnum,
      PAYMENTRECEIVED: 'PAYMENT_RECEIVED' as TransitionTypeEnum,
      PAYMENTAPPROVED: 'PAYMENT_APPROVED' as TransitionTypeEnum,
      PAYMENTREJECTED: 'PAYMENT_REJECTED' as TransitionTypeEnum,
      MANUALREFUND: 'MANUAL_REFUND' as TransitionTypeEnum,
      PAYMENTERROR: 'PAYMENT_ERROR' as TransitionTypeEnum,
      RETIREMENTPENDINGAPPROVAL: 'RETIREMENT_PENDING_APPROVAL' as TransitionTypeEnum,
      RETIREMENTAPPROVED: 'RETIREMENT_APPROVED' as TransitionTypeEnum,
      RETIREMENTREJECTED: 'RETIREMENT_REJECTED' as TransitionTypeEnum,
      RETIREMENTREVERSED: 'RETIREMENT_REVERSED' as TransitionTypeEnum,
      CANCELLATIONPENDINGAPPROVAL: 'CANCELLATION_PENDING_APPROVAL' as TransitionTypeEnum,
      CANCELLATIONAPPROVED: 'CANCELLATION_APPROVED' as TransitionTypeEnum,
      CANCELLATIONREJECTED: 'CANCELLATION_REJECTED' as TransitionTypeEnum,
      CANCELLATIONREVERSED: 'CANCELLATION_REVERSED' as TransitionTypeEnum,
    };
    export type UserTypeEnum =
      | 'TYPE_MER_ADMIN'
      | 'TYPE_MER_RO_ADMIN'
      | 'TYPE_OBPS_RO_ADMIN'
      | 'TYPE_CUST'
      | 'TYPE_CUST_RO'
      | 'TYPE_VB_MAO';
    export const UserTypeEnum = {
      MERADMIN: 'TYPE_MER_ADMIN' as UserTypeEnum,
      MERROADMIN: 'TYPE_MER_RO_ADMIN' as UserTypeEnum,
      OBPSROADMIN: 'TYPE_OBPS_RO_ADMIN' as UserTypeEnum,
      CUST: 'TYPE_CUST' as UserTypeEnum,
      CUSTRO: 'TYPE_CUST_RO' as UserTypeEnum,
    };
  }

  export interface IAccountPage extends IPageable {
    content: IAccountData[];
  }

  export interface IAccountData {
    authorizedUserId?: number;
    authorizedUserName?: string;
    accountTypeName?: string;
    accountSubType?: IAccountSubType;
    accountSubTypeNames?: string;
    additionalLegalName?: string;
    address?: IAddress;
    businessStructure?: IBusinessStructure;
    charitableFirm?: boolean;
    certificateNumber?: string;
    documents?: IDocument[];
    entityActionList?: IEntityAction[];
    externalId?: number;
    facilityId?: number;
    id?: number;
    jurisdiction?: string;
    legalName?: string;
    naics?: string;
    obligationStatus?: string;
    phone?: string;
    registrationNumber?: string;
    registrationType?: IRegistrationType;
    status?: NSAccountData.StatusEnum;
    termsAndConditions?: boolean;
    totalHoldings?: string;
    user?: IUser;
    userType?: NSAccountData.UserTypeEnum;
    users?: ISaveUser[];
    persons?: IPersons[];
    masterAccountId?: any;
    masterAccountName?: string;
    accountSubTypes?: string;
    postalAddress?: any;
    authorizedOfficial?: any;
    masterAccount?: any;
    subAccountReasonId?: number;
    displayAoProfilePublicView?: boolean;
  }

  export interface IPersons {
    id?: number;
    organizationId?: number;
    accountId?: number;
    name?: string;
    businessNumber?: string;
  }

  export namespace NSAccountData {
    export type StatusEnum =
      | 'NOT_SUBMITTED'
      | 'PENDING_REVIEW_ADMIN'
      | 'PENDING_REVIEW_ADMIN2'
      | 'REJECTED'
      | 'ACTIVE'
      | 'CLOSED'
      | 'DISCARDED'
      | 'LOCKED';
    export const StatusEnum = {
      NOTSUBMITTED: 'NOT_SUBMITTED' as StatusEnum,
      PENDINGREVIEWADMIN: 'PENDING_REVIEW_ADMIN' as StatusEnum,
      REJECTED: 'REJECTED' as StatusEnum,
      ACTIVE: 'ACTIVE' as StatusEnum,
      CLOSED: 'CLOSED' as StatusEnum,
      DISCARDED: 'DISCARDED' as StatusEnum,
      LOCKED: 'LOCKED' as StatusEnum,
      PENDING_REVIEW_ADMIN2: 'PENDING_REVIEW_ADMIN2' as StatusEnum,
    };
    export type UserTypeEnum =
      | 'TYPE_ECCC_ADMIN'
      | 'TYPE_MOP'
      | 'TYPE_CUST'
      | 'TYPE_OBPS_CUST'
      | 'TYPE_CFS_CUST'
      | 'TYPE_CFS_ADMIN'
      | 'TYPE_MER_ADMIN'
      | 'TYPE_MER_CUST';
    export const UserTypeEnum = {
      ECCCADMIN: 'TYPE_ECCC_ADMIN' as UserTypeEnum,
      MOP: 'TYPE_MOP' as UserTypeEnum,
      CUST: 'TYPE_CUST' as UserTypeEnum,
      OBPSCUST: 'TYPE_OBPS_CUST' as UserTypeEnum,
      CFSCUST: 'TYPE_CFS_CUST' as UserTypeEnum,
      CFSADMIN: 'TYPE_CFS_ADMIN' as UserTypeEnum,
      MERADMIN: 'TYPE_MER_ADMIN' as UserTypeEnum,
      MERCUST: 'TYPE_MER_CUST' as UserTypeEnum,
    };
  }

  export interface ICompensationObligation {
    year?: number;
    origObligation?: number;
    obligationToDate?: number;
    outstandingObligation?: number;
    creditsIssued?: number;
    compStatus?: string;
  }

  export interface IRole {
    id?: number;
    name?: string;
  }

  export interface ITandcContent {
    content?: string;
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    effectiveFrom?: string;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    link?: string;
    version?: number;
    policyLink?: any;

    contentEn?: string;
    contentFr?: string;
    createdDate?: any;
  }

  export interface IUser {
    name?: string;
    programId?: number;
    accounts?: IApp.IFacilityNamesLookup[];
    userAccountList?: IFacility[];
    accessToken?: string;
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    documentIds?: number[];
    email?: string;
    firstName?: string;
    fullName?: string;
    id?: number;
    invalidLoginAttempts?: number;
    invalidLoginDate?: string;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    lastName?: string;
    passwordUpdatedOn?: string;
    phoneNumber?: string;
    telephone?: string;
    preferredLanguage?: NSUser.PreferredLanguageEnum;
    role?: IRole;
    status?: NSUser.StatusEnum;
    tandcContents?: ITandcContent[];
    title?: string;
    transitionType?: NSUser.TransitionTypeEnum;
    userName?: string;
    validLoginDateStr?: string;
    version?: number;
    workflowStatusChanged?: boolean;
    // temporary, eventually the service will contain user list of actions
    actionList: IAction[];
    associationsList?: ILinkedAccount[];
    displayAoProfilePublicView?: boolean;
  }

  export interface ISaveUser {
    source?: string;
    accessToken?: string;
    accounts?: IDict[];
    accountId?: number;
    accountIds?: [];
    standardIds?: [];
    documentIds?: number[];
    documents?: IDocument[];
    associationsList?: ILinkedAccount[];
    email?: string;
    firstName?: string;
    fullName?: string;
    id?: number;
    role?: IRole;
    status?: string;
    phoneNumber?: string;
    enableNotification?: boolean;
    userCreateAllowed?: boolean;
    deliveryMedium?: string;
    title?: string;
    subContractingUserType?: string;
    businessTelephone?: string;
    civicAddress?: any;
    entityActionList?:  IEntityAction[];
    registrationLink?: string;
    roleId?: number;
    aoReplacement?: boolean;
  }

  export namespace NSUser {
    export type PreferredLanguageEnum = 'EN' | 'FR';
    export const PreferredLanguageEnum = {
      EN: 'EN' as PreferredLanguageEnum,
      FR: 'FR' as PreferredLanguageEnum,
    };
    export type StatusEnum =
      | 'NOT_SUBMITTED'
      | 'PENDING_REVIEW_ADMIN'
      | 'REJECTED'
      | 'ACTIVE'
      | 'LOCKED'
      | 'DISCARDED';
    export const StatusEnum = {
      NOTSUBMITTED: 'NOT_SUBMITTED' as StatusEnum,
      PENDINGREVIEWADMIN: 'PENDING_REVIEW_ADMIN' as StatusEnum,
      REJECTED: 'REJECTED' as StatusEnum,
      ACTIVE: 'ACTIVE' as StatusEnum,
      LOCKED: 'LOCKED' as StatusEnum,
      DISCARDED: 'DISCARDED' as StatusEnum,
    };
    export type TransitionTypeEnum =
      | 'ACCOUNT_SUBMITTED'
      | 'ACCOUNT_REJECTED'
      | 'ACCOUNT_DISCARDED'
      | 'ACCOUNT_CLOSED'
      | 'ACCOUNT_LOCKED'
      | 'NEED_MORE_INFO'
      | 'ACCOUNT_REOPENED'
      | 'ACCOUNT_UPDATE'
      | 'ACCOUNT_UNLOCKED'
      | 'ACCOUNT_APPROVED'
      | 'USER_VALID_LOGIN'
      | 'USER_INVALID_LOGIN'
      | 'USER_PASSWORD_UPDATE'
      | 'USER_UI_CREATE'
      | 'USER_API_CREATE'
      | 'USER_UPDATE'
      | 'USER_SUBMITTED'
      | 'USER_APPROVED'
      | 'USER_REJECTED'
      | 'USER_DISCARDED'
      | 'USER_ACTIVATED'
      | 'USER_DEACTIVATED'
      | 'USER_LOCKED'
      | 'USER_UNLOCKED'
      | 'USER_LOGOUT'
      | 'CREDIT_SUBMITTED'
      | 'CREDIT_APPROVED'
      | 'CREDIT_REJECTED';
    export const TransitionTypeEnum = {
      ACCOUNTSUBMITTED: 'ACCOUNT_SUBMITTED' as TransitionTypeEnum,
      ACCOUNTREJECTED: 'ACCOUNT_REJECTED' as TransitionTypeEnum,
      ACCOUNTDISCARDED: 'ACCOUNT_DISCARDED' as TransitionTypeEnum,
      ACCOUNTCLOSED: 'ACCOUNT_CLOSED' as TransitionTypeEnum,
      ACCOUNTLOCKED: 'ACCOUNT_LOCKED' as TransitionTypeEnum,
      NEEDMOREINFO: 'NEED_MORE_INFO' as TransitionTypeEnum,
      ACCOUNTREOPENED: 'ACCOUNT_REOPENED' as TransitionTypeEnum,
      ACCOUNTUPDATE: 'ACCOUNT_UPDATE' as TransitionTypeEnum,
      ACCOUNTUNLOCKED: 'ACCOUNT_UNLOCKED' as TransitionTypeEnum,
      ACCOUNTAPPROVED: 'ACCOUNT_APPROVED' as TransitionTypeEnum,
      USERVALIDLOGIN: 'USER_VALID_LOGIN' as TransitionTypeEnum,
      USERINVALIDLOGIN: 'USER_INVALID_LOGIN' as TransitionTypeEnum,
      USERPASSWORDUPDATE: 'USER_PASSWORD_UPDATE' as TransitionTypeEnum,
      USERUICREATE: 'USER_UI_CREATE' as TransitionTypeEnum,
      USERAPICREATE: 'USER_API_CREATE' as TransitionTypeEnum,
      USERUPDATE: 'USER_UPDATE' as TransitionTypeEnum,
      USERSUBMITTED: 'USER_SUBMITTED' as TransitionTypeEnum,
      USERAPPROVED: 'USER_APPROVED' as TransitionTypeEnum,
      USERREJECTED: 'USER_REJECTED' as TransitionTypeEnum,
      USERDISCARDED: 'USER_DISCARDED' as TransitionTypeEnum,
      USERACTIVATED: 'USER_ACTIVATED' as TransitionTypeEnum,
      USERDEACTIVATED: 'USER_DEACTIVATED' as TransitionTypeEnum,
      USERLOCKED: 'USER_LOCKED' as TransitionTypeEnum,
      USERUNLOCKED: 'USER_UNLOCKED' as TransitionTypeEnum,
      USERLOGOUT: 'USER_LOGOUT' as TransitionTypeEnum,
      CREDITSUBMITTED: 'CREDIT_SUBMITTED' as TransitionTypeEnum,
      CREDITAPPROVED: 'CREDIT_APPROVED' as TransitionTypeEnum,
      CREDITREJECTED: 'CREDIT_REJECTED' as TransitionTypeEnum,
    };
  }

  export interface IDict {
    id: number;
    name: string;
    dataId?: number;
  }

  export interface IExpertese {
    id: number;
    name: string;
  }

  export interface IBusinessStructure {
    id: number;
    name: string;
  }

  export interface IFluxDict {
    roleDtos: IDict[];
  }

  export interface IAccountSearchFilter {
    accountTypeList?: number[];
    facilityNameList?: number[];
    facilityNumberList?: number[];
    facilityStatusList?: NSAccountSearchFilter.FacilityStatusListEnum[];
    freeText?: string;
    obligationStatusList?: string[];
  }
  export namespace NSAccountSearchFilter {
    export type FacilityStatusListEnum =
      | 'NOT_SUBMITTED'
      | 'PENDING_REVIEW_ADMIN'
      | 'REJECTED'
      | 'ACTIVE'
      | 'CLOSED'
      | 'DISCARDED'
      | 'LOCKED';
    export const FacilityStatusListEnum = {
      NOTSUBMITTED: 'NOT_SUBMITTED' as FacilityStatusListEnum,
      PENDINGREVIEWADMIN: 'PENDING_REVIEW_ADMIN' as FacilityStatusListEnum,
      REJECTED: 'REJECTED' as FacilityStatusListEnum,
      ACTIVE: 'ACTIVE' as FacilityStatusListEnum,
      CLOSED: 'CLOSED' as FacilityStatusListEnum,
      DISCARDED: 'DISCARDED' as FacilityStatusListEnum,
      LOCKED: 'LOCKED' as FacilityStatusListEnum,
    };
  }

  export class ISort {
    direction: string;
    property: string;
    ignoreCase: boolean;
    nullHandling: string;
    ascending: boolean;
  }

  export interface IPageable {
    totalElements?: number;
    totalPages?: number;
    last?: boolean;
    first?: boolean;
    size?: number;
    number?: number;
    sort?: ISort;
    numberOfElements?: number;
  }

  export interface IPagination {
    page?: number;
    size?: number;
    sort?: string;
    dir?: string;
  }

  export interface IPageableData extends IPageable {
    content: any[];
  }

  export class ISimpleTableData {
    content: any[];

    constructor() {
      this.content = [];
    }
  }

  export class Entity {
    id?: number;

    constructor() {
      this.id = null;
    }
  }
  export class CiEgnergySource {
    entity: Entity;
		name: string;
		quantity: number;

    constructor() {
      this.entity = new Entity();
      this.name = null;
      this.quantity = null;
    }
  }

  export interface IUnitPage extends IPageable {
    content?: IUnitData[];
  }

  export interface IUnitData {
    numberOfCompCreditsProvisionallyCreated?: number;
    numberOfCreditApplied?: number;
    invalidSum?: boolean;
    offeredQuantity?: number;
    id?: number;
    facilityName?: string;
    quantity?: number;
    unitClassCode?: string;
    serialNumber?: string;
    complianceYear?: number;
    remittedComplianceYear?: number;
    status?: string;
    account?: IAccount;
    standardId?: number;
    version?: number;
    issuanceDateStr?: string;
    accountId?: number;
    creditActionId?: number;
    bulkActionId?: number;
    actionType?; string;
    certificateNumber?: string;
    cadPrice?: number;
    obligationId?: number;
    enableDetails?: boolean;
    refundQuantity?: number;
    applicableRate?: number;
    projectProjectId?: string;
    projectName?: string;
    protocol?: string;
    project?: any;
    projectId?: number;
    jurisdiction?: string;
    jurisdictionStr?: string;
    issuanceYear?: any;
    unitClass?: {
      code?: string;
    };
    creditClass?: string;
    creditGroupId?: number;
    creditGroupName?: string;
    creditTypeId?: number;
    creditTypeCode?: string;
    fuelType?: string;
    fuelId?: number;
    organizationName?: string;
    creditCreationReportDetailDto?: any;
    creditActionDto?: any;
    issuedQuantity?: number;
    calculatedVolumeOfLowCiFuel?: number;
    finalCi?: number;
    associatedVolume?: number;
    isEia?: boolean;
  }

  export interface IActivityLog {
    account?: IAccount;
    actionPerformedBy?: IUser;
    date?: Date;
    detail?: string;
    facilityName?: string;
    facilityType?: string;
    id?: number;
    programType?: string;
    transitionType?: NSUser.TransitionTypeEnum;
  }

  export interface IActivityLogPage extends IPageable {
    content: IActivityLog[];
  }
  // for initial set up purposes only
  export interface IDummyPagable extends IPageable {
    content: any[];
  }

  export interface IActivityLogSearchFilter {
    accountTypeList?: number[];
    facilityNameList?: number[];
    facilityNumberList?: number[];
    facilityStatusList?: NSAccountSearchFilter.FacilityStatusListEnum[];
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
  }

  export interface ISubOrganizationFilter {
    accountId?: number;
    discriminatorType?: string;
  }

  export interface IUnitSearchFilter {
    facilityNameList?: number[];
    compliancePeriodList?: number[];
    unitStatusList?: any[];
    freeText?: string;
  }

  export interface IRefundRequestSearchFilter {
    compliancePeriodList?: number[];
    refundMode?: string[];
    refundStatus?: string[];
    freeText?: string;
  }

  export interface IUserSearchFilter {
    freeText?: string;
  }

  export interface IUserTandc {
    accepted?: string;
    deleted?: boolean;
    id?: number;
    tandcId?: number;
    userId?: number;
  }

  export interface ITandcSearchFilter {
    freeText?: string;
    pagination?: IPagination;
  }

  export interface ITermsAndConditionsPage extends IPageable {
    content: ITermsAndConditions[];
  }

  export interface ITermsAndConditions {
    createdBy?: number;
    createdDate?: string;
    deleted?: boolean;
    effectiveFrom?: string;
    id?: number;
    policyLink?: string;
    version?: number;
  }

  export interface IReportFormConfig {
    disabled: boolean;
    showAdminFields: boolean;
    editAdminFields: boolean;
    uploadDisabled: boolean;
    additionalInfoFields?:boolean;
  }

  export interface ICreditForm {
    formName: string;
    buttons: ICreditButtonConfig[];
    documentList: IApp.IDocument[];
    entityId?: number;
    formFields: ICreditFormFields;
    editablePermissions?: string[];
  }

  export interface ICreditFormFields {
        account?: boolean;
        unitType?: boolean;
        quantity?: ICreditFormFieldDetail;
        reason?: boolean;
        reportTypes?: boolean;
        creditSubsections?: boolean;
        accountId?: boolean;
        provisionOfErrorWhereErrorOccurred?: boolean;
        ministerStatement?: boolean;
        complianceYear?: boolean;
        beneficialOwner?: ICreditFormFieldDetail;
        jurisdictionOfBeneficialOwner?: ICreditFormFieldDetail;
        issuanceDate?: boolean;
        cancelledOn?: boolean;
        comments?: ICreditFormFieldDetail;
        discloseRemarksPermission?: string;
        documents?: boolean;
        outstandingObligation?: boolean;
        remittedComplianceYear?: boolean;
        withdraw?: ICreditFormFieldDetail;
        applicableRate?: boolean;
        excessEmissionsCompensationProvidedInTonnes?: boolean;
        project?: boolean;
        issuanceYear?: boolean;
        vintageYear?: boolean;
        jurisdiction?: boolean;
        projectGroup?: boolean;
        reasonForCancellation?: ICreditFormFieldDetail;
        hideDiscloseRemarks?: boolean;
        serialNumber?: boolean;
        fuelType?: boolean;
        unitClassCode?: boolean;
   }

  export interface ICreditFormFieldDetail {
      show: boolean;
      disabledOnExisting: boolean;
      enabledOnExistingPermission?: string;
      label?: string;
      type?: string;
   }

  export interface ICreditFormAction {
    action: string;
  }

  export interface ICreditButtonConfig {
    label: string;
    actionUri: string;
    show?: boolean;
    permission?: string;
    status?: string;
    showOnNew?: boolean;
    className?: string;
    id?: string;
    workflowAction?: string;
    configAction?: string;
  }

  export interface ICreditActionData {
    id?: number;
    creditBlockList?: ICreditActionUnitData[];
    unitIds?: number[];
    unitDtoList?: IUnitData[];
    comments?: string;
    adminRemark?: string;
    discloseComment?: boolean;
    complianceYear?: number;
    documentList?: IDocument[];
    documentIds?: number[];
    jurisdiction?: string;
    [key: string]: any;
    issuanceDateStr?: string;
    respond?: string;
    reasonName?: string;
    accountId?: number;
    facilityName?: string;
    bulkActionId?: number;
    transactionNumber?: string;
    configAction?: string;
    standardId?: number;
    transitionType?: string;
  }

  export interface ICreditActionUnitData {
    unitId?: number;
    quantity?: number;
    facilityName?: string;
    unitClassCode?: string;
    standardId?: number;
    version?: number;
    approvedCliValue?: number;
    selected?: boolean;
    status?: string;
    remittedComplianceYear?: number;
    accountId?: number;
    id?: number;
    withdrawn?: boolean;
    obligationId?: number;
    projectName?: string;
    comments?: string;
    cancellationReason?: string;
    serialNumber?: string;
    complianceYear?: number;
    fuelType?: string;
    fuelId?: number;
    calculatedVolumeOfLowCiFuel?: number;
    creditCreationReportDetailDto?: any;
    province?: string;
    jurisdiction?: string;
    transitionType?: string;
    finalCi?: number;
  }

  export interface FacilityResultVersionSearchFilter {
    certificateNumbers?: string[];
    compliancePeriods?: string[];
    freeText?: string;
    pagination?: IPagination;
    resultTypes?: FacilityResultVersionSearchFilter.ResultTypesEnum[];
    versionNumbers?: number[];
  }
  export namespace FacilityResultVersionSearchFilter {
    export type ResultTypesEnum =
      | 'Annual'
      | 'Corrected'
      | 'Ministers Intervention';
    export const ResultTypesEnum = {
      Annual: 'Annual' as ResultTypesEnum,
      Corrected: 'Corrected' as ResultTypesEnum,
      MinistersIntervention: 'Ministers Intervention' as ResultTypesEnum,
    };
  }

  export interface IFacilityResultVersion {
    approvalDate?: any;
    approved?: boolean;
    certificateNumber?: string;
    compliancePeriod?: number;
    facilityId?: number;
    reportSubmissionDate?: any;
    reportVersionId?: number;
    result?: number;
    resultType?: NSFacilityResultVersion.ResultTypeEnum;
    status?: NSFacilityResultVersion.StatusEnum;
    versionNumber?: number;
  }
  export namespace NSFacilityResultVersion {
    export type ResultTypeEnum =
      | 'Annual'
      | 'Corrected'
      | 'Ministers Intervention';
    export const ResultTypeEnum = {
      Annual: 'Annual' as ResultTypeEnum,
      Corrected: 'Corrected' as ResultTypeEnum,
      MinistersIntervention: 'Ministers Intervention' as ResultTypeEnum,
    };
    export type StatusEnum =
      | 'PENDING_CONFIRMATION'
      | 'APPROVED'
      | 'REJECTED'
      | 'ERROR';
    export const StatusEnum = {
      PENDINGCONFIRMATION: 'PENDING_CONFIRMATION' as StatusEnum,
      APPROVED: 'APPROVED' as StatusEnum,
      REJECTED: 'REJECTED' as StatusEnum,
      ERROR: 'ERROR' as StatusEnum,
    };
  }

  export interface IPageResponseFacilityResultVersion {
    content?: IFacilityResultVersion[];
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    size?: number;
    totalElements?: number;
    totalPages?: number;
  }

  export interface IPaymentInformationSearchFilter {
    complianceYearList?: number[];
    craDocumentImageAddressList?: string[];
    craPaymentIdList?: string[];
    facilityCertificateNumberList?: string[];
    freeText?: string;
    pagination?: IPagination;
    paymentAmountList?: number[];
    paymentDateList?: any[];
    remittanceChannelList?: number[];
    saPostingTimeStampList?: any[];
  }

  export interface IPageResponsePaymentInformation {
    content?: IPaymentInformationSearchFilter[];
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    size?: number;
    totalElements?: number;
    totalPages?: number;
  }

  export interface ICreditSummary {
    totalIssued?: number;
    currentHoldings?: number;
    totalVolunatryCancellations?: number;
    totalPendingOutgoingUnits?: number;
    totalPendingIncomingUnits?: number;
    totalActiveSurplusCredits?: number;
    totalActiveOffsetCredits?: number;
    totalOffsetCreditIssued?: number;
    totalIssuedStr?: string;
    currentHoldingsStr?: string;
    totalVolunatryCancellationsStr?: string;
    totalPendingOutgoingUnitsStr?: string;
    totalPendingIncomingUnitsStr?: string;
    totalActiveSurplusCreditsStr?: string;
    totalActiveOffsetCreditsStr?: string;
    totalOffsetCreditIssuedStr?: string;
  }

  export interface IProgramDeadline {
    submissionDateBenchMark?: number;
    regularRateDeadline?: number;
    increasedRateDeadline?: number;
  }

  export interface IProgramDeadlines {
    [key: string]: IProgramDeadline;
  }

  export interface IAccountObligation {
    outstandingObligation?: number;
    outstandingObligationStr?: string;
    originalCompensation?: number;
    creditsIssued?: number;
    compensationProvidedToDate?: number;
    compensationProvidedToDateStr?: string;
    status?: string;
    overRemitted?: boolean;
    obligationId?: number;
  }

  export interface IAccountObligations {
    [key: string]: IAccountObligation;
  }

  export interface IPerson {
    accountId: number;
    businessNumber: string;
    createdBy: number;
    creationDate: string;
    deleted: boolean;
    id: number;
    lastModifiedBy: number;
    lastModifiedDate: string;
    name: string;
    organizationId: number;
    version: number;
  }

  export interface IResultDetail {
    issued: number;
    revokeIssued: number;
    assigned: number;
    revokeAssigned: number;
    reviewAllowed: boolean;
  }

  export interface ReportSearchFilter {
    accountId?: number;
    compliancePeriodList?: number[];
    endDate?: Date;
    freeText?: string;
    pagination?: IPagination;
    startDate?: Date;
    userId?: number;
  }

  export interface INOESearchFilter {
    provinces?: string;
    creditGroupList?: number[];
    creditCreationReportIds?: number[];
    creditClassList?: number[];
    onlyIssued?: boolean;
  }

  export interface IPageResponseOutstandingCompensation extends IPageable {
    content?: IOutstandingCompensation[];
  }

  export interface IOutstandingCompensation {
    accountId?: number;
    accountOwner?: string;
    accountRemittanceResponse?: IAccountRemittanceResponse;
    certificateNumber?: string;
    compensationProvidedToDate?: number;
    compliancePeriod?: number;
    creditsIssued?: number;
    excessEmissionCharge?: number;
    facilityId?: number;
    facilityName?: string;
    jurisdiction?: string;
    naics?: string;
    originalCompensation?: number;
    outstandingObligation?: number;
    overRemitted?: boolean;
    resultType?: NSOutstandingCompensation.ResultTypeEnum;
    ruRemittanceAmount?: number;
    scrRemittanceAmount?: number;
    status?: NSOutstandingCompensation.StatusEnum;
    versionProvision?: string;
  }
  export namespace NSOutstandingCompensation {
    export type ResultTypeEnum =
      | 'Annual'
      | 'Corrected'
      | 'Minister Intervention';
    export const ResultTypeEnum = {
      Annual: 'Annual' as ResultTypeEnum,
      Corrected: 'Corrected' as ResultTypeEnum,
      MinistersIntervention: 'Minister Intervention' as ResultTypeEnum,
    };
    export type StatusEnum = 'IN_COMPLIANCE' | 'NOT_IN_COMPLIANCE';
    export const StatusEnum = {
      INCOMPLIANCE: 'IN_COMPLIANCE' as StatusEnum,
      NOTINCOMPLIANCE: 'NOT_IN_COMPLIANCE' as StatusEnum,
    };
  }

  export namespace AccountRemittanceResponse {
    export type TypeEnum = 'PAYMENT' | 'SCR' | 'RU';
    export const TypeEnum = {
      PAYMENT: 'PAYMENT' as TypeEnum,
      SCR: 'SCR' as TypeEnum,
      RU: 'RU' as TypeEnum,
    };
  }

  export interface IPaymentConfirmation {
    accountId?: number;
    applicableRate?: number;
    complianceYear?: number;
    containsAmountNotConvertedToTonnes?: string;
    date?: Date;
    dateFormatted?: string;
    facilityId?: number;
    facilityJurisdiction?: string;
    facilityNaicsCode?: string;
    facilityName?: string;
    paymentAmount?: number;
    paymentAmountInTonnes?: number;
    paymentDate?: Date;
    paymentDateFormatted?: string;
    transactionNumber?: string;
    submissionDateITP?: any;
    status?: any;
    paymentType?: any;
    sec58report?: any;
    obligationId: number;
    itpRefNumber?: string;
    intendedPaymentDateStr?: string;
    intendedPaymentAmount?: number;
  }

  export interface IuRemittance {
    accountId?: number;
    accreditedVerifierName?: string;
    adminReviewCompleted?: string;
    applicableRate?: number;
    certificateNumber?: string;
    compliancePeriod?: number;
    effectiveAmount?: number;
    ghgReductionDate?: number;
    ghgReductionDateStr?: string;
    jurisdiction?: string;
    offsetProtocol?: string;
    offsetProtocolVersion?: string;
    projectStartDate?: Date;
    projectStartDateStr?: string;
    provisionCompensation?: string;
    remittanceQuantity?: number;
    serialNumber?: string;
    obligationId: number;
    versionProvisions: IVersionProvision[];
    status: string;
    adminRemarks?: any;
    comments: string;
    actionPerformedBy?: any;
    sec58report?: any;
    facilityName?: any;
    vintage?: any;
  }

  export interface ICredit {
    accountId?: number;
    applicableRate?: number;
    complianceYear?: number;
    creditType?: string;
    currentAccountHolder?: string;
    currentAccountName?: string;
    currentStatus?: string;
    date?: string;
    effectiveAmount?: number;
    facilityNaicsCode?: string;
    facilityName?: string;
    issuanceDate?: string;
    jurisdiction?: string;
    quantity?: number;
    remarks?: string;
    serialNumber?: string;
    transactionNumber?: string;
    vintage?: number;
    obligationId?: any;
    actionPerformedBy?: any;
    sec58report?: any;
    entityActionList?: any[];
  }

  export interface IProperty {
    component?: string;
    deleted?: boolean;
    description?: string;
    id?: number;
    key?: string;
    permission?: number;
    value?: string;
  }

  export interface IDocumentsPage extends IPageable {
    content?: IApp.IDocument[];
  }

  export interface PendingAccountDocuments {
    name?: string;
    documents?: IApp.IDocument[];
    documentPage?: IApp.IDocumentsPage;
    accounts?: IApp.IAccount[];
  }

  export interface IDocumentUploadStatus {
    id?: number;
    status?: string;
  }

  export interface IDocumentInProgress {
    uri: string;
    id?: number;
  }

  export interface IObligationManagement extends IAccountObligation {}

  export interface IObligationManagementPage extends IPageable {
    content: IObligationManagement[];
  }

  export interface RefundMode {
    unusedCadAmount?: number;
    refundModes?: any[];
    obligationIds?: any[];
    maxAllowedCashReturnAmount: number;
    towardsObligation?: boolean;
  }

  export interface IObligation {
    id ?: number;
    obligationId ?: number;
    businessNumber?: string;
    facilityName ?: string;
    versionProvision ?: string;
    certificateNumber ?: string;
    scrRemittanceAmount ?: number;
    ruRemittanceAmount ?: number;
    excessEmissionCharge ?: number;
    accountRemittanceResponse ?: IAccountRemittanceResponse;
    regularRateDeadlineStr ?: string;
    increasedRateDeadlineStr ?: string;
    assignmentDate?: string;
    facilityId ?: number;
    accountOwner ?: string;
    accountId ?: number;
    compliancePeriod ?: number;
    resultType ?: string;
    outstandingObligation ?: number;
    originalCompensation ?: number;
    creditsIssued ?: number;
    compensationProvidedToDate ?: number;
    status ?: string;
    jurisdiction ?: number;
    naics ?: number;
    unusedCadAmount ?: number;
    responsiblePersons ?: any;
    regular ?: boolean;
    increased ?: boolean;
    totalHolding ?: number;
    dueDate  ?: Date;
    dueDateStr?: string;
    overRemitted ?: boolean;
    overPaidQuantity ?: number;
    sapCustomerNumber?: string;
    reportingPeriodEndDate?: string;
    reportingPeriodStartDate?: string;
    entityActionList?: [IEntityAction];
    projectId?: number;
    projectName?: string;
    projectProjectId?: string;
    projectReportingPeriod?: string;
    unusedAmountFromPrevPayment?: number;
  }

  export interface IAccountRemittanceResponse {
    compliancePeriod?: number;
    accountId?: number;
    type?: AccountRemittanceResponse.TypeEnum;
    paymentConfirmationDto?: IPaymentConfirmation[];
    scrRemittanceUnits?: ICredit[];
    ocrRemittanceUnits?: ICredit[];
    ruRemittanceUnits?: IuRemittance[];
    versionProvisions: IVersionProvision[];
    totalQuantity: number;
    totalScrQuantity: number;
    totalRuQuantity: number;
    totalPaymtQuantity: number;
    scrPercentage: number;
    ocrPercentage: number;
    ruPercentage: number;
    paymtPercentage: number;
    remainingPercentage: number;
    overpaidPercentage: number;
  }

  export interface IVersionProvision {
    createdBy: number;
    creationDate: any;
    lastModifiedBy: number;
    lastModifiedDate: any;
    deleted: boolean;
    version: number;
    id: number;
    provision: string;
    type: string;
  }

  export interface IRecognizedUnitPage extends IPageable {
    content: IRecognizedUnit[];
  }

  export interface IRecognizedUnit {
    id?: number;
    obligationId?: number;
    facilityName?: string;
    versionProvision?: number;
    certificateNumber?: number;
    scrRemittanceAmount?: number;
    ruRemittanceAmount?: number;
    excessEmissionCharge?: number;
    accountRemittanceResponse?: IAccountRemittanceResponse;
    regularRateDeadlineStr?: Date;
    increasedRateDeadlineStr?: Date;
    facilityId?: number;
    accountOwner?: number;
    accountId?: number;
    compliancePeriod?: number;
    resultType?: string;
    outstandingObligation?: number;
  }

  export interface IAssessmentResultReport {
    facilityId?: number;
    certificateNumber?: string;
    compliancePeriod?: number;
    id?: number;
    versionNumber?: number;
    result?: number;
    resultType?: string;
    resultTypeName?: string;
    approved?: boolean;
    reportSubmissionDate?: Date;
    approvalDate?: Date;
    reportSubmissionDateStr?: string;
    approvalDateStr?: string;
    status?: string;
    obligations?: IObligation[];
  }

  export interface IRefundRequestPage extends IPageable {
    content: IRefundRequest[];
  }

  export interface IRefundRequest {
    id: number;
    obligationId: number;
    compliancePeriod: number;
    transcationNumber: string;
    facilityName: string;
    certificateNumber: string;
    refundMode: string;
    overPaidQuantity: number;
    towardsObligationId: number;
    amountRequested: number;
    actualRefundedQuantity: number;
    equivalentPaymentAmount: number;
    adminRemarks: number;
    approvedBy: string;
    approvedOn: string;
    submittedOn: string;
    requestedBy: string;
    status: string;
    entityName: string;
    ccDto: any;
    attachmentDto: any;
  }

  export interface ItpPayment {
    id?: number;
    obligationId?: number;
    facilityName?: string;
    certificateNumber?: string;
    intendedPaymentDate?: string;
    actualPaymentDate?: string;
    actualPaymentAmount?: number;
    paymentAmountInTonnes?: number;
    paymentDateStr?: string;
    intendedCompensationAmount?: number;
    discloseComment?: boolean;
    adminRemarks?: string;
    showRegular181errorMessage?: boolean;
    showRegularIncreasedMessage?: boolean;
    showActual181errorMessage?: boolean;
    showActualIncreasedMessage?: boolean;
    regularRateDeadlineStr?: string;
    intendedPaymentAmount?: number;
    outstandingObligation?: number;
    compliancePeriod?: number;
    accountId?: number;
    responsiblePersons?: string;
    unusedAmountFromPrevPayment?: number;
    businessNumber?: string;
    outstandingQuantity?: number;
    increasedRateDeadlineStr?: string;
    projectReportingPeriod?: string;
    projectProjectId?: string;
    projectName?: string;
    accountOwner?: string;

    status?: string;
    intentToPayForm?: IApp.IDocument;
    refundAmount?: number;
    refundAmountInTonnes?: number;
    transactionNumber?: string;
    paymentType?: string;
    requestJsonId?: number;
    _applicableRateStr?: string;
    _chargePerTonneStr?: string;
    _excessEmissions?: number;
  }

  export interface IUploadOBPSResults extends IPageable {
    content: UploadOBPSResults[];
  }

  export interface UploadOBPSResults {
    fileName?: string;
    fileUploadedOn?: string;
    fileUploadedBy?: string;
    fileLastReviewedOn?: string;
    fileLastApprovedBy?: string;
    status?: string;
    view?: string;
  }

  export interface IBulletinBoardBidList extends IPageable {
    content: IBulletinBoardBid[];
  }

  export interface IBulletinBoardBid {}

  export interface IBulletinBoardOfferList extends IPageable {
    content: IBulletinBoardOffer[];
  }

  export interface IBulletinBoardOffer {}

  export interface IsectionReport58 {
    personName: string;
    facilityName: string;
    certificateNumber: string;
    provision: string;
    quantity: number;
    creditBlockList: Isection58TableContent[];
    pdfFooter: IPdfFooter;
    applicableRateDescription?: string;
  }

  export interface IPdfFooter {
    correctedBy: string;
  }

  export interface Isection58Table {
    content?: Isection58TableContent[];
  }

  export interface Isection58TableContent {
    quantity?: number;
    transactionRemittancrDate?: string;
    remittanceTransactionNumber?: number;
    serialNumber?: number;
    unitIssueDate?: string;
  }
  export interface IBidsList {
    bids: IBid[];
  }

  export interface IBid {
    certificateNumber?: number;
    accountName?: string;
    unitType?: string;
    unitCompliancePeriod?: number;
    quantity?: number;
    unitPrice?: number;
  }

  export interface OMPAccountType {
    accountSubType?: IAccountSubType;
    registrationType?: IRegistrationType;
  }

  export interface IUploadOBPSResultFilter {
    status?: any;
  }

  export interface ILinkedAccount {
    id?: number;
    isNew?: boolean;
    roleId?: number;
    programId?: number;
    program?: string;
    accountId?: number;
    status?: string;
    actionList?: IEntityAction[];
    entityActionList?: IEntityAction[];
    permissions?: string[];
    role?: string;
    accountDto?: IAccount;
    userStatus?: string;
    projectNames?: string;
    accountLevel?: string;
    masterAccountId?: number;
  }

  export interface IApplicationCompressions {
    id?: number;
    name?: string;
    entity?: any;
    entityId?: number;
    actionList?: IEntityAction[];
    isNew?: boolean;
  }

  export interface IAuthSAMLProvider {
    id?: number;
    name?: string;
    clientId?: number;
    url?: string;
    enabled?: boolean;
    displayIssueText?: boolean;
    issueTextKey?: string;
    callbackUrl?: string;
    logoutUrl?: string;
    idp?: boolean;
    cookieDomain?: string;
  }

  export type TaskTypeEnum =
    'ACCOUNT_REVIEW' |
    'OBPS_RESULT_REVIEW' |
    'EEC_PAYMENT_REMITTANCE' |
    'SURPLUS_CREDIT_REMITTANCE' |
    'RECOGNIZED_UNIT_REMITTANCE' |
    'REFUND_REQUEST_REVIEW' |
    'EEC_PAYMENT_MANUAL_REVIEW' |
    'TRANSFER' |
    'CANCELLATION' |
    'REVIEW_CREDIT_SUSPENSION' |
    'PROCESS_INTENT_TO_PAY_ADMIN';

  export interface ITask {
    participantComments?: string;
    projectPid: any;
    id?: number;
    taskType?: TaskTypeEnum;
    accountName?: string;
    participantOrganizationName?: string;
    createdOn?: string;
    outstandingObligation?: string;
    status?: string;
    applicationStatus?: string;
    assignedTo?: number;
    accountId?: number;
    masterAccountId?: number;
    userType?: NSAccount.UserTypeEnum;
    entityType?: NSDocument.EntityTypeEnum;
    entityId?: number;
    complianceYear?: number;
    compliancePeriod?: number;
    quantity?: number;
    transferQuantity?: number;
    comments?: string;
    dateInitiatedOn?: string;
    sourceAccountId?: number;
    destinationAccountId?: number;
    obligationId?: number;
    compensationOutstandingObligation?: string;
    obligationAssignmentDate?: string;
    regularRateDeadline?: string;
    increasedRateDeadline?: string;
    assignmentDate?: string;
    isRead?: boolean;
    auditablePermissionName?: string;
    unitClassCode?: string;
    itpRefNumber?: string;
    intendedPaymentDateStr?: string;
    intendedPaymentAmount?: string;
    submissionType?: string;
    activity?: string;
    entityJson?: any;
    entityActionList?: [IEntityAction];
    notes?: string;
    verificationViewDto?: any;
    taskTypeTranslated?: any;
    reportingPeriod?: any;
    projectReportId?: any;
    projectType?: string;
    noenmiComments?: any;
    nmiComments?: any;
    vbIdentifier?: any;
    accountSubTypes?: string;
    quantityOfCreditsToBeCancelled?: number;
    insufficientCreditId?: any;
  }

  export interface ITableList extends IPageable {
    content: any[];
  }

  export interface ITasksList extends IPageable {
    content: ITask[];
  }

  export interface IReportList extends IPageable {
    content: any;
  }

  export interface ITaskResultFilter {
    freeText?: string;
    taskTypeList?: [TaskTypeEnum];
  }

  export interface IWireTransferDetails {
    [p: string]: string;
  }

  export interface IRegistrationOwnerCheck {
    required: boolean;
    label?: string;
  }

  export namespace NSAccountSubType {
    export enum StatusEnum {
      PS = 'PS',
      RC = 'RC',
      VB = 'VB',
      FS = 'FS',
      CIC = 'CIC',
    };
  }

  export namespace NSActivityCode {
    export type StatusEnum =
      | 'PRODFF'
      | 'IMPFF'
      | 'ERP'
      | 'PRODCI'  | 'IMPLCI'| 'EUFS' | 'BPE' | 'RESCH'| 'NRESCH' |  'VFB'|  'VLB'|  'VFBVLB' | 'PLCIMIE';
    export const StatusEnum = {
      PRODFF: 'PRODFF' as StatusEnum,
      IMPFF: 'IMPFF' as StatusEnum,
      ERP: 'ERP' as StatusEnum,
      PRODCI: 'PRODCI' as StatusEnum,
      IMPLCI: 'IMPLCI' as StatusEnum,
      EUFS: 'EUFS' as StatusEnum,
      RESCH: 'RESCH' as StatusEnum,
      NRESCH: 'NRESCH' as StatusEnum,
      BPE: 'BPE' as StatusEnum,
      VFB: 'VFB' as StatusEnum,
      VLB: 'VLB' as StatusEnum,
      VFBVLB: 'VFBVLB' as StatusEnum,
      PLCIMIE: 'PLCIMIE' as StatusEnum,
    };
  }

  export namespace NSEntityType {
    export type EntityTypeEnum =
      | 'FACILITY'
      | 'EQUIPMENT'
      | 'PROVINCE'
      | 'ACCREDITATION_BODY'  | 'IMPLCI'| 'EUFS' | 'BPE' | 'RESCH'| 'NRESCH' | 'NonResidential' | 'FuelingStation';
    export const EntityTypeEnum = {
      FACILITY: 'FACILITY' as EntityTypeEnum,
      EQUIPMENT: 'EQUIPMENT' as EntityTypeEnum,
      PROVINCE: 'PROVINCE' as EntityTypeEnum,
      ACCREDITATION_BODY: 'ACCREDITATION_BODY' as EntityTypeEnum,
      IMPLCI: 'IMPLCI' as EntityTypeEnum,
      EUFS: 'EUFS' as EntityTypeEnum,
      NonResidential: 'NonResidential' as EntityTypeEnum,
      FuelingStation: 'FuelingStation' as EntityTypeEnum,
    };
  }

  export interface IRegistrationEntityOptions {
    addNew: boolean;
    addExisting: boolean;
    chooseFromOwn: boolean;
    noFuel?: boolean;
  }

  export interface IRegistrationEntityType {
      facilityName: boolean;
      provinces?: boolean;
      prefix?: string;
      hideQuery?: boolean;
      entityType?: string;
      entityTypes?: string[];
      ownerCheck?: IRegistrationOwnerCheck;
      showExisting?: boolean;
      options?: IRegistrationEntityOptions;
      postalAddress: boolean;
      fuelTypes: IFuelType[];
      contactPerson: boolean;
      description: boolean;
      accountSubType: NSAccountSubType.StatusEnum;
      activityCode: NSActivityCode.StatusEnum;
      disabled?: boolean;
      ignoreDefaultCountry?: boolean;
      disableCountryField?: boolean;
      skipCountries?: ISkipAddressCountries;
      validateCompleteness?: boolean;
      postalCodeNotRequired?: boolean;
      activitiesAllowed?: boolean;
      countrySrc?: number;
      mapMissingFn?: any;
  }

  export interface ISkipAddressCountries {
    [key: string]: string[];
  }

  export interface IFuelType {
    addMore?: boolean;
    path?: string;
    dropdown?: boolean;
    checkboxLabel?: string;
    hidden?: boolean;
    byType?: boolean;
    optional?: boolean;
    forActivity?: boolean;
    label?: string;
  }
  export interface IAddressConfig {
      label?: string;
      key: string;
      prefix: string;
      hidePoBox: boolean;
      optionalPoBox?: boolean;
      hideLatLong: boolean;
      requiredLatLong?: boolean;
      showAddressList?: boolean;
      changeExpr?: string;
      hidden?: boolean;
      disabled?: boolean;
      sameAsOrganization?: ISameAddressConfig;
      sameAsPostal?: ISameAddressConfig;
      ignoreDefaultCountry?: boolean;
      disableCountryField?: boolean;
      skipCountries?: string[];
      postalCodeNotRequired?: boolean;
      formDisabled?: boolean;
      formReadOnly?: boolean;
      privinceData?: string[];
      index?: string;
      sameAsPostalAddressKey?: string;
      sameAsPostalAddressLabel?: string;
      sameAsPostalAddressDisabled?: any;
      provinceAsFreeTextExpr?: string;
      provinceStateLabel ?: string;
      postalCodeLabel?: string;
      optional?: boolean;
      countrySrc?: number;
      countryDataType?: string;
      mapMissingFn?: any;
      className?: string;
      onCountryListLoad?: any;
      tooltip?:string
  }

  export interface ISameAddressConfig {
    hide: any;
    changeExpr?: string;
    spacer?: boolean;
  }

  export interface IActionMap {
    [key: string]: {
      message?: string;
      editable?: boolean;
      status?: string;
      rrStatus?: string;
      duStatus?: string;
      caStatus?: string;
    };
  }

  export interface ITableAction {
    [key: string]: {
      action?: string;
      uri?: string;
      uiUri?: string;
      actionLabel?: string;
      confirmationMessage?: string;
      submissionType?: string;
      prefix?: string;
      id?: string;
      status?: string;
      type?: string;
      endpoint?: string;
      customAction?: boolean;
    };
  }

  export interface ISubActionMap {
    [key: string]: {
      message?: string;
      editable?: boolean;
      status?: string;
      code?: string;
    };
  }

  export interface IUserFieldMap {
    [key: string]: IUserFields;
  }

  export interface IFormConfig {
    hidden?: boolean;
    required?: boolean;
    disabled?: boolean;
    label?: string;
  }

  export interface IFormAddressConfig extends IFormConfig {
    provinceStateLabel?: string;
    postalCodeLabel?: string;
    hidePoBoxCivic?: boolean;
    civicAddressLabel?: string;
    postalAddressLabel?: string;
    sameAsPostalLabel?: string;
  }
  export interface IUserFields {
      title: IFormConfig;
      fullName: IFormConfig;
      email: IFormConfig;
      businessTelephone: IFormConfig;
      faxNumber?: IFormConfig;
      professionalQualification: IFormConfig;
      address: IFormAddressConfig;
      uploadDocuments: IFormConfig;
      linkedAccountsText: IFormConfig;
      phoneNumber?: IFormConfig;
      deliveryMedium?: IFormConfig;
  }

  export class IFacilityEquipmentPage {
    content: any[];

    constructor() {
      this.content = [];
    }
  }
  export interface ISubmissionManagementPage extends IPageable {
    content: ISubmissionManagement[];
  }

  export interface ISubmissionManagement {
    opaAccountId?: number;
    entityActionList?: IEntityAction[];
    submissionType?: string
  }

  export interface IVerificationReportPage extends IPageable {
    content: ISubmissionManagement[];
  }

  export interface IApplication {
    id?: number;
    applicationId?: number;
    fuelId?: number;
    activityId?: number;
    submittedBy?: number;
    registrationAccountId?: number;
    accountId?: number;
    status?: string;  // todo change to enum
    type?: string;  // todo change to enum
    formType?: string;  // todo change to enum
    regulatoryRegime?: string;
    submittedOn?: number;
    quantificationMethod?: any;
    contactPerson?: any;
    startDate?: number;
    endDate?: number;
    liquidComplianceCredit?: number;
    solidComplianceCredit?: number;
    gaseousComplianceCredit?: number;
    fundingBasedOn?: number;
    version?: number;
    applicationTypeFieldForApplicationId?: number;
  }

  export class DownloadPayload {
    id?: number;
    accountId?: number;

    constructor() {
      this.id = null;
      this.accountId = null;
    }
  }

  export interface CacheMap {
    [key: string]: Observable<IApp.ILookup[]>;
  }

  export namespace Verification {

    export type StatusEnum =
      | 'READY_FOR_VERIFCATION'
      | 'UNDER_VERIFICATION'
      | 'UNDER_PATICIPANT_REVIEW'
      | 'UNDER_VERIFICATION_IR_REVIEW'
      | 'VERIFICATION_COMPLETE'
      | 'UNDER_VERIFICATION_PVB_IR_REVIEW'
      | 'UNDER_VERIFICATION_PVB_LV_REVIEW';
    export const StatusEnum = {
      READY_FOR_VERIFCATION: 'READY_FOR_VERIFCATION' as StatusEnum,
      UNDER_VERIFICATION: 'UNDER_VERIFICATION' as StatusEnum,
      UNDER_PATICIPANT_REVIEW: 'UNDER_PATICIPANT_REVIEW' as StatusEnum,
      UNDER_VERIFICATION_IR_REVIEW: 'UNDER_VERIFICATION_IR_REVIEW' as StatusEnum,
      VERIFICATION_COMPLETE: 'VERIFICATION_COMPLETE' as StatusEnum,
      UNDER_VERIFICATION_PVB_IR_REVIEW: 'UNDER_VERIFICATION_PVB_IR_REVIEW' as StatusEnum,
      UNDER_VERIFICATION_PVB_LV_REVIEW: 'UNDER_VERIFICATION_PVB_LV_REVIEW' as StatusEnum,
    };
  }

  export namespace Agreement {

    export type StatusEnum =
      | 'IN_PROCESS'
      | 'PENDING_OPA_APPROVAL'
      | 'PENDING_RCA_APPROVAL'
      | 'PENDING_AGREEMENT_UPLOAD'
      | 'ACTIVE'
      | 'UNDER_VERIFICATION_PVB_IR_REVIEW'
      | 'UNDER_VERIFICATION_PVB_LV_REVIEW';
    export const StatusEnum = {
      IN_PROCESS: 'IN_PROCESS' as StatusEnum,
      PENDING_OPA_APPROVAL: 'PENDING_OPA_APPROVAL' as StatusEnum,
      PENDING_RCA_APPROVAL: 'PENDING_RCA_APPROVAL' as StatusEnum,
      PENDING_AGREEMENT_UPLOAD: 'PENDING_AGREEMENT_UPLOAD' as StatusEnum,
      ACTIVE: 'ACTIVE' as StatusEnum,
      UNDER_VERIFICATION_PVB_IR_REVIEW: 'UNDER_VERIFICATION_PVB_IR_REVIEW' as StatusEnum,
      UNDER_VERIFICATION_PVB_LV_REVIEW: 'UNDER_VERIFICATION_PVB_LV_REVIEW' as StatusEnum,
    };
  }

  export interface ICancellationComment {
    comment: string;
    commentedOn: number;
    isAddedByAdmin: boolean;
    commentedByUser?: string;
    commentedByUserId?: number;
    id?: number;
  }

  export interface IRegistrationDiscussionComment {
    comment: string;
    commentTimestampStr?: string;
    accountId?: number;
  }

  export interface IRegistrationDiscussion {
    discussionId?: number;
    accountId?: number;
    userComment?: string;
    userCommentTimestampStr?: string;
    comments?: IRegistrationDiscussionComment[];
  }

  export interface IProjectDetails {
    id: number;
    projectId: string;
    type: string;
    name: string;
    legalName: string;
    masterProjectName: string;
    reason: string;
    status?: string;
    cancellationReasonId?: number;
    adminCancellationRemark?: string;
    additionalRemark?: string;
    adminInternalRemark?: string;
    discloseComment?: boolean;
  }

  export interface IProjectNameList {
    [accountId: string]: string;
  }

  export interface IReportType {
    name: string;
    value: string;
  }

  export interface IDescFieldItem {
    key?: string;
    template?: string;
    translate?: boolean;
    label: string;
  }

  export interface ILinkedAccountUpdate {
    items: IApp.ILinkedAccount[];
    refresh?: boolean;
  }

  export interface IAdminTakeAction {
    id: number;
    accountId?: number;
    adminRemark?: string;
    userComment?: string;
    itpAdminRemarks?: string;
  }

  export interface IModalData {
    model: any;
    formConfig: IRawForm[] | IRawFormBase[];
    title: string;
    customActionBtn?: string;
    primaryActionBtn?: string;
  }

  export interface IModalCloseResult {
    model?: any;
    customAction?: boolean;
    additionalAction?: boolean;
  }

  export interface IOVBDetails {
    assignedComponentDetails?: string;
    civicAddress?: string;
    englishTradeName?: string;
    facilities?: any[]
    frenchTradeName?: string;
    id?: number;
    legalName?: string;
    orgBusinessEmail?: string;
    orgBusinessPhone?: string;
    postalAddress?: string;
    registrationNumber?: string;
    status?: string;
    teamMemberIds?: number[];
    totalAssignmentUndertakenPercentage?: number;
    transitionType?: string;
    verificationTeamMembers?: any[];
    vbAccountName?: string;
    selectedIR?: string;
    submissionType?: string;
    organizationName?: string;
    submissionId?: number;
  }

}
