import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { validateStep } from '@shared/decorators/validate-step.decorator';
import { AppInjector } from '@shared/services/app-injector.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { FormService } from '@shared/services/form.service';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StepAnchorDirective } from '../steps/directive/step-anchor.directive';
import * as moment from 'moment-timezone';
import { NavigationService } from '@shared/services/navigation.service';
import { from } from 'rxjs';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { CFR_PROPOSE_RR_ACTIONS } from '@module/cfr-registration/cfr-registration.const';
const DATE_FORMAT = 'YYYY-MM-DD';
const TIMEZONE = 'America/New_York';
export class BaseStepperComponent {
    constructor(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this._destroy$ = new Subject();
        this._componentRef = null;
        this.viewReady = false;
        const injector = AppInjector.getInjector();
        this.router = injector.get(Router);
        this._location = injector.get(Location);
        this.helperService = injector.get(HelperService);
        this.storeService = injector.get(StoreService);
        this._svc = injector.get(BaseStepperService);
        this.modalService = injector.get(ModalService);
        this.translate = injector.get(TranslateService);
        this.componentFactoryResolver = injector.get(ComponentFactoryResolver);
        this.navigationService = injector.get(NavigationService);
        this.formService = injector.get(FormService);
        this.uploadDocumentService = injector.get(UploadDocumentService);
        this.documentUploadInProgress = injector.get(DocumentUploadInProgressService);
        if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.service) {
            this.service = injector.get(this.activatedRoute.snapshot.data.service);
            this.entryComponentsMap = this.activatedRoute.snapshot.data.entryComponentsMap;
        }
        else {
            throw new Error('No service provided in route data');
        }
        if (history.state.data && history.state.data.inputData) {
            this.service.inputData = history.state.data.inputData;
            if (history.state.data.history) {
                this.service.disable();
                this._svc.disable();
                this.service.currentData = history.state.data.inputData;
                this.service.inputData.history = true;
            }
            this.steps = history.state.data.steps;
            this.footerButtonConfig = history.state.data.buttons;
            if (this.service.disabled && this.footerButtonConfig) {
                this.footerButtonConfig.showSave = false;
                this.footerButtonConfig.showSubmit = false;
            }
            this.initStepper();
            this.setCurrentComponent();
        }
    }
    set formContainer(formContainer) {
        if (formContainer) {
            this.scrollableFormContainer = formContainer;
            this.initStepper();
        }
    }
    set content(content) {
        if (content) {
            this.appStepAnchor = content;
        }
    }
    ngOnInit() {
    }
    initStepper() {
        this.viewReady = true;
        this.service.init(this.steps, this.scrollableFormContainer);
        this.service.extraSteps = this.extraSteps;
        this._svc.scrollEl = this.scrollableFormContainer;
    }
    get registrationSteps() {
        return this.service.steps;
    }
    onStepChange(step) {
        this.confirmOnClose().subscribe(result => {
            if (result) {
                const currentData = this.service.prevData;
                const mainStep = this.service.getStepById(step.id);
                this.service.currentStep = {
                    id: step.id,
                    subId: step.subId,
                    data: currentData,
                };
                if (!step.subId && mainStep.skipMainStep && mainStep.enabledSubSteps) {
                    step.subId = 1;
                }
                if (step.subId) {
                    if (!mainStep.enabledSubSteps) {
                        this.service.currentStep = {
                            id: step.id,
                            subId: null,
                            data: currentData,
                        };
                    }
                }
                const stepSrc = this.service.getStepById(step.id, step.subId, false);
                if (stepSrc.inputData) {
                    this.service.inputData = Object.assign({}, this.service.inputData, stepSrc.inputData);
                }
            }
        });
    }
    get currentStep() {
        return this.service.currentStep;
    }
    set currentStep(val) {
        this.service.currentStep = val;
    }
    get isLastStep() {
        return this.service.isLastStep;
    }
    get subStepsChooserSelected() {
        return this.service.subStepsChooserSelected;
    }
    get isFirstStep() {
        return this.currentStep.id === 1 && (!this.currentStep.subId || this.currentStep.subId === 1);
    }
    get isSingleStep() {
        return this.service.steps.length === 1;
    }
    nextStep() {
        this.service.nextStep();
    }
    prevStep() {
        this.confirmOnClose().subscribe(result => {
            if (result) {
                this.service.prevStep(this.service.prevData);
            }
        });
    }
    saveAsDraft() {
        console.log('saveAsDraft');
    }
    setCurrentComponent() {
        this.service.currentStep$
            .pipe(takeUntil(this._destroy$), distinctUntilChanged((x, y) => {
            return y['id'] === x['id'] && y['subId'] === x['subId'];
        }))
            .subscribe(d => this.getComponent(this.service.stepComponent));
    }
    getComponent(component) {
        setTimeout(() => {
            const factories = Array.from(this.componentFactoryResolver['_factories'].values());
            const factoryClass = factories.find((_component) => typeof component === 'string'
                ? _component.componentType.key === component
                : _component.componentType.key === component.key);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponentsMap[factoryClass.componentType.key]);
            if (this.appStepAnchor) {
                const viewContainerRef = this.appStepAnchor.viewContainerRef;
                viewContainerRef.clear();
                this._componentRef = viewContainerRef.createComponent(componentFactory);
                this._componentRef.instance._svc = this._svc;
            }
        });
    }
    get currentStepComponent() {
        return this._componentRef.instance;
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
        this.service.resetStepper();
    }
    close() {
        if (!this.isDirty || this.service.disabled || this.currentStep.disableMessageOnClose) {
            this.service.resetStepper();
            this.returnPreviousLocation(null);
        }
        else {
            this.confirmOnClose().subscribe((result) => {
                if (result) {
                    this.service.resetStepper();
                    this.returnPreviousLocation(null);
                }
            });
        }
    }
    returnPreviousLocation(event) {
        event && event.preventDefault();
        if (this.footerButtonConfig && this.footerButtonConfig.onCloseRedirectUrl) {
            this.storeService.clearLastVisitedUrl();
            this.navigationService.removeLast();
            this.router.navigate(this.helperService.getTranslatedPath(this.footerButtonConfig.onCloseRedirectUrl));
        }
        else {
            this.navigationService.back();
        }
    }
    executeAction(value, payload) {
        console.log('execute action');
        this.service.doAction(payload.uri, payload).pipe(takeUntil(this._destroy$)).subscribe(() => {
            if (value.workflowAction !== 'SAVE_ADMIN_REMARKS' && value.workflowAction !== 'SAVE_COMMENT') {
                this._location.back();
            }
        }, error => this._svc.onError$.next(error));
    }
    triggerActionController(value, defaultMessage, copyEntityId = true) {
        const mapData = this.actionsList[value.workflowAction];
        const payload = Object.assign({}, this.service.currentData, mapData);
        if (mapData && mapData.uiUri) {
            payload.id = this.service.currentData[mapData.id];
            payload.status = this.service.currentData[mapData.status];
            this.router.navigate(this.helperService.getTranslatedPath(mapData.uiUri), { state: { data: payload } });
        }
        else if (mapData && mapData.executeCallback) {
            this.service.doAction(value);
        }
        else if (value.workflowAction === 'DOWNLOAD_APPLICATION_PDF') {
            this.downloadApplicationPDF(this.service.currentData[mapData.id]);
        }
        else if (value.workflowAction === 'APPROVE_PROPOSED' || value.workflowAction === 'REJECT_PROPOSED') {
            const msg = 'REGISTRATION_ACCOUNT_PROPOSED_UPDATE_' + value.workflowAction;
            this.service.openConfirmationModal(msg)
                .subscribe(result => {
                if (result) {
                    this.service.proposedRegistrationUpdateHandler({
                        id: this.service.currentData.id,
                        accountId: this.service.currentData.accountId,
                        discriminatorType: 'REGISTRATION_ACCOUNT'
                    }, CFR_PROPOSE_RR_ACTIONS[value.workflowAction]).subscribe(() => this.ngOnInit());
                }
            });
        }
        else if (value.workflowAction === 'VIEW_VERIFICATION_REPORT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
        }
        else {
            const confirmationMessage = mapData.confirmationMessage ? mapData.confirmationMessage : defaultMessage;
            const defaultLabel = this.translate.instant(`COMMON.actionsLabel.${value.workflowAction}`);
            const actionLabel = mapData.actionLabel ? this.translate.instant(`COMMON.actionsLabel.${mapData.actionLabel}`) : defaultLabel;
            payload.id = copyEntityId ? payload.entityId : payload.id;
            this.modalService.open(ServiceMessageComponent, {
                messages: null,
                message: confirmationMessage,
                metaDataList: [
                    actionLabel,
                    this.translate.instant(`COMMON.submissionTypeList.${this.service.currentData.submissionType}`),
                    this.service.currentData.legalName,
                    this.translate.instant(`VERIFICATION_MANAGEMENT_MODULE.report.${this.service.currentData.vbIdentifier}`),
                ],
                type: "WARNING" /* WARNING */,
            }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
                if (result) {
                    this.executeAction(value, payload);
                }
            });
        }
    }
    downloadApplicationPDF(id) {
        const uri = this.service.getDownloadApplicationUrl(id);
        this.uploadDocumentService.downloadDocument(uri);
    }
    get entityActions() {
        return this.service.currentData.entityActionList;
    }
    get maxStep() {
        return this.service.getMaxStep();
    }
    currentDate() {
        return moment().local().tz(TIMEZONE).format(DATE_FORMAT);
    }
    get isDirty() {
        return this.service.isDirty;
    }
    initModelChangeTracking(data, fieldsToOmit = null, wasModified = false) {
        if (fieldsToOmit) {
            this.service.fieldsToOmit = fieldsToOmit;
        }
        if (data) {
            this.service.currentData = JSON.parse(JSON.stringify(data));
        }
        this.service.initModelChangeTracking(wasModified);
    }
    get submitButtonEnabled() {
        return true;
    }
    get uploadInProgress$() {
        return this.documentUploadInProgress.inProgress$;
    }
    get wasModified() {
        return this.service.wasModified;
    }
    checkForSkip() {
        const step = this.currentStep;
        if (step.skipMainStep && step.enabledSubSteps && step.subSteps.length) {
            const stepSrc = this.service.getStepById(step.id, 1, false);
            if (stepSrc.inputData) {
                this.service.inputData = Object.assign({}, this.service.inputData, stepSrc.inputData);
            }
            this.currentStep = Object.assign({}, stepSrc, { data: step.data });
        }
    }
    confirmOnClose() {
        if (!this.isDirty || this.service.disabled) {
            return from([true]);
        }
        return this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: 'confirmOnClose',
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
}
tslib_1.__decorate([
    validateStep()
], BaseStepperComponent.prototype, "nextStep", null);
tslib_1.__decorate([
    validateStep()
], BaseStepperComponent.prototype, "triggerActionController", null);
