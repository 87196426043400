/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-transfer-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../back-btn/back-btn.component.ngfactory";
import * as i3 from "../../back-btn/back-btn.component";
import * as i4 from "../../../services/navigation.service";
import * as i5 from "../../../pipes/custom-translate.pipe";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../simple-table-wrapper/simple-table-wrapper.component.ngfactory";
import * as i8 from "../../simple-table-wrapper/simple-table-wrapper.component";
import * as i9 from "@angular/common/http";
import * as i10 from "../../../../core/store/store.service";
import * as i11 from "../../../../core/services/lookup.service";
import * as i12 from "../../../services/modal.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i15 from "@ngx-formly/core";
import * as i16 from "@angular/forms";
import * as i17 from "./project-transfer-list.component";
import * as i18 from "../../back-btn/backbtnservice.service";
import * as i19 from "../../../services/table-template-controls.service";
import * as i20 from "../../../services/helper.service";
var styles_ProjectTransferListComponent = [i0.styles];
var RenderType_ProjectTransferListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectTransferListComponent, data: {} });
export { RenderType_ProjectTransferListComponent as RenderType_ProjectTransferListComponent };
export function View_ProjectTransferListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-back-btn", [], null, null, null, i2.View_BackBtnComponent_0, i2.RenderType_BackBtnComponent)), i1.ɵdid(2, 114688, null, 0, i3.BackBtnComponent, [i4.NavigationService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-simple-table-wrapper", [], null, null, null, i7.View_SimpleTableWrapperComponent_0, i7.RenderType_SimpleTableWrapperComponent)), i1.ɵdid(7, 245760, null, 0, i8.SimpleTableWrapperComponent, [i9.HttpClient, i10.StoreService, i11.LookupService, i12.ModalService, i13.ActivatedRoute, i6.TranslateService], { tableDetails: [0, "tableDetails"], initialFilterState: [1, "initialFilterState"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "formly-form", [], null, null, null, i14.View_FormlyForm_0, i14.RenderType_FormlyForm)), i1.ɵprd(512, null, i15.FormlyFormBuilder, i15.FormlyFormBuilder, [i15.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(10, 966656, null, 0, i15.FormlyForm, [i15.FormlyFormBuilder, i15.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i16.FormGroupDirective]], { form: [0, "form"], fields: [1, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.tableDetails; var currVal_2 = _co.filter; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.actionBtnForm; var currVal_4 = _co.actionFields; _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("PROJECTS_MODULE.transferrequest.field_61a")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ProjectTransferListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-transfer-list", [], null, null, null, View_ProjectTransferListComponent_0, RenderType_ProjectTransferListComponent)), i1.ɵdid(1, 245760, null, 0, i17.ProjectTransferListComponent, [i6.TranslateService, i18.BackbtnserviceService, i19.TableTemplateControls, i13.Router, i20.HelperService, i13.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectTransferListComponentNgFactory = i1.ɵccf("app-project-transfer-list", i17.ProjectTransferListComponent, View_ProjectTransferListComponent_Host_0, {}, {}, []);
export { ProjectTransferListComponentNgFactory as ProjectTransferListComponentNgFactory };
