import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FaqComponent } from '@core/components/faq/faq.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBell,
  faCaretSquareLeft,
  faClone,
  faEnvelope,
  faEnvelopeOpen,
  faLifeRing,
  faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faMinusCircle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ResultReportModalComponent } from '@module/program-management/components/result-report-modal/result-report-modal.component';
import { AlternateContactModalComponent } from '@module/project-report/components/alternate-contact-modal/alternate-contact-modal.component';
import { SelectUnitsComponent } from '@module/project-report/components/select-units/select-units.component';
import { CreateReportNotificationModalComponent } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.component';
import { NgbAccordionModule, NgbCollapse, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldType, FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { ChooserPageComponent } from '@shared/components/chooser-page/chooser-page.component';
import { ConfirmMigrationComponent } from '@shared/components/confirm-migration/confirm-migration.component';
import { ConfirmationCodeComponent } from '@shared/components/confirmation-code/confirmation-code.component';
import { ContactSupportModalComponent } from '@shared/components/contact-support-modal/contact-support-modal.component';
import { NgSelectModule } from '@shared/components/ng-select/ng-select.module';
import { ProjectMilestonesModule } from '@shared/components/project/components/project-milestones/project-milestones.module';
import { ProjectTasksModule } from '@shared/components/project/components/project-tasks/project-tasks.module';
import { PasswordComplexityValidatorDirective } from '@shared/components/reset-password/password-complexity.validator';
import { DisableOnUploadDirective } from '@shared/directives/disable-on-upload.directive';
import { DisableWithValidationDirective } from '@shared/directives/disable-with-validation.directive';
import { FitToTextDirective } from '@shared/directives/fit-to-text.directive';
import { HasPermissionDirective } from '@shared/directives/has-permission.directive';
import { TemplateRefDirective } from '@shared/directives/template-ref.directive';
import { TruncateValueDirective } from '@shared/directives/truncate-value.directive';
import { WCAGDummyLinkTargetDirective } from '@shared/directives/wcag-dummy-link-target.directive';
import { WCAGLabelDirective } from '@shared/directives/wcag-label.directive';
import { FormlyFieldAccoundIdSearch } from '@shared/formly/formly-custom-types/account-id-search.type';
import { FormlyFieldButton } from '@shared/formly/formly-custom-types/button.type.component';
import { FormlyFieldCheckboxExtra } from '@shared/formly/formly-custom-types/checkbox.type.component';
import { FormlyFieldChips } from '@shared/formly/formly-custom-types/chips.type.component';
import { CustomFormlyTemplateType } from '@shared/formly/formly-custom-types/custom-template.type';
import { FormlyFieldDummy } from '@shared/formly/formly-custom-types/dummy.component';
import { FormlyBlurb } from '@shared/formly/formly-custom-types/formly-blurb.type';
import { FormlyTable } from '@shared/formly/formly-custom-types/formly-table-view';
import { FormlyFieldInputExtra } from '@shared/formly/formly-custom-types/input.type.componente';
import { FormlyFieldMultiCheckbox } from '@shared/formly/formly-custom-types/multi-checkbox.type.component';
import { FormlyFieldMultiSelect } from '@shared/formly/formly-custom-types/multi-select.type.component';
import { FormlyFieldRadioExtra } from '@shared/formly/formly-custom-types/radio.type.component';
import { FormlyFieldReadOnlyInput } from '@shared/formly/formly-custom-types/read-only-input.type.component';
import { RepeatTypeComponent } from '@shared/formly/formly-custom-types/repeat-section.type';
import { FormlyFieldTextAreaExtra } from '@shared/formly/formly-custom-types/text-area.type.component';
import { NumberSuffixPipe } from '@shared/pipes/number-suffix.pipe';
import { TemplateRefPipe } from '@shared/pipes/template-ref.pipe';
import { SharedFormlyModule } from 'app/shared-formly/shared-formly.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { InputSwitchModule, MessagesModule, OverlayPanelModule, SelectButtonModule, TooltipModule } from 'primeng';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { AccountSelectionComponent } from './components/account-selection/account-selection.component';
import { AddAdditionalUsersComponent } from './components/add-additional-users/add-additional-users.component';
import { BackBtnComponent } from './components/back-btn/back-btn.component';
import { BaseActionsComponent } from './components/base-actions/base-actions.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { BaseStepperComponent } from './components/base-stepper/base-stepper.component';
import { BlurbComponent } from './components/blurb/blurb.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { EditableTableViewComponent } from './components/editable-table-view/editable-table-view.component';
import { FieldItemComponent } from './components/field-item/field-item.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormArrayTableComponent } from './components/form-array-table/form-array-table.component';
import { FormHistoryComponent } from './components/form-history/form-history.component';
import { FormPopupWrapperComponent } from './components/form-popup-wrapper/components/form-popup-wrapper/form-popup-wrapper.component';
import { FormPopupWrapperModule } from './components/form-popup-wrapper/form-popup-wrapper.module';
import { HeaderComponent } from './components/header/header.component';
import { LinkedAccountsComponent } from './components/linked-accounts/linked-accounts.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OffsetAccountTypeComponent } from './components/offset-account-type/offset-account-type.component';
import { OmpAccountTypesComponent } from './components/omp-account-types/omp-account-types.component';
import { PendingRegistrationsComponent } from './components/pending-registrations/pending-registrations.component';
import { PreviousDiscussionComponent } from './components/previous-discussion/previous-discussion.component';
import { ProgramSwitcherComponent } from './components/program-switcher/program-switcher.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CreateNewProjectComponent } from './components/project-builder/create-new-project/create-new-project.component';
import { ProjectDocumentsComponent } from './components/project-builder/projec-documents/project-documents.component';
import { ProjectActivityCardComponent } from './components/project-builder/project-activity-card/project-activity-card.component';
import { ProjectActivityFormComponent } from './components/project-builder/project-activity-form/project-activity-form.component';
import { ProjectActivityListComponent } from './components/project-builder/project-activity-list/project-activity-list.component';
import { ProjectActivitySelectorComponent } from './components/project-builder/project-activity-selector/project-activity-selector.component';
import { ProjectActivitySummaryCardComponent } from './components/project-builder/project-activity-summary-card/project-activity-summary-card.component';
import { ProjectBuilderWrapperComponent } from './components/project-builder/project-builder-wrapper/project-builder-wrapper.component';
import { ProjectDescriptionComponent } from './components/project-builder/project-description/project-description.component';
import { ProjectListWrapperComponent } from './components/project-builder/project-list-wrapper/project-list-wrapper.component';
import { ProjectListComponent } from './components/project-builder/project-list/project-list.component';
import { ProjectSummaryComponent } from './components/project-builder/project-summary/project-summary.component';
import { ProjectTransferListComponent } from './components/project-builder/project-transfer-list/project-transfer-list.component';
import { ProjectTransferRequestComponent } from './components/project-builder/project-transfer-request/project-transfer-request.component';
import { SubProjectDocumentsComponent } from './components/project-builder/sub-project-documents/sub-project-documents.component';
import { SubProjectFormComponent } from './components/project-builder/sub-project-form/sub-project-form.component';
import { SubProjectListComponent } from './components/project-builder/sub-project-list/sub-project-list.component';
import { SubProjectWrapperComponent } from './components/project-builder/sub-project-wrapper/sub-project-wrapper.component';
import { SubProjectsTableComponent } from './components/project-builder/sub-projects-table/sub-projects-table.component';
import { ProjectDiscussionModule } from './components/project-discussion/project-discussion.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RetrieveUsernameComponent } from './components/retrieve-username/retrieve-username.component';
import { SelectAccountsComponent } from './components/select-accounts/select-accounts.component';
import { SessionEndComponent } from './components/session-end/session-end.component';
import { SetIssuancesDatesComponent } from './components/set-issuances-dates/set-issuances-dates.component';
import { SimpleFormlyTableAddRemoveComponent } from './components/simple-formly-table-add-remove/simple-formly-table-add-remove.component';
import { SimpleTableWrapperComponent } from './components/simple-table-wrapper/simple-table-wrapper.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CfsOrgAccRegComponent } from './components/steps/cfr-registration/cfs-org-acc-reg/cfs-org-acc-reg.component';
import { CfsOrgRoleComponent } from './components/steps/cfr-registration/cfs-org-role/cfs-org-role.component';
import { CfsSecureSignInComponent } from './components/steps/cfr-registration/cfs-secure-sign-in/cfs-secure-sign-in.component';
import { CfsVbAccRegComponent } from './components/steps/cfr-registration/cfs-vb-acc-reg/cfs-vb-acc-reg.component';
import { OrgRegProgressComponent } from './components/steps/cfr-registration/org-reg-progress/org-reg-progress.component';
import { StepAnchorDirective } from './components/steps/directive/step-anchor.directive';
import { OffsetRegProgressComponent } from './components/steps/offset-reg-progress/offset-reg-progress.component';
import { StepTitleComponent } from './components/steps/step-title/step-title.component';
import { StepsComponent } from './components/steps/steps/steps.component';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { TableNewRowComponent } from './components/table-new-row/table-new-row.component';
import { TableSimpleComponent } from './components/table-simple/table-simple.component';
import { TableViewComponent } from './components/table-view/table-view.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { TemplatePopupComponent } from './components/template-popup/template-popup.component';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { UploadPendingDocumentsComponent } from './components/upload-pending-documents/upload-pending-documents.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { CraPaymentActionsComponent } from './controls/cra-payment-actions/cra-payment-actions.component';
import { DocumentActionsComponent } from './controls/document-actions/document-actions.component';
import { ExcessEmissionChargeActionsComponent } from './controls/excess-emission-charge-actions/excess-emission-charge-actions.component';
import { GenericActionControlComponent } from './controls/generic-action-control/generic-action-control.component';
import { GenericViewControlComponent } from './controls/generic-view-control/generic-view-control.component';
import { ManageFacilitiesActionsComponent } from './controls/manage-facilities-actions/manage-facilities-actions.component';
import { OverlayPanelOnClickActionComponent } from './controls/overlay-panel-on-click-action/overlay-panel-on-click-action.component';
import { RegReptActionsComponent } from './controls/reg-rept-actions/reg-rept-actions.component';
import { TcActionsComponent } from './controls/tc-actions/tc-actions.component';
import { UserActionsComponent } from './controls/user-actions/user-actions.component';
import { RouterLinkDirective } from './directives/router-link.directive';
import { AddFacilityTypeComponent } from './formly/formly-custom-types/addFacility.type';
import { AddFacilityAccordionTypeComponent } from './formly/formly-custom-types/addFacilityAccordion.type';
import { AgreementFacilityRadio } from './formly/formly-custom-types/agreement-facility-radio.type';
import { FormlyCalendarInputComponent } from './formly/formly-custom-types/calendar.type/calendar.type.component';
import { CoiChecklistCheckBoxType } from './formly/formly-custom-types/coi-checklist-checkbox.type';
import { CoiChecklistradioType } from './formly/formly-custom-types/coi-checklist-radio.type';
import { FormlyFieldDictionarySelect } from './formly/formly-custom-types/dictionary-select.type.component';
import { DynamicFormlyTemplateType } from './formly/formly-custom-types/dynamic-template.type';
import { FormlyFormArrayTypeComponent } from './formly/formly-custom-types/form-array.type';
import { FormlyEditableTable } from './formly/formly-custom-types/formly-editable-table.type';
import { FormlyInputSwitch } from './formly/formly-custom-types/input-switch.type';
import { FormlyFieldTextOnlyField } from './formly/formly-custom-types/text-only-field.component';
import { FormlyTextOnly } from './formly/formly-custom-types/text-only-type.component';
import { FormlyFieldTypeahead } from './formly/formly-custom-types/typeahead.type.component';
import { registerTranslateExtension } from './formly/formly-extensions/translate.extension';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SubProjectsAdditionComponent } from './components/project-builder/sub-projects-addition/sub-projects-addition.component';
import { TrustedStylePipe } from '@shared/pipes/trusted-style.pipe';
import { UnitDetailsComponent } from '@module/project-report/components/unit-details/unit-details.component';
import { SummaryTableComponent } from '@module/project-report/components/summary-table/summary-table.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ReadOnlyPrivacyComponent } from './components/read-only-privacy/read-only-privacy.component';
import { ExecuteLinkActionModalComponent } from '@module/cfr-registration/components/execute-link-action-modal/execute-link-action-modal.component';
import { CreateNewCreditCreationReportComponent } from '@module/credit-creation-reports/components/create-new-credit-creation-report/create-new-credit-creation-report.component';
import { AddDetailsFormComponent } from '@module/credit-creation-reports/components/add-details-form/add-details-form.component';
import { CreditCreationAdditionalDetailsComponent } from '@module/credit-creation-reports/components/credit-creation-additional-details/credit-creation-additional-details.component';
import { ProjectDetailsFormComponent } from '@module/credit-creation-reports/components/project-details-form/project-details-form.component';
import { ContactPersonInformationFormComponent } from '@module/credit-creation-reports/components/contact-person-information-form/contact-person-information-form.component';
import { FitToLabelDirective } from '@shared/directives/fit-to-label.directive';
import { FormlyAddressViewTypeComponent } from '@shared/formly/formly-custom-types/address-view.type';
import { AddressViewModule } from '@shared/components/address-view/address-view.module';
import { FormModalComponent } from '@module/submission-management/components/form-modal/form-modal.component';
import { ChargingStationAddressFormComponent } from '@module/credit-creation-reports/components/charging-station-address-form/charging-station-address-form.component';
import { AdminTakeActionModalComponent } from '@shared/components/admin-take-action-modal/admin-take-action-modal.component';
import { UnitDetailsPopupComponent } from '@module/offset-public-registry/components/unit-details-popup/unit-details-popup.component';
import {
  CreateComplianceReportComponent
} from '@module/compliance-report/components/create-compliance-report/create-compliance-report.component';
import { FormlyKeyValuePairs } from '@shared/formly/formly-custom-types/formly-key-value-pairs.type';
import {
  ComplianceReportAdditionalDetailsComponent
} from '@module/compliance-report/components/compliance-report-additional-details/compliance-report-additional-details.component';
import { FormlyDocumentsType } from '@shared/formly/formly-custom-types/formly-documents.type';
import { VbProofAccreditationComponent } from '@module/cfr-registration/components/vb-proof-accreditation/vb-proof-accreditation.component';
import { EntityHistoryListModalComponent } from './components/entity-history-list-modal/entity-history-list-modal.component';
import { TransferProjectToAggregationComponent } from './components/project-builder/transfer-project-to-aggregation/transfer-project-to-aggregation.component';
import { TransferAggregationRequestComponent } from './components/project-builder/transfer-aggregation-request/transfer-aggregation-request.component';
import { ProjectTransferDetailsComponent } from './components/project-builder/project-transfer-details/project-transfer-details.component';
import { CreateCreditBalanceReportComponent } from '@module/credit-balance/components/create-credit-balance-report/create-credit-balance-report.component';
import {
  AddDetailsAdjustmentFormComponent
} from '@module/credit-adjustment-report/components/add-details-adjustment-form/add-details-adjustment-form.component';
import { NewCreditClearanceMechanismComponent } from '@module/credit-clearance-mechanism/new-credit-clearance-mechanism/new-credit-clearance-mechanism.component';
import { CreateComplimentaryComplianceReportComponent } from '@module/complimentary-compliance-report/components/create-complimentary-compliance-report/create-complimentary-compliance-report.component';
import { ComplimentaryComplianceAddDetailsFormComponent } from '@module/complimentary-compliance-report/components/complimentary-compliance-add-details-form/complimentary-compliance-add-details-form.component';
import { ComplimentaryComplianceReportAdditionalDetailsComponent } from '@module/complimentary-compliance-report/components/complimentary-compliance-report-additional-details/complimentary-compliance-report-additional-details.component';
import {
  CreditAdjustmentReportViewAddressComponent
} from '@module/credit-adjustment-report/components/credit-adjustment-report-view-address/credit-adjustment-report-view-address.component';
import { CreateCreditRevenueReportComponent } from '@module/credit-revenue-report/create-credit-revenue-report/create-credit-revenue-report.component';
import { AddRevenueDetailsComponent } from '@module/credit-revenue-report/add-revenue-details/add-revenue-details.component';

export function showError(f: FieldType): boolean {
  return (
    f.formControl &&
    f.formControl.invalid &&
    (f.formControl.dirty || (f.options.parentForm && f.options.parentForm.submitted) || (f.field.validation && f.field.validation.show))
  );
}

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationsComponent,
    RouterLinkDirective,
    WCAGLabelDirective,
    TableViewComponent,
    FooterComponent,
    LoadingComponent,
    FormlyFieldTypeahead,
    FormlyFieldDictionarySelect,
    FormlyFieldMultiSelect,
    FormlyFieldReadOnlyInput,
    BasePageComponent,
    TableFilterComponent,
    AddAdditionalUsersComponent,
    DateRangeComponent,
    UploadDocumentComponent,
    DocumentsComponent,
    DocumentActionsComponent,
    UserActionsComponent,
    SelectAccountsComponent,
    RepeatTypeComponent,
    TcActionsComponent,
    EditableTableViewComponent,
    StepperComponent,
    ProgressBarComponent,
    FormlyCalendarInputComponent,
    FormlyTextOnly,
    FormlyFieldAccoundIdSearch,
    FormlyFieldInputExtra,
    CraPaymentActionsComponent,
    TableNewRowComponent,
    ConfirmationCodeComponent,
    ResetPasswordComponent,
    ContactSupportModalComponent,
    SetIssuancesDatesComponent,
    ResultReportModalComponent,
    RetrieveUsernameComponent,
    BaseActionsComponent,
    PasswordComplexityValidatorDirective,
    TableSimpleComponent,
    SessionEndComponent,
    WCAGDummyLinkTargetDirective,
    DisableOnUploadDirective,
    FormlyFieldTextAreaExtra,
    FormlyFieldRadioExtra,
    FormlyFieldCheckboxExtra,
    CustomPopupComponent,
    DocumentViewerComponent,
    WizardComponent,
    OmpAccountTypesComponent,
    AccountSelectionComponent,
    PendingRegistrationsComponent,
    ChooserPageComponent,
    LinkedAccountsComponent,
    FormlyFieldButton,
    FaqComponent,
    TaskDetailsComponent,
    ConfirmMigrationComponent,
    BlurbComponent,
    FitToTextDirective,
    HasPermissionDirective,
    CfsOrgRoleComponent,
    CfsOrgAccRegComponent,
    CfsSecureSignInComponent,
    OrgRegProgressComponent,
    OffsetRegProgressComponent,
    CfsVbAccRegComponent,
    FormlyFieldMultiCheckbox,
    RegReptActionsComponent,
    ManageFacilitiesActionsComponent,
    UploadPendingDocumentsComponent,
    FormlyFieldChips,
    StepsComponent,
    StepAnchorDirective,
    AddFacilityTypeComponent,
    AddFacilityAccordionTypeComponent,
    StepTitleComponent,
    BaseStepperComponent,
    BaseStepperStepComponent,
    FormlyFormArrayTypeComponent,
    CoiChecklistradioType,
    SimpleTableWrapperComponent,
    CoiChecklistCheckBoxType,
    AgreementFacilityRadio,
    FormArrayTableComponent,
    GenericActionControlComponent,
    GenericViewControlComponent,
    FormArrayTableComponent,
    FormHistoryComponent,
    AgreementFacilityRadio,
    ProgramSwitcherComponent,
    DisableWithValidationDirective,
    SimpleFormlyTableAddRemoveComponent,
    FormlyFieldDummy,
    OffsetAccountTypeComponent,
    ProjectBuilderWrapperComponent,
    ProjectDescriptionComponent,
    FormlyInputSwitch,
    ProjectActivityCardComponent,
    ProjectActivitySummaryCardComponent,
    ProjectActivityListComponent,
    ProjectActivityFormComponent,
    ProjectActivitySelectorComponent,
    ProjectSummaryComponent,
    CustomFormlyTemplateType,
    DynamicFormlyTemplateType,
    FormlyAddressViewTypeComponent,
    ProjectDocumentsComponent,
    ProjectListComponent,
    CreateNewProjectComponent,
    SubProjectListComponent,
    SubProjectsTableComponent,
    SubProjectFormComponent,
    PreviousDiscussionComponent,
    ProjectTransferRequestComponent,
    SubProjectWrapperComponent,
    ProjectTransferListComponent,
    BackBtnComponent,
    ExcessEmissionChargeActionsComponent,
    OverlayPanelOnClickActionComponent,
    TruncateValueDirective,
    SubProjectDocumentsComponent,
    FormlyBlurb,
    FormlyEditableTable,
    SubProjectsAdditionComponent,
    FormlyTable,
    ProjectListWrapperComponent,
    FormlyFieldTextOnlyField,
    FieldItemComponent,
    TemplatePopupComponent,
    TemplateRefDirective,
    TemplateRefPipe,
    NumberSuffixPipe,
    SelectUnitsComponent,
    AlternateContactModalComponent,
    CreateReportNotificationModalComponent,
    TrustedStylePipe,
    UnitDetailsComponent,
    SummaryTableComponent,
    DatepickerComponent,
    ReadOnlyPrivacyComponent,
    ExecuteLinkActionModalComponent,
    CreateNewCreditCreationReportComponent,
    AddDetailsFormComponent,
    ComplimentaryComplianceAddDetailsFormComponent,
    CreditCreationAdditionalDetailsComponent,
    ProjectDetailsFormComponent,
    ContactPersonInformationFormComponent,
    FitToLabelDirective,
    FormModalComponent,
    ChargingStationAddressFormComponent,
    AdminTakeActionModalComponent,
    UnitDetailsPopupComponent,
    CreateComplianceReportComponent,
    CreateComplimentaryComplianceReportComponent,
    CreateCreditBalanceReportComponent,
    FormlyKeyValuePairs,
    ComplianceReportAdditionalDetailsComponent,
    ComplimentaryComplianceReportAdditionalDetailsComponent,
    FormlyDocumentsType,
    VbProofAccreditationComponent,
    EntityHistoryListModalComponent,
    TransferProjectToAggregationComponent,
    TransferAggregationRequestComponent,
    ProjectTransferDetailsComponent,
    AddDetailsAdjustmentFormComponent,
    NewCreditClearanceMechanismComponent,
    CreditAdjustmentReportViewAddressComponent,
    CreateCreditRevenueReportComponent,
    AddRevenueDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    NgSelectModule,
    TableModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CalendarModule,
    SelectButtonModule,
    ButtonModule,
    FieldsetModule,
    DialogModule,
    SharedFormlyModule,
    ListboxModule,
    InputSwitchModule,
    ProjectDiscussionModule,
    NgxMaskModule.forRoot(),
    FormlyModule.forRoot({
      extras: {
        showError,
      },
      types: [
        { name: 'typeahead', component: FormlyFieldTypeahead },
        { name: 'dict-select', component: FormlyFieldDictionarySelect },
        { name: 'multi-select', component: FormlyFieldMultiSelect },
        { name: 'readonly-input', component: FormlyFieldReadOnlyInput, wrappers: ['form-field'] },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'calendar-input', component: FormlyCalendarInputComponent },
        { name: 'text-only', component: FormlyTextOnly },
        { name: 'account-search', component: FormlyFieldAccoundIdSearch, wrappers: ['form-field'] },
        { name: 'input', component: FormlyFieldInputExtra, wrappers: ['form-field'] },
        { name: 'textarea', component: FormlyFieldTextAreaExtra, wrappers: ['form-field'] },
        { name: 'radio', component: FormlyFieldRadioExtra, wrappers: ['form-field'] }, // this name 'radio' should be different as it overides the original radio elmenet
        { name: 'checkbox', component: FormlyFieldCheckboxExtra, wrappers: ['form-field'] },
        { name: 'button', component: FormlyFieldButton, wrappers: ['form-field'] },
        { name: 'multi-checkbox', component: FormlyFieldMultiCheckbox, wrappers: ['form-field'] },
        { name: 'chips', component: FormlyFieldChips, wrappers: ['form-field'] },
        { name: 'add-facility', component: AddFacilityTypeComponent },
        { name: 'add-facility-accordion', component: AddFacilityAccordionTypeComponent },
        { name: 'form-array', component: FormlyFormArrayTypeComponent, wrappers: ['form-field'] },
        { name: 'coi-radio-type', component: CoiChecklistradioType },
        { name: 'coi-checklist-checkbox-type', component: CoiChecklistCheckBoxType },
        { name: 'agreement-facility-radio', component: AgreementFacilityRadio },
        { name: 'dummy', component: FormlyFieldDummy },
        { name: 'input-switch', component: FormlyInputSwitch },
        { name: 'parsed-template', component: CustomFormlyTemplateType, wrappers: ['form-field'] },
        { name: 'dynamic-template', component: DynamicFormlyTemplateType, wrappers: ['form-field'] },
        { name: 'address-view', component: FormlyAddressViewTypeComponent, wrappers: ['form-field'] },
        { name: 'blurb', component: FormlyBlurb },
        { name: 'editable-table', component: FormlyEditableTable },
        { name: 'text-only-field', component: FormlyFieldTextOnlyField },
        { name: 'table', component: FormlyTable },
        { name: 'key-value-pairs', component: FormlyKeyValuePairs },
        { name: 'documents', component: FormlyDocumentsType },
      ],
    }),
    ReactiveFormsModule,
    LocalizeRouterModule,
    A11yModule,
    FormlySelectModule,
    PdfViewerModule,
    PdfJsViewerModule,
    CheckboxModule,
    DropdownModule,
    RadioButtonModule,
    AngularEditorModule,
    MessagesModule,
    NgbAccordionModule,
    TooltipModule,
    SharedPipesModule,
    OverlayPanelModule,
    FormPopupWrapperModule,
    ProjectTasksModule,
    ProjectMilestonesModule,
    AddressViewModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotificationsComponent,
    BasePageComponent,
    NgbCollapse,
    RouterLinkDirective,
    TableFilterComponent,
    AddAdditionalUsersComponent,
    TableViewComponent,
    LoadingComponent,
    FormlyModule,
    DateRangeComponent,
    UploadDocumentComponent,
    DocumentsComponent,
    ProjectListComponent,
    DocumentActionsComponent,
    SelectAccountsComponent,
    UserActionsComponent,
    TcActionsComponent,
    EditableTableViewComponent,
    StepperComponent,
    ProgressBarComponent,
    CraPaymentActionsComponent,
    FormlyCalendarInputComponent,
    ResetPasswordComponent,
    ConfirmationCodeComponent,
    ContactSupportModalComponent,
    ResultReportModalComponent,
    RetrieveUsernameComponent,
    PasswordComplexityValidatorDirective,
    WCAGLabelDirective,
    WCAGDummyLinkTargetDirective,
    DisableOnUploadDirective,
    FormlyFieldInputExtra,
    FormlyFieldTextAreaExtra,
    FormlyFieldRadioExtra,
    FormlyFieldCheckboxExtra,
    CustomPopupComponent,
    DocumentViewerComponent,
    PdfViewerModule,
    WizardComponent,
    CheckboxModule,
    DropdownModule,
    TableModule,
    ChooserPageComponent,
    TableSimpleComponent,
    FormlyFieldButton,
    LinkedAccountsComponent,
    FaqComponent,
    TaskDetailsComponent,
    AngularEditorModule,
    CalendarModule,
    BlurbComponent,
    FitToTextDirective,
    HasPermissionDirective,
    FormlyFieldMultiCheckbox,
    RegReptActionsComponent,
    ManageFacilitiesActionsComponent,
    UploadPendingDocumentsComponent,
    FormlyFieldChips,
    StepsComponent,
    CfsOrgRoleComponent,
    CfsOrgAccRegComponent,
    CfsSecureSignInComponent,
    OrgRegProgressComponent,
    OffsetRegProgressComponent,
    CfsVbAccRegComponent,
    StepAnchorDirective,
    NgbAccordionModule,
    AddFacilityTypeComponent,
    AddFacilityAccordionTypeComponent,
    StepTitleComponent,
    BaseStepperComponent,
    BaseStepperStepComponent,
    FormlyFormArrayTypeComponent,
    FieldsetModule,
    ListboxModule,
    RadioButtonModule,
    CoiChecklistradioType,
    SimpleTableWrapperComponent,
    CoiChecklistCheckBoxType,
    AgreementFacilityRadio,
    FormArrayTableComponent,
    FormHistoryComponent,
    GenericActionControlComponent,
    DisableWithValidationDirective,
    SimpleFormlyTableAddRemoveComponent,
    FormlyFieldDummy,
    CustomFormlyTemplateType,
    DynamicFormlyTemplateType,
    FormlyAddressViewTypeComponent,
    ProjectDescriptionComponent,
    ProjectActivityFormComponent,
    ProjectActivityCardComponent,
    ProjectActivityListComponent,
    ProjectActivityFormComponent,
    ProjectActivitySelectorComponent,
    ProjectDocumentsComponent,
    SubProjectsTableComponent,
    SubProjectFormComponent,
    ProjectTransferRequestComponent,
    BackBtnComponent,
    OverlayPanelOnClickActionComponent,
    ExcessEmissionChargeActionsComponent,
    PreviousDiscussionComponent,
    TruncateValueDirective,
    FormlyBlurb,
    FormlyEditableTable,
    FormlyFieldTextOnlyField,
    FieldItemComponent,
    TemplateRefDirective,
    TemplateRefPipe,
    NumberSuffixPipe,
    FormlyTable,
    SelectUnitsComponent,
    AlternateContactModalComponent,
    TrustedStylePipe,
    UnitDetailsComponent,
    SummaryTableComponent,
    AddDetailsFormComponent,
    ComplimentaryComplianceAddDetailsFormComponent,
    ProjectDetailsFormComponent,
    ContactPersonInformationFormComponent,
    FitToLabelDirective,
    ChargingStationAddressFormComponent,
    FormlyKeyValuePairs,
    ComplianceReportAdditionalDetailsComponent,
    ComplimentaryComplianceReportAdditionalDetailsComponent,
    FormlyDocumentsType,
    VbProofAccreditationComponent,
    ProjectTransferDetailsComponent,
    AddDetailsAdjustmentFormComponent,
    CreditAdjustmentReportViewAddressComponent,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] },
  ],
  entryComponents: [
    UploadDocumentComponent,
    TableNewRowComponent,
    ContactSupportModalComponent,
    SetIssuancesDatesComponent,
    ResultReportModalComponent,
    SessionEndComponent,
    CustomPopupComponent,
    StepsComponent,
    BaseStepperComponent,
    BaseStepperStepComponent,
    CfsOrgRoleComponent,
    CfsOrgAccRegComponent,
    CfsSecureSignInComponent,
    OrgRegProgressComponent,
    OffsetRegProgressComponent,
    CfsVbAccRegComponent,
    CreateNewProjectComponent,
    SubProjectFormComponent,
    SubProjectsAdditionComponent,
    FormPopupWrapperComponent,
    TemplatePopupComponent,
    SelectUnitsComponent,
    AlternateContactModalComponent,
    CreateReportNotificationModalComponent,
    UnitDetailsComponent,
    SummaryTableComponent,
    ReadOnlyPrivacyComponent,
    ExecuteLinkActionModalComponent,
    CreateNewCreditCreationReportComponent,
    AddDetailsFormComponent,
    ComplimentaryComplianceAddDetailsFormComponent,
    CreditCreationAdditionalDetailsComponent,
    ProjectDetailsFormComponent,
    ContactPersonInformationFormComponent,
    FormModalComponent,
    ChargingStationAddressFormComponent,
    AdminTakeActionModalComponent,
    UnitDetailsPopupComponent,
    CreateComplianceReportComponent,
    CreateComplimentaryComplianceReportComponent,
    CreateCreditBalanceReportComponent,
    ComplianceReportAdditionalDetailsComponent,
    ComplimentaryComplianceReportAdditionalDetailsComponent,
    VbProofAccreditationComponent,
    EntityHistoryListModalComponent,
    ProjectTransferDetailsComponent,
    AddDetailsAdjustmentFormComponent,
    NewCreditClearanceMechanismComponent,
    CreditAdjustmentReportViewAddressComponent,
    CreateCreditRevenueReportComponent,
    AddRevenueDetailsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faBell,
      faLifeRing,
      faExclamationCircle,
      faCheckCircle,
      faTimesCircle,
      faPlusSquare,
      faCaretSquareLeft,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faExclamationTriangle,
      faEnvelope,
      faEnvelopeOpen,
      faCircle,
      faMinusCircle,
      faChevronDown,
      faChevronUp,
      faQuestionCircle,
      faInfoCircle,
      faClone,
    );
  }
}
