import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';

export const CCR_TYPE = {
  QUARTERLY: 'QUARTERLY',
  NIL: 'NIL',
  ANNUAL: 'ANNUAL',
  ONETIME: 'ONETIME',
  CREDIT_ADJUSTMENT: 'CREDIT_ADJUSTMENT',
};

export const CCR_ID_KEY = 'CCR_ID';
export const CCR_DATA_KEY = 'CCR_DATA';
export const CCR_CURRENT_STEP_KEY = 'CCR_STEP_KEY';

export const CCR_TABLE_CONFIG_PREFIX = 'CREDIT_CREATION_REPORT_';
export const CCR_COMPLIANCE_CREDITS_CREATED_TOOLTIP = 'CREDIT_CREATION_REPORT.reportStep.numberOfComplianceCredits';

export const CCR_STEP_COMPONENTS = {
  CREDIT_CREATION_REPORT_STEP: 'CreditCreationReportStepComponent',
  CREDIT_CREATION_CONTACT_PERSON: 'CreditCreationContactPersonComponent',
  CREDIT_CREATION_SUMMARY: 'CreditCreationSummaryComponent',
  CREDIT_CREATION_VERIFICATION: 'CcrVerificationDetailsComponent',
};

export const CCR_MAX_STEP_NUMBER = 10;

export const CCR_CO_PROCESSING_PROJECT_FUELS = ['CLCIG', 'CLCID', 'CLCIJ', 'CLCILFO', 'CLCIHFO', 'CLCIP', 'OCLIF'];

export const summaryTableConfig: ITableViewConfig = {
  translationPrefix: 'CREDIT_CREATION_REPORT.creditCreationReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'accountName',
      header: 'accountName',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'quantity',
      header: 'quantity',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};
export const summaryTableConfigForCreditTransfer: ITableViewConfig = {
  translationPrefix: 'CREDIT_CREATION_REPORT.creditCreationReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'accountName',
      header: 'accountName',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'fuelType',
      header: 'fuelType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'carbonIntensityId',
      header: 'carbonIntensityId',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'quantity',
      header: 'quantity',
      numeric: true,
      // className: 'text-right',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const ANNUAL_ACTIVITIES = {
  1: 'ERP_LIQUID',
  2: 'EUFS_FOSSIL_FUEL',
  3: 'EUFS_RENEWABLE_FUEL',
  4: 'EUFS_GPC',
  5: 'EUFS_CSH',
  6: 'EUFS_HYDROGEN'
};

export const QUARTERLY_ACTIVITEIS = {
  1: 'PRODCI_LIQUID',
  2: 'PRODCI_GAS',
  3: 'PRODCI_GAS_FOR_FUELING_STATION',
  4: 'IMPLCI_LIQUID',
  5: 'IMPLCI_GAS',
  6: 'IMPLCI_GAS_FOR_FUELING_STATION',
  7: 'CREDIT_TRANSFER',
  8: 'BPE_GAS'
};


export const CCR_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  sameAsOrganization: {
      hide: false,
      changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
};

export const CCR_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'civicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
};
