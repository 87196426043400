import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { SignAgreementComponent } from '@module/agreement-credits/components/sign-agreement/sign-agreement.component';
import { REPORT_VERIFICATION_COMPONENTS, VERIFICATION_MANAGEMENT_ACTIONS } from '@module/submission-management/const/verification-management.const';
import { BaseStepperComponent } from '@shared/components/base-stepper/base-stepper.component';
import { AGREEMENT_CREATE_CREDITS_STEPS, AGREEMENT_DOCUMENT_STEPS, AGREEMENT_REVIEW_CREDITS_STEPS} from '@shared/components/steps/constants/agreement-create-credits';
import { StepAnchorDirective } from '@shared/components/steps/directive/step-anchor.directive';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { validateStep } from '@shared/decorators/validate-step.decorator';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { ITableViewConfig } from '@shared/models/table-view.model';

@Component({
  selector: 'app-base-stepper',
  templateUrl: './agreement-stepper.component.html',
})
export class AgreementStepperComponent extends BaseStepperComponent implements OnInit, OnDestroy {

  @ViewChild(StepAnchorDirective, { static: false }) set content(content: StepAnchorDirective) {
    if (content) {
      this.appStepAnchor = content;
    }
  }

  applicationId;
  applicationType;
  applicationData;
  viewReady = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public componentFactoryResolver: ComponentFactoryResolver,
    private store: StoreService
  ) {
    super(activatedRoute);
  }

  disableIfApplicable(upload: boolean, edit: boolean) {
    if (upload) {
      return this.service.currentData.status !== IApp.Agreement.StatusEnum.PENDING_AGREEMENT_UPLOAD;
    }else if (edit) {
      return this.service.currentData.status !== IApp.Agreement.StatusEnum.IN_PROCESS &&
     this.service.currentData.status !== IApp.Agreement.StatusEnum.PENDING_AGREEMENT_UPLOAD;
    } else {
      return this.service.currentData.status !== IApp.Agreement.StatusEnum.IN_PROCESS; //&
      //this.service.currentData.status !== IApp.Agreement.StatusEnum.PENDING_AGREEMENT_UPLOAD;
    }
  }

  ngOnInit() {

    this.service.hideSaveAsDraft = false;

    this.activatedRoute.params.subscribe(params => {
      const upload = params.type === 'upload' ;
      const edit = params.type === 'edit' ;
      let steps = upload ? AGREEMENT_DOCUMENT_STEPS : AGREEMENT_CREATE_CREDITS_STEPS;
      if (params.id) {
        this.service.getAgreementById(params.id).subscribe(data => {
          this.service.inputData = data;
          this.service.currentData = this.service.inputData;
          this.service.initModelChangeTracking();
          if (!upload){
            if (data.status !== 'IN_PROCESS' && !edit){
             steps = AGREEMENT_REVIEW_CREDITS_STEPS;
            }else if (data.opaDetail && !data.opaDetail.editable){
              steps.steps[2] = {...steps.steps[2], name: 'REVIEW_OPA'};
              steps.steps[3] = {...steps.steps[3], name: 'REVIEW_OPA_AO_DETAILS'};
            }else {
              steps.steps[2] = {...steps.steps[2], name: 'ENTER_OPA'};
              steps.steps[3] = {...steps.steps[3], name: 'ENTER_OPA_AO_DETAILS'};
            }

            if(data.status === 'PENDING_AGREEMENT_UPLOAD' || data.status === 'PENDING_ADMIN_REVIEW' || data.status === 'ACTIVE'){
              this.service.currentData.step = 100;
            }else if(data.status === 'PENDING_OPA_APPROVAL'){
              this.service.currentData.step = 6;
            }else if(data.status === 'PENDING_RCA_APPROVAL'){
              this.service.currentData.step = 7;
            }
          }
          this.steps = steps.steps;
          this.footerButtonConfig ={...steps.buttons};
          // this.service.currentData.showOpaReview = true;
          if (this.disableIfApplicable(upload, edit) ||  data.registrationAccount.status === 'LOCKED') {
            this.service.disable();
            this._svc.disable();
          } else {
            this.service.enable();
            this._svc.enable();
          }

          if (!this.store.user.hasPermission('SUBMIT_CR_APPLICATION_CCR')) {
            this.service.disable();
            this._svc.disable();
          }

          if (this.service.disabled) {
            this.steps.forEach(element => {
              element.showSubmit = false;
            });
            this.footerButtonConfig.showSave = false;
            this.footerButtonConfig.showSubmit = false;
          }
          this.extraSteps = this.getExtraSteps(this.service.inputData.type);

          if (this.service.currentData.entityActionList) {
            this.service.currentData.entityActionList.map(t => {
              const status = this.service.currentData.status;
              if (t.workflowAction === 'REGISTER_OPA_SIGN') {
              //  this.steps.splice(4, 1);
                this.footerButtonConfig.showSubmit = true;
                this.service.currentData.showOpaReview = true;
                this.service.currentData.action = t.workflowAction ;
                this.service.currentData.opaUser = this.storeService.user.fullName;
                this.service.currentData.opaSignedOn = this.currentDate();
              } else if (t.workflowAction === 'SIGN_AGREEMENT') {
             //   this.steps.splice(4, 1);
                this.footerButtonConfig.showSubmit = true;
                this.service.currentData.showOpaReview = true;
                this.service.currentData.showRcaReview = true;
                this.service.currentData.action = t.workflowAction ;
                this.service.currentData.rcaUser = this.storeService.user.fullName;
                this.service.currentData.rcaSignedOn = this.currentDate();
              } else if (status === IApp.Agreement.StatusEnum.PENDING_RCA_APPROVAL) {
             //   this.steps.splice(4, 1);
                this.service.currentData.showOpaReview = true;
              } else if (status === IApp.Agreement.StatusEnum.IN_PROCESS) {
             //   this.steps.splice(4, 1);
              }else if (status === IApp.Agreement.StatusEnum.ACTIVE && this.service.opaExists()) {
            //    this.steps.splice(4, 1);
                this.service.currentData.step = 100;
                this.service.currentData.showOpaReview = true;
                this.service.currentData.showRcaReview = true;
              }

            });
          }

          this.init();
        });
      } else if (history.state.data) {
        this.service.inputData = history.state.data.inputData;
        this.service.currentData = history.state.data.currentData || {};
        this.service.initModelChangeTracking();
        this.steps = history.state.data.steps;
        this.footerButtonConfig = {...history.state.data.buttons};
        this.service.enable();
        this._svc.enable();
        this.footerButtonConfig.showSave = true;
       // this.footerButtonConfig.showSubmit = false;
        // this.extraSteps = this.getExtraSteps(this.service.inputData.type);
        this.init();
      } else {
        this._location.back();
      }
    });

  }

  getStepConfiguration(prefix: string) {
    return {
      page_title: 'VERIFICATION_MANAGEMENT_MODULE.verificationReport.mainStepTitle',

      steps: [
        {
          id: 1,
          name: `ISSUE_${prefix}_STATEMENT`,
          completed: false,
          component: REPORT_VERIFICATION_COMPONENTS.ISSUE_STATEMENT_COMPONENT,
          // component: REPORT_VERIFICATION_COMPONENTS.PATHWAY_INFORMATION,
        },
        {
          id: 2,
          name: `UPLOAD_${prefix}_REPORT`,
          completed: false,
          component: REPORT_VERIFICATION_COMPONENTS.PATHWAY_INFORMATION,
        },
      ],

      buttons: {
        showSave: true,
        showSubmit: true,
        submitPrefix: 'submitRegistrationReportButton',
      },
    };
  }

  getExtraSteps(prefix: string) {
    return {
      showOpaReview: {
        name: `OPA_SIGN`,
        completed: false,
        suffix: 'opa',
        component: SignAgreementComponent,
      },
      showRcaReview: {
        name: `RCA_SIGN`,
        completed: false,
        suffix: 'rca',
        component: SignAgreementComponent,
      },
    };
  }

  init() {
    this.viewReady = true;
    setTimeout(() => {
      this.actionsList = VERIFICATION_MANAGEMENT_ACTIONS;
      this.initStepper();
      this.setCurrentComponent();
    });
  }

  @validateStep()
  nextStep() {
    this._svc.onSaveAndProceed$.next(true);
  }

  @validateStep()
  submit() {
    this._svc.onSubmit$.next(true);
  }

  prevStep() {
   if (this.currentStep.reloadOnPrevious && !this.service.disabled){
    this.service.getAgreementById(this.service.currentData.id).subscribe(data => {
      this.service.currentData = data;
      this.service.prevStep();
    });
   }else {
    this.service.prevStep();
   }
  }

  saveAsDraft() {
    this._svc.onSaveAsDraft$.next(true);
  }

  get submitButtonPrefix() {
    let label = this.service.currentData ? this.service.currentData.status : '';
    if (label === IApp.Agreement.StatusEnum.IN_PROCESS && this.service.opaExists()) {
      label = `${label}_OPA`;
    }
    return `SUBMIT_BUTTON_${label}`;
  }

  triggerActionController(value: IApp.IEntityAction): void {
    const data = this.service.currentData;
    this.service.triggerActionController({ action: value, data }, true);
  }

  get entityActions(): IApp.IEntityAction[] {
    return this.service.currentData.entityActionList ? this.service.currentData.entityActionList.filter(function (t) {
        return t.workflowAction !== 'REGISTER_OPA_SIGN' &&  t.workflowAction !== 'SIGN_AGREEMENT';
    }) :  [];
  }

  openEditPopUp(tableConfig: ITableViewConfig, respondModel: any) {

    this.modalService.open(TableNewRowComponent, {
      form: tableConfig,
      defaultValues: respondModel,
      editTitle: 'reject',
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
    } as TableNewRow).afterClosed().subscribe((result?: any) => {
      if (result) {
        this._location.back();
      }
    });
  }

  getRejectConfig(uri) {
    return {
      translationPrefix: 'VERIFICATION_MANAGEMENT_MODULE.report',
      paginator: true,
      editService: `/cfr-service/verification${uri}`,
      columns: [
        {
          field: 'id',
          header: 'id',
          sortField: 'id',
          width: '200px',
          editable: false,
          fieldType: 'readonly-input',
          inputType: 'number',
          editRowField: false,
        }, {
          field: 'report.irDeclaration.comment',
          header: 'comment',
          sortField: 'comment',
          width: '200px',
          editable: false,
          fieldType: 'textarea',
          inputType: 'string',
          editRowField: true,
          className: 'col-12',
        },
      ],
    };
  }

}
