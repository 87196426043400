import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { CIVIC_ADDRESS_FORM_SECTION, POSTAL_ADDRESS_FORM_SECTION } from '@shared/components/stepper/stepper.const';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { STEP4_AUTH_OFFICIAL_INFO, STEP4_CONTACT_PERSON_INFO ,PREVIOUS_AGREEMENT_DETAILS} from '../constant/AgreementCreateCredits.const';
import { IRawFormInput } from '@core/models/raw-form.types';
import { cloneDeep} from 'lodash';

@Component({
  selector: 'app-auth-contact-person-info',
  templateUrl: './auth-contact-person-info.component.html',
  styleUrls: ['./auth-contact-person-info.component.scss'],
})
export class AuthContactPersonInfoComponent extends BaseStepperStepComponent {

  static key = 'AuthContactPersonInfoComponent';

  model: any = {};

  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  prevFormGroup = new FormGroup({});
  prevFields: FormlyFieldConfig[];

  formGroupOtherParty = new FormGroup({});
  fieldsOtherParty: FormlyFieldConfig[] = [];

  existInMarkit: boolean;

  private opaAgreementId;

  constructor(
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    private helperService: HelperService,
    private router: Router,
    private modalService: ModalService,
    public service: AgreementCreditsService) { super(); }

  ngOnInit() {

    this.model = this.service.currentData;

  if (this.service.currentData && this.service.currentData.opaOldAAg) {
    this.model.opaDetail.authorizedOfficial = this.service.currentData.opaOldAAg
    this.model.opaDetail.authorizedOfficial.name = this.service.currentData.opaOldAAg.fullName
  }

    this.opaAgreementId = this.model.opaAgreementId;

    if (this.model.opaDetail && this.model.opaDetail.editable) {
      this.existInMarkit = false;
    } else {
      this.existInMarkit = true;
      setTimeout(() => {
        this.formGroup.disable();
        this.formGroupOtherParty.disable();
      });
    }

    // opaDetail.authorizedOfficial.
    const PREVIOUS_FORM_FIELDS = PREVIOUS_AGREEMENT_DETAILS;
    (PREVIOUS_FORM_FIELDS[0].fieldGroup[1] as IRawFormInput).dataType = this.model.registrationAccount.id;
    this.prevFields = this.formFactoryService.configureForm(PREVIOUS_FORM_FIELDS);

    const FORM_FIELDS = STEP4_AUTH_OFFICIAL_INFO;
    this.fields = this.formFactoryService.configureForm(FORM_FIELDS);
    // this will have to change based on the type as for the 2nd user the title will be different
    POSTAL_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Auth_Address.';
    POSTAL_ADDRESS_FORM_SECTION.key = 'opaDetail.authorizedOfficial.postalAddress';
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = false;
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.authorizedOfficial.postalAddress\')';

    CIVIC_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Auth_Address.';
    CIVIC_ADDRESS_FORM_SECTION.key = 'opaDetail.authorizedOfficial.civicAddress';
    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.authorizedOfficial.postalAddress\', \'opaDetail.authorizedOfficial.civicAddress\')';

    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION, 'opaDetail.postalAddress')[0]);
    this.fields.push(this.getContactInformation()[0]);
    this.fields.push(this.formFactoryService.configureForm(STEP4_CONTACT_PERSON_INFO)[0]);
    // this will have to change based on the type as for the 2nd user the title will be different
    POSTAL_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Contact_Person.';
    POSTAL_ADDRESS_FORM_SECTION.key = 'opaDetail.contactPerson.postalAddress';
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = false;
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.contactPerson.postalAddress\')';

    CIVIC_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Contact_Person.';
    CIVIC_ADDRESS_FORM_SECTION.key = 'opaDetail.contactPerson.civicAddress';

    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.contactPerson.postalAddress\', \'opaDetail.contactPerson.civicAddress\')',

    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION, 'opaDetail.postalAddress')[0]);
    this.service.disableFormIfApplicable(this.formGroup);
    this.service.disableFormIfApplicable(this.formGroupOtherParty);
    this.formFactoryService.dictCallback
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          this.callbackFunction(data)
          this.service.initModelChangeTracking();
        }
      );
  }

  getContactInformation(){
    return [{
        groupFields: false,
        fieldGroup: [
           {
                className: 'col-6',
                template: this.translateService.instant('AGREEMENT_CREDITS.Step_4.otherPartyContactPersonInfo')
            }
        ]
    }];
  }
  callbackFunction(fieldData): void {
    if (!this.existInMarkit  && fieldData.key === `opaAgreementId` && this.checkForDisableState() ) {
      if(this.model.opaAgreementId){
      this.opaAgreementId = this.model.opaAgreementId;
      this.service.getAgreementById(this.model.opaAgreementId).subscribe(
        result => {
          console.log(result.opaAccount);
          //this.model.opaDetail = result.opaAccount;
          setTimeout(() => {
            this.formGroup.disable();
            this.formGroupOtherParty.disable();
            this.prevFormGroup.get('opaAgreementId').enable();
            this.formGroup.patchValue({ opaDetail: result.opaAccount });
            this.formGroupOtherParty.get('opaDetail.contactPerson').patchValue(result.opaAccount.contactPerson);
            this.model.opaDetail.editable = true;
            this.model.opaDetail.id = result.opaAccount.id;
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
      );
    }else{
      setTimeout(() => {
            if(!fieldData.event || !fieldData.event.initial){
              if (this.opaAgreementId) {
                setTimeout(() => {
                  this.model.opaAgreementId = this.opaAgreementId;
                  this.prevFormGroup.get('opaAgreementId').setValue(this.opaAgreementId);
                });
                this.modalService.open(
                  ServiceMessageComponent,
                  {
                    messages: [
                      {
                        message: 'opaAgreementIdCanBeRemovedFromStep3',
                      },
                    ],
                    type: SereviceMessageType.ERROR,
                  },
                  true,
                  DEFAULT_DIALOG_CONFIG,
                )
                .afterClosed().subscribe();
                return;
              }
            this.formGroup.enable();
            this.formGroupOtherParty.enable();
            this.prevFormGroup.get('opaAgreementId').enable();
            this.model.opaDetail = {
              authorizedOfficial : {},
              contactPerson: {},
              editable: true,
            };
            this.formGroup.reset({ opaDetail: this.model.opaDetail });
            this.formGroupOtherParty.reset({'opaDetail.contactPerson' : this.model.opaDetail});

            }
          });
    }
    }
  }

 saveAsDraft() {
    this.saveAndProceed(true);
  }

  saveAndProceed(draft: boolean = false) {
    if (this.formGroup.status !== 'DISABLED' && !this.validateForm(this.formGroup)) {
      this.markInvalid();
      return;
    }
    if (this.checkForDisableState()) {
      this.model.action = 'SAVE';
      this.service.saveOpaAccount(this.model, draft).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          console.log(data);
          this.service.currentData = data.entity;
          this.service.prevStepData = cloneDeep(this.service.currentData);
          this.service.initModelChangeTracking();
          if (!draft) {
            this.service.nextStep();
          }
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrors(this.formGroup.controls.opaDetail as FormGroup, error.error);
          setTimeout(() => this.validateForm(this.formGroup));
        },
      );
    } else {
      this.service.nextStep();
    }
  }

  submit() {
    if (this.formGroup.status !== 'DISABLED' && !this.validateForm(this.formGroup)) {
      this.markInvalid();
      return;
    }
    const message =this.existInMarkit ? 'submitToOpaMessage' : 'generateAgreementMessage';
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message,
        metaDataList: [
          this.model.opaName],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.model.action = 'SUBMIT';
        this.model.opaAccount = null;
        this.service.saveOpaAccount(this.model).pipe(
          takeUntil(this._destroy$),
        ).subscribe(
          data => {
            console.log(data);
            this.router.navigate(this.helperService.getTranslatedPath(
              `/agreement-credits/generate/${data.entity.id}/${this.existInMarkit}`));
          },
          (error: HttpErrorResponse) => {
            this.formService.parseErrors(this.formGroup.controls.opaDetail as FormGroup, error.error);
            setTimeout(() => this.validateForm(this.formGroup));
          },
    );
      }

    });


  }

}
