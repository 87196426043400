import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import {
  CCR_CURRENT_STEP_KEY,
  CCR_DATA_KEY,
  CCR_STEP_COMPONENTS, CCR_TYPE,
} from '@module/credit-creation-reports/const/credit-creation-reports.const';
import {
  ICreditCreationReport,
  ICreditCreationReportGetAlphNumCI,
  ICreditCreationReportGetFacilityPayload,
  ICreditCreationReportPayload,
} from '@module/credit-creation-reports/credit-creation-reports.types';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FilterModel } from '@core/models/filter.model';
import { HelperService } from '@shared/services/helper.service';
import { IProject } from '@shared/services/project.service.types';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { StoreService } from '@core/store/store.service';
import { LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP } from '@core/services/lookup.service';
import { downloadBlob } from '@core/utilities/utilities.constants';

export const NEW_CREDIT_CREATION_REPORT_URI = '/cfr-service/creditCreationReport/getNewCreditCreationReport';
export const CCR_FACILITIES_URI = '/cfr-service/creditCreationReport/getFacilities';
export const CCR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export const CCR_FUEL_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const CCR_CREDIT_TYPE_REPLACEMENT_URI = '/cfr-service/creditCreationReport/getCreditTypeForFuelType';
export const CCR_FEEDSTOCK_TYPE_URI = '/account-service/application/getForCCRLookup';
export const CCR_APPLICATION_DETAILS_URI = CCR_FEEDSTOCK_TYPE_URI;
export const CCR_REFERENCE_CARBON_INTENSITY = '/cfr-service/creditCreationReport/getReferencedCarbonIntensity';
export const CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForFuel';
export const CCR_STEP_DETAILS = '/cfr-service/creditCreationReport/pageSearchCreditCreationReportDetail';
export const CCR_GET_REFERENCE_CI_ELECTRICITY = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForElectricity/';

export const CCR_SAVE_REPORT = '/cfr-service/creditCreationReport/saveCreditCreationReport';
export const CCR_SUBMIT_REPORT = '/cfr-service/creditCreationReport/submitCreditCreationReport';
export const CCR_RE_SUBMIT_REPORT = '/cfr-service/creditCreationReport/reSubmitCreditCreationReport';
export const CCR_SAVE_AND_PROCEED = '/cfr-service/creditCreationReport/saveAndProceedCreditCreationReport';
export const CCR_APPROVED_CARBONINTENSITIES = '/cfr-service/creditCreationReport/getApprovedCarbonIntensity/';
export const CCR_FUEL_REPLACEMENT_TYPE = '/cfr-service/creditCreationReport/getReplacementTypesForFuelType/';
export const CCR_UPDATE_STATUS = '/cfr-service/creditCreationReport/updateStatus';
export const CCR_DISCARDS = '/cfr-service/creditCreationReport/discard';
export const CCR_DOWNLOAD_REPORT = '/cfr-service/creditCreationReport/download/report/';

export const GET_FACILITY_DETAILS = '/account-service/cfrAccount/account/getFacilityDetail/';
export const GET_FOREIGN_SUPPLIER_DETAILS = '/account-service/account/getFacility/';
export const GET_REPORT_BY_ID = '/cfr-service/creditCreationReport/getReportById/';
export const GET_REPORT_BY_ID_AND_STEP = '/cfr-service/creditCreationReport/getReportByIdAndStep/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/creditCreationReport/getReportToResubmit/';
export const REPORT_LIST_URI = '/cfr-service/creditCreationReport/pageSearchByFilter';
export const GET_FUELING_STATION = '/account-service/getFuelStation/';

export const CCR_PROJECT_LIST_FOR_ACCOUNT = '/cfr-service/creditCreationReport/getProjectListForAccount';
export const CCR_PROJECT_DETAILS = '/account-service/application/get';
export const GET_CI_OF_REGISTRATION_REPORT = '/account-service/agreementTuc/findCIsForActiveAgreements/';
export const LOAD_AGREEMENT_BY_ID = '/account-service/agreementTuc/load/';
export const CREDIT_TRANSFER_DOWNLOAD_PDF = '/obps-service/creditAction/download/transfer';

export const COMP_REP_DOC_UPLOAD_ID = '/cfr-service/complianceReport/getDocumentIdForReference';

export const CCR_PROPOSE = '/cfr-service/creditCreationReport/proposeCreditCreationReport';

@Injectable({
  providedIn: 'root',
})
export class CreditCreationReportsService extends BaseStepperService {

  public registrationReportsDetail: any;
  // public report: ICreditCreationReport;

  stepRoutineMap: any = {};

  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();

  private _callMethod$ = new BehaviorSubject<any>(null);
  public callMethod$ = this._callMethod$.asObservable();

  public currentStepInvalid = false;

  constructor(
    public http: HttpClient,
    public router: Router,
    public helperService: HelperService,
    public modalService: ModalService,
    public store: StoreService
  ) {
    super();
  }

  public callMethod(method: string, args: any = null) {
    this._callMethod$.next({method, args});
  }

  getNewCreditCreationReport(payload: ICreditCreationReportPayload): Observable<any> {
    return this.http.post(`${ environment.apiUrl }${ NEW_CREDIT_CREATION_REPORT_URI }`, payload);
  }

  getCCRStepDetails(reportId: number, stepCode: string) {
    const payload = { reportId, stepCode };
    return this.http.post<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_STEP_DETAILS }`, payload);
  }

  getFacilities(payload: ICreditCreationReportGetFacilityPayload) {
    return this.http.post(`${ environment.apiUrl }${ CCR_FACILITIES_URI }`, payload);
  }

  getAlphanumericIDofCI(payload: ICreditCreationReportGetAlphNumCI): Observable<IApp.ILookup[]> {
    return this.http.post<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_CARBON_INTENSITY_IDS_LOOKUP_URI }`, payload);
  }

  getFeedstockRegion(id: number) {
    return this.http.get(`${ environment.apiUrl }${ CCR_FEEDSTOCK_TYPE_URI }/${ id }`);
  }

  getApplicationDetails(id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ CCR_APPLICATION_DETAILS_URI }/${ id }`);
  }

  getFuelEnergyDensity(fuelId: number): Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_FUEL_ENERGY_DENSITY_URI }/${ fuelId }`);
  }

  getCreditTypeReplacement(type: string): Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ CCR_CREDIT_TYPE_REPLACEMENT_URI }/${ type }`);
  }

  getReferenceCarbonIntensity(year: number, fuelType: string): Observable<number> {
    return this.http.get(`${ environment.apiUrl }${ CCR_REFERENCE_CARBON_INTENSITY }/${ year }/${ fuelType.toUpperCase() }`)
      .pipe(map((response: IApp.ILookup) => Number(response.value)));
  }

  getCFRReferenceCarbonIntensity(year: number, fuelId: number): Observable<any> {
    return this.http.get(`${ environment.apiUrl }${ CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL }/${ year }/${ fuelId }`);
  }

  storeCurrentData() {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(CCR_DATA_KEY, data);
    const currentStep = JSON.stringify(this.currentStep);
    sessionStorage.setItem(CCR_CURRENT_STEP_KEY, currentStep);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CCR_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CCR_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
      const currentStep = sessionStorage.getItem(CCR_CURRENT_STEP_KEY);
      if (currentStep) {
        sessionStorage.removeItem(CCR_CURRENT_STEP_KEY);
        return JSON.parse(currentStep);
      } else {
        return null;
      }
  }

  saveReport(payload) {
    return this.http.post(`${ environment.apiUrl }${CCR_SAVE_REPORT }`, {...payload, action: 'SAVE'})
  }

  saveAndProceed(payload) {
    return this.http.post(`${ environment.apiUrl }${CCR_SAVE_AND_PROCEED}`, {...payload, action: 'SAVE'})
  }

  getApprovedCarbonIntensities(complianceYear, fuelId, facilityName, reg = false, defaultCICode = null) {
    facilityName = facilityName.replace(/[^\w\s]/gi, '');
    let uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/${facilityName}`;
    if (defaultCICode) {
      uri += `/${defaultCICode}`;
    }
    if (reg) {
      uri += '?reg=true';
    }
    return this.http.get(`${ environment.apiUrl }${uri}`);
  }

  getFuelReplacementType(fuelType):Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${CCR_FUEL_REPLACEMENT_TYPE}${fuelType}`);
  }

  getFacilityDetails(facilityId, skipValidation = false): Observable<any> {
    let params = new HttpParams();
    params = params.append('skipValidation', `${skipValidation}`);
    return this.http.get<any>(`${ environment.apiUrl }${ GET_FACILITY_DETAILS }${facilityId}`, {params});
  }

  getEnergyEfficiencyForAnnualStep(stepCode: string) {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP }/${ stepCode }`);
  }

  getForeignSupplierDetails(facilityId): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_FOREIGN_SUPPLIER_DETAILS }${facilityId}`);
  }

  getReportById(reportId, summary: boolean) {
    const uri = summary ? `${reportId}/${summary}`: `${reportId}`;
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${uri}`);
  }

  getReportByIdandStep(reportId, currentStepCode ,summary: boolean){
    const uri = summary ? `${reportId}/${summary}`: `${reportId}/${currentStepCode}`;
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_AND_STEP }${uri}`);
  }

  getCreditReportList(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);

    return this.http.post<ICreditCreationReport>(`${environment.apiUrl}${ REPORT_LIST_URI }`, filterState, { params });
  }

  triggerActionController(metadata: any, redirect = false) {
    const action = metadata.action;
    const payload = metadata.data;

    if (action.workflowAction === 'VIEW') {
      this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}`));
    } else if (action.workflowAction === 'DOWNLOAD_CREDIT_CREATION_REPORT') {
      this.downloadDocument(payload.id);
    } else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
    } else if (action.workflowAction === 'RESUBMIT') {
      if (payload.type === 'CREDIT_ADJUSTMENT') {
        this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${payload.id}/RESUBMIT`));
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}/RESUBMIT`));
      }
    } else if (action.workflowAction === 'SUBMIT_TO_ECCC_VERIFICATION_REPORT' || action.workflowAction === 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT') {
      if (payload.type === 'CREDIT_ADJUSTMENT') {
        this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${payload.id}`));
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${payload.id}`));
      }
    } else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
    } else {
      this.executeAction(payload, action, redirect);
    }
  }

  getReferenceCarbonIntensityForFuel(year: number, fuelId: number): Observable<number> {
    return this.http.get(`${ environment.apiUrl }${ CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL }/${ year }/${ fuelId }`)
      .pipe(map((response: IApp.ILookup) => Number(response.value)));
  }

  public updateCCR(payload: any): Observable<IProject> {
    const uri = payload.action === 'DISCARD' ? CCR_DISCARDS : CCR_UPDATE_STATUS;
    return this.http.post<any>(`${environment.apiUrl}${uri}`, payload);
  }

  public downloadDocument(documentId) {
    const uri = environment.serviceUrl + CCR_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  executeAction(payload , action, redirect) {
    const request = {id: payload.id, action: action.workflowAction};
    const confirmationMessage = action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ? 'confirmationMessageNOECreation' : payload.type !=='CREDIT_ADJUSTMENT' ?  'confirmationMessageCCReport' : 'confirmationMessageCAReport';
    const actionLabel = action.workflowAction;

    if (action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ||
      action.workflowAction === 'CREATE_NOTICE_OF_SUSPENSION') {
      const uri = action.workflowAction === 'CREATE_NOTICE_OF_ERROR' ? '/notice-of-error' : '/notice-of-suspension';
      this.router.navigate(this.helperService.getTranslatedPath(uri),
        {state: { data: payload, formType: action.workflowAction }});
      return;
    }

    const sub$ = this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          payload.legalName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      sub$.unsubscribe();
      if (result) {
        this.modalService.closeAllMessageDialogs();


        this.updateCCR(request).subscribe(response => {
          if (!redirect) {
            this._refreshData$.next(true);
          } else {
            this.resetStepper();
            this.store.back();
          }
        });
      }
    });
  }

  public getFuelingStation(orgId, stationId) {
    return this.http.get(`${ environment.apiUrl }${ GET_FUELING_STATION }${orgId}/${stationId}`);
  }

  public submitReport(payload) {
    return this.http.post<any>(`${environment.apiUrl}${CCR_SUBMIT_REPORT}`, {...payload, action: 'SUBMIT'});
  }

  public getProjectListForAccount(accountId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${CCR_PROJECT_LIST_FOR_ACCOUNT}/${accountId}`);
  }

  public getProjectDetails(projectId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${CCR_PROJECT_DETAILS}/${projectId}`);
  }

  public openConfirmationModal(msg, yesNoButton = false) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          yesNoButton,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

  clearCallMethod(): void {
    this._callMethod$.next(null);
  }


  public showErrorMessage(message: string) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          message: message,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

  showWarning(message, metaDataList?) {
    return this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: message,
        metaDataList,
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed();
  }

  public getCarvonIntensitiesOfRegistrationReport(accountId, complianceYear) {
    return this.http.get(`${ environment.apiUrl }${ GET_CI_OF_REGISTRATION_REPORT }${accountId}/${complianceYear}`);
  }

  public getAgreementByID(id) {
    return this.http.get<any>(`${ environment.apiUrl}${LOAD_AGREEMENT_BY_ID}${id}`);
  }

  public getReferencedCarbonIntensityForElectricity(province) {
    return this.http.get<any>(`${ environment.apiUrl}${CCR_GET_REFERENCE_CI_ELECTRICITY}${province}`);
  }

  public toggleCreditTransferStep(NILStepsCount) {
    if (NILStepsCount === 6) {
      this.steps = this.steps.filter(step => step.code !== 'STEP-7').map((step, index) => ({...step, id: index + 1}));
    } else if (this.steps.findIndex(step => step.code === 'STEP-7') === -1) {
      this.setReportSteps();
    }
  }

  public setReportSteps() {
    const steps = this.currentData.associatedSteps.map((step, index) => {

      const currentStepIdentifier = step.code.split('-')[1].trim();
      let component =  CCR_STEP_COMPONENTS.CREDIT_CREATION_REPORT_STEP;

      if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '9') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '7')) {
        component = CCR_STEP_COMPONENTS.CREDIT_CREATION_CONTACT_PERSON;
      } else if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '10') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '8')) {
        component = CCR_STEP_COMPONENTS.CREDIT_CREATION_VERIFICATION;
      } else if ((this.currentData.type === CCR_TYPE.QUARTERLY && currentStepIdentifier === '11') || (this.currentData.type === CCR_TYPE.ANNUAL && currentStepIdentifier === '9')) {
        component = CCR_STEP_COMPONENTS.CREDIT_CREATION_SUMMARY;
      }
      return {
        ...step,
        name: this.getStepName(step[this.translateService.currentLang]),
        completed: false,
        skipTranslate: true,
        id: index + 1,
        component,
      }
    });
    this.steps = [...steps];
  }

  public getStepName(val): string {
    const arr = val.split(': ');
    arr.shift();
    return arr.join(': ');
  }

  reSubmitReport(payload): Observable<any> {
    return this.http.post(`${ environment.apiUrl }${ CCR_RE_SUBMIT_REPORT }`, {...payload, action: 'RESUBMIT'});
  }

  getReportByIdToReSubmit(reportId) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_TO_RESUBMIT }${ reportId }`);
  }

  public getDocUploadID() {
    return this.http.get<any>(`${environment.apiUrl}${COMP_REP_DOC_UPLOAD_ID}`);
  }

  public propose(payload) {
    return this.http.post(`${ environment.apiUrl }${CCR_PROPOSE}`, payload)
  }

}
