import { HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { exportPdfAction, exportxlsAction, FilterModel } from '@core/models/filter.model';
import { CurrentUser } from '@core/models/user.model';
import { isEmpty } from 'lodash';
import {
  LOOKUP_ACCOUNT_LEVELS,
  LOOKUP_ACTIVITY_LOG_ORGNAMES,
  LOOKUP_ACTIVITY_LOG_ORGROLES,
  LOOKUP_AGREEMENT_ACTIVITY,
  LOOKUP_AGREEMENT_ISSUE_YEAR,
  LOOKUP_AGREEMENT_STATUS,
  LOOKUP_ALL_ACTION_PERFORMED_BY,
  LOOKUP_ALL_FUELS,
  LOOKUP_ALL_USER_ROLES,
  LOOKUP_ALL_USER_ROLES_LIST,
  LOOKUP_ALL_USER_STATUSES,
  LOOKUP_COI_STATUS,
  LOOKUP_CROSS_COMPLIANCE_PERIODS,
  LOOKUP_ERP_FUEL_LIST,
  LOOKUP_ERP_PROJECT_NAME_LIST,
  LOOKUP_ERP_PROJECT_STATUS_LIST,
  LOOKUP_OFFSET_DEADLINE_TYPE,
  LOOKUP_OFFSET_GFG_PROTOCOLS,
  LOOKUP_OFFSET_PROJECT_IDNAMES,
  LOOKUP_OFFSET_PROJECT_NAMES,
  LOOKUP_OFFSET_PROJECT_STATUS,
  LOOKUP_OFFSET_PROTOCOL_STATUS,
  LOOKUP_OFFSET_SUBMISSIONS_STATUS,
  LOOKUP_OPA_NAME,
  LOOKUP_PROJECT_TYPE,
  LOOKUP_PROVINCE_BY_CANADA,
  LOOKUP_PUBLIC_OFFSET_PROJECT_ID,
  LOOKUP_PUBLIC_OFFSET_PROJECT_NAMES,
  LOOKUP_PUBLIC_OFFSET_PROJECT_PROPONENTS,
  LOOKUP_PUBLIC_OFFSET_PROJECT_STATUS,
  LOOKUP_PUBLIC_OFFSET_PROJECT_TYPE,
  LOOKUP_RCA_NAME,
  LOOKUP_SEARCH_REPORT_OFFSET_REGISTERED_PROJECT_NAMES,
  LOOKUP_SEARCH_REPORT_OFFSET_REJECTED_PROJECT_NAMES,
  LOOKUP_UNIT_VINTAGE_YEAR,
  LOOKUP_VB_STATUS,
  LOOKUP_VB_SUBMISSION_TYPE,
  LOOKUP_VB_TYPE,
  LOOKUP_VINTAGE_YEARS,
  LookupService,
  LOOKUP_CREDIT_GROUP_LIST,
  LOOKUP_CREDIT_CLASS_LIST,
  LOOKUP_CREDIT_COMPLIANCE_YEAR_LIST,
  LOOKUP_CREDIT_STATUS_LIST,
  LOOKUP_UNIT_FUEL_LIST,
  LOOKUP_TUC_FUEL_TYPE,
  LOOKUP_TUC_COMPLIANCE_PERIOD,
  LOOKUP_TUC_TRANSFEROR_ORGANIZATION_NAME,
  LOOKUP_TUC_TRANSFEREE_ORGANIZATION_NAME,
  LOOKUP_TUC_CURRENT_STATUS,
  LOOKUP_CSR_COMPLIANCE_YEAR,
  LOOKUP_CSR_JURISDICTION,
  LOOKUP_CSR_CREDIT_CLASS,
  LOOKUP_CSR_CREDIT_CATEGORY,
  LOOKUP_CSR_ACTIVITY_TYPE,
  LOOKUP_CSR_FUEL_TYPE,
  LOOKUP_CSR_QUANTIFICATION_METHOD,
  LOOKUP_CSR_STATUS,
  LOOKUP_STATUS_FOR_CREDIT_CREATION,
  LOOKUP_PUBLIC_OFFSET_UNIT_PROPONENTS,
  LOOKUP_PUBLIC_OFFSET_UNIT_ISSUANCE_YEAR,
  LOOKUP_PUBLIC_OFFSET_UNIT_PROTOCOL,
  LOOKUP_PUBLIC_OFFSET_UNIT_VINTAGE,
  LOOKUP_PUBLIC_OFFSET_ACCOUNT_TYPE,
  LOOKUP_PUBLIC_OFFSET_VERIFICATION_BODY,
  LOOKUP_ERP_FACILITY_LIST,
  LOOKUP_CHR_COMPLIANCE_PERIOD,
  LOOKUP_CHR_CREDIT_CLASS,
  LOOKUP_CHR_CREDIT_CATEGORY,
  LOOKUP_CHR_TRANSACTION_TYPE,
  LOOKUP_REPORT_TYPE_CCR,
  LOOKUP_NOE_INSUFFICIENT_CREDIT_STATUS_LIST, LOOKUP_REPORT_INSUFFICIENT_CREDITS_TO_CANCEL_YEAR_LIST,
  LOOKUP_COI_NOTICE_STATUS,
  LOOKUP_VERIFICATION_REPORT_STATUS,
  LOOKUP_COI_CHECKLIST_ACCOUNT_NAMES,
  LOOKUP_GET_CCM_STATUS,
  LOOKUP_GET_CB_STATUS,
  LOOKUP_CREDIT_BALANCE_COMPLIANCE_YEARS,
  LOOKUP_CREDIT_REVENUE_COMPLIANCE_YEARS,
  LOOKUP_CREDIT_REVENUE_STATUS_LIST,
  GET_ALL_FUELS,
  LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEAR_LIST,
} from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IDateRange } from '@shared/components/date-range/date-range.component';
import { NgSelectComponent } from '@shared/components/ng-select/ng-select.component';
import {
  TABLE_FILTER_DATE_FIELDS,
  TABLE_FILTER_DATE_RANGE_FIELDS,
  TABLE_FILTER_SETTINGS_KEY
} from '@shared/components/table-filter/table-filter.const';
import {
  ActionOptionType,
  FilterFieldsLists,
  IActionOptionsModel,
  ICustomButton,
  IDateFieldConfig,
  IDateRangeFields,
  IFilterModel,
  ITableFilterActionModel,
  ITableFilterSortedField,
} from '@shared/models/table-filter.models';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { ITableFilterParams, ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { distinctUntilChanged, filter, first, map, skip, takeUntil } from 'rxjs/operators';
import { TableNewRowComponent } from '../table-new-row/table-new-row.component';
import { uniqBy } from 'lodash';
import { insufficientCredits } from '@module/reports/reports.const';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fields: ITableFilterActionModel;
  @Input('config') config: ITableViewConfig;
  @Input('fallback') fallback?: boolean;
  @Input() filterParams: ITableFilterParams;
  @Input() filterNameSuffix: string;
  @Input() wideMode = false;
  @Input() defaultValuesForNewRow = {};

  @Output() actionSelected = new EventEmitter<IActionOptionsModel>();
  @Output() filterChanged = new EventEmitter<FilterModel>();
  @Output() filterCleared = new EventEmitter<any>();
  @Output() filterInit = new EventEmitter<FilterModel>();
  @Output() requestExport = new EventEmitter<string>();
  @Output() newEntryAdded = new EventEmitter<any>();
  @Output() newPopUpCreated = new EventEmitter<any>();
  @Output() adminActions = new EventEmitter<any>();
  @Output() customButtonAction = new EventEmitter<any>();

  @ViewChildren(NgSelectComponent) ngSelectList!: QueryList<NgSelectComponent>;
  @ViewChild('searchTextBox', { static: false }) searchTextBox: ElementRef;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();
  private filterParent: string;
  filterFields: FilterFieldsLists;

  filterRequest: FilterModel;
  user: CurrentUser;

  selectedActionOption: IActionOptionsModel;
  actionSelectedDummyModel: IActionOptionsModel = {};

  sortedFieldsList: ITableFilterSortedField[] = [];

  private allFacilityNames: IApp.ILookup[];
  public facilityNamesModel: number[];
  private _facilityNamesChanged = new BehaviorSubject<number[]>(null);

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    private lookupService: LookupService,
    private translate: TranslateService,
    private store: StoreService,
    private router: Router,
    private helperService: HelperService,
    private uploadDocumentService: UploadDocumentService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.previousValue !== undefined) {
      this._destroy$.next();
      setTimeout(() => this.ngOnInit());
    }
  }

  ngOnInit() {
    this.filterParent = this.filterName;
    this.user = this.store.user;
    this.setDefaultFilter();
    this.filterFields = new FilterFieldsLists();
    this.getLookUpData(false);
    this.translateActionOptions();
    this.translateAdminOptions();

    // maintain correct order of the fields
    this.buildFilterFieldsList();

    this.translate.onLangChange.pipe(takeUntil(this._destroy$)).subscribe((event: LangChangeEvent) => {
      this.getLookUpData(true);
      this.translateActionOptions();
      this.translateAdminOptions();
      for (let i = 0; i < this.fields.actionModel.length; i++) {
        this.fields.actionModel[i].options = [...this.fields.actionModel[i].options];
      }
    });

    this.store.accountFacilities$
      .pipe(
        takeUntil(this._destroy$),
        filter(data => data !== null),
        skip(1),
      )
      .subscribe(() => {
        this.getFacilityNames();
        if (this.store.accountFacilities.length > 0) {
          this.updateFilterFacilityNames();
          this.filterChanged.emit(this.filterRequest);
        }
      });
  }

  translateActionOptions() {
    if (this.fields.actionModel) {
      this.fields.actionModel.forEach(actionModel => {
        actionModel.options.forEach(option => (option.labelFormatted = this.translate.instant(option.label)));
        actionModel.options.sort((a, b) => a.labelFormatted.localeCompare(b.labelFormatted));
        actionModel.id = this.randomId;
        return actionModel;
      });
    }
  }

  translateAdminOptions() {
    if (this.fields.adminActions) {
      this.fields.adminActions.forEach(adminModel => {
        adminModel.options.forEach(option => (option.labelFormatted = this.translate.instant(option.label)));
        return adminModel;
      });
    }
  }

  setDefaultFilter() {
    this.filterRequest = new FilterModel();
    this.getStoredFilterSettings();
    this.filterInit.emit(this.filterRequest);
  }
  addRow() {
    if (this.fallback) {
      this.newPopUpCreated.emit();
    } else {
      this.modalService
        .open(TableNewRowComponent, { form: this.config, actionType: TABLE_NEW_ROW_ACTION.NEW, defaultValues: this.defaultValuesForNewRow } as TableNewRow)
        .afterClosed()
        .subscribe((result?: any) => {
          if (result) {
            this.newEntryAdded.emit(result);
          }
        });
    }
  }

  getOptions(action) {
    return action.options.map(option => ({ ...option, label: this.translate.instant(option.label) }));
  }

  getLookUpData(langChange: boolean) {
    this.fields.filterListsModel.forEach(actionModel => {
      switch (actionModel.itemsType) {
        case 'accountIds':
        case 'facilityNames':
        case 'legalName':
          this.getFacilityNames();
          break;
        case 'crossFacilityNames':
        case 'crossAccountIds':
          this.getCrossFacilityNames();
          break;
        case 'facilityStatus':
          this.getFacilityStatus();
          break;
        case 'cfrfacilityStatus':
          this.getFacilityStatus('cfr');
          break;
        case 'accountTypes':
          this.getAccountTypes();
          break;
        case 'accountSubTypeNames':
          this.getAccountSubTypeNames();
          break;
        case 'facilityCertificateNos':
          this.getFacilityCertificateNos();
          break;
        case 'responsiblePersons':
          this.getResponsiblePersons();
          break;
        case 'unitStatus':
          this.getUnitStatus();
          break;
        case 'ruUnitStatus':
          this.getRuUnitStatus();
          break;
        case 'publicUnitStatus':
          this.getPublicUnitStatus();
          break;
        case 'compliancePeriod':
          this.getCompliancePeriod();
          break;
        case 'unitVintage':
          this.getLookup(LOOKUP_UNIT_VINTAGE_YEAR, actionModel.itemsType);
          break;
        case 'crossCompliancePeriod':
          this.getLookup(LOOKUP_CROSS_COMPLIANCE_PERIODS, actionModel.itemsType);
          break;
        case 'resultCompliancePeriods':
          this.getResultCompliancePeriods();
          break;
        case 'versions':
          this.getFacilityVersions();
          break;
        case 'obligationType':
        case 'obpsResortType':
          this.getObpsReportTypes(actionModel.itemsType);
          break;
        case 'versionStatus':
          this.getVerionStatus();
          break;
        case 'paymentStatus':
          this.getPaymentStatus();
          break;
        case 'transactionType':
          this.getTransactionType();
          break;
        case 'remittanceTypes':
          this.getRemittanceTypes();
          break;
        case 'compensationStatus':
          this.getObligationStatus(actionModel.itemsType);
          break;
        case 'paymentRefundModes':
          this.getPaymentRefundMode();
          break;
        case 'paymentRefundStatus':
          this.getPaymentRefundStatus();
          break;
        case 'documentStatusList':
          this.getUploadOBPSStatus();
          break;
        case 'workflowApprovalTypes':
          this.getWorkflowApprovalTypes();
          break;
        case 'taskTypeList':
          this.getTaskType();
          break;
        case 'assignedToList':
          this.getAssignedToList();
          break;
        case 'entityTypes':
          this.getOrgEntityType();
          break;
        case 'provinces':
          this.getProvinces();
          break;
        case 'submissionType':
          this.getSubmissionTypes();
          break;
        case 'submissionsType':
          this.getSubmissionsType();
          break;
        case 'reportingPeriod':
          this.getReportingPeriod();
          break;
        case 'typeCode':
          this.getTypeCodes();
          break;
        case 'accountLevels':
          this.getLookup(LOOKUP_ACCOUNT_LEVELS, actionModel.itemsType, 'COMMON.accountLevelList.');
          break;
        case 'vbSubmissionType':
          this.getLookup(LOOKUP_VB_SUBMISSION_TYPE, actionModel.itemsType, 'COMMON.submissionTypeList.');
          break;
        case 'vbRole':
          this.getLookup(LOOKUP_VB_TYPE, actionModel.itemsType, 'COMMON.roleList.');
          break;
        case 'vbStatus':
          this.getLookup(LOOKUP_VB_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'agreementIssueYear':
          this.postLookup(LOOKUP_AGREEMENT_ISSUE_YEAR, actionModel.itemsType);
          break;
        case 'rcaName':
          this.postLookup(LOOKUP_RCA_NAME, actionModel.itemsType);
          break;
        case 'opaName':
          this.postLookup(LOOKUP_OPA_NAME, actionModel.itemsType);
          break;
        case 'agreementActivity':
          this.postLookup(LOOKUP_AGREEMENT_ACTIVITY, actionModel.itemsType, 'COMMON.submissionTypeList.');
          break;
        case 'agreementStatus':
          this.postLookup(LOOKUP_AGREEMENT_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'coiStatus':
          this.postLookup(LOOKUP_COI_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'assignedVB':
          this.getAssignedVB();
          break;
        case 'regActivityTypes':
          this.getRegReportActivities();
          break;
        case 'cfrCompliancePeriod':
          this.getCfrCompliancePeriod();
          break;
        case 'submissionReportStatus':
          this.getSubmissionReportStatus();
          break;
        case 'areaOfExpertise':
          this.getAreaOfExpertise('REGISTRATION_ACCOUNT', 'areaOfExpertise');
          break;
        case 'allAreaOfExpertise':
          this.getAreaOfExpertise('ALL', 'allAreaOfExpertise');
          break;
        case 'vbAreaOfExpertise':
          this.getAreaOfExpertise('VB_USER', 'vbAreaOfExpertise');
          break;
        case 'quantificationMethods':
          this.getQuantificationMethods(langChange);
          break;
        case 'feedstockTypes':
          this.getFeedstock();
          break;
        case 'fuelTypes':
          this.getFuelTypes();
          break;
        case 'allFuels':
          this.getLookup(GET_ALL_FUELS, actionModel.itemsType);
          break;
        case 'facilitiesList':
          this.getFacilitiesByProvince(actionModel.flattenResponse);
          break;
        case 'CIFacilitiesList':
          this.getCIFacilitiesByProvince();
          break;
        case 'taskFamilyList':
          this.getTaskFamilyList();
          break;
        case 'rolesList':
          this.getLookup(LOOKUP_ALL_USER_ROLES, actionModel.itemsType, 'COMMON.roleList.');
          break;
        case 'cfrRolesList':
          this.getLookup(LOOKUP_ALL_USER_ROLES_LIST, actionModel.itemsType, 'COMMON.cfrRoleList.');
          break;
        case 'statusList':
          this.getLookup(LOOKUP_ALL_USER_STATUSES, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'cfrStatusList':
          this.getLookup(LOOKUP_ALL_USER_STATUSES, actionModel.itemsType, 'COMMON.cfrstatusList.');
          break;
        case 'actionPerformedBy':
          this.postLookup(LOOKUP_ALL_ACTION_PERFORMED_BY, actionModel.itemsType, null, true);
          break;
        case 'alOrgRoles':
          this.postLookup(LOOKUP_ACTIVITY_LOG_ORGROLES, actionModel.itemsType, 'COMMON.accountSubTypeList.');
          break;
        case 'alOrgNames':
          this.postLookup(LOOKUP_ACTIVITY_LOG_ORGNAMES, actionModel.itemsType);
          break;
        case 'type':
          if (this.getFilterParam('projectTypeId')) {
            this.getProjectHistoryActionByTypeById(this.getFilterParam('projectTypeId'));
          }
          if (this.getFilterParam('type')) {
            this.getAccountHistoryActionByTypeById(this.getFilterParam('type'));
          }
          break;
        case 'action':
          if (this.getFilterParam('projectActionId')) {
            this.getProjectHistoryActionPerformedByUserById(this.getFilterParam('projectActionId'));
          }
          if (this.getFilterParam('action')) {
            this.getAccountHistoryActionPerformedByUserById(this.getFilterParam('action'));
          }
          break;
        case 'projectName':
          this.getLookup(LOOKUP_OFFSET_PROJECT_NAMES, actionModel.itemsType, null, 'subProjects');
          break;
        case 'publicProjectName':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_PROJECT_NAMES, actionModel.itemsType, null, 'subProjects');
          break;
        case 'submissionProjectName':
          this.getHeirarchialProjectNames(actionModel);
          break;
        case 'projectJurisdiction':
          this.getLookup(LOOKUP_PROVINCE_BY_CANADA, actionModel.itemsType, 'COMMON.jurisdictionsList.');
          break;
        case 'projectNameJurisdiction':
          this.getJurisdictions();
          break;
        case 'publicProjectLocation':
          this.getLookup(LOOKUP_PROVINCE_BY_CANADA, actionModel.itemsType, 'COMMON.jurisdictionsList.');
          break;
        case 'offsetProtocol':
          this.getLookup(LOOKUP_OFFSET_GFG_PROTOCOLS, actionModel.itemsType);
          break;
        case 'projectStatus':
          this.getLookup(LOOKUP_OFFSET_PROJECT_STATUS, actionModel.itemsType, 'COMMON.projectStatusList.');
          break;
        case 'submisionsStatus':
          this.getLookup(LOOKUP_OFFSET_SUBMISSIONS_STATUS, actionModel.itemsType, 'COMMON.projectStatusList.');
          break;
        case 'searchOffsetProjectIds':
          this.getOffsetProjectIds(LOOKUP_OFFSET_PROJECT_IDNAMES, actionModel.itemsType);
          break;
        case 'searchOffsetRegisteredProjectName':
          this.getLookup(LOOKUP_SEARCH_REPORT_OFFSET_REGISTERED_PROJECT_NAMES, actionModel.itemsType);
          break;
        case 'searchOffsetRejectedProjectName':
          this.getLookup(LOOKUP_SEARCH_REPORT_OFFSET_REJECTED_PROJECT_NAMES, actionModel.itemsType);
          break;
        case 'unitVintageYear':
          this.getLookup(LOOKUP_VINTAGE_YEARS, actionModel.itemsType);
          break;
        case 'offsetProtocolStatus':
          this.getLookup(LOOKUP_OFFSET_PROTOCOL_STATUS, actionModel.itemsType);
          break;
        case 'submissionsType':
          const submissionId = this.getFilterParam('submissionsId');
          if (submissionId) {
            this.getSubmissionsHistoryActivityTypes(submissionId);
          }
          break;
        case 'submissionsAction':
          const submissionsId = this.getFilterParam('submissionsId');
          if (submissionsId) {
            this.getSubmissionsHistoryUsers(submissionsId);
          }
          break;
        case 'deadLineType':
          this.getLookup(LOOKUP_OFFSET_DEADLINE_TYPE, actionModel.itemsType, 'COMMON.submissionTypeList.', 'subProjects');
          break;
        case 'publicProjectProponent':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_PROJECT_PROPONENTS, actionModel.itemsType);
          break;
        case 'publicUnitProponent':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_UNIT_PROPONENTS, actionModel.itemsType);
          break;
        case 'unitType':
          this.getUnitTypes();
          break;
        case 'publicProjectType':
          this.getProjectActivityType(LOOKUP_PUBLIC_OFFSET_PROJECT_TYPE, actionModel.itemsType);
          break;
        case 'publicProjectStatus':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_PROJECT_STATUS, actionModel.itemsType, 'COMMON.projectStatusList.');
          break;
        case 'publicOffsetProjectIds':
          this.getOffsetProjectIds(LOOKUP_PUBLIC_OFFSET_PROJECT_ID, actionModel.itemsType);
          break;
        case 'erpProjectNames':
          this.getLookup(LOOKUP_ERP_PROJECT_NAME_LIST, actionModel.itemsType);
          break;
        case 'erpProjectStatus':
          this.getLookup(LOOKUP_ERP_PROJECT_STATUS_LIST, actionModel.itemsType, 'COMMON.erpProjectStatusList.');
          break;
        case 'erpFuelTypes':
          this.getLookup(LOOKUP_ERP_FUEL_LIST, actionModel.itemsType);
          break;
        case 'creditGroup':
          this.getLookup(LOOKUP_CREDIT_GROUP_LIST, actionModel.itemsType);
          break;
        case 'creditClass':
          this.getLookup(LOOKUP_CREDIT_CLASS_LIST, actionModel.itemsType);
          break;
        case 'cfrUnitCompliancePeriod':
          const uri = this.filterNameSuffix === insufficientCredits.permission ?
            LOOKUP_REPORT_INSUFFICIENT_CREDITS_TO_CANCEL_YEAR_LIST :
            LOOKUP_CREDIT_COMPLIANCE_YEAR_LIST;
          this.getLookup(uri, actionModel.itemsType);
          break;
        case 'cfrUnitStatus':
          this.getLookup(LOOKUP_CREDIT_STATUS_LIST, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'unitFuelType':
          this.getLookup(LOOKUP_UNIT_FUEL_LIST, actionModel.itemsType);
          break;
        case 'transferorOrganizationNames':
          this.postLookup(LOOKUP_TUC_TRANSFEROR_ORGANIZATION_NAME, actionModel.itemsType);
          break;
        case 'transfereeOrganizationNames':
          this.postLookup(LOOKUP_TUC_TRANSFEREE_ORGANIZATION_NAME, actionModel.itemsType);
          break;
        case 'agreementTucCompliancePeriod':
          this.postLookup(LOOKUP_TUC_COMPLIANCE_PERIOD, actionModel.itemsType);
          break;
        case 'agreementTucFuelType':
          this._postAgreementTucFuelType(LOOKUP_TUC_FUEL_TYPE, actionModel.itemsType);
          break;
        case 'agreementTucStatus':
          this.postLookup(LOOKUP_TUC_CURRENT_STATUS, actionModel.itemsType, 'COMMON.statusList.' );
          break;
        case 'csrCompliancePeriodList':
          this.postLookup(LOOKUP_CSR_COMPLIANCE_YEAR, actionModel.itemsType);
          break;
        case 'csrJurisdictionList':
          this.postLookup(LOOKUP_CSR_JURISDICTION, actionModel.itemsType, 'COMMON.jurisdictionsList.');
          break;
        case 'csrCreditClassList':
          this.postLookup(LOOKUP_CSR_CREDIT_CLASS, actionModel.itemsType, 'REPORTS_MODULE.creditsClassList.');
          break;
        case 'csrCreditCategoryList':
          this.postLookup(LOOKUP_CSR_CREDIT_CATEGORY, actionModel.itemsType, 'REPORTS_MODULE.creditCategoryNameList.');
          break;
        case 'csrActivityTypeList':
          this.postLookup(LOOKUP_CSR_ACTIVITY_TYPE, actionModel.itemsType);
          break;
        case 'csrFuelTypeList':
          this.postLookup(LOOKUP_CSR_FUEL_TYPE, actionModel.itemsType);
          break;
        case 'csrQuantificationMethodList':
          this.postLookup(LOOKUP_CSR_QUANTIFICATION_METHOD, actionModel.itemsType);
          break;
        case 'csrUnitStatusList':
          this.postLookup(LOOKUP_CSR_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'creditCreationReportStatus':
          this.getCreditCreationReportStatus(actionModel.itemsType);
          break;
        case 'publicIssuanceYear':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_UNIT_ISSUANCE_YEAR, actionModel.itemsType, null, null, 'code');
          break;
        case 'publicOffsetProtocol':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_UNIT_PROTOCOL, actionModel.itemsType);
          break;
        case 'publicUnitVintage':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_UNIT_VINTAGE, actionModel.itemsType);
          break;
        case 'publicAccountType':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_ACCOUNT_TYPE, actionModel.itemsType, 'COMMON.accountTypeList.');
          break;
        case 'publicVerificationBody':
          this.getLookup(LOOKUP_PUBLIC_OFFSET_VERIFICATION_BODY, actionModel.itemsType);
          break;
        case 'erpFacilitiesList':
          this.getLookup(LOOKUP_ERP_FACILITY_LIST, actionModel.itemsType);
          break;
        case 'ccrCompliancePeriod':
          this.getCcrCompliancePeriod(actionModel.itemsType);
          break;
        case 'chrCompliancePeriodList':
          this.postLookup(LOOKUP_CHR_COMPLIANCE_PERIOD, actionModel.itemsType);
          break;
        case 'chrTransactionTypeList':
          this.postLookup(LOOKUP_CHR_TRANSACTION_TYPE, actionModel.itemsType, 'REPORTS_MODULE.creditHistoryActivityList.');
          break;
        case 'chrCreditClassList':
          this.postLookup(LOOKUP_CHR_CREDIT_CLASS, actionModel.itemsType, 'REPORTS_MODULE.creditsClassList.');
          break;
        case 'chrCreditCategoryList':
          this.postLookup(LOOKUP_CHR_CREDIT_CATEGORY, actionModel.itemsType, 'REPORTS_MODULE.creditCategoryNameList.');
          break;
        case 'reportType':
          this.getLookup(LOOKUP_REPORT_TYPE_CCR, actionModel.itemsType, 'COMMON.filters.');
          break;
        case 'insufficientCreditStatus':
          this.getLookup(LOOKUP_NOE_INSUFFICIENT_CREDIT_STATUS_LIST, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'vbLegalName':
          this.getVerificationFilter();
          break;
        case 'technicalScope':
          this.getAreaOfExpertise('REGISTRATION_ACCOUNT', 'technicalScope');
          break;
        case 'complianceReportStatus':
          this.getComplianceReportStatus(actionModel.itemsType);
          break;
        case 'coiNoticeStatus':
          this.getLookup(LOOKUP_COI_NOTICE_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'vbSubmissionId':
          this.getVbSubmissionIds(actionModel.itemsType);
          break;
        case 'cfrComplianceReportCompliancePeriod':
          this.getLookup(LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEAR_LIST, actionModel.itemsType);
          break;
        case 'verificationReportStatus':
          this.getLookup(LOOKUP_VERIFICATION_REPORT_STATUS, actionModel.itemsType, 'COMMON.verificationReportStatusList.');
          break;
        case 'coiSubmissionTypes':
          this.getChecklistSubmissionTypes(actionModel.itemsType);
          break;
        case 'coiFacilityNames':
          this.getLookup(LOOKUP_COI_CHECKLIST_ACCOUNT_NAMES, actionModel.itemsType);
          break;
        case 'ccmStatus':
          this.getLookup(LOOKUP_GET_CCM_STATUS, actionModel.itemsType, 'COMMON.creditClearanceStatusList.');
          break;
        case 'cbStatus':
          this.getLookup(LOOKUP_GET_CB_STATUS, actionModel.itemsType, 'COMMON.statusList.');
          break;
        case 'complianceYearCB':
          this.getLookup(LOOKUP_CREDIT_BALANCE_COMPLIANCE_YEARS, actionModel.itemsType);
          break;
        case 'ccmCompliancePeriod':
          this.getCCMCompliancePeriod(actionModel.itemsType);
          break;
        case 'compliancePeriodCCRR':
          this.getLookup(LOOKUP_CREDIT_REVENUE_COMPLIANCE_YEARS, actionModel.itemsType);
          break;
        case 'statusCCRR':
          this.getLookup(LOOKUP_CREDIT_REVENUE_STATUS_LIST, actionModel.itemsType, 'COMMON.statusList.');
          break;
      }
    });
  }

  private getHeirarchialProjectNames(actionModel: IFilterModel) {
    this.lookupService.getOffsetProjectNames(undefined, { name: 'filterForSubmissions', value: true as any }).subscribe(projects => {
      this.filterFields[actionModel.itemsType] = projects.map(project => {
        if (!project.subProjects) {
          project.subProjects = [];
        }
        return project;
      });
    });
  }

  getJurisdictions(fieldName = 'projectNameJurisdiction') {
    this.lookupService.getJurisdictions()
    .pipe(takeUntil(this._destroy$))
    .subscribe(data => {
      this.filterFields[fieldName] = data.map(option => ({
        ...option,
        name: this.translate.instant(`COMMON.jurisdictionsList.${option.name}`),
      }));
      this.filterFields.provinces.sort((a, b) => a.name.localeCompare(b.name));
    });
  }


  getOffsetProjectIds(uri: string, fieldName: string, translationPrefix: string = null) {
    this.lookupService
      .getLookup(uri)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] = value.map(option => ({
          ...option,
          id: option.code,
        }));
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getAreaOfExpertise(type: string, field: string) {
    this.lookupService
      .getAreaOfExpertise(type)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields[field] = data.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getQuantificationMethods(langChange, skipTranslate = true) {
    this.lookupService
      .getQuantificationMethods(langChange)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.quantificationMethods = value.map(option => ({
          ...option,
          name: skipTranslate ? option.name : this.translate.instant(`COMMON.quantificationList.${option.name}`),
        }));
        this.filterFields.quantificationMethods.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getProvinces() {
    this.lookupService
      .getAllProvince()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.provinces = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.jurisdictionsList.${option.name}`),
        }));
        this.filterFields.provinces.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getVerificationFilter() {
    this.lookupService
      .getVerificationFilters()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.vbLegalName = value.map(option => ({
          id: option.id,
          name: option.name
        }));
        const countryData = value.map(option => ({
          id: option.code,
          name: this.translate.instant(`COMMON.countryList.${option.code}`),
        }));
        let result = [];
        countryData.forEach(function(item){
          let index = result.findIndex(x => x.id == item.id);
          if(item.id && index <= -1){
            result.push({id: item.id, name: item.name});
          }
        });
        this.filterFields.vbCountry = result;
        const provinceData = value.map(option => ({
          id: option.value,
          name: this.translate.instant(`COMMON.jurisdictionsList.${option.value}`),
        }));
        let uniqueProvinces = [];
        provinceData.forEach(function(item){
          let index = uniqueProvinces.findIndex(x => x.id == item.id);
          if(item.id && index <= -1){
            uniqueProvinces.push({id: item.id, name: item.name});
          }
        });
        this.filterFields.vbProvinces = uniqueProvinces;
        this.filterFields.vbLegalName.sort((a, b) => a.name.localeCompare(b.name));
        this.filterFields.vbCountry.sort((a, b) => a.name.localeCompare(b.name));
        this.filterFields.vbProvinces.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getSubmissionTypes() {
    this.lookupService
      .getSubmissionTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.submissionType = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.submissionTypeList.${option.name}`),
        }));
        this.filterFields.submissionType.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getChecklistSubmissionTypes(itemsType) {
    this.lookupService
      .getChecklistSubmissionTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[itemsType] = value.map(option => ({
          ...option,
          id: option.code,
          name: this.translate.instant(`COMMON.submissionTypeList.${option.code}`),
        }));
        this.filterFields[itemsType].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getSubmissionsType() {
    this.lookupService
      .getSubmissionsType$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.submissionType = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.submissionTypeList.${option.name}`),
        }));
        this.filterFields.submissionType.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getReportingPeriod(): void {
    this.lookupService
      .getReportingPeriod$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.reportingPeriod = value;
        if (this.filterFields.submissionType) {
          this.filterFields.submissionType.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
  }

  getTypeCodes(): void {
    this.lookupService
      .getTypeCodes$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.typeCode = value
          .map(option => ({
            ...option,
            name: this.translate.instant(`COMMON.submissionTypeList.${option.name}`),
            id: option.code,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getLookup(uri: string, fieldName: string, translationPrefix: string = null, groupBy: string = null, bindValue = 'id') {
    this.lookupService
      .getLookup(uri)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (groupBy) {
          value.map(option => {
            const _opt = option;
            _opt[groupBy] = _opt[groupBy] ? _opt[groupBy] : [];
            return _opt;
          });
        }
        this.filterFields[fieldName] = translationPrefix
          ? value.map(option => ({
              ...option,
              name: this.translate.instant(`${translationPrefix}${option.name}`),
            }))
          : value;
        if (bindValue) {
          this.filterFields[fieldName] = this.filterFields[fieldName].map(option => ({
            ...option,
            id: option[bindValue],
          }));
        }
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getOffsetProjectNames(uri: string, fieldName: string, translationPrefix: string = null) {
    this.lookupService
      .getLookup(uri)
      .pipe(takeUntil(this._destroy$))
      .pipe(
        map(data => {
          let res = [...data];
          const masterProjects = data.filter(p => p.subProjects && p.subProjects.length > 0);
          masterProjects.map(p => (res = [...res, ...p.subProjects]));
          return res;
        }),
      )
      .subscribe(value => {
        this.filterFields[fieldName] = translationPrefix
          ? value.map(option => ({
              ...option,
              name: this.translate.instant(`${translationPrefix}${option.name}`),
            }))
          : value;
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  postLookup(uri: string, fieldName: string, translationPrefix: string = null, serviceUrl: boolean = false) {
    console.log('fieldName', fieldName);

    this.lookupService
      .postLookup(uri, serviceUrl)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] = translationPrefix
          ? value.map(option => ({
              ...option,
              name: this.translate.instant(`${translationPrefix}${option.name}`),
            }))
          : value;
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getAssignedVB() {
    this.lookupService
      .getAssignedVB()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.assignedVB = value;
        this.filterFields.assignedVB.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getRegReportActivities() {
    this.lookupService
      .getSubmissionSubTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.regActivityTypes = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.submissionTypeList.${option.name}`),
        }));
        this.filterFields.regActivityTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getCfrCompliancePeriod() {
    this.lookupService
      .getCfrCompliancePeriod()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.cfrCompliancePeriod = value;
      });
  }
  getCcrCompliancePeriod(fieldName) {
    this.lookupService
      .getComplianceYearCcr()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] = value;
      });
  }

  getCCMCompliancePeriod(fieldName) {
    this.lookupService
      .getComplianceYearCCM()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] = value;
      });
  }

  getCompRepCompliancePeriod() {
    this.lookupService
      .getCompRepCompliancePeriod()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.cfrComplianceReportCompliancePeriod = value;
      });
  }

  getCreditCreationReportStatus(itemType: string) {
    this.lookupService
      .getCreditCreationReportStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[itemType]  = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields[itemType].sort((a, b) => a.name.localeCompare(b.name));
        this.filterFields[itemType] = uniqBy(this.filterFields[itemType], 'name');
      });
  }

  getComplianceReportStatus(itemType: string) {
    this.lookupService
      .getComplianceReportStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[itemType]  = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields[itemType].sort((a, b) => a.name.localeCompare(b.name));
        this.filterFields[itemType] = uniqBy(this.filterFields[itemType], 'name');
      });
  }

  getVbSubmissionIds(itemType: string) {
    this.lookupService
      .getSubmissionIds()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[itemType]  = value;
      });
  }

  getSubmissionReportStatus() {
    this.lookupService
      .getSubmissionReportStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.submissionReportStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.submissionReportStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getOrgEntityType() {
    this.lookupService
      .getOrgEntityType()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.entityTypes = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.organizationEntityTypeList.${option.name}`),
        }));
        this.filterFields.entityTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getAccountTypes() {
    this.lookupService
      .getAccountTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.accountTypes = value;
      });
  }

  getFeedstock() {
    this.lookupService
      .getFeedStockFilters()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.feedstockTypes = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.feedstockList.${option.name}`),
        }));
        this.filterFields.feedstockTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getFuelTypes() {
    this.lookupService
      .getFuelTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.fuelTypes = value;
      });
  }

  getFacilitiesByProvince(flatten = false) {
    const fetchFacilities = (organizationIds: number[] = []) => {
      this.lookupService
        .getFacilitiesByProvinces(organizationIds, flatten)
        .pipe(takeUntil(this._destroy$), first())
        .subscribe(value => {
          this.filterFields.facilitiesList = value.sort((a, b) => a.name && a.name.localeCompare(b.name));
        });
    };
    this._stopPrevious$.next();
    this._facilityNamesChanged
      .pipe(distinctUntilChanged(), takeUntil(this._destroy$), takeUntil(this._stopPrevious$))
      .subscribe(organizationIds => fetchFacilities(organizationIds));
    fetchFacilities(this.facilityNamesModel);
  }

  getCIFacilitiesByProvince() {
    const fetchFacilities = (organizationIds: number[] = []) => {
      this.lookupService
        .getFacilitiesByProvinces(organizationIds)
        .pipe(takeUntil(this._destroy$), first())
        .subscribe(value => {
          value = Object.keys(value).map(key => ({id: key, name: key}));
          this.filterFields["CIFacilitiesList"] = value.sort((a, b) => a.name && a.name.localeCompare(b.name));
        });
    };
    this._stopPrevious$.next();
    this._facilityNamesChanged
      .pipe(distinctUntilChanged(), takeUntil(this._destroy$), takeUntil(this._stopPrevious$))
      .subscribe(organizationIds => fetchFacilities(organizationIds));
    fetchFacilities(this.facilityNamesModel);
  }

  getAccountSubTypeNames() {
    this.lookupService
      .getAccountSubTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.accountSubTypeNames = value.map(option => ({
          ...option,
          id: option.name,
          name: this.translate.instant(`COMMON.accountSubTypeList.${option.name}`),
        }));
        this.filterFields.accountSubTypeNames.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getWorkflowApprovalTypes() {
    this.lookupService
      .getWorkflowApprovalTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        value =>
          (this.filterFields.workflowApprovalTypes = value.map(option => {
            const label = `COMMON.workflowConfigurationList.${option['approvalType']}`;
            const approvalType = this.translate.instant(label);
            let name =  option['approvalType'];
            if(approvalType !== label){
              name = approvalType;
            }
            return { id: option['approvalType'], name};
          }).sort((a, b) => a.name.localeCompare(b.name))),
      );
  }

  getFacilityStatus(prefix: string = '') {
    this.lookupService
      .getFacilityStatus()
      .pipe(
        filter(d => d !== null),
        takeUntil(this._destroy$),
      )
      .subscribe(value => {
        this.filterFields[`${prefix}facilityStatus`] = value
          .map(option => ({ ...option, name: this.translate.instant(`COMMON.${prefix}statusList.${option.name}`) }))
          .sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getFacilityNames() {
    this.lookupService
      .getFacilityNames()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.facilityNames = value;
        this.filterFields.accountIds = value.map(unit => ({
          id: unit.id,
          name: `${unit.id}`,
        }));
      });
  }

  getCrossFacilityNames() {
    this.lookupService
      .getCrossFacilityNames()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.crossFacilityNames = value;
        this.filterFields.crossAccountIds = value.map(unit => ({
          id: unit.id,
          name: `${unit.id}`,
        }));
      });
  }

  getFacilityCertificateNos() {
    this.lookupService
      .getFacilityCertificateNos()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.filterFields.facilityCertificateNos = value));
  }

  getResponsiblePersons() {
    this.lookupService
      .getResponsiblePersons()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.filterFields.responsiblePersons = value));
  }

  getObligationStatus(type) {
    this.lookupService
      .getObligationStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[type] = value.map(option => ({ ...option, name: this.translate.instant(`COMMON.statusList.${option.name}`) }));
        this.filterFields[type].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getCompliancePeriod() {
    this.lookupService
      .getCompliancePeriods()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.filterFields.compliancePeriod = value));
  }

  getResultCompliancePeriods() {
    this.lookupService
      .getResultCompliancePeriods()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.filterFields.resultCompliancePeriods = value));
  }

  getUnitStatus() {
    this.lookupService
      .getAllUnitStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.unitStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.unitStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getPublicUnitStatus() {
    this.lookupService
      .getPublicUnitStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.publicUnitStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.publicUnitStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getRuUnitStatus() {
    this.lookupService
      .getRuUnitStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.ruUnitStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.ruUnitStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getFacilityVersions() {
    this.lookupService
      .getFacilityVersions()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.filterFields.versions = value));
  }

  getPaymentRefundStatus() {
    this.lookupService
      .getPaymentRefundStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.paymentRefundStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
      });
  }

  getPaymentRefundMode() {
    this.lookupService
      .getPaymentRefundMode()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.paymentRefundModes = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
      });
  }

  getObpsReportTypes(type) {
    this.lookupService
      .getObpsReportTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[type] = value;
        this.filterFields[type].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getVerionStatus() {
    this.lookupService
      .getVersionStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.versionStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.versionStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getPaymentStatus() {
    this.lookupService
      .getPaymentStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.paymentStatus = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
        this.filterFields.paymentStatus.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getTransactionType() {
    this.lookupService
      .getTransactionType({})
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.transactionType = value.map(option => ({
          ...option,
          name: this.translate.instant(`ACTIVITY_LOGS_MODULE.transitionType.${option.name}`),
        }));
        this.filterFields.transactionType.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getTaskType(taskFamily?) {
    this.lookupService
      .getTaskType(taskFamily)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.taskTypeList = value;
        this.filterFields.taskTypeList.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getAssignedToList() {
    this.lookupService
      .getAssignedTo()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.assignedToList = value.map(option => ({ ...option, name: option.name }));
        this.filterFields.assignedToList.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  getRemittanceTypes() {
    this.lookupService
      .getRemittanceTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.remittanceTypes = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.unitClassList.${option.name}`),
        }));
        this.filterFields.remittanceTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
  }
  searchServiceManager() {
    this.ngSelectList.forEach(element => {
      element.searchTerm = '';
    });
    this.storeFilterSettings();
    this.updateFilterFacilityNames();
    this.filterRequest.freeText = this.filterRequest.freeText ? this.filterRequest.freeText.trim() : null;
    this.filterChanged.emit(this.filterRequest);
  }

  updateFilterFacilityNames() {
    if (this._facilityNamesChanged.getValue() !== this.facilityNamesModel) {
      this._facilityNamesChanged.next(this.facilityNamesModel);
    }
    if (this.facilityNamesModel && this.facilityNamesModel.length > 0) {
      this.filterRequest['facilityNameList'] = this.facilityNamesModel;
    } else if (this.store.accountFacilities && this.store.accountFacilities.length > 0) {
      if (this.store.accountFacilities[0] !== -1) {
        this.filterRequest['facilityNameList'] = this.store.accountFacilities;
      } else {
        delete this.filterRequest['facilityNameList'];
      }
    }
  }

  getUploadOBPSStatus() {
    this.lookupService
      .getFileUploadStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.documentStatusList = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.statusList.${option.name}`),
        }));
      });
  }

  getTaskFamilyList() {
    this.lookupService
      .getTaskFamilyList()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields.taskFamilyList = value.map(option => ({
          ...option,
          name: this.translate.instant(`COMMON.taskFamilyList.${option.name}`),
        }));
      });
  }

  clearSearch() {
    this.ngSelectList.toArray().forEach(ngSelect => ngSelect.handleClearClick());
    this.searchTextBox && (this.searchTextBox.nativeElement.value = '');
    this.filterRequest.freeText = '';
    this.filterRequest[this.dateRangeFields.startDate] = null;
    this.filterRequest[this.dateRangeFields.endDate] = null;
    this.storeFilterSettings();
    this.setDefaultFilter();
    this.filterCleared.emit();
    this.filterChanged.emit(this.filterRequest);
    (document.activeElement as HTMLElement).blur();
    document.getElementById('filter-clear-btn').focus();
  }

  onDateRangeChanged(newDateRange: IDateRange) {
    this.filterRequest[this.dateRangeFields.startDate] = moment(newDateRange.dateFrom).format('YYYY-MM-DDT00:00:00');
    this.filterRequest[this.dateRangeFields.endDate] = moment(newDateRange.dateTo).format('YYYY-MM-DDT23:59:59');
  }

  onDateChange(date: Date) {
    this.filterRequest[this.dateFieldConfig.dateField] = moment(date).format(this.dateFieldConfig.format);
  }

  export(event) {
    let exportAction: any = exportxlsAction;
    if (event.id === 'exportPdf') {
      exportAction = exportPdfAction;
    }

    const payload: any = {};
    const fileName = event.uri;
    payload.responseType = exportAction.responseType;
    payload.reportType = fileName.toUpperCase();
    payload.searchFilter = this.filterRequest;
    if (!event.typeNotRequired) {
      payload.searchFilter.type = event.typeValue || payload.reportType;
    }
    if (this.getItemId()) { payload.searchFilter.id = this.getItemId(); }
    if(!isEmpty(event.additionalParameter)) {
      payload.searchFilter = {...payload.searchFilter, ...event.additionalParameter};
    }

    this.lookupService
      .export(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: HttpResponse<Blob>) => {
        const blob = new Blob([data.body], { type: exportAction.type });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(blob, this.createFileName(data, exportAction.extension));
        } else {
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = this.createFileName(data, `.${exportAction.extension}`);
          anchor.href = url;
          document.body.appendChild(anchor);
          anchor.click();
        }
      });
    // this.requestExport.emit(exportType);
  }

  private getItemId() {
    if (this.activatedRoute.snapshot.params.projectId) { return this.activatedRoute.snapshot.params.projectId; }
    if (this.activatedRoute.snapshot.params.accountId) { return this.activatedRoute.snapshot.params.accountId; }
    return false;
  }

  private createFileName(response: HttpResponse<Blob>, extension: string): string {
    return this.uploadDocumentService.getFileNameFromHeaderOrDefult(response);
    // const day = moment().format('YYYY-MM-DD');
    // return extension ? filename + '-' + day + extension : filename + '-' + day;
  }

  onChangeAction(event) {
    if (event.type === ActionOptionType.EXPORT) {
      this.export(event);
    } else if (event.type === ActionOptionType.DOWNLOAD) {
      this.download(event.uri);
    } else {
      this.actionSelected.emit(event);
    }

    // Reset selected action
    // if (event.type === ActionOptionType.EXPORT || event.type === ActionOptionType.DOWNLOAD || event.type === ActionOptionType.APPROVE || event.type === ActionOptionType.REJECT) {
    setTimeout(() => {
      Object.keys(this.actionSelectedDummyModel).forEach(key => (this.actionSelectedDummyModel[key] = null));
      (document.activeElement as HTMLElement).blur();
    }, 0);
    // }
  }

  onAdminActionSelected(event) {
    // note: id is actual method name
    this.adminAction(event.id);
  }

  download(uri: string) {
    this.uploadDocumentService.downloadDocument(`${environment.apiUrl}${uri}`);
  }

  private get randomId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);
  }

  onSearchFieldKeyUp(event) {
    if (event.key === 'Enter') {
      this.searchServiceManager();
    }
  }

  getDropdownId(i, dropdown) {
    const id = dropdown.placeholder ? this.translate.instant(dropdown.placeholder).toLowerCase().replace(/ /g, '_') : 'dropdown';
    return dropdown.data_id ? dropdown.data_id : `table_filter_${id}_${i}`;
  }

  /**
   * This method maintains order of the fields to handle correct tab sequence (CATS-877)
   */
  public buildFilterFieldsList() {
    this.sortedFieldsList = [];
    this.fields.filterListsModel.forEach(f => this.sortedFieldsList.push({ fieldType: 'filterListModel', data: f }));
    this.fields.dateRange &&
      this.sortedFieldsList.push({ fieldType: 'dateRange', options: this.fields.options && this.fields.options.dateRange });
    this.fields.datePicker &&
      this.sortedFieldsList.push({ fieldType: 'datePicker', options: this.fields.options && this.fields.options.datePicker });
    this.fields.searchBox && this.sortedFieldsList.push({ fieldType: 'searchBox' });
    this.fields.searchButtons &&
      this.sortedFieldsList.push({ fieldType: 'searchButtons', options: this.fields.options && this.fields.options.searchButtons });
    this.fields.addNewRow && this.sortedFieldsList.push({ fieldType: 'addNewRow' });
    this.fields.openNewPageForNewRow && this.sortedFieldsList.push({ fieldType: 'openNewPageForNewRow' });
    this.fields.adminActions && this.sortedFieldsList.push({ fieldType: 'adminActions', data: this.fields.adminActions[0] });
    this.fields.dummy && this.sortedFieldsList.push({ fieldType: 'dummy', data: this.fields.dummy });
    this.fields.customButtons && this.sortedFieldsList.push({ fieldType: 'customButtons', data: this.fields.customButtons });
    if(this.sortedFieldsList.length)
    this.sortedFieldsList[this.sortedFieldsList.length - 1].last = true;

    let i = 3;
    if (this.fields.actionModel.length > 0) {
      this.fields.actionModel.forEach((f, idx) => {
        if (f.position) {
          this.sortedFieldsList.splice(f.position, 0, { fieldType: 'actionModel', index: idx, data: f });
        } else {
          i = this.sortedFieldsList.length > i ? i : this.sortedFieldsList.length;
          this.sortedFieldsList.splice(i, 0, { fieldType: 'actionModel', index: idx, data: f });
          i += 3;
        }
      });
    }
  
  }

  storeFilterSettings() {
    if (this.filterParent) {
      let filterSettings = sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY) || '{}';
      filterSettings = JSON.parse(filterSettings);
      filterSettings[this.filterParent] = this.filterRequest;
      if (filterSettings[this.filterParent]['facilityNameList']) {
        filterSettings[this.filterParent]['facilityNameList'] = this.facilityNamesModel;
      }
      sessionStorage.setItem(TABLE_FILTER_SETTINGS_KEY, JSON.stringify(filterSettings));
    }
  }

  getStoredFilterSettings() {
    if (this.filterParent) {
      let filterSettings = sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY) || '{}';
      filterSettings = JSON.parse(filterSettings);
      this.filterRequest = filterSettings[this.filterParent] || {};
      !this.filterRequest[this.dateRangeFields.startDate] && (this.filterRequest[this.dateRangeFields.startDate] = null);
      !this.filterRequest[this.dateRangeFields.endDate] && (this.filterRequest[this.dateRangeFields.endDate] = null);
      this.filterRequest['facilityNameList'] && (this.facilityNamesModel = this.filterRequest['facilityNameList']);
      if (
        this.store.accountFacilities &&
        this.store.accountFacilities[0] !== -1 &&
        (!this.filterRequest['facilityNameList'] || this.filterRequest['facilityNameList'].length === 0)
      ) {
        this.filterRequest['facilityNameList'] = this.store.accountFacilities;
      }
    }
  }

  adminAction(method: string) {
    this.adminActions.emit(method);
  }

  customButton(method: string) {
    this.customButtonAction.emit(method);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  hasAdminPermission() {
    return this.store.user.hasPermission('ASSIGN_181_OBLIGATION');
  }

  openNewPageForNewRow(uri) {
    this.router.navigate(this.helperService.getTranslatedPath(uri));
  }

  selectionChange(event) {
    if (event === 'taskFamily') {
      this.getTaskType(this.filterRequest[event]);
    }
  }

  getAccountHistoryActionByTypeById(accountId: string) {
    this.lookupService
      .getAccountHistoryActionByTypeById(accountId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['type'] = data.map(option => ({
          ...option,
          id: option.name,
          name: this.translate.instant(`ACTIVITY_LOGS_MODULE.transitionType.${option.name}`),
        }));
      });
  }

  getAccountHistoryActionPerformedByUserById(accountId: string) {
    this.lookupService
      .getAccountHistoryActionPerformedByUserById(accountId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['action'] = data.map(option => ({
          ...option,
          id: option.name,
        }));
      });
  }

  private getFilterParam(param) {
    return this.filterParams && Object.keys(this.filterParams).includes(param) ? this.filterParams[param] : null;
  }

  getProjectHistoryActionByTypeById(projectId: string) {
    this.lookupService
      .getProjectHistoryActionByTypeById(projectId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['type'] = data.map(option => ({
          ...option,
          id: option.name,
          name: this.translate.instant(`ACTIVITY_LOGS_MODULE.transitionType.${option.name}`),
        }));
      });
  }

  getProjectHistoryActionPerformedByUserById(projectId: string) {
    this.lookupService
      .getProjectHistoryActionPerformedByUserById(projectId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['action'] = data.map(option => ({
          ...option,
          id: option.name,
        }));
      });
  }

  getSubmissionsHistoryActivityTypes(submissionsId: string) {
    this.lookupService
      .getSubmissionsHistoryActivityTypes(submissionsId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['submissionsType'] = data.map(option => ({
          ...option,
          id: option.name,
          name: this.translate.instant(`ACTIVITY_LOGS_MODULE.transitionType.${option.name}`),
        }));
      });
  }

  getSubmissionsHistoryUsers(submissionsId: string) {
    this.lookupService
      .getSubmissionsHistoryUsers(submissionsId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterFields['submissionsAction'] = data.map(option => ({
          ...option,
          id: option.name,
        }));
      });
  }

  public updateFacilityList(items) {
    this.facilityNamesModel = items.map(i => [i.id, ...(i.subAccounts ? i.subAccounts.map(j => j.id) : [])]).flat();
    this.updateFilterFacilityNames();
    this.filterChanged.emit(this.filterRequest);
  }

  private get filterName() {
    let name = this.viewContainerRef['_data'].componentView.parent.component.constructor.name || null;
    if (this.filterNameSuffix && name) {
      name = `${name}_${this.filterNameSuffix}`;
    }
    return name;
  }

  apply3cols(button: ICustomButton): boolean {
    return !button.ignoreLayout && this.translate.instant(`${'COMMON.filters.'}${button.label}`).length <= 19;
  }

  apply4cols(button: ICustomButton): boolean {
    return !button.ignoreLayout && this.translate.instant(`${'COMMON.filters.'}${button.label}`).length >= 20 && button.label.length <= 100;
  }

  apply5cols(button: ICustomButton): boolean {
    return !button.ignoreLayout && this.translate.instant(`${'COMMON.filters.'}${button.label}`).length > 100;
  }

  get dateRangeFields(): IDateRangeFields {
    const fields = { ...TABLE_FILTER_DATE_RANGE_FIELDS } as IDateRangeFields;
    const opts = this.fields && this.fields.options;
    if (opts && opts.dateRange) {
      if (opts.dateRange.startDateField) {
        fields.startDate = opts.dateRange.startDateField;
      }
      if (opts.dateRange.endDateField) {
        fields.endDate = opts.dateRange.endDateField;
      }
    }
    return fields;
  }

  get dateFieldConfig(): IDateFieldConfig {
    const fieldConfig = { ...TABLE_FILTER_DATE_FIELDS } as IDateFieldConfig;
    const opts = this.fields.options;
    if (opts && opts.datePicker) {
      if (opts.datePicker.dateField) {
        fieldConfig.dateField = opts.datePicker.dateField;
      }
      if (opts.datePicker.format) {
        fieldConfig.format = opts.datePicker.format;
      }
    }
    return fieldConfig;
  }

  getUnitTypes() {

    this.lookupService.getUnitClassForListing()
    .pipe(takeUntil(this._destroy$))
    .subscribe(data => {
      this.filterFields.unitType = data.map(option => ({
        ...option,
        name: this.translate.instant(`COMMON.unitClassList.${option.name}`),
      }));
      this.filterFields.unitType.sort((a, b) => a.name.localeCompare(b.name));
    })
  }

  private getProjectActivityType(uri: string, fieldName: string): void {
     this.lookupService
      .getLookup(uri)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] =
          value.map(option => ({
            id: `${option.id}`,
            name: option[this.currentLanguage],
        }));
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  private get currentLanguage(): string {
    return this.translate.currentLang;
  }

  private _postAgreementTucFuelType(uri, fieldName) {
    this.lookupService
      .postLookup(uri)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterFields[fieldName] = value.map(option => ({
          id: `${option.id}`,
          name: option[this.currentLanguage],
        }));
        this.filterFields[fieldName].sort((a, b) => a.name.localeCompare(b.name));
      });
  }
}
