import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import {
  APPROVE_REGISTRATION_REPORT, DISCARD_REGISTRATION_REPORT,
  CfrRegistrationService, REJECT_REGISTRATION_REPORT,
} from '@module/cfr-registration/services/cfr-registration.service';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER } from 'app/app.const';
import { CFR_PROPOSE_RR_ACTIONS } from '@module/cfr-registration/cfr-registration.const';
import { VB_NEED_MORE_INFO_CONFIG } from '@module/account-management/account-management.const';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TableNewRow, TABLE_NEW_ROW_ACTION } from '@shared/models/table-new-row.model';

const REPLACE_MAO = '/replace-mao';

@Component({
  selector: 'app-reg-rept-actions',
  templateUrl: './reg-rept-actions.component.html',
  styleUrls: ['./reg-rept-actions.component.scss']
})
export class RegReptActionsComponent extends BaseActionsComponent implements OnInit {

  @Input() actionsList: IApp.IEntityAction[];
  @Input() data: any;
  @Input() accountId: number;
  @Input() appendTo: string;
  @Input() accountStatus: string;
  @Output() actionSuccessfull = new EventEmitter<boolean>();

  constructor(private helperService: HelperService,private router: Router,
    private modalService: ModalService,
    private cfrRegistrationService: CfrRegistrationService,
    private uploadDocumentService: UploadDocumentService,
    private storeService: StoreService,
    private accountManagementService : AccountManagementService,
    private notificationService: NotificationsService
   ) { super(); }

  ngOnInit() {
    this.actionsList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
    this.actionsList.sort((a,b) => a.label.localeCompare(b.label));
  }

  triggerActionController(value: IApp.IEntityAction): void {
    const isCustomer = this.storeService.user.hasPermission(MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER);
    if (isCustomer && this.accountStatus === 'LOCKED') {
        this.resetSelection();
        this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnLockedAccount');
        return;
    }
    const id = this.data.entityId ? this.data.entityId: this.data.id;
    this.data.id = id;
    this.resetSelection();
    switch(value.workflowAction) {
      case 'REPLACE_MAO':
        this.data.accountId = this.accountId;
        this.router.navigate(this.helperService.getTranslatedPath(REPLACE_MAO), {state:{data:this.data}});
        break;
      case 'APPROVE_MAO':
         const userId = this.data.authorizedUserId ;
         this.router.navigate(this.helperService.getTranslatedPath(`/account-management/approve-account/${this.data.accountId}/${userId}`));
         break;
      case 'REJECT_MAO':
        this.reject();
        break;
      case 'DOWNLOAD':
        this.uploadDocumentService.downloadRegistrationReport(`${this.accountId}/${this.data.id}`,
          this.translateService.instant('RegistrationAccountPdf.detail.reportTitle'));
        break;
      case 'VIEW_REPLACE_MAO':
      case 'REJECT_REPLACE_MAO':
        this.data.accountId = this.accountId;
        this.router.navigate(this.helperService.getTranslatedPath(REPLACE_MAO),
         {state: {maoReplaceRequestDto: this.data.maoReplaceRequestDto}});
        break;
      case 'REVIEW_REPLACE_MAO':
        this.showMessage('replaceAOConfirmationMessage');
        break;
      case 'APPROVE':
        this.processRegistration(value, APPROVE_REGISTRATION_REPORT);
        break;
      case 'APPROVE2':
        this.processRegistration(value, `${APPROVE_REGISTRATION_REPORT}2`);
        break;
      case 'REJECT':
        this.processRegistration(value, REJECT_REGISTRATION_REPORT);
        break;
      case 'REJECT2':
        this.processRegistration(value, `${REJECT_REGISTRATION_REPORT}2`);
        break;
      case 'CANCEL_REGISTRATION':
      case 'VIEW_CANCELLATION':
      case 'APPROVE_CANCELLATION':
      case 'REJECT_CANCELLATION':
        const filterState = { accountId : this.accountId,};
        this.accountManagementService.getRegistrationDetails({ size: this.data.reportVersion }, filterState)
         .subscribe(
            data => {
              const rawData = data.content.find(t => t.id === this.data.id);
              this.router.navigate(this.helperService.getTranslatedPath('/request-registration-cancellation'),
              {state:{data:{ rawData, actionType:value.workflowAction}}});
            }
        );
        break;
      case 'VIEW':
      case 'EDIT':
        const code = this.data.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB ?
        IApp.NSAccountSubType.StatusEnum.VB :  IApp.NSAccountSubType.StatusEnum.PS;
        this.storeService.setHistoryUrl();
        this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RR${code}/${this.data.accountId}/${id}`));
        break;
      case 'DISCARD':
        this.processRegistration(value, `${DISCARD_REGISTRATION_REPORT}`);
        break;
      case 'UPLOAD_IDENTITY_VERI_DOCS':
      case 'REVIEW_IDENTITY_VERI_DOCS':
      case 'VIEW_IDENTITY_VERI_DOCS':
        this.navigateToDocuments();
        break;
      case 'APPROVE_PROPOSED':
      case 'REJECT_PROPOSED':
        const msg = 'REGISTRATION_ACCOUNT_PROPOSED_UPDATE_' + value.workflowAction;
        this.cfrRegistrationService.openConfirmationModal(msg)
          .subscribe(result => {
            if (result) {
              this.cfrRegistrationService.proposedRegistrationUpdateHandler(
                {
                  id: this.data.id,
                  accountId: this.accountId,
                  discriminatorType: 'REGISTRATION_ACCOUNT'
                },
                CFR_PROPOSE_RR_ACTIONS[value.workflowAction]
              ).subscribe(() => this.actionSuccessfull.emit(true));
            }
          });
        break;
      case 'NEED_MORE_INFO':
        const defaultValue = {
          id: this.data.id,
          accountId: this.data.accountId
        }
        this.openNeedMoreInfoPopUp(VB_NEED_MORE_INFO_CONFIG, defaultValue, 'comment');
        break;
    }
  }

  showMessage( message: string) {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            }],
          message: message,
          type: SereviceMessageType.WARNING
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.data.accountId = this.accountId;
          this.router.navigate(this.helperService.getTranslatedPath('/replace-mao'),
          {state: {maoReplaceRequestDto: this.data.maoReplaceRequestDto}});
        }
      });
  }

  processRegistration(value: IApp.IEntityAction,  uri: string) {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: 'confirmationMessageRegReport',
          metaDataList: [value.label.toLowerCase(), this.data.legalName],
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.cfrRegistrationService.processRegistrationReport(uri, this.data).subscribe( response => {
             this.actionSuccessfull.emit(true);
          });
        }
      });
  }
  reject() {
    const message ='replaceAORejectionConfirmationMessage';
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          message: message,
          type: SereviceMessageType.WARNING
        },
        true,
        DEFAULT_DIALOG_CONFIG
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          const payload = {
            email:  this.data.authorizedUserEmail,
            accountId: this.data.accountId,
          }
          this.cfrRegistrationService.rejectReplaceMAO(payload).subscribe( response => {
            this.actionSuccessfull.emit(true);
          })
        }
      });
  }

  navigateToDocuments() {
    this.storeService.setHistoryUrl();
    this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/DUPS/${this.data.accountId}`));
  }

  openNeedMoreInfoPopUp(tableConfig: ITableViewConfig, defaultValues: any, editTitle = 'rejectionDetails') {
    this.modalService.open(TableNewRowComponent, {
      form: tableConfig,
      defaultValues: defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
      saveBtnLabel: 'COMMON.actionsLabel.SUBMIT',
      editTitle,
    } as TableNewRow).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.actionSuccessfull.emit(true);
      }
    });
  }

}
