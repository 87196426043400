<p-fieldset [legend]="legend" *ngIf="address">
  <div class="row">
    <div class="col-12">
      {{streetNumberAndName}}
    </div>
    <div class="col-12">
      {{cityAndProvince}}
    </div>
    <div class="col-12">
      {{poBoxAndPostalCode}}
    </div>    
    <div class="col-12">
      {{'COMMON.countryList.'+address.country | translate}}
    </div>
  </div>
</p-fieldset>
