<div class="inner">
  <h2 class="mt-5">{{ 'REGISTRATION_PAGE.pendingRegistrations.facilitiesInProgressText' | translate }}</h2>
  <ol>
		<li *ngFor="let facility of facilities">{{ facility.legalName }}
			<span *ngIf="facility.certificateNumber">({{ facility.certificateNumber }}) </span>
		</li>
	</ol>

  <h2 class="mt-5">{{ 'REGISTRATION_PAGE.pendingRegistrations.yourKYCDocumentsText' | translate }}</h2>
  <p [innerHTML]="actionDesc"></p>
  <div *ngIf="editable">
  <h2 class="mt-5">{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.uploadKycDocuments' | translate }}</h2>

  <div class="upload-btn" >
    <button id="upload-document" class="btn btn-primary" (click)="openUploadPopup($event)">
      <fa-icon class="mr-1" [icon]="['far', 'plus-square']"></fa-icon>
      <label> {{ 'ACCOUNTS_MODULE.uploadDocument.uploadNewDocument' | translate }}</label>
    </button>
  </div>

  <!--<div class="upload-btn ml-2">
    <button class="btn btn-primary" (click)="downloadKYCTemplate()">
      <label> {{ 'ACCOUNTS_MODULE.uploadDocument.downloadTemplate' | translate }}</label>
    </button>
  </div>-->
</div>
  <h2 class="mt-5">{{'REGISTRATION_PAGE.enterFacilitySection.addedDocuments' | translate}}</h2>

  <table class="table table-framed documents-table">
    <thead>
    <tr>
      <th>{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.documentName' | translate }}</th>
      <th>{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.documentTime' | translate }}</th>
      <th>{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.comments' | translate }}</th>
      <th style="width: 250px">{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.actions' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="files.length > 0; else NO_FILES">
      <tr *ngFor="let file of files; let id = index;">
        <td>
                  <span class="wrapped">
                    <span>{{ file.name }}</span>
                    <span
                      *ngIf="file._uploadStatus === 'IN_PROGRESS'"
                      class="processing">
                      <mat-spinner diameter="16"></mat-spinner>
                    </span>
                  </span>
        </td>
        <td>{{file.lastModifiedDateStr}}</td>
        <td>
                  <span class="wrapped" contenteditable="true" (blur)="updateList(id, 'comments', $event)">
                    {{file.comments}}</span>
        </td>
        <td>
          <div *ngIf="file._uploadStatus !== 'IN_PROGRESS'; else UPLOADING">
            <app-document-actions [document]="file"
                                  [appendTo]="''"
                                  (successfullAction)="refreshTable($event)"></app-document-actions></div>
          <ng-template #UPLOADING>
            <span>{{ 'COMMON.messageSection.documentUploading' | translate }}</span>
          </ng-template>
        </td>
      </tr>
    </ng-container>
    <ng-template #NO_FILES>
      <tr>
        <td colspan="4"
            class="text-center">{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.noFilesUploaded' | translate }}</td>
      </tr>
    </ng-template>
    </tbody>
  </table>

  <p>{{ 'REGISTRATION_PAGE.pendingRegistrations.supportedFileTypes' | translate }}  {{ docAllowedFileTypes }}</p>

  <div class="col-12 p-0 sticky-action-button sticky-down" *ngIf="editable">
    <button
      id="stp4-btn"
      class="btn btn-primary float-right"
      type="submit"
      [disabled]="filesValidated"
      (click)="submitKycFiles()">{{ 'REGISTRATION_PAGE.uploadKycDocumentSection.submit' | translate }}
    </button>
  </div>
</div>
