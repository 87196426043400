import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ADMIN_REMARKS_FIELDS,
  AGGREGATED_ATTN_DECLARATION_FORM,
  DOWNLOAD_TEMPLATE_TASK_LIST, NEED_MORE_iNFOR_CONFIG,
  PROJECT_TYPE_MAP, SUBMIT_ACTIONS,
} from '@shared/components/project-builder/consts/project-builder.const';
import { ProjectBuilderService } from '@shared/components/project-builder/project-builder.service';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { ProjectService } from '@shared/services/project.service';
import { IProject } from '@shared/services/project.service.types';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import * as moment from 'moment';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isNull } from 'util';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { IProjectAttestation } from '@shared/components/project-builder/project-builder.types';
import { ATTESTATION_FIELDS, PROJECT_ADMIN_REMARKS_FIELDS } from '@shared/components/project/project.constants';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-sub-projects-addition',
  templateUrl: './sub-projects-addition.component.html',
  styleUrls: ['./sub-projects-addition.component.scss'],
})
export class SubProjectsAdditionComponent implements OnInit, OnDestroy {

  @ViewChild('UPLOAD', {static: false}) uploadContainer: ElementRef<any>;
  @ViewChild('ngForm', { static: false }) formEl: ElementRef;

  public form = new FormGroup({});
  public aggregatedAttnDeclarationFields: FormlyFieldConfig[];
  public aggregatedAttnDeclarationModel: IProjectAttestation = {};
  public needMoreInfoFields: FormlyFieldConfig[];
  public needMoreInfoModel: any = {};

  public taskList = DOWNLOAD_TEMPLATE_TASK_LIST;
  public downloadSelected = null;

  public viewReady = false;

  public minProjectStartDate;
  apiCalled = false;

  private projectsInAggregationId;

  private _destroy$ = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private formFactoryService: FormFactoryService,
    private translateService: TranslateService,
    public projectBuilderService: ProjectBuilderService,
    private projectService: ProjectService,
    private lookupService: LookupService,
    private storeService: StoreService,
    private modalService: ModalService,
    private uploadDocumentService: UploadDocumentService,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private navigationSvc: NavigationService,
  ) { }

  ngOnInit() {
    const masterProject = history.state.masterProject;

    this.taskList = this.taskList = [{
      id: 1,
      name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
    }];

    this.projectBuilderService.updateProject = true;
    this.storeService.getOffsetMinStartDate().subscribe(
      prop => {
      this.minProjectStartDate = moment(prop.value);
    });

    const masterProjectId = this.activatedRoute.snapshot.params['masterProjectId'];
    if (masterProject) {
      this.setProjectBuilderModel(masterProject, true);
      this.viewReady = true;
    } else {
      this.getMaster(masterProjectId);
    }
    this.aggregatedAttnDeclarationFields = this.formFactoryService.configureForm(AGGREGATED_ATTN_DECLARATION_FORM);

    if (this.projectBuilderService.hasApproveAction()) {
      this.needMoreInfoFields = this.formFactoryService.configureForm(ADMIN_REMARKS_FIELDS);
    }

    this.translateService.onLangChange.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.taskList = [{
        id: 1,
        name: this.translateService.instant('PROJECTS_MODULE.ProjectBuilder.downloadTemplate')
      }];
    })
  }

  private getMaster(masterProjectId) {
    this.viewReady = false;
    let model: any = {};
    this.projectService.getProjectDetails(+masterProjectId)
      .pipe(
        tap(data => model = {...data}),
        switchMap((data: IProject) => this.projectService.searchProjectAccount({
          accountId: data.accountId,
          masterProjectId,
          forAdditionOfProjectsToAggregation: true,
        })),
      ).subscribe(result => {
        model = {
          ...model,
          projectsInAggregationId: result.projectsInAggregationId,
        };
        this.projectsInAggregationId = result.projectsInAggregationId;
        this.setProjectBuilderModel(model);
        this.viewReady = true;
    },
    () => this.back());
  }

  private setProjectBuilderModel(result, skipReset = false) {
    this.projectBuilderService.model = result;
    this.projectBuilderService.model = this.checkCreditingPeriodDates(this.projectBuilderService.model);
    this.projectBuilderService.model.discardSubProjects = [];
    this.projectBuilderService.projectType = PROJECT_TYPE_MAP[result.type];
    this.projectBuilderService.addPermission(result.entityActionList);
    if (!skipReset) {
      this.projectBuilderService.model.subProjects = [];
    }
    if (this.projectBuilderService.model.subProjects && this.projectBuilderService.model.subProjects.length) {
      ATTESTATION_FIELDS.forEach(field => {
         this.aggregatedAttnDeclarationModel[field] = this.projectBuilderService.model.subProjects[0].activity[field] || false;
      });
      PROJECT_ADMIN_REMARKS_FIELDS.forEach(field => {
        this.needMoreInfoModel[field] = this.projectBuilderService.model.subProjects[0][field];
      });
    }
    this.needMoreInfoModel.adminPermisson = this.projectBuilderService.model.adminPermisson;
    setTimeout(() => (this.projectBuilderService.selectedCard = result.activity.activityType));
  }

  private checkCreditingPeriodDates(model) {
    return {
      ...model,
      activity: {
        ...model.activity,
        _creditingPeriodStartDateVisible: !!model.activity.creditingPeriodStartDate,
        _creditingPeriodEndDateVisible: !!model.activity.creditingPeriodEndDate,
      },
    };
  }

  get model() {
    return this.projectBuilderService.model;
  }

  set model(val: any) {
    this.projectBuilderService.model = val;
  }

  public addSubProject() {
    this.projectBuilderService.addSubProject();
  }

  public uploadSubProjects() {
    if (!this.projectBuilderService.model.name) {
      this.displayNoSubProjectError('addNameAndDescriptionToUploadSubProjects');
    } else {
      const input = this.renderer.createElement('input');
      this.renderer.setAttribute(input, 'type', 'file');
      this.renderer.setStyle(input, 'display', 'none');
      this.renderer.listen(input, 'change', event => {
        const file = event.target.files[0];
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('project', JSON.stringify({
          id: this.model.id,
          action: 'SAVE',
          additionOfProjectsInAggregation: true,
          projectsInAggregationId: this.projectBuilderService.model.projectsInAggregationId,
          discardSubProjects: this.projectBuilderService.model.discardSubProjects
        }));
        const sub$ = this.projectService.uploadSubProjects(formData)
          .subscribe(result => {
            this.projectBuilderService.model = {...this.projectBuilderService.model, ...result.entity, discardSubProjects: []};
            this.projectBuilderService.subProjectAdded$.next();
            sub$.unsubscribe();
          });
      });
      this.renderer.appendChild(this.uploadContainer.nativeElement, input);
      input.click();
    }
  }

  public downloadTemplate(ev) {
    const uri = this.translateService.currentLang === 'en' ? '/account-service/document/downloadTemplate/Projects.csv' : '/account-service/document/downloadTemplate/Projets.csv';
    this.uploadDocumentService.downloadDocument(`${ environment.serviceUrl }${uri}`);
    //this.uploadDocumentService.downloadDocument(`${ environment.serviceUrl }/offset-service/project/downloadSubProjectUploadTemplate`);
    setTimeout(() => {
      this.downloadSelected = null;
      (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  private displayNoSubProjectError(message = 'atLeastTwoSubProjectRequired') {
    const dialogRef = this.modalService.open(ServiceMessageComponent,
      {
        messages: [
          {
            message,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe(() => {
      dialogRef.unsubscribe();
    });
  }

  public back() {
    this.navigationSvc.back();
  }

  private setModel(result, save = false) {
    this.projectBuilderService.addPermission(result.entityActionList, result.status);
    this.model = result;
    this.projectBuilderService.isProjectPublicExistingValue = this.model.activity.isPublic;
    this.viewReady = true;
  }

  public submit() {
    if (!this.projectBuilderService.model.subProjects.length) {
      this.showErrorMsg('noSubProjectsAdded');
      return;
    }
    const payload = this.createPayload();
    this.formFactoryService.markFormGroupDirty(this.form);
    if (this.form.valid) {
      this.apiCalled = false;
      this.projectBuilderService.validateFormsReq$.next();
      this.projectBuilderService.validateRequiredDocsRes$.pipe(
        filter(result => {
          if (!result) {
            return;
          }
          if (result && !this.apiCalled) {
            this.apiCalled = true;
            return true;
          } else {
            return false;
          }
        }),
        switchMap(() => this.projectService.submitProject(payload)),
      )
      .subscribe(() => {
        this.back();
      });
    }
  }

  private createPayload() {
    const payload = {...this.model, ...this.form.getRawValue() };
    payload.activity.activityType = this.model.activity.activityType;
    payload.activity.id = this.model.activity.id;
    delete payload.activity.location.file;
    payload.additionOfProjectsInAggregation = true;
    payload.subProjects = payload.subProjects.map(subProject => ({
      ...subProject,
      ...this.needMoreInfoModel,
      activity: {
        ...subProject.activity,
        ...this.aggregatedAttnDeclarationModel,
      },
    }));
    return payload;
  }

  public save() {
    if (!this.projectBuilderService.model.subProjects.length) {
      this.showErrorMsg('noSubProjectsAdded');
      return;
    }
    const payload = this.createPayload();
    if (!payload.name) {
      const dialogRef = this.modalService.open(ServiceMessageComponent,
        {
          messages: [
            ...(!payload.name ? [{
              message: 'subProjectNameAndRequired',
            }] : []),
          ],
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed().subscribe(() => {
        dialogRef.unsubscribe();
      });
    } else {
      if (this.model.entityActionList && this.model.entityActionList.length && this.model.entityActionList.some(action => action.workflowAction === 'EDIT')) {

        let actionLabel = 'EDIT';
        let message = 'confirmChangesMessageProject';
        if (!this.projectBuilderService.isProjectPublicExistingValue && payload.activity.isPublic) {
          actionLabel = null;
          message = 'projectPublicViewChangeConfirmation';
        }

        const dialogRef$ = this.projectBuilderService.openConfirmationModal(actionLabel, this.model.name, message).subscribe(result => {
          if (result) {
            this.saveProject(payload);
            dialogRef$.unsubscribe();
          }
        });
      } else {
        this.saveProject(payload);
      }
    }
  }

  private saveProject(payload) {
    const valid = this.projectBuilderService.validateIncorrectValues(this.form, true);
    if (valid) {
      this.projectService.saveProject(payload)
      .subscribe(result => {
        this.setModel(result.entity, true);
      });
    }
  }

  private showErrorMsg(errorMsg: string): void {
    const dialogRef = this.modalService.open(ServiceMessageComponent,
      {
        messages: [{
          message: errorMsg,
        }],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe(() => {
      dialogRef.unsubscribe();
    });
  }

  private openNeedMoreInfoPopUp() {
    const defaultValues =  {
      id: this.model.id,
      action: 'NEED_MORE_INFO',
      additionOfProjectsInAggregation: true,
      projectsInAggregationId: this.model.projectsInAggregationId,
    };
    const sub$ = this.modalService.open(TableNewRowComponent, { form: NEED_MORE_iNFOR_CONFIG, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT } as TableNewRow).afterClosed().subscribe(data =>{
        sub$.unsubscribe();
        if (data) {
          this.back();
        }
    });
  }

  public triggerAction(action) {
    if (SUBMIT_ACTIONS.includes(action.workflowAction)) {
      this.submit();
      return;
    } else if (action.workflowAction === 'NEED_MORE_INFO') {
      this.openNeedMoreInfoPopUp();
      return;
    } else {



      const payload: any = {
        id: this.model.id,
        action: action.workflowAction,
        adminRemark: this.form.get('adminRemark') && this.form.get('adminRemark').value,
        discloseComment: this.form.get('discloseComment') && this.form.get('discloseComment').value,
        additionOfProjectsInAggregation: true,
        projectsInAggregationId: this.model.projectsInAggregationId,
      };

      let uri = 'review';
      if (['REJECT_SECOND', 'APPROVE_SECOND', 'REJECT_REJECTION', 'APPROVE_REJECTION'].includes(action.workflowAction)) {
        uri = 'review2';
      }


      const sub$ = this.projectBuilderService.openConfirmationModal(action.workflowAction, this.model.account.legalName)
        .pipe(
          filter(result => result),
          switchMap(() => this.projectService.projectWorkflowAction(payload, uri)),
        )
        .subscribe(() => {
          sub$.unsubscribe();
          this.back();
        });
    }
  }

  ngOnDestroy(): void {
    this.projectBuilderService.clearData();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
