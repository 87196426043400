import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ProjectService } from '@shared/services/project.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { NEED_MORE_iNFOR_CONFIG, PROJECT_APPLICATION_TYPES, PROJECT_TYPES } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TableNewRow, TABLE_NEW_ROW_ACTION } from '@shared/models/table-new-row.model';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { StoreService } from '@core/store/store.service';
import { isNull } from 'util';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-sub-project-wrapper',
  templateUrl: './sub-project-wrapper.component.html',
  styleUrls: ['./sub-project-wrapper.component.scss']
})
export class SubProjectWrapperComponent implements OnInit, OnDestroy {

  public model: any = {
    type: PROJECT_APPLICATION_TYPES[PROJECT_TYPES.SUB],
    activity: {
      location: {}
    },
  };

  public form = new FormGroup({});

  public viewReady = true;

  public masterProject:any = {};

  private apiCalled = false;
  private minProjectStartDate;

  constructor(
    private projectService: ProjectService,
    private formFactoryService: FormFactoryService,
    private activatedRoute: ActivatedRoute,
    // private location: Location,
    public projectBuilderService: ProjectBuilderService,
    private translateService: TranslateService,
    private modalService: ModalService,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private storeService: StoreService,
    private navigation: NavigationService
  ) { }

  ngOnInit() {
    this.storeService.getOffsetMinStartDate().subscribe(
      prop => {
      this.minProjectStartDate = moment(prop.value);
    });

    const masterProjectId = this.activatedRoute.snapshot.params['masterProjectId'];
    const id = this.activatedRoute.snapshot.params['id'];
    this.model.masterProjectId = masterProjectId;
    const stateData = history.state.data;
    if (stateData && stateData.historic) {
      this.viewReady = false;
      this.projectBuilderService.historicData = true;
      this.setModel(stateData);
    } else if (id) {
      this.getProjectDetails(id, masterProjectId);
    } else {
      this.getMaster(masterProjectId);
    }
  }

  public back() {
    if (!this.projectBuilderService.historicData &&
      this.form.dirty && this.form.touched) {
      const sub$ = this.projectBuilderService.openConfirmationModal(null, null, 'migrateAccountWarning').subscribe(result => {
        if (result) {
          this.navigation.back();
        }
        sub$.unsubscribe();
      });
    } else {
      this.navigation.back();
    }
  }

  public save() {
    const payload = this.createPayload();
    if (!payload.name) {
      const dialogRef = this.modalService.open(ServiceMessageComponent,
        {
          messages: [
            ...(!payload.name ? [{
              message: 'subProjectNameAndRequired',
            }]:[])
          ],
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed().subscribe(() => {
        dialogRef.unsubscribe();
      });
    } else {
      if (this.model.entityActionList && this.model.entityActionList.length && this.model.entityActionList.some(action => action.workflowAction === 'EDIT') || this.formDisabled) {

        let actionLabel = 'EDIT';
        let message = 'confirmChangesMessageProject';
        if (!this.projectBuilderService.isProjectPublicExistingValue && payload.activity.isPublic) {
          actionLabel = null,
          message = 'projectPublicViewChangeConfirmation';
        }

        const dialogRef$ = this.projectBuilderService.openConfirmationModal(actionLabel, this.model.name, message).subscribe(result => {
          if (result) {
            this.saveProject(payload);
            dialogRef$.unsubscribe();
          }
        });
      } else {
        this.saveProject(payload);
      }
    }
  }

  public submit() {
    const payload = this.createPayload();
    this.formFactoryService.markFormGroupDirty(this.form);
    let valid = true;
    if (this.form.get('activity.location.type').value === 'DECIMAL') {
      const latitudeControl = this.form.get('activity.location.latitudeDecimal') as FormControl;
      if(!this.projectBuilderService.validateDecimalLatLong(latitudeControl as FormControl, latitudeControl.value)) {
        valid = false;
      }
      const longitudeControl = this.form.get('activity.location.longitudeDecimal') as FormControl;
      if(!this.projectBuilderService.validateDecimalLatLong( longitudeControl, longitudeControl.value)) {
        valid = false;
      }
    }
    if (!this.checkForInvalidDate()) {
      valid = false;
    }
    if (this.form.valid && valid) {
      this.apiCalled = false;
      this.projectBuilderService.validateSubProjectDocsReq$.next();
      this.projectBuilderService.validateRequiredDocsRes$.pipe(
        filter((result) => {
          if (result && !this.apiCalled) {
            this.apiCalled = true;
            return true;
          } else {
            return false;
          }
        }),
        switchMap(() => this.projectService.submitProject(payload))
      )
      .subscribe(() => {
        this.navigation.back();
      });
    }
  }

  public triggerAction(action) {
    let payload: any = {
      id: this.model.id,
      action: action.workflowAction,
      adminRemark: this.form.get('adminRemark') && this.form.get('adminRemark').value,
      discloseComment: this.form.get('discloseComment') && this.form.get('discloseComment').value
    };

    let uri = 'review';

    if (action.workflowAction === 'SUBMIT' || action.workflowAction === 'RESUBMIT') {
      this.submit();
      return;
    } else if (action.workflowAction === 'NEED_MORE_INFO') {
      this.openNeedMoreInfoPopUp();
      return;
    }else if (['REJECT_SECOND', 'APPROVE_SECOND', 'REJECT_REJECTION', 'APPROVE_REJECTION'].includes(action.workflowAction)) {
      uri = 'review2';
    } else if (action.workflowAction === 'DISCARD') {
      uri = 'discard';
    }
    const sub$ = this.projectBuilderService.openConfirmationModal(action.workflowAction, this.model.account.legalName)
      .pipe(
        filter(result => result),
        switchMap(() => this.projectService.projectWorkflowAction(payload, uri))
      )
      .subscribe(() => {
        sub$.unsubscribe();
        this.navigation.back();
      });

  }

  public updateModelId(id) {
    this.model.id = id;
    this.model = {...this.model}
  }

  private getMaster(masterProjectId) {
    this.viewReady = false;
    this.projectService.getProjectDetails(+masterProjectId).subscribe(result => {
      this.projectBuilderService.addPermission([], null);
      this.masterProject = result;
      this.model.accountId = result.accountId;
      this.model.isAddedSeparately = true;
      this.model.activity.activityType = result.activity.activityType;
      this.model.adminPermisson = this.projectBuilderService.model.adminPermisson,
      this.viewReady = true;
    });
  }

  private getProjectDetails(id, masterProjectId) {
    this.viewReady = false;
    this.projectService.getProjectDetails(+masterProjectId).pipe(
      tap(result => {
        this.masterProject = result;
      }),
      switchMap(() => this.projectService.getProjectDetails(+id))
    ).subscribe(result => {
      this.setModel(result);
    })
  }

  private setModel(result, save = false) {
    this.projectBuilderService.addPermission(result.entityActionList, result.status);
    this.model = result;
    this.projectBuilderService.isProjectPublicExistingValue = this.model.activity.isPublic;
    this.viewReady = true;
    if (this.projectBuilderService.checkForDisabledState(result.entityActionList, this.model.status)) {
      setTimeout(() => {
        this.form.disable();
        // this.needMoreInfoForm.disable();
        this.model = {
          ...this.model,
          adminPermisson: this.projectBuilderService.model.adminPermisson,
          editParticipant: this.projectBuilderService.model.editParticipant,
          disabled: this.projectBuilderService.disabled,
          editPublicVisibility: this.projectBuilderService.hasEditProjectPublicVisibilityAction()
        };
        if (save) {
          this.form.reset(this.model);
        }
      })
    }
  }

  private checkForInvalidDate() {
    const control = this.form.get('activity.activityStartDate');
    if (control.value && control) {
      if (moment(control.value).isBefore(this.minProjectStartDate)) {
        control.markAsDirty();
        control.setErrors({ 'direct': `${this.translateService.instant('COMMON.messageSection.startDateCannotBeLessThenJan2017')}
        ${this.minProjectStartDate.format('MMM D, YYYY')}`});
        return false;
      }
      return true;
    }
    return false;
  }

  private openNeedMoreInfoPopUp() {
    const defaultValues =  {
      id: this.model.id,
      action: 'NEED_MORE_INFO'
    };
    const sub$ = this.modalService.open(TableNewRowComponent, { form: NEED_MORE_iNFOR_CONFIG, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT } as TableNewRow).afterClosed().subscribe(data =>{
        sub$.unsubscribe();
        if (data) {
          this.navigation.back();
        }
    });
  }

  private createPayload() {
    const payload = {...this.model, ...this.form.getRawValue() };
    payload.activity.activityType = this.model.activity.activityType;
    payload.activity.id = this.model.activity.id;
    delete payload.activity.location.file;
    return payload;
  }

  private saveProject(payload) {
    const valid = this.projectBuilderService.validateIncorrectValues(this.form, true);
      if (valid) {
        this.projectService.saveProject(payload)
        .subscribe(result => {
          this.setModel(result.entity, true);
        });
      }
  }

  get formDisabled(): boolean {
    return !!(this.projectBuilderService.disabled && this.model.entityActionList &&
      !this.model.entityActionList.some(action => action.workflowAction === 'EDIT'));
  }

  ngOnDestroy() {
    this.projectBuilderService.clearData();
    this.projectBuilderService.validateRequiredDocsRes$.next(false);
  }

}
