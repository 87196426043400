<div *ngIf="documentUploadConfig.showHeader">
<h3 class="mt-2">{{ 'REGISTRATION_PAGE.enterFacilitySection.addedDocuments' | translate}}</h3>
</div>
<div class="panel">
  <div *ngIf="documentUploadConfig.title">
     <h2 >{{ documentUploadConfig.title | translate}}</h2>
  </div>

  <app-blurb *ngIf='guidanceText' [message]="guidanceText"></app-blurb>

<div class="upload-btn" *ngIf="!readOnly && user.hasPermission('DOCUMENT_UPLOAD')">
  <button type="button" class="btn btn-primary" (click)="openUploadPopup($event)" [disabled]="disabled" [hidden]="hidden">
    <fa-icon class="mr-1"  [icon]="['far', 'plus-square']"></fa-icon>
    <span> {{ 'ACCOUNTS_MODULE.uploadDocument.uploadNewDocument' | translate }}</span>
  </button>
</div>


<div class="table-area">
  <app-table-view
    [data]="documentsPage"
    [config]="tableConfig"
    [firstItemIndex]="firstItemIndex"
    [relative]="relative"
    [paginationDisabled]="uploadInProgress | async"
    (paginationChanged)="onPaginationChanged($event)">
  </app-table-view>
</div>

<ng-template #accountDocumentType let-data>
    <div>{{data.documentType?.name}}</div>
  </ng-template>

<ng-template #accountDocumentActions let-data>
  <ng-container *ngIf="!data._placeholder">
    <div *ngIf="data._uploadStatus !== 'IN_PROGRESS'; else UPLOADING">
      <app-document-actions [document]="data" [documentUploadConfig]="documentUploadConfig"
      (successfullAction)="refreshTable($event)">

      </app-document-actions></div>
    <ng-template #UPLOADING>
      <span>{{ 'COMMON.messageSection.documentUploading' | translate }}</span>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #documentName let-data>
  <ng-container *ngIf="data._placeholder; else DOC">
    <a href="#" class="doc-link" (click)="openUploadPopup($event, data.documentType)">
      <fa-icon class="mr-1"  [icon]="['far', 'plus-square']"></fa-icon>
      <span class="font-italic">{{ 'COMMON.actionsLabel.uploadDocument' | translate }}</span>
    </a>
  </ng-container>
  <ng-template #DOC>
    <span>{{ data.name }}</span>
    <span
      *ngIf="data._uploadStatus === 'IN_PROGRESS'"
      class="processing">
      <mat-spinner diameter="16"></mat-spinner>
    </span>
  </ng-template>
</ng-template>
</div>

<ng-template #makePublicTmpl let-data>
  <div class="d-flex"
       *ngIf="!data._placeholder">
    <p-checkbox name="isPublic-{{ data.id }}"
                value="true"
                class="m-auto"
                [binary]="true"
                [disabled]="!makePublicEnabled || data._uploadStatus === 'IN_PROGRESS' || data.makePublicDisabled"
                (onChange)="updateVisibility(data)"
                [(ngModel)]="data.isPublic"
                wcag-label>
    </p-checkbox>
  </div>
</ng-template>
