import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';

export const CAR_PREFIX = 'CREDIT_ADJUSTMENT_REPORT';
export const CAR_STEP_PREFIX = '.reportStep';
export const CAR_ACTIVITY_PREFIX = '.reportActivity';
export const CAR_CREATE_POPUP = '.createReport';
export const CAR_CHARGING_STATION_ADDRESS_POPUP = '.chargingStationAddress';
export const CAR_TYPE_PREFIX = 'CREDIT_ADJUSTMENT_REPORT.reportTypes';
export const CAR_CCR_SUB_REPORT = 'QUARTERLY';
export const CAR_OTHER_ADJUSTMENTS = 'otherErrorsApartFromCorrections';
export const CAR_NATURE_OF_ERROR= 'natureOfModificationError';
export const CAR_PROVISIONS = 'provisionOfSchedule12';
export const CAR_CREDITS_DEPOSITED ='complianceCreditsToBeDeposited';
export const CAR_CREDITS_CANCELLED='complianceCreditsToBeCancelled';
export const CAR_GASEOUS_CREDITS_DEPOSITED ='gasiousComplianceCreditsToBeDeposited';
export const CAR_LIQUID_CREDITS_DEPOSITED ='liquidComplianceCreditsToBeDeposited';
export const CAR_GASEOUS_CREDITS_CANCELLED ='gasiousComplianceCreditsToBeCancelled';
export const CAR_LIQUID_CREDITS_CANCELLED ='liquidComplianceCreditsToBeCancelled';

export const CAR_IMMEDIATE_TRANSFER='CREDIT_TRANSFER';

export const CAR_ACTIVITIES = {
  1: 'PRODCI_LIQUID',
  2: 'PRODCI_GAS',
  3: 'PRODCI_GAS_FOR_FUELING_STATION',
  4: 'IMPLCI_LIQUID',
  5: 'IMPLCI_GAS',
  6: 'IMPLCI_GAS_FOR_FUELING_STATION',
  7: 'CREDIT_TRANSFER',
  8: 'BPE_GAS'
}

export const CAR_CCR_SECTION = 'CREDIT_ADJUSTMENT_REPORT.reportStep.ccrSection';
export const CAR_ADJ_SECTION = 'CREDIT_ADJUSTMENT_REPORT.reportStep.adjSection';

export const CAR_ID_KEY = 'CAR_ID';
export const CAR_DATA_KEY = 'CAR_DATA';
export const CAR_CURRENT_STEP_KEY = 'CAR_STEP_KEY';

export const CAR_TABLE_CONFIG_PREFIX = 'CREDIT_ADJUSTMENT_REPORT_';
export const CAR_COMPLIANCE_CREDITS_CREATED_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.numberOfComplianceCredits';
export const CAR_TABLE_PREFIX = `${ CAR_PREFIX }.table`;
export const CAR_FUEL_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.lowCIFuelType';
export const GASEOUS_CAR_FUEL_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousLowCIFuelType';
export const IMPORTED_CAR_FUEL_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedLowCIFuelType';
export const IMPORTED_GASEOUS_CAR_FUEL_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousLowCIFuelType';
export const ELECTRICITY_FUEL_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityLowCICreditFuelType';
export const CAR_FACILITY_NAME_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.lowCIFacilityName';
export const GASEOUS_CAR_FACILITY_NAME_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousLowCIFacilityName';
export const IMPORTED_CAR_FACILITY_NAME_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedLowCIFacilityName';
export const IMPORTED_GASEOUS_CAR_FACILITY_NAME_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousLowCIFacilityName';
export const ELECTRICITY_CAR_FACILITY_NAME_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityLowCIFacilityName';
export const CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.liqAlphanumericId';
export const GASEOUS_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousLiqAlphanumericId';
export const GASEOUS_FUEL_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousFuellingLiqAlphanumericId';
export const IMPORTED_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedLiqAlphanumericId';
export const IMPORTED_GASEOUS_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousLiqAlphanumericId';
export const IMPORTED_GASEOUS_FUELLED_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousFuelledLiqAlphanumericId';
export const EXPORT_LOW_CI_FUEL_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousFuelledLiqAlphanumericId1';
export const IMPORTED_GASEOUS_FUELLED_CAR_ALPHANUMERIC_ID_TOOLTIP1 = 'importedGaseousFuelledLiqAlphanumericId1';
export const ELECTRICITY_FUELLED_CAR_ALPHANUMERIC_ID_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityFuelledLiqAlphanumericId';
export const IMPORTED_CIVIC_ADDRESS_TOOLTIP = 'importedCivicAddress';
export const IMPORTED_GASEOUS_CIVIC_ADDRESS_TOOLTIP = 'importedGaseousCivicAddress';
export const IMPORTED_FOREIGN_SUPPLIER = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCivicAddress';
export const IMPORTED_GASEOUS_FOREIGN_SUPPLIER = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCivicAddress';
export const CAR_CARBON_INTENSITY_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carCarbonIntensityFuel';
export const CAR_CARBON_INTENSITY_FUEL_TOOLTIP1 = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carCarbonIntensityFuel1';
export const IMPORTED_CAR_CARBON_INTENSITY_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarCarbonIntensityFuel';
export const ELECTRICITY_CAR_CARBON_INTENSITY_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityCarCarbonIntensityFuel';
export const CAR_CREDITS_TYPES_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.creditsTypes';
export const GASEOUS_CAR_CREDITS_TYPES_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCreditsTypes';
export const IMPORTED_CAR_CREDITS_TYPES_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCreditsTypes';
export const IMPORTED_GASEOUS_CAR_CREDITS_TYPES_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCreditsTypes';
export const CAR_FUEL_REPLACEMENT_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carFuelReplacementType';
export const CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carQuantityDifference';
export const GASEOUS_CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCarQuantityDifference';
export const GASEOUS_FUEL_CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousFuelCarQuantityDifference';
export const IMPORTED_CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarQuantityDifference';
export const IMPORTED_GASEOUS_CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCarQuantityDifference';
export const IMPORTED_GASEOUS_FUEL_CAR_QUANTITY_DIFFERENCE_PARA_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedFuelGaseousCarQuantityDifference';
export const CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carQuantityOfLowCiFuel';
export const CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP_READONLY = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carQuantityOfLowCiFuelReadonly';
export const GASEOUS_CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCarQuantityOfLowCiFuel';
export const GASEOUS_FUEL_CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousFuelCarQuantityOfLowCiFuel';
export const IMPORTED_CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarQuantityOfLowCiFuel';
export const IMPORTED_GASEOUS_CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCarQuantityOfLowCiFuel';
export const IMPORTED_GASEOUS_FUEL_CAR_QUANTITY_OF_LOW_CI_FUEL_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousFuelCarQuantityOfLowCiFuel';
export const CREDITS_CREATED_FOR_PARTICIPANT_STEP7_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.getNumberOfCreditsCreatedforParticipantStep7';
export const CAR_ENERGY_DENSITY_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carEnergyDensity';
export const GASEOUS_CAR_ENERGY_DENSITY_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCarEnergyDensity';
export const IMPORTED_CAR_ENERGY_DENSITY_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarEnergyDensity';
export const IMPORTED_GASEOUS_CAR_ENERGY_DENSITY_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCarEnergyDensity';
export const ELECTRICITY_CAR_ENERGY_DENSITY_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityCarEnergyDensity';
export const ELECTRICITY_CAR_ENERGY_DENSITY_TOOLTIP1 = 'electricityCarEnergyDensity';
export const ELECTRICITY_ENERGY_EQUIP_TOOLTIP ='CREDIT_ADJUSTMENT_REPORT.reportStep.electricalEnergyEquipment';
export const CAR_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carFeedStockType';
export const GASEOUS_CAR_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCarFeedStockType';
export const IMPORTED_CAR_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarFeedStockType';
export const IMPORTED_GASEOUS_CAR_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCarFeedStockType';
export const ELECTRICITY_CAR_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityCarFeedStockType';
export const CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.carReadOnlyFeedStockType';
export const GASEOUS_CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.gaseousCarReadOnlyFeedStockType';
export const IMPORTED_CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedCarReadOnlyFeedStockType';
export const IMPORTED_GASEOUS_CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.importedGaseousCarReadOnlyFeedStockType';
export const ELECTRICITY_CAR_READONLY_FEED_STOCK_TYPE_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.electricityCarReadOnlyFeedStockType';
export const CAR_LIQCOMP_TOTAL_CREDITS_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.totalNoOfcreditsCreated';
export const CAR_DESCRIPTION_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.CarDescription';
export const ELECTRICITY_CAR_PRODUCED_BIOGAS ='CREDIT_ADJUSTMENT_REPORT.reportStep.electricityCarbonProducedBiogas';
export const ELECTRICITY_TOTAL_QUANTITY_BIOGAS_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.totalQuantityOfBiogasValue';
export const ELECTRICITY_BIOGAS_QUANTITY_DFF_TOOLTIP = 'CREDIT_ADJUSTMENT_REPORT.reportStep.biogasQuantityDff';
export const CAR_FACILITY_DIFFERENT_FROM_BIOGAS ='facilityDifferentFromBiogas';
export const CAR_ELECTRICITY_FACILITY_CIVIC_ADDRESSS ='civicAddressOfElectricityFacility';
export const CAR_FACILITY_FUEL_CIVIC_ADDRESS_LABEL = 'facilityFuelCivicAddress'
export const CAR_ELECTRICITY_FACILITY_NAME ='electricityFacilityName';


export const CAR_STEP_COMPONENTS = {
  CREDIT_ADJUSTMENT_REPORT_STEP: 'CreditAdjustmentReportStepComponent',
  CREDIT_ADJUSTMENT_CONTACT_PERSON: 'CreditAdjustmentContactPersonComponent',
  CREDIT_ADJUSTMENT_DOCUMENTS: 'CreditAdjustmentDocumentsComponent',
  CREDIT_ADJUSTMENT_OTHER_ADJUSTMENTS: 'CreditAdjustmentOtherAdjustmentsComponent',
  CREDIT_ADJUSTMENT_SUMMARY: 'CreditAdjustmentReportSummaryComponent',
  CREDIT_ADJUSTMENT_EXPORT_LOW_CI_FUELS: 'CreditAdjustmentReportExportLowciFuelsComponent',
  CREDIT_ADJUSTMENT_SUB_STEP_SUMMARY: 'CreditAdjustmentSubStepSummaryComponent'
};

export const CAR_STEPS_DATA_FLD = 'associatedCreditAdjustmentSteps';
export const CAR_QUARTERLY_STEP_CODE = 'quarterCode';
export const CAR_QUARTERLY_STEP_REPORT_ID_FLD = 'creditCreationReportId';
export const CAR_ORGANIZATION_NAME_FLD = 'accountId';
export const CAR_TYPE_FLD = 'reportType';
export const CAR_ACTIVITY_CODE_FLD = 'activityCode';
export const CAR_COMPLIANCE_PERIOD_FLD = 'compliancePeriod';
export const CAR_QUARTER_FLD = 'quarterCode';
export const CAR_FACILITY_NAME_FLD = 'facilityName';
export const CAR_REFERRED_REPORT_DETAIL_ID_FLD = 'referredReportDetailId';
export const CAR_REFERRED_REPORT_DETAIL_FLD = 'referredReportDetail';
export const CAR_ATTESTATIONS_FLD = 'attestations';
export const CAR_NO_CCR_IGNORE_QUARTER_FLG = 'noCCRignoreQuarter';
export const CAR_STEP_REQUIRED_UNITS_FLD = 'requiredUnits';
export const CAR_QUANTITY_TO_SATISFY = 'quantityToSatisfy';
export const CAR_QUANTITY_TO_CANCEL = 'quantityToCancel';
export const CAR_TOTAL_AVAILABLE_CREDITS = 'totalAvailableCredits';
export const CAR_TOTAL_CREDITS_TO_CANCEL_APPLIED_FOR_EXPORT_STEP = 'totalCreditsToCancelAppliedForExportStep';
export const CAR_ENGAGE_IN_ACTIVITIES = 'engageInActivities';
export const CAR_ERROR_DESCRIPTIONS ='errorDescriptions'


// STEP table row data
export const CAR_FACILITY_FLD = 'facility';
export const CAR_FACILITY_EQUIPMENT = 'facilityEquipment';
export const CAR_PROVINCE_FLD = 'province';
export const CAR_QUARTER_NAME_FLD = 'quarterName';
export const CAR_FACILITY_ID_FLD = 'facility.id';
export const CAR_FUEL_FLD = 'fuel';
export const CAR_FUEL_ID_FLD = 'fuel.id';
export const CAR_CI_ID_FLD = 'ciApplicationId';
export const CAR_ALPHANUMERIC_CI_FLD = 'alphanumericId';
export const CAR_ALPHANUMERIC_CODE ='alphanumericCode';
export const CAR_CI_ID = 'ciID';
export const CAR_CI_LIQUID_CI_FUEL_FLD = 'approvedCliValue';
export const CAR_CI_LIQUID_CI_FUEL_ID = 'approvedCliId';
export const CAR_CI_INSTRUMENT_ID = 'instrumentId';
export const CAR_CI_LIQUID_CI_FUEL_HYDROGEN_LABEL = 'approvedCliValueHydrogen';
export const CAR_CI_LIQUID_CI_FUEL_STEP_Q1 = 'approvedCliValueSTEPQ1Label';
export const CAR_CI_LIQUID_CI_FUEL_STEP_Q2_CFR_LABEL = 'approvedCliValueSTEPQ2CFRLabel';
export const CAR_CI_LIQUID_CI_FUEL_STEP_Q2_CAR_LABEL = 'approvedCliValueSTEPQ2CARLabel';
export const CAR_CI_LIQUID_CI_FUEL_GAS_LABEL = 'approvedCliValueGas';
export const CAR_CI_LIQUID_CI_FUEL_BIOGAS_LABEL = 'approvedCliValueBiogas';
export const CAR_FEEDSTOCK_NAME_FLD = 'feedstockName';
export const CAR_FEEDSTOCK_REGION_FLD = 'feedstockRegion';
export const CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD = 'energyDensity';
export const CAR_LO_CI_FUEL_ENERGY_DENSITY_HYDROGEN_LABEL = 'energyDensityHydrogen';
export const CAR_LO_CI_FUEL_ENERGY_DENSITY_GAS_LABEL = 'energyDensityGas';
export const CAR_LO_CI_FUEL_QUANTITY_FLD = 'complianceCreditQuantity';
export const CAR_LO_CI_FUEL_QUANTITY_DIFF_FLD = 'quantityDifference';
export const CAR_BIOGAS_ENERGY_OUTPUT_DIFF = 'energyOutputDifference'
export const CAR_BIOGAS_ENERGY_PRODUCED_DIFF = 'energyProducedDifference'
export const CAR_LO_CI_FUEL_QUANTITY_ = 'complianceCreditQuantity';
export const CAR_LO_CI_FUEL_QUANTITY_GAS_LABEL = 'complianceCreditQuantityGas';
export const CAR_FUEL_REPLACEMENT_FLD = 'fuelReplacementCode';
export const CAR_FUEL_REPLACEMENT_TYPE = 'fuelReplacementType';

export const CAR_LO_CI_FUEL_QUANTITY_STEP_Q1_CFR_LABEL = 'complianceCreditQuantityStepQ1CFRLabel';
export const CAR_LO_CI_FUEL_QUANTITY_STEP_Q1_CAR_LABEL = 'complianceCreditQuantityStepQ1CARLabel';
export const CAR_LO_CI_FUEL_QUANTITY_STEP_Q1_DIFF_LABEL = 'complianceCreditQuantityStepQ1DiffLabel';

export const CAR_CREDITS_TYPE = 'creditType';
export const CAR_CREDITS_TYPE_ID = 'creditTypeId';
export const CAR_CREDITS_TYPE_FLD = 'typeOfCredit';
export const CAR_CREDITS_TYPE_OBJ = 'creditType.id';
export const CAR_LIQUID_COMPLIANCE_CREDITS_FLD = 'calculatedComplianceCredits';
export const CAR_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL = 'gaseousComplianceCreditsCreated';
export const CAR_OVERRIDE_CREDITS_NUMBER_FLD = 'overrideComplianceCredits';
export const CAR_COMPLIANCE_CREDIT_CREATED_VALUE = 'complianceCreditsCreated';
export const CAR_UPDATED_COMPLIANCE_CREDIT_VALUE = 'updatedComplianceCreditsCreated';
export const CAR_REASON_FOR_OVERRIDING_FLD = 'reason';
export const CAR_SEARCH_BUTTON = 'search';
export const CAR_CLEAR_BUTTON = 'clear';
export const CAR_VIEW_PROJECT_INFO = 'viewProjectInfo';
export const CAR_VIEW_CONTACT_PERSON_INFO = 'viewContactPerson';
export const CAR_NAME_OF_QUARTER = 'nameOfQuarter';
export const CAR_FUELING_STATION_REGISTERED = 'fuelingStationRegisteredInCats';
export const CAR_ORGNIZATION_ID = 'searchedOrganizationId';
export const CAR_ORGNIZATION_ID_PLACEHOLDER = 'searchedOrganizationIdPlaceholder';
export const CAR_STATION_ID = 'searchedStationId';
export const CAR_STATION_ID_PLACEHOLDER = 'searchedStationIdPlaceholder';
export const CAR_FUELING_STATION_OWNER_NAME = 'fuelingStation.accountName';
export const CAR_FUELING_STATION_OWNER_NAME_LABEL = 'fuelingStationOwnerName';
export const CAR_FUELING_STATION_NAME = 'fuelingStation.name';
export const CAR_FUELING_STATION_NAME_LABEL = 'fuelingStationName';
export const CAR_FACILITY_CIVIC_ADDRESS = 'facilityCivicAddress';
export const CAR_POSTAL_ADDRESS = 'postalAddress';
export const CAR_CIVIC_ADDRESS = 'civicAddress';
export const CAR_FUELING_STATION_POSTAL_ADDRESS = 'fuelingStationPostalAddress';
export const CAR_FUELING_STATION_CIVIC_ADDRESS = 'fuelingStationCivicAddress';
export const CAR_FUELING_STATION_POSTAL_ADDRESS_FLD = 'fuelingStation.postalAddress';
export const CAR_FUELING_STATION_CIVIC_ADDRESS__FLD = 'fuelingStation.civicAddress';
export const CAR_FACILITY_CIVIC_ADDRESS_FLD = 'facility.civicAddress';
export const CAR_FOREIGN_SUPPLIER_NAME = 'nameOfForeginSupplier';
export const CAR_FOREIGN_SUPPLIER_CIVIC_ADDRESS = 'civicAddressOfForeignSupplier';
export const CAR_FACILITY_EQUIPMENT_CIVIC_ADDRESS = 'facilityEquipmentAddress';
export const CAR_TRANSFEREE = 'transfereeName';
export const CAR_CARBON_INTENSITY_OF_FUEL = 'carbonIntensityOfFuel';
export const CAR_NUMBER_OF_CREDITS_TO_BE_TRANSFERED = 'numberOfCreditsToBeTransfered';
export const CAR_ELECTRICAL_ENERGY_OUTPUT = 'electricalEnergyOutput';
export const CAR_HEAT_ENERGY_PRODUCED = 'heatEnergyProduced';
export const CAR_ELECTRICAL_ENERGY_PRODUCED = 'electricalEnergyProduced';
export const CAR_REFERENCE_CI_OF_ELECTRICITY = 'referenceCiOfElectricity';
export const CAR_REFERENCE_CI_OF_ELECTRICITY_CODE_FLD = 'referenceCiOfElectricityCode';
export const CAR_CI_OF_ELECTRICITY_PRODUCED = 'carbonIntensityElecProducedUsingBiogas';
export const CAR_SECTION_VALIDATION_EQUATION = 'sectionValidationEquation';
export const CAR_CI_OF_ELECTRICITY_ASSOCIATED_WITH_QUANTITY_FLD = 'carbonIntensityOfElectricity';
export const CAR_SECTION_FLD = 'section';
export const CAR_TOTAL_QTY_BIOGAS = 'totalQuantityOfBiogas';
export const CAR_QUANTITY_OF_FUEL='fuelCreditQuatity'
export const CAR_NUMBER_OF_CREDITS_OF_PARTICIPANT='numberOfparticipantCredits'
export const CAR_INVOLVED_IN_ACTIVITIES_FLD = 'involvedInActivities';
export const CAR_ENERGY_DENSITY_FLD = 'energyDensity';
export const CCR_KEY = 'CCR';
export const CAR_KEY = 'CAR';

// additional details
export const CAR_BLANK_LABEL = 'BLANK';
export const CAR_COMPLIANCE_CREDITS_CREATED_BY_EXPORTED_FUEL_FLD = 'complianceCreditsCreatedByExportedFuel';
export const CAR_COMPLIANCE_CREDITS_TO_BE_DEPOSITED_FLD = 'complianceCreditsToBeDeposited';
export const CAR_COMPLIANCE_CREDITS_DEPOSITED_FINAL ='finalComplianceCreditsToBeDeposited';
export const CAT_COMPLIANCE_CREDITS_CANCELLED_FINAL ='finalComplianceCreditsToBeCancelled';
export const CAR_OVERRIDE_CREATED_COMPLIANCE_CREDITS_FLD = 'overrideCreatedComplianceCredits';
export const CAR_COMPLIANCE_CREDITS_CREATED_FLD = 'complianceCreditsCreated';
export const CAR_UPDATED_COMPLIANCE_CREDIT_FLD = 'updatedComplianceCredit';
export const CAR_UPDATED_COMPLIANCE_CREDIT_CANCELLED ='updatedComplianceCreditCancelled'
export const CAR_REASON_FOR_COMPLIANCE_CREDIT_OVERRIDE_LABEL = 'reasonForComplianceCreditOverride'
export const CAR_REASON_FOR_COMPLIANCE_CREDIT_DEPOSITED_OVERRIDE_FLD = 'reasonForComplianceCreditDepositedOverride';
export const CAR_REASON_FOR_COMPLIANCE_CREDIT_CANCELLED_OVERRIDE_FLD = 'reasonForComplianceCreditCancelledOverride';
export const CAR_OVERRIDE_CHANGE_ERROR_DESCRIPTION_FLD = 'overrideChangeErrorDescription';
export const CAR_COMPLIANCE_CREDITS_TO_BE_CANCELLED_FLD = 'complianceCreditsToBeCancelled';
export const CAR_OVERRIDE_CANCELLED_COMPLIANCE_CREDITS_FLD = 'overrideCancelledComplianceCredits';
export const CAR_CANCELLED_CHANGE_ERROR_DESCRIPTION_FLD = 'cancelledChangeErrorDescription';
export const CAR_REFERENCE_CARBON_INTENSITY_FLD = 'referencedCarbonIntensity';
export const CAR_QUANTITY_FUEL_EXPORTED_FLD ='quantityOfFuelExported';
export const CAR_DESCRIPTION_OF_CHANGE_OR_ERROR = 'descriptionOfChangeOrError';

export const CAR_TOTAL_CREDITS_TO_BE_ISSUED_FLD = 'totalCreditsToBeIssued';
export const CAR_TOTAL_FINAL_CREDITS_ISSUED_FLD = 'finalCreditsIssued';
export const CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED = 'totalLiquidGasolineCreditsCreated';
export const CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED = 'totalLiquidDiselCreditsCreated';
export const CAR_TOTAL_LIQUID_OTHER_CREDITS_CREATED = 'totalLiquidOtherCreditsCreated';
export const CAR_TOTAL_GASEOUS_CREDITS_CREATED = 'totalGaseousCreditsCreated';
export const CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED = 'totalLiquidCreditsCreated';

export const CAR_TOTAL_LIQUID_GASOLINE_CREDITS_TO_BE_TRANSFERED = 'totalLiquidGasolineCreditsToBeTransfered';
export const CAR_TOTAL_LIQUID_DIESEL_CREDITS_TO_BE_TRANSFERED = 'totalLiquidDieselCreditsToBeTransfered';
export const CAR_TOTAL_LIQUID_OTHER_CREDITS_TO_BE_TRANSFERED = 'totalLiquidOtherCreditsToBeTransfered';
export const CAR_TOTAL_LIQUID_CREDITS_TO_BE_TRANSFERED = 'totalLiquidCreditsToBeTransfered';
export const CAR_TOTAL_GASEOUS_CREDITS_TO_BE_TRANSFERED = 'totalGaseousCreditsToBeTransfered';
export const CAR_TOTAL_LIQUID_GASOLINE_CREDITS_TO_BE_REPLACED = 'totalLiquidGasolineCreditsToBeReplaced';
export const CCR_TOTAL_LIQUID_DIESEL_CREDITS_TO_BE_REPLACED = 'totalLiquidDieselCreditsToBeReplaced';
export const CAR_TOTAL_LIQUID_FUEL_CREDITS_TO_BE_TRANSFERED = 'totalLiquidFuelCreditsToBeTransfered';
export const CAR_TOTAL_GASEOUS_FUEL_CREDITS_TO_BE_TRANSFERED = 'totalGaseousFuelCreditsToBeTransfered';


export const CAR_SUMMARY_CREDITS_TO_DEPOSIT_FLD = 'creditsToDepositInAccount';
export const CAR_SUMMARY_CREDITS_TO_DEPOSIT_IN_OTHER_ACCOUNT_FLD = 'creditsToDepositInOtherAccount';
export const CAR_SUMMARY_FINAL_CREDITS_TO_DEPOSIT = 'finalCreditsToDepositInAccount';
export const CAR_FINAL_CREDITS_IN_ACCOUNT_SUMMARY = 'finalCreditsInAccountSummary';
export const CAR_SUMMARY_CREDITS_TO_EXPORT = 'creditsToExport';

export const CAR_EQUIPMENT_SERIAL_NO_FLD = 'serialNumberOfEquipement';
export const CAR_PROJECT_ID_FLD = 'erpProjectId';
export const CAR_CONTACT_PERSON_NAME_FLD = 'contactPerson';
export const CAR_INVOLVED_IN_ACTIVITIES = 'involvedInActivitiesForComplianceCredits';

export const CAR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE_FLD = 'complianceCreditsCreated';
export const CAR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE = 'annualComplianceCreditsCreated';
export const CAR_ANNUAL_ENTER_CREDIT_INFO_LABEL = 'enterCreditInfoLabel';
export const CAR_ANNUAL_CO2_LIQUID_CREDITS = '_liquidCredits';
export const CAR_ANNUAL_CO2_GASEOUS_CREDITS = '_gaseousCredits';

export const CAR_FUELING_STATION_FLD = 'fuelingStation';
export const CAR_FUELING_STATION_HYDROGEN_FLD = 'fuelingStationHydrogen';
export const CAR_FUELING_STATION_FUEL_FLD = 'fuelingStationFuel';
export const CAR_FUELING_STATION_RENEWABLE_FUEL_FLD = 'fuelingStationRenewableFuel';
export const CAR_QUANTITY_VEHICLE_FUEL_FLD = 'quantityOfFuelMeasured';
export const CAR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD = 'quantityOfFuelSupplied';
export const CAR_QUANTITY_FUEL_SUPPLIED_TO_VEHICLE_FLD = 'complianceCreditQuantity';
export const CAR_ANNUAL_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL = 'gaseousAnnualComplianceCreditsCreated';
export const CAR_EDITED_PROJECT_DETAILS = 'erpEditedProject';
export const CAR_FUEL_PURCHASE_PERSON_NAME = 'fuelSellerName';
export const CAR_VOLUME_RENEWABLE_VEHICLE_FUEL = 'quantityOfFuelSupplied';
export const CAR_FUEL_QUANTITY_FLD = 'quantityOfFuel';
export const CAR_TOTAL_SUBTRACTIONS_FROM_POOL_FLD = 'totalSubtractionsFromPool';
export const CAR_ENERGY_EFFICIENCY_RATIO = 'energyEfficiencyRatio';
export const CAR_DEFAULT_ENERGY_EFFICIENCY_RATIO_FLD = 'defaultEnergyEfficiencyRatio';
export const CAR_ELECTRICITY_SUPPLIED_TO_PROVINCE_FLD = 'complianceCreditQuantity';
export const CAR_ELECTRICITY_SUPPLIED_TO_PROVINCE = 'electricitySuppliedToProvince';
export const CAR_ELECTRICITY_SUPPLIED_TO_VEHICLES = 'electricitySuppliedToVehicles';
export const CAR_PROVINCE_TERRITORY = 'provinceTerritory';
export const CAR_CHARGING_STATION_FLD = 'chargingStation';
export const CAR_CHARGING_STATION_LAT_FLD = 'chargingStation.civicAddress.latitude';
export const CAR_CHARGING_STATION_LON_FLD = 'chargingStation.civicAddress.longitude';
export const CAR_CHARGING_STATION_ID_FLD = 'chargingStationId';
export const CAR_CHARGING_STATION_NAME_FLD = 'chargingStationName';
export const CAR_ACCESSIBILITY_TO_MARINE_VESSELS = 'chAccesbilityToMarineVessels';
export const CAR_PHYSICAL_ADDRESS_OF_LOCATION = 'physicalAddressOfLocation';
export const CAR_VIEW_ADDRESS = 'viewAddressDetails';
export const CAR_EDIT_ADDRESS = 'editAddressDetails';
export const CAR_CHARGING_STATION_PHYSICAL_ADDRESS_FLD = 'chargingStation.physicalAddress';
export const CAR_TOTAL_NUMBER_OF_LICOMP_ELECTRICITY = 'totalNumberOfLiquidAnnualStep5';
export const CAR_TOTAL_NUMBER_OF_CREDITS_STEP_Q1_CFR_LABEL = 'totalNumberOfCreditsStepQ1CFRLabel';
export const CAR_TOTAL_NUMBER_OF_CREDITS_STEP_Q1_CAR_LABEL = 'totalNumberOfCreditsStepQ1CARLabel';

export const CAR_QUANTITY_OF_HYDROGEN = 'quantityOfHydrogen';
export const CAR_HYDROGEN_ENERGY_EFFICIENCY_RATIO_LABEL = 'energyEfficiencyRatioHydrogen';

export const CAR_MANUAL_ENERGY_DENSITY = 'manualEnergyDensity';
export const CAR_MANUAL_ENERGY_DENSITY_VALUE = 'manualEnergyDensityValue';
export const CAR_AGREEMENT_IDENTIFIER = 'agreementToCreditId';

export const CAR_OPINION_TYPE = 'opinionType';
export const CAR_CONCLUSION_COMMENT = 'conclusionComment';
export const CAR_VERIFICATION_BODY = 'vbName';

export const CAR_MATERIAL_CHANGE = 'materialChange';
export const CAR_VOLUNTARY_SUBMISSION = 'voluntarilySubmission';

export const CAR_FUEL_NAME_EN_FLD = 'fuelName';
export const CAR_FUEL_NAME_FR_FLD = 'fuelNameFr';

export const CAR_FUEL_QUANTITY_STEP_5_FLD = 'quantitySTEP-5';
export const CAR_FUEL_QUANTITY_STEP_5_TOOLTIP = `${CAR_PREFIX}${CAR_STEP_PREFIX}.quantityStep5Tooltip`;
export const CAR_STEP_5_TABLE_TITLE = `${ CAR_PREFIX }${ CAR_STEP_PREFIX }.step_5_table_title`;
export const CAR_TOTAL_CREDITS_TO_BE_CANCELLED_FLD = 'totalCreditsToCancelForExportStep';
export const CAR_NUMBER_ADJUSTED_LIQUID_CREDITS_TO_CANCEL_FLD = 'adjustedLiquidCreditToCancel';
export const CAR_NUMBER_ADJUSTED_GASEOUS_CREDITS_TO_CANCEL_FLD = 'adjustedGasiousCreditToCancel';


export const CAR_STEP_5_CREDITS_HIDE_EXPRESSION = false;
export const CAR_TOTAL_CREDITS_SELECTED_FOR_CANCELLATION_FLD = 'totalCreditsSelectedForCancellation';
export const CAR_CHECKBOX_STEP_5_FLD = 'agreeForLowCiExport';
export const CAR_QUANTITY_LOWCI_FUEL_EXPORTED_TOOLTIP='lowCiQuantityExported';
export const GASEOUS_CAR_QUANTITY_LOWCI_FUEL_EXPORTED_TOOLTIP='gaseousLowCiQuantityExported';
export const IMPORTED_CAR_QUANTITY_LOWCI_FUEL_EXPORTED_TOOLTIP='importedLowCiQuantityExported';
export const IMPORTED_GASEOUS_CAR_QUANTITY_LOWCI_FUEL_EXPORTED_TOOLTIP='importedGaseousLowCiQuantityExported';
export const IMPORTED_GASEOUS_FUELLED_CAR_QUANTITY_LOWCI_FUEL_EXPORTED_TOOLTIP='importedGaseousFuelledLowCiQuantityExported';
export const CAR_COMPLIANCE_CREDITS_EXPORTED_TOOLTIP='numberOfComplianceCreditsExported';
export const CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='numberOfComplianceCreditsDeposited';
export const GASEOUS_CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='gaseousNumberOfComplianceCreditsDeposited';
export const GASEOUS_FUEL_CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='gaseousFuelNumberOfComplianceCreditsDeposited'
export const IMPORTED_CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='importedNumberOfComplianceCreditsDeposited';
export const IMPORTED_GASEOUS_CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='importedGaseousNumberOfComplianceCreditsDeposited';
export const IMPORTED_GASEOUS_FUEL_CAR_COMPLIANCE_CREDITS_DEPOSITED_TOOLTIP='importedGaseousFuelNumberOfComplianceCreditsDeposited'
export const STEP7_DESCRIPTION_TOOLTIP = 'DescriptionForStep7';
export const  CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='numberOfComplianceCreditsCancelled';
export const  GASEOUS_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='gaseousNumberOfComplianceCreditsCancelled';
export const  GASEOUS_FUEL_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='gaseousFuelNumberOfComplianceCreditsCancelled';
export const  IMPORTED_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='importedNumberOfComplianceCreditsCancelled';
export const  IMPORTED_GASEOUS_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='importedGaseousNumberOfComplianceCreditsCancelled';
export const  IMPORTED_GASEOUS_FUELLED_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='importedGaseousFuelledNumberOfComplianceCreditsCancelled';
export const  IMPORTED_GAS_FUEL_CAR_COMPLIANCE_CREDITS_CANCELLED_TOOLTIP='importedGasFuelNumberOfComplianceCreditsCancelled';
export const CAR_SAME_QUANTITY_AS_IN_CCR = 'sameVolumeAsReportedInCCR';
export const CAR_SAME_ENERGY_OUTPUT_AS_IN_CCR = 'sameEnergyOutputAsReportedInCCR';
export const CAR_SAME_ENERGY_PRODUCED_AS_IN_CCR = 'sameEnergyProducedAsReportedInCCR';
export const CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_CHECKBOX_FLD = 'overrideDiffBetweenQuantities';
export const CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_FLD = 'updatedQuantityDifference';
export const CAR_OVERRIDE_THE_DIFF_OF_QUANTITY_REASON_FLD = 'overridingQuantityReason';
export const CAT_FUELLING_STATION_DATA_CHANGE_TOOLTIP = 'toChangeFuellingStationDataContactAdminTooltip';
export const CAR_FUELING_STATION_OWNER_TOOLTIP ='fuelingStationOwnerTooltip';
export const CAR_FUELING_STATION_NAME_TOOLTIP ='nameOfFuelingStationTooltip';
export const IMPORTED_GASEOUS_CAR_FUELING_STATION_OWNER_TOOLTIP = 'importedGaseousCarFuelStationOwner';
export const IMPORTED_GASEOUS_CAR_FUELING_NAME_TOOLTIP ='importedGaseousCarFuelStation';
export const GASEOUS_CAR_FUELING_STATION_NAME_TOOLTIP = 'gaseousLowCIFacilityName';
export const IMPORTED_GASEOUS_CAR_FUELING_STATION_NAME_TOOLTIP = 'importedGaseousLowCIFacilityName';



export const CAR_SUMMARY_FIELDS = {
  'STEP-1': [
    CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
    CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
    CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
  ],
  'STEP-2': [CAR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-3': [CAR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-4': [
    CAR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
    CAR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
    CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
  ],
  'STEP-5': [CAR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-6': [CAR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED],
  'STEP-7': [],
  'STEP-8': [CAR_TOTAL_GASEOUS_CREDITS_CREATED]
};

export const CAR_DEFAULT_ENERGY_EFFICIENCY_RATIO = 2.5;
export const CAR_DEFAULT_HYDROGEN_ENERGY_EFFICIENCY_RATIO = 1.5;

export const CAR_YES_NO_ITEMS = [
  { id: 'yes', name: 'prYes', value: true },
  { id: 'no', name: 'prNo', value: false },
];

export const CAR_FUEL_TYPE = {
  LIQUID: 'LIQUID',
  GAS: 'GAS',
};

export const CAR_DATE_ONLY_FORMAT = 'YYYY-MM-DD';

export const CARApplyPrefix = (value: string, subPrefix: string = ''): string => `${CAR_PREFIX}${subPrefix}.${value}`;

export const CAR_DOCUMENTS_ENTITY_ANNUAL = 'CREDIT_CREATION_REPORT_ANNUAL';
export const CAR_DOCUMENTS_ENTITY_ANNUAL_STEP1 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP1';
export const CAR_DOCUMENTS_ENTITY_ANNUAL_STEP3 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP3';
export const CAR_DOCUMENTS_ENTITY_ANNUAL_STEP4 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP4';
export const CAR_DOCUMENTS_ENTITY_ANNUAL_STEP5 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP5';

export const CAR_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: `${ CAR_PREFIX }${ CAR_CHARGING_STATION_ADDRESS_POPUP }.`,
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  provinceStateLabel: 'CREDIT_CREATION_REPORT.reportStep.provinceTerritory',
  sameAsOrganization: {
      hide: false,
      changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
  disableCountryField: true,
};

export const CAR_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: `${ CAR_PREFIX }${ CAR_CHARGING_STATION_ADDRESS_POPUP }.`,
  key: 'civicAddress',
  provinceStateLabel: 'CREDIT_CREATION_REPORT.reportStep.provinceTerritory',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
  disableCountryField: true,
};

export const creditsAdjustedTableConfig: ITableViewConfig = {
  translationPrefix: 'CREDIT_ADJUSTMENT_REPORT.creditAdjustmentReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'creditAdjustedOrganization',
      header: 'creditAdjustedOrganization',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'totalCreditsToBeDeposited',
      header: 'totalCreditsToBeDeposited',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'totalCreditsToBeCancelled',
      header: 'totalCreditsToBeCancelled',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    }
  ],
};

export const creditsTransferedTableConfig: ITableViewConfig = {
  translationPrefix: 'CREDIT_ADJUSTMENT_REPORT.creditAdjustmentReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'accountName',
      header: 'accountName',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'fuelType',
      header: 'fuelType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'carbonIntensityId',
      header: 'carbonIntensityId',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'quantity',
      header: 'quantity',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    }
  ],
};

export const remainingCreditsTableConfig: ITableViewConfig = {
  translationPrefix: 'CREDIT_ADJUSTMENT_REPORT.creditAdjustmentReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'accountName',
      header: 'accountNameAdjusted',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditsDeposited',
      header: 'creditsDeposited',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditsTransfered',
      header: 'creditsTransfered',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditsRemaining',
      header: 'creditsRemaining',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditCancelled',
      header: 'creditCancelled',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const creditsExportTableConfig: ITableViewConfig = {
  translationPrefix: 'CREDIT_ADJUSTMENT_REPORT.creditAdjustmentReportSummaryStep',
  paginator: false,
  columns: [
    {
      field: 'accountName',
      header: 'accountNameForWhichCreditsTobeCancelled',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditCancelled',
      header: 'totalCreditsToCancelForExportStep',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
    }
  ],
};


export const CAR_SERIAL_NUMBER_FLD = 'serialNumber';
export const CAR_TYPE_OF_CREDITS_FLD = 'creditClass';
export const CAR_CREDIT_TYPE_CODE_FLD = 'creditTypeCode';
export const CAR_CREDIT_CLASS_HEADER = 'creditClassHeader';
export const CAR_CREDIT_GROUP_FLD = 'creditGroupName';
export const CAR_QUANTITY_OF_CREDITS_FLD = 'quantity';
export const CAR_QUANTITY_FLD = 'quantity';
export const CAR_QUANTITY_HEADER = 'quantityHeader';
export const CAR_COMPLIANCE_YEAR = 'complianceYear';
export const CAR_UNIT_STATUS_FLD = 'status';
export const CAR_UNIT_STATUS_HEADER = 'unitStatus';
export const CAR_NUM_CREDITS_TO_CANCEL = 'numCompCreditsTOCancel';
export const CAR_ASSOCIATED_VOLUME_FLD = 'associatedVolume';
export const CAR_ASSOCIATED_VOLUME_TOOLTIP = `${ CAR_TABLE_PREFIX }.associatedVolumeTooltip`;
export const CAR_CARBON_INTENSITY_FLD = 'approvedCliValue';
export const CAR_CARBON_INTENSITY_VALUE = 'finalCi';
export const CAR_CARBON_INTENSITY_TOOLTIP = `${ CAR_TABLE_PREFIX }.carbonIntensityTooltip`;

export const CAR_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID = 'creditsBlocksToBeCancelledTable';
export const CAR_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: CAR_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: CAR_SERIAL_NUMBER_FLD,
      sortField: CAR_SERIAL_NUMBER_FLD,
      header: CAR_SERIAL_NUMBER_FLD,
    },
    {
      field: CAR_CREDIT_TYPE_CODE_FLD,
      sortField: CAR_TYPE_OF_CREDITS_FLD,
      header: CAR_TYPE_OF_CREDITS_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: CAR_CREDIT_GROUP_FLD,
      sortField: CAR_CREDIT_GROUP_FLD,
      header: CAR_CREDIT_GROUP_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: CAR_QUANTITY_OF_CREDITS_FLD,
      sortField: CAR_QUANTITY_OF_CREDITS_FLD,
      header: CAR_QUANTITY_OF_CREDITS_FLD,
      numeric: true,
    },
    {
      field: CAR_ASSOCIATED_VOLUME_FLD,
      sortField: CAR_ASSOCIATED_VOLUME_FLD,
      header: CAR_ASSOCIATED_VOLUME_FLD,
      numeric: true,
      toolTip: {
        text: CAR_ASSOCIATED_VOLUME_TOOLTIP,
      },
    },
    {
      field: CAR_CARBON_INTENSITY_VALUE,
      sortField: CAR_CARBON_INTENSITY_VALUE,
      header: CAR_CARBON_INTENSITY_FLD,
      numeric: true,
      toolTip: {
        text: CAR_CARBON_INTENSITY_TOOLTIP,
      },
    },
  ],
};

export const CONFIRMATION_MESSAGES_ON_SUBMISSION = {
  confirmationMessageCreditsToBeCreated: 'confirmationMessageCreditsToBeCreated',
  confirmationMessageCreditsTransferUponCreation: 'confirmationMessageCreditsTransferUponCreation',
  confirmationMessageCreditsCancelAfterCreation: 'confirmationMessageCreditsCancelAfterCreation',
  confirmationMessageCreditsCancelAndDeposit: 'confirmationMessageCreditsCancelAndDeposit',
  confirmationMessageCreditsDepositCancelAndTransfered:'confirmationMessageCreditsDepositCancelAndTransfered',
  confirmationMessageNoCreditsCancelAndDeposit: 'confirmationMessageNoCreditsCancelAndDeposit',
  confirmationMessageSubmitRpeortToECCC:'confirmationMessageSubmitRpeortToECCC'
};

export const CAR_CI_PLACEHOLDER ='CREDIT_ADJUSTMENT_REPORT.tooltip.placeholder'
export const CAR_NONEDITABLE_YEAR_PROPERTY ='/registry-server/property/get?key=credit.adjustment.report.ci.fields.disable.for.years'

