import { IRawForm } from '@core/models/raw-form.types';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig, ROWS_PER_PAGE} from '@shared/models/table-view.model';

export const accountListTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.accountList',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: 'legalName',
      header: 'legalName',
      width: '110px',
      sortField: 'legalName',
    },
    {
      field: 'certificateNumber',
      header: 'certificateNumber',
      width: '150px',
      sortField: 'certificateNumber',
    },
    {
      field: 'jurisdiction',
      header: 'jurisdiction',
      width: '70px',
      sortField: 'jurisdiction',
    },
    {
      field: 'accountTypeName',
      header: 'accountType',
      width: '100px',
      sortField: 'accountTypeName',
    },
    {
      field: 'status',
      header: 'facilityStatus',
      sortField: 'status',
      width: '100px',
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
    {
      field: 'compensationObligationStatus',
      header: 'compensationObligationStatus',
      sortField: 'compensationObligationStatus',
      width: '110px',
    },
    {
      field: 'totalHoldings',
      header: 'totalHoldings',
      sortField: 'totalHoldings',
      width: '120px',
    },
  ],
};

export const regReportTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.compOblHistTable',
  columns: [

  ]
};

export const compensationObligationTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.compOblHistTable',
  columns: [
    {
      field: 'compliancePeriod',
      header: 'year',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'compliancePeriod',
    },
    {
      field: 'obligationId',
      header: 'obligationId',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'obligationId',
    },
    {
      field: 'originalCompensation',
      header: 'origObligation',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'originalCompensation',
      numeric: true,
    },
    {
      field: 'compensationProvidedToDate',
      header: 'obligationToDate',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'compensationProvidedToDate',
      numeric: true,
    },
    {
      field: 'outstandingObligation',
      header: 'outstandingObligation',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'outstandingObligation',
      numeric: true,
    },
    {
      field: 'creditsIssued',
      header: 'creditsIssued',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'creditsIssued',
      numeric: true,
    },
    {
      field: 'regularRateDeadlineStr',
      header: 'regularRateDeadline',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'regularRateDeadlineStr'
    },
    {
      field: 'increasedRateDeadlineStr',
      header: 'increasedRateDeadline',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'increasedRateDeadlineStr'
    },
    {
      field: 'status',
      header: 'compStatus',
      sortField: 'status',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
  ],
};

export const usersListTableConfig: ITableViewConfig = {
  translationPrefix: 'USERS_MODULE.usersList',
  columns: [
    {
      expandIcon: true,
      expandCollapse: true,
      field: 'id',
      header: null,
      expandSectionData: 'associationsList',
    },
    {
      field: 'fullName',
      header: 'name',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'fullName',
    },
    {
      field: 'email',
      header: 'email',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'email',
    },
    {
      field: 'phoneNumber',
      header: 'phone',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'phoneNumber',
    },
    {
      field: 'roleName',
      header: 'roleName',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'roleName',
      translate: true,
      translationPrefix: 'COMMON.roleList',
    },
    {
      field: 'status',
      header: 'status',
      sortField: 'status',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
  ],
};

export const documentsTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.uploadDocument',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: 'type',
      header: 'type',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.documentList',
      sortField: 'type'
    },
    {
      field: 'comments',
      header: 'comments',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'comments',
    },
    {
      field: 'status',
      header: 'status',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translationPrefix: 'COMMON.statusList',
      translate: true,
      sortField: 'status'
    },
    {
      field: 'lastModifiedDateStr',
      header: 'timestamp',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'timestamp',
    },
  ],
};

export const topTransactionsTableConfig: ITableViewConfig = {
  translationPrefix: 'COMMON.titles',
  paginator: false,
  columns: [
    {
      field: 'activityDate',
      header: 'transactionDate',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'date',
    },
    {
      field: 'transitionType',
      header: 'transactionName',
      translate: true,
      translationPrefix: 'ACTIVITY_LOGS_MODULE.transitionType',
      sortField: 'transitionType',
    },
    {
      field: 'quantity',
      header: 'quantity',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'quantity',
    },
    {
      field: 'actionPerformedBy',
      header: 'performedBy',
      sortField: 'actionPerformedBy',
    },
  ],
};

export enum AccountType {
  NFMPA = 'NFMPA',
  OBPS = 'OBPS',
}

export const accountRespondConfig: ITableViewConfig = {
    translationPrefix: 'ACCOUNTS_MODULE.accountList',
    paginator: true,
    rowsPerPage: ROWS_PER_PAGE,
    editService: '/account-service/account/needMoreInformation',
    columns: [
      {
        field: 'id',
        header: 'id',
        sortField: 'id',
        width: '200px',
        editable: false,
        fieldType: 'readonly-input',
        inputType: 'number',
        editRowField: false,
      }, {
        field: 'comment',
        header: 'comment',
        sortField: 'comment',
        width: '200px',
        editable: false,
        fieldType: 'textarea',
        inputType: 'string',
        editRowField: true,
        className: 'col-12',
      },
    ],
  };




  export const accountRejectConfig: ITableViewConfig = {
    translationPrefix: 'ACCOUNTS_MODULE.accountList',
    paginator: true,
    rowsPerPage: ROWS_PER_PAGE,
    // editService: '/account-service/cfrAccount/rejectSCAccount',
    editService: '/account-service/account/reject',
    columns: [
      {
        field: 'id',
        header: 'id',
        sortField: 'id',
        width: '200px',
        editable: false,
        fieldType: 'readonly-input',
        inputType: 'number',
        editRowField: false,
      }, {
        field: 'comment',
        header: 'rejectionComment',
        sortField: 'comment',
        width: '200px',
        editable: false,
        fieldType: 'textarea',
        inputType: 'string',
        editRowField: true,
        className: 'col-12',
      },
    ],
  };

export const linkedAccountsTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.linkedAccounts',
  paginator: false,
  columns: [
    {
      field: 'role_id',
      header: 'role',
      width: '150px',
    },
    {
      field: 'account_id',
      header: 'account',
      width: '70px',
    },
    {
      field: 'account_type',
      header: 'accountType',
      width: '70px',
    },
    {
      field: 'program_id',
      header: 'associatedProgram',
      width: '70px',
    },
    {
      field: 'userStatus',
      header: 'status',
      width: '100px',
      translationPrefix: 'COMMON.statusList',
      translate: true,
    },
  ],
};

export const upcomingCompensationDeadlinesTableConfig: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.upcomingDeadlines',
  paginator: false,
  columns: [
    {
      field: 'certificateNumber',
      header: 'facilityCertificateNumberText',
      width: '110px',
      sortField: 'certificateNumber',
    },
    {
      field: 'compliancePeriod',
      header: 'compliancePeriodText',
      sortField: 'compliancePeriod',
    },
    {
      field: 'obligationId',
      header: 'obligationIdText',
      width: '70px',
      sortField: 'obligationId',
    },
    {
      field: 'outstandingObligation',
      header: 'outstandingObligationText',
      sortField: 'outstandingObligation',
    },
    {
      field: 'regularRateDeadlineStr',
      header: 'regularRateDeadline',
      sortField: 'regularRateDeadlineStr',
    },
    {
      field: 'increasedRateDeadlineStr',
      header: 'increasedRateDeadline',
      sortField: 'increasedRateDeadlineStr',
    },
  ],
};

export const ACCOUNT_SUMMARY_PUBLIC_FIELD: IRawForm = {
  type: 'dict-select',
  className: 'col-6',
  key: 'displayAccountInfoPublicView',
  dataProvided: true,
  items: [
    {id: true, name: 'yes'},
    {id: false, name: 'no'},
  ],
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.displayAccountInfoPublicView',
  placeholder: 'selectItem',
  translatePrefix: 'COMMON.yesNoList',
  defaultValue: true,
  clearable: false,
  appendTo: 'self',
};

export const VB_NEED_MORE_INFO_CONFIG: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.accountList',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/account-service/cfrAccount/changeRegistrationReportInNeedMoreInfo',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    },
    {
      field: 'accountId',
      header: 'accountId',
      sortField: 'accountId',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    }, {
      field: 'adminRemark',
      header: 'comments',
      sortField: 'adminRemark',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
    },
  ],
};
