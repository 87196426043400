import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { EventEmitter, TemplateRef } from '@angular/core';
import { Pgination } from '@core/models/pagination.model';
import { environment } from '@env/environment';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableNewRowComponent } from '../table-new-row/table-new-row.component';
export class SimpleTableWrapperComponent extends AbstractPaginationService {
    constructor(http, store, lookupService, modalService, activatedRoute, translateService) {
        super();
        this.http = http;
        this.store = store;
        this.lookupService = lookupService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.initialFilterState = {};
        this.selections = [];
        this.exportTableData = new EventEmitter();
        this.actionSelected = new EventEmitter();
        this.selectedCheckboxes = new EventEmitter();
        this.customButtonAction = new EventEmitter();
        this._destroy$ = new Subject();
        this._stopPrevious$ = new Subject();
        // Filter variables
        this.filterFields = {};
        this.firstItemIndex = 0;
        this.pagination = new Pgination(0, 20);
        this.filterState = {};
    }
    ngOnInit() {
        this.activatedRoute.data.subscribe(data => {
            if (data['offsetType']) {
                this.initialFilterState = { offsetType: data['offsetType'] };
            }
        });
        if (this.refreshData) {
            this.refreshData
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => this.loadData(this.pagination, this.filterState));
        }
        this.activatedRoute.params.subscribe(params => {
            if (params.applicationId) {
                this.initialFilterState = { applicationId: params.applicationId };
            }
            if (params.accountId) {
                this.initialFilterState = { id: params.accountId };
            }
            if (params.projectId) {
                this.initialFilterState = { id: params.projectId };
            }
            if (params.submissionsId) {
                this.initialFilterState = { id: params.submissionsId };
            }
            if (params.projHistId) {
                this.initialFilterState = { projectId: params.projHistId };
            }
            this.filterState = Object.assign({}, this.initialFilterState);
            this.filterFields = this.tableDetails.tableFilter;
            this.getTableHeader();
            this.translateService.onLangChange
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => this.loadData(this.pagination, this.filterState));
        });
    }
    setupCustomColumnTemplates() {
        if (this.tableDetails.customColumnTemplates) {
            const fields = Object.keys(this.tableDetails.customColumnTemplates);
            fields.forEach(field => this.setTemplate(this.tableDetails.customColumnTemplates[field], null, field));
        }
    }
    get summaryTemplateRef() {
        return this.tableDetails.summaryTemplateRef;
    }
    getTableHeader() {
        this.lookupService.getTableConfigurations(this.tableDetails.tableHeaderType).subscribe(data => {
            this.tableConfig = data;
            if (this.tableDetails.filtersFromConfig) {
                this.filterFields = Object.assign({}, this.filterFields, { filterListsModel: data.filters });
            }
            if (!this.isAdmin && this.tableDetails.adminFilters && this.tableDetails.adminFilters.length) {
                this.filterFields = Object.assign({}, this.filterFields, { filterListsModel: this.filterFields.filterListsModel.filter(filter => !this.tableDetails.adminFilters.includes(filter.itemsType)) });
            }
            if (this.tableDetails.hasViewTemple) {
                this.setTemplate(this.viewColTemplate, 'view');
            }
            if (this.tableDetails.hasActionTemple) {
                this.setTemplate(this.actionTemplate, 'actions');
            }
            if (this.tableDetails.hasEditActionTemple && this.store.user.hasPermission(this.tableDetails.hasEditActionTemple.permission)) {
                this.setTemplate(this.editActionTemplate, 'editAction'); // Todo add editAction to translation
            }
            if (this.tableDetails.adminProtocol) {
                this.adminProtocolManagementTableDetailsConfig();
            }
            if (this.tableDetails.projectTypeManagement) {
                this.adminProjectTypeManagementTableDetailsConfig();
            }
            this.setupCustomColumnTemplates();
            if (this.tableDetails.checkbox) {
                this.tableConfig.columns.unshift({
                    checkboxSelect: true,
                    width: '3em',
                });
            }
            if (!data.paginator) {
                this.loadData(null);
            }
        });
    }
    setTemplate(templateType, headerTitle, field = null) {
        const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
        let _columns = [
            ...this.tableConfig.columns,
        ];
        if (field) {
            const columnIdx = this.tableConfig.columns.findIndex(col => col.field === field);
            if (columnIdx > -1) {
                _columns[columnIdx].templateRef = templateType;
            }
        }
        else {
            _columns = [
                ..._columns,
                {
                    header: headerTitle,
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: templateType,
                },
            ];
        }
        this.tableConfig = Object.assign({}, config, { columns: _columns });
    }
    onFilterChanged(filterData, loadData = true) {
        this.firstItemIndex = 0;
        this.pagination.page = this.firstItemIndex;
        this.filterState = Object.assign({}, this.filterState, filterData);
        loadData && this.loadData(this.pagination, this.filterState);
    }
    onFilterCleared() {
        this.filterState = Object.assign({}, this.initialFilterState);
        this._filterId = null;
    }
    onPaginationChanged(pagination) {
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        this.loadData(this.pagination, this.filterState);
    }
    loadData(pagination, filterState = {}) {
        this._stopPrevious$.next();
        this.getTableListData(pagination, filterState)
            .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$)).subscribe(data => {
            this.tableData = data;
            if (this.tableDetails.needsTableData) {
                this.exportTableData.emit(data);
            }
        });
    }
    get actionService() {
        return this.tableDetails.actionService;
    }
    onActionSelected(actionData) {
        this.actionService.triggerAction(actionData);
    }
    openEditPopUp(rowData) {
        // tslint:disable-next-line: max-line-length
        this.modalService.open(TableNewRowComponent, { form: this.tableConfig, defaultValues: rowData, actionType: 0 /* EDIT */ }).afterClosed().subscribe((result) => {
            this.newEntryAdded(result);
        });
    }
    newEntryAdded(result) {
        if (result) {
            this.loadData(this.pagination, this.filterState);
        }
    }
    getTableListData(pagination, filterState = {}) {
        const params = this.addPagination(new HttpParams(), pagination);
        return this.http.post(`${environment.apiUrl}${this.tableDetailsUri}`, filterState, { params });
    }
    get tableDetailsUri() {
        return this.tableDetails.tableListApiUri || this.tableConfig.uri;
    }
    adminProtocolManagementTableDetailsConfig() {
        this.tableConfig.editService = '/account-service/offsetProtocol/saveGHG';
        this.tableConfig.columns.map(obj => {
            obj.sortField = obj.field;
            if (obj.field === 'protocolPublishedDate') {
                return obj.fieldType = 'app-formly-datepicker';
            }
            else {
                return obj.fieldType = 'input';
            }
        });
        this.tableConfig.columns.map(obj => {
            obj.sortField = obj.field;
            if (obj.field === 'status') {
                obj['source'] = 'getOffsetProtocolStatuses';
                obj.toolTip = { text: 'ADMIN_MODULE.offsetProtocolManagement.statusMessage' };
                return obj.fieldType = 'dict-select';
            }
        });
    }
    onCheckboxActionSelected(selectedRows) {
        this.actionSelected.emit(selectedRows);
    }
    onCustomButtonAction(event) {
        this.customButtonAction.emit(event);
    }
    onRowSelect(check) {
        if (this.actionService) {
            const actionModel = this.actionService.addCheckBoxAction(check, this.tableDetails);
            this.filterFields = Object.assign({}, this.filterFields, { actionModel });
            this.selectedCheckboxes.emit(check);
        }
    }
    get isAdmin() {
        return this.store.user.isAdmin;
    }
    adminProjectTypeManagementTableDetailsConfig() {
        this.tableConfig.editService = '/offset-service/projectActivityType/save';
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
