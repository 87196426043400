import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { NgSelectComponent } from '@shared/components/ng-select/ng-select.component';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { ControlActionsService } from '@shared/services/control-actions.service';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document-actions',
  templateUrl: './document-actions.component.html',
  styleUrls: ['./document-actions.component.scss'],
})
export class DocumentActionsComponent extends BaseActionsComponent implements OnInit {

  @ViewChild('actionDropdown', { static: true }) public actionDropdown: NgSelectComponent;

  @Input() document: IApp.IDocument;
  @Input() documentUploadConfig : IApp.IDocumentUploadConfig;
  @Input() appendTo = 'app-documents';
  @Output() successfullAction = new EventEmitter<any>();
  placeholderLabel: string;
  actionList: any[] = [];

  constructor(private controlActionsService: ControlActionsService,
              private modalService: ModalService,
              private uploadDocumentService: UploadDocumentService,
              private documentUploadInProgress: DocumentUploadInProgressService,
  ) {
    super();
  }

  ngOnInit() {

    this.placeholderLabel = this.translateService.instant(`COMMON.actionsLabel.actions`);

    if (this.document && this.document.actionList) {
     this.document.actionList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.action}`));
     this.actionList = this.document.actionList;
     this.actionList.sort((a,b) => a.label.localeCompare(b.label));
    }

    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
      this.populateActions();
    });
  }

  private populateActions() {
    this.actionList = [];
    this.document.actionList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.action}`));
    this.document.actionList.forEach(action => {
      this.actionList.push(action);
    });
    this.actionList.sort((a,b) => a.label.localeCompare(b.label));
  }

  triggerActionController(value: IApp.IAction): void {

    this.resetSelection();
    if (value.action === 'DOWNLOAD') {
        this.uploadDocumentService.download(this.document.id);
    } else {
      let message  = 'confirmationMessageCommonDocument';
      if (this.document.documentType && this.document.documentType.code && this.documentUploadConfig.entityDetail){
        message  = 'confirmationMessageDocument';
      }
      this.modalService.open(ServiceMessageComponent,
        {
          messages: null,
          message,
          metaDataList: [
            this.translateService.instant(`COMMON.actionsLabel.${value.action}`),
            this.translateService.instant(`COMMON.documentList.${this.document.documentType.code}`),
            this.documentUploadConfig.entityDetail],
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed().subscribe((result?: any) => {
        if (result) {
          this.executeAction(value);
        }
        this.resetSelection();
      });
    }
  }

  public executeAction(value: IApp.IAction): void {
    let action = value.action === 'DELETE' ? 'Delete' : 'Action';
    if(value.action === 'DELETE_NOT_SUBMITTED'){
      action = 'DeletePending';
    }
    this.document.action = value.action as IApp.NSDocument.ActionEnum;

    this.controlActionsService.documentDoAction(this.document, action).subscribe(data => {
      if (data['entity']) {
        this.document = data['entity'];
        this.populateActions();
      }
      this.successfullAction.emit({
        action: value.action,
        document: this.document,
      });
    });
  }

}
