import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import {
  COMPLIMENTARY_COMPLIANCE_REPORT_ID_KEY,
  COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX,
  COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX,
  COMPLIMENTARY_COMPLIANCE_REPORT_CURRENT_STEP_KEY,
  COMPLIMENTARY_COMPLIANCE_REPORT_DATA_KEY,
  COMPLIMENTARY_COMPLIANCE_REPORT_STEP_COMPONENTS,
  COMP_TOTAL_REDUCTION_REQUIREMENT_FLD,
} from '@module/complimentary-compliance-report/complimentary-compliance-report.const';
import { IComplimentaryComplianceReport, ICreateComplimentaryComplianceReportPayload } from '@module/complimentary-compliance-report/complimentary-compliance-report.types';
import { environment } from '@env/environment';
import { sortBy } from 'lodash';
import { HelperService } from '@shared/services/helper.service';
import { StoreService } from '@core/store/store.service';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '@shared/services/modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { Subject } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import { cloneDeep } from 'lodash';

export const GET_REPORT_BY_ID = '/cfr-service/complimentryComplianceReport/getReportById/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/complimentryComplianceReport/getReportToResubmit/';
export const COMP_REP_RE_SUBMIT_REPORT = '/cfr-service/complimentryComplianceReport/resubmitReport';
export const COMP_REP_UPDATE_STATUS = '';
export const COMP_REP_DISCARDS = '';
export const COMP_REP_DOWNLOAD_REPORT = '/cfr-service/complimentryComplianceReport/download/report/';
export const COMP_REP_SUBMIT_REPORT = '/cfr-service/complimentryComplianceReport/submitReport';
export const COMP_REP_PROPOSE_REPORT = '/cfr-service/complimentryComplianceReport/proposeReport';
export const CREATE_COMP_COMPLIANCE_REPORT = '/cfr-service/complimentryComplianceReport/getNewComplimentryReport';
export const COMP_REP_CCM_CREDIT_BLOCKS = '/obps-service/unit/findAll';
export const COMP_REP_DOC_UPLOAD_ID = '/cfr-service/complianceReport/getDocumentIdForReference';
export const COMP_REP_SAVE_REPORT = '/cfr-service/complimentryComplianceReport/saveComplimentryReport';
export const COMP_REP_SUMMARY = '/cfr-service/complimentryComplianceReport/getReportSummary/';
export const COMP_REP_MAIN_REPORT_SUMMARY = '/cfr-service/complianceReport/getReportSummary/';
export const COMP_REP_SAVE_AND_PROCEED = '/cfr-service/complimentryComplianceReport/saveAndProceedComplimentryReport';
export const COMP_REP_GET_ACTIVE_CREDITS = '/obps-service/findActiveCredits';
export const COMP_CHECK_CCM_SETUP = '/cfr-service/lookup/checkCCMSetup/';
export const COMP_GET_TOTAL_CCM_CREDITS = '/cfr-service/complimentryComplianceReport/getAllCcmCredits/';
@Injectable({
  providedIn: 'root'
})
export class ComplimentaryComplianceReportService extends BaseStepperService {

  public translationPrefix = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }${ COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX }`;
  public registrationReportsDetail: any;
  public report: IComplimentaryComplianceReport;

  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();
  stepRoutineMap: any = {};
  public currentStepInvalid = false;
  public updateSummary$ = new Subject();
  public maxStep;
  public volumeAssociatedWithCreditsGasoline = 0;
  public volumeAssociatedWithCreditsDiesel = 0;
  public ccmCreditsAcquired = true;
  public totalCCMCredits = 0;
  public totalGenericCredits = 0;
  public totalLiquidCredits = 0;
  public creditsTypeCount = [
    {
      code: 'GQM',
      dataId: this.totalGenericCredits
    },
    {
      code: 'LIQUID',
      dataId: this.totalLiquidCredits
    }
  ];
  public isCCMActive = false;

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private router: Router,
    private store: StoreService,
    private modalService: ModalService,
  ) { 
    super();
  }

  storeCurrentData(): void {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(COMPLIMENTARY_COMPLIANCE_REPORT_ID_KEY, data);
  }

  restoreLastStep(): any {
    const currentStep = sessionStorage.getItem(COMPLIMENTARY_COMPLIANCE_REPORT_CURRENT_STEP_KEY);
    if (currentStep) {
      sessionStorage.removeItem(COMPLIMENTARY_COMPLIANCE_REPORT_CURRENT_STEP_KEY);
      return JSON.parse(currentStep);
    } else {
      return null;
    }
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(COMPLIMENTARY_COMPLIANCE_REPORT_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(COMPLIMENTARY_COMPLIANCE_REPORT_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  getReportById(reportId) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${ reportId }`);
  }

  getReportByIdToReSubmit(reportId) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_TO_RESUBMIT }${ reportId }`);
  }

  public setReportSteps() {
    const steps = this.currentData.associatedSteps.map((step, index) => {

      const currentStepIdentifier = step.code.split('-')[1].trim();

      const subSteps = [];
      if (step.internalSteps && step.internalSteps.length) {
        sortBy(step.internalSteps, ['sequence']).map((subStep, j) => {
          subSteps.push(this.getSubStepContent(subStep, index, j));
        });
      }
      const _step = this.getStepContent(step, index);
      if (subSteps.length) {
        _step.subSteps = subSteps;
        _step.skipMainStep = true;
        _step.enabledSubSteps = true;
      }
      return _step;
    });

    this.steps = [...steps];
    return [...steps];
  }

  private getSubStepContent(step, index, subStepIndex = null) {
    const component =  COMPLIMENTARY_COMPLIANCE_REPORT_STEP_COMPONENTS[step.code];
    const _step = {
      ...step,
      name: `.${step.code}_title`,
      stepType: `${ step.associatedActivityStepCode }_${ step.fuelCodeList }`,
      completed: false,
      id: index + 1,
      component,
    };
    _step.id = index + 1;
    if (subStepIndex !== null) {
      _step.subId = subStepIndex + 1;
    }
    return _step;
  }

  private getStepContent(step, index, subStepIndex = null) {
    const component = COMPLIMENTARY_COMPLIANCE_REPORT_STEP_COMPONENTS[step.code];
    const _step = {
        ...step,
        name: `.${ step.code }_title`,
        completed: false,
        id: index + 1,
        component,
      };
    _step.id = index + 1;
    if (subStepIndex !== null) {
      _step.subId = subStepIndex + 1;
    }
    return _step;
  }

  triggerAction(metadata: any, redirect = false) {
    const action = metadata.action;
    const payload = metadata.data;

    if (['VIEW', 'EDIT', 'SUBMIT_TO_ECCC_VERIFICATION_REPORT', 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT'].includes(action.workflowAction)) {
      this.router.navigate(this.helperService.getTranslatedPath(`/complimentary-compliance-report/report/${ payload.id }`));
    } else if (action.workflowAction === 'RESUBMIT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${ payload.id }/RESUBMIT`));
    } else if (action.workflowAction === 'DOWNLOAD_COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.downloadDocument(payload.id);
    } else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
    } else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
    } else {
      this.executeAction(payload, action, redirect);
    }
  }

  executeAction(payload , action, redirect) {
    const request = {id: payload.id, action: action.workflowAction};
    const actionLabel = action.workflowAction;

    if (action.workflowAction === 'ASSIGN_VB') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/select-verification-body/ASSIGN_VB/${payload.verificationBodyId}`));
      return;
    }

    const sub$ = this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'confirmationMessageComplimentaryComplianceReport',
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          payload.legalName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      sub$.unsubscribe();
      if (result) {
        this.modalService.closeAllMessageDialogs();
        if (action.workflowAction === 'SUBMIT') {
          this.getReportById(payload.id)
            .pipe(switchMap((report: IComplimentaryComplianceReport) => this.submitReport(report)))
            .subscribe(() => this._refreshData$.next(true));
        } else {
          this.updateComplimentaryComplianceReport(request).subscribe(response => {
          if (!redirect) {
            this._refreshData$.next(true);
          } else {
            this.resetStepper();
            this.store.back();
          }
        });
      }
    }});
  }

  public updateComplimentaryComplianceReport(payload: any): Observable<IComplimentaryComplianceReport> {
    const uri = payload.action === 'DISCARD' ? COMP_REP_DISCARDS : COMP_REP_UPDATE_STATUS;
    return this.http.post<any>(`${environment.apiUrl}${uri}`, payload);
  }

  public downloadDocument(documentId) {
    const uri = environment.serviceUrl + COMP_REP_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  submitReport(payload): Observable<any> {
    payload.ccmActive = this.isCCMActive;
    payload.complimentryReportDetailsToUpdated = this.updateReportDetails(payload);
    if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
      return this.http.post(`${ environment.apiUrl }${ COMP_REP_PROPOSE_REPORT }`, {...payload, action: 'PROPOSE'});
    } else {
      return this.http.post(`${ environment.apiUrl }${ COMP_REP_SUBMIT_REPORT }`, {...payload, action: 'SUBMIT'});
    }
  }

  createComplianceReport(payload: ICreateComplimentaryComplianceReportPayload): any {
    return this.http.post<any>(`${ environment.apiUrl }${ CREATE_COMP_COMPLIANCE_REPORT }`, payload);
  }

  public openConfirmationModal(msg, yesNoButton = false) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          yesNoButton,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

  public getCCMCreditBlocks(pagination: IApp.IPagination, complianceYear?, accountId = this.currentData.accountId): Observable<any> {

    const unitStatusList = ['CCM_ON_HOLD'];
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const filterState = {
      freeText: null, unitStatusList,
      ccr: true,
      beforeComplianceYear: true,
      compliancePeriodList:[this.currentData.complianceYear],
      issuanceYear: this.currentData.complianceYear,
      facilityNameList: [accountId]
    };
    if (complianceYear) {
      filterState.compliancePeriodList = [complianceYear];
      filterState.issuanceYear = complianceYear;
    } else {
      filterState.compliancePeriodList = [this.currentData.complianceYear];
      filterState.issuanceYear = this.currentData.complianceYear;
    }
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_CCM_CREDIT_BLOCKS }`, filterState, { params });
  }

  public showErrorMessage(message: string, metaDataList = []) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            { message },
          ],
          message,
          metaDataList,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public getDocUploadID() {
    return this.http.get<any>(`${environment.apiUrl}${COMP_REP_DOC_UPLOAD_ID}`);
  }

  saveAndProceed(payload): Observable<any>  {
    payload.ccmActive = this.isCCMActive;
    payload.nonComplianceDeferredPortionAllowed = this.ccmCreditsAcquired;
    payload.complimentryReportDetailsToUpdated = this.updateReportDetails(payload);
    return this.http.post(`${ environment.apiUrl }${ COMP_REP_SAVE_AND_PROCEED }`, {...payload, action: 'SAVE'})
  }

  public getReportSummary(id, mainReport = false) {
    let url = COMP_REP_SUMMARY + id;
    if (mainReport) {
      url = COMP_REP_MAIN_REPORT_SUMMARY + id + '/STEP-0';
    }
    return this.http.get<any>(`${environment.apiUrl}${url}`);
  }

  saveReport(payload): Observable<any>  {
    payload.ccmActive = this.isCCMActive;
    payload.nonComplianceDeferredPortionAllowed = this.ccmCreditsAcquired;
    payload.complimentryReportDetailsToUpdated = this.updateReportDetails(payload);
    return this.http.post(`${ environment.apiUrl }${ COMP_REP_SAVE_REPORT }`, {...payload, action: 'SAVE'})
      .pipe(tap(response => this.report = response.entity));
  }

  getActiveCredits(accountId): Observable<any> {
    const payload = {freeText: null, unitStatusList: ['ACTIVE'], ccr: true, beforeComplianceYear: true, compliancePeriodList:[this.currentData.complianceYear], issuanceYear: this.currentData.complianceYear};
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_GET_ACTIVE_CREDITS }/${ accountId }`, payload);
  }

  public getCCMSetup(accountId, complianceYear) {
    return this.http.get<any>(`${environment.apiUrl}${COMP_CHECK_CCM_SETUP}${accountId}/${complianceYear}`);
  }

  reSubmitReport(payload): Observable<any> {
    payload.ccmActive = this.isCCMActive;
    payload.complimentryReportDetailsToUpdated = this.updateReportDetails(payload);
    if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
      return this.http.post(`${ environment.apiUrl }${ COMP_REP_PROPOSE_REPORT }`, {...payload, action: 'PROPOSE'});
    } else {
      return this.http.post(`${ environment.apiUrl }${ COMP_REP_RE_SUBMIT_REPORT }`, {...payload, action: 'RESUBMIT'});
    }
  }

  getCCMSetupCheck () {
    this.getCCMSetup(this.currentData.accountId, this.currentData.complianceYear)
    .subscribe((data) => {
      if (data && data.ccmActive !== null) {
        this.isCCMActive = data.ccmActive
      }
      if (data && data.ccmActive && (data.maxAcquireAllowedCredit === null || (data.maxAcquireAllowedCredit !== null && 
        ((data.totalCcmOnHoldCredit !== null && data.maxAcquireAllowedCredit > data.totalCcmOnHoldCredit) || data.totalCcmOnHoldCredit === null)))) {
         this.ccmCreditsAcquired = (this.currentData.parentId && this.currentData.nonComplianceDeferredPortionAllowed)? true: false;
      } else {
        this.ccmCreditsAcquired = true;
      }
    });
  }

  public calculateMaxPortion() {
    const currentYearOutstandingData = this.currentData.complimentryReportDetails.filter((data) => data.step.code === 'STEP-1');
    let maxPortion = 0;
    if (currentYearOutstandingData && currentYearOutstandingData.length) {
      let deferralsSum = 0;
      const lastFiveYearsDeferrals = this.currentData.deferrals.filter((deferral) => 
      deferral.complianceYear !== this.currentData.complianceYear && 
      (this.currentData.complianceYear - deferral.complianceYear) <= 5);
      if (lastFiveYearsDeferrals && lastFiveYearsDeferrals.length) {
          lastFiveYearsDeferrals.forEach((deferral) => {
            deferralsSum += ((deferral.calcOutstandingDieselReductionRequirement + deferral.calcOutstandingGasolineReductionRequirement));
        });
      }
      const reductionReq = this.getTotalTenPercentLimit();
      maxPortion = Math.round(reductionReq - deferralsSum);
      if (maxPortion < 0) {
        maxPortion = 0;
      }
    }
    return maxPortion;
  }

  getTotalCCMCredits (accountId, complianceYear) {
    return this.http.get<any>(`${environment.apiUrl}${COMP_GET_TOTAL_CCM_CREDITS}${accountId}/${complianceYear}`);
  }

  private getCCMCredits(accountId, complianceYear) {
    this.getTotalCCMCredits(accountId, complianceYear).subscribe(result => {
      if (result && result.length) {
        this.totalCCMCredits = result.reduce((acc,unit) => acc+unit.id,0);
        const genericUnits = result.filter((unit) =>  unit.creditGroup && unit.creditGroup.code === 'GQM');
        this.totalGenericCredits = genericUnits.reduce((acc,unit) => acc+unit.id,0);
        const liquidUnits = result.filter((unit) => unit.creditType && unit.creditType.code === 'LIQCC');
        this.totalLiquidCredits = liquidUnits.reduce((acc,unit) => acc+unit.id,0);
        this.creditsTypeCount[0].dataId= this.totalGenericCredits;
        this.creditsTypeCount[1].dataId = this.totalLiquidCredits;
      }
    });
  }

  getTotalTenPercentLimit() {
    const reductionReq = (0.1 * (this.currentData[COMP_TOTAL_REDUCTION_REQUIREMENT_FLD] || 0));
    return reductionReq;
  }

  updateReportDetails(payload) {
    const data = cloneDeep(payload);
    let reportDetails = [];
    if (this.currentData.parentId && this.currentData.resubmitFirstTime) {
      reportDetails = this.currentData.complimentryReportDetails? cloneDeep(this.currentData.complimentryReportDetails.filter((data) => data.step.code === 'STEP-3_3')): [];
    }
    if (data.complimentryReportDetailsToUpdated && data.complimentryReportDetailsToUpdated.length) {
      data.complimentryReportDetailsToUpdated.forEach((unit) => {
        if (unit.step.code === 'STEP-3_3') {
          const detailIndex = reportDetails.findIndex((detail) => (detail.deferralId && unit.deferralId && detail.deferralId === unit.deferralId) && detail.reductionType === unit.reductionType && ((detail.id && detail.id === unit.id) || (detail.parentId && detail.parentId === unit.parentId)));
          if (detailIndex === -1) {
            reportDetails.push(unit);
          }
        } else {
          reportDetails.push(unit);
        }
      });
    }
    return reportDetails;
  }

  resetCredits () {
    this.ccmCreditsAcquired = true;
    this.totalCCMCredits = 0;
    this.totalGenericCredits = 0;
    this.totalLiquidCredits = 0;
    this.creditsTypeCount = [
      {
        code: 'GQM',
        dataId: this.totalGenericCredits
      },
      {
        code: 'LIQUID',
        dataId: this.totalLiquidCredits
      }
    ];
    this.isCCMActive = false;
    this.volumeAssociatedWithCreditsGasoline = 0;
    this.volumeAssociatedWithCreditsDiesel = 0;
  }
}
