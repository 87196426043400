import { IApp } from './app.interfaces';

export class FilterModel {
    crossProgram?: boolean;
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    id?:number;
    accountId?: number;
    registrationAccountId?: number;
    accountIdNotIn?: number;
    type?: string;
    facilityNameList?: number[];
    masterAccountId?: number;
    reportingPeriodList?: string[];
    unitIdNotIn?: number[]=[];
    submissionTypes?: string[];
    compliancePeriodList?: number[];
    statusList?: string[];
    coiFacilityNameList?: number[];
    accountSubTypeList?: string[];
    facilityStatusList?: string[];
}

export class SubOrganizationFilterModel implements  IApp.ISubOrganizationFilter {
    accountId?:number;
    discriminatorType?:string;
}

export class WorkflowConfigurationFilterModel implements  IApp.IActivityLogSearchFilter {
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    id?: number;
    approvalTypeList?: string[] = [];
}

export class ActivityLogFilterModel implements IApp.IActivityLogSearchFilter{

    accountTypeList?: number[]=[];
    facilityNameList?: number[]=[];
    facilityNumberList?: number[]=[];
    facilityStatusList?:IApp.NSAccountSearchFilter.FacilityStatusListEnum[]=[];
    transitionTypeList?: any[]=[];
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    accountSubTypeList?:[];
    actionPerformedByList?:[];
    organizationRoleList?:[];
    creditClassList?: number[]=[];

}

export class UnitFilterModel implements IApp.IUnitSearchFilter{

    compliancePeriodList?: number[]=[];
    facilityNameList?: number[]=[];
    unitStatusList?: any[]=[];
    freeText?: string;
    id?: number;
    projectIdList?: number[]=[];
    unitIdNotIn?: number[]=[];
    creditGroupList?: number[]=[];
    fuelTypeList?: number[]=[];
    creditClassList?: number[]=[];
    fuelCodeList?: any[]=[];
    selectedUnitWithQty?: Array<{unitId: number, qty: number}> = [];
    constructor(){

    }
}

export class UserFilterModel implements IApp.IUserSearchFilter{


    freeText?: string;
    facilityNameList?: number[]=[];
    constructor(){

    }
}

export class ObligationMgmtFilterModel implements IApp.IUnitSearchFilter{

    compliancePeriodList?: number[]=[];
    obligationTypeList?: number[]=[];
    compensationStatusList?: any[]=[];
    freeText?: string;
    facilityNameList?: number[] = [];
    dueDate?: string;
    dateRange?: number;
    id?:number;
    obligationId?:number;
    constructor(){

    }
}

export class RecognizedUnitsFilterModel implements IApp.IUnitSearchFilter{

    compliancePeriodList?: number[]=[];
    ruUnitStatusList?: any[]=[];
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    facilityNameList?: number[];

  constructor(){

    }
}

export class RefundRequestFilterModel implements IApp.IRefundRequestSearchFilter {

    compliancePeriodList?: number[]=[];
    paymentRefundModes?: string[]=[];
    paymentRefundStatus?: string[]=[];
    freeText?: string;
    id?: number;
    facilityNameList?: number[];

}

export class BulletinBoardBidFilterModel {

    compliancePeriodList?: number[]=[];
    freeText?: string;
    unitTypeList?: any[]=[];
    jurisdictionNameList?: any[]=[];

}

export class BulletinBoardOfferFilterModel {

    compliancePeriodList?: number[]=[];
    freeText?: string;
    unitTypeList?: any[]=[];
    jurisdictionList?: any[]=[];

}

export const exportPdfAction: any = {
    label: 'COMMON.commonSection.exportToPdf',
    iconClass: 'fa-file-pdf',
    type: 'application/pdf',
    extension: 'pdf',
    responseType: 'PDF',
};

export const exportxlsAction: any = {
    label: 'COMMON.commonSection.exportToExcel',
    iconClass: 'fa-file-excel',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
    responseType: 'EXCEL',
};

export class UploadOBPSResultsFilterModel {
    status?:any;
    id?:any;
}

export class TaskFilterModel {
    freeText?: string;
    filterType:any[];
    taskTypeList?: string[]=[];
    facilityNameList?: number[]=[];
    assignedToList?:any[]=[];
    taskFamily?: string;
    constructor(){
        this.freeText = '';
    }
}

export class ManageFacilitiesFlterModel {
    freeText?: string;
    entityTypes:string;
    provinces?: string;
    facilityNameList?: number[] = [];
    constructor(){
        this.freeText = '';
        this.entityTypes = '';
    }
}

export class SubmissionFilterModel {

    statusList?: string[]=[];
    submissionTypeList?: string[]=[];
    assignedVBList?: number[]=[];
    facilityNameList?: number[]=[];
    freeText?: string;
    compliancePeriodList?: number[] = [];
    id?:number;

    constructor(){

    }
}

export class VerificationFilterModel {

    statusList?: string[]=[];
    submissionTypeList?: string[]=[];
    vbRoleList?: number[]=[];
    facilityNameList?: number[]=[];
    freeText?: string;
    compliancePeriodList?: number[] = [];
    id?:number;
    applicationId?: number;

    constructor(){

    }
}

export class VBVRFilterModel {
    freeText?:string;
    provice?:any;
    areaOfExpertise?:any;

    constructor() {

    }
}

export class CrossReportFilterModel {
    crossProgram?: boolean;
    freeText?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    id?:number;
    accountId?: number;
    registrationAccountId?: number;
    accountIdNotIn?: number;
    type?: string;
    facilityNameList?: number[];
    crossFacilityNameList?: number[];
    masterAccountId?: number;
    reportingPeriodList?: string[];
    accountIdList?: number[];
    crossAccountIdList?: number[];

    constructor() {

    }
}

export class COINoticeFilterModel {

    statusList?: string[]=[];
    submissionTypeList?: string[]=[];
    facilityNameList?: number[]=[];
    freeText?: string;
    compliancePeriodList?: number[] = [];
    id?:number;
    verificationId?: number;

    constructor(){

    }
}

export class VerificationReportFilterModel {

    statusList?: string[]=[];
    submissionTypeList?: string[]=[];
    facilityNameList?: number[]=[];
    freeText?: string;
    compliancePeriodList?: number[] = [];
    id?:number;
    verificationId?: number;
    submissionTypes?: string[]=[];

    constructor(){

    }
}

export class NOEFilterModel implements IApp.INOESearchFilter{
  provinces?: string;
  creditGroupList?: number[]=[];
  creditCreationReportIds?: number[]=[];
  creditClassList?: number[]=[];
  onlyIssued?: boolean = true;
  constructor(){ }
}
