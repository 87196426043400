import { NavigationEnd } from '@angular/router';
import { last } from 'lodash';
import { LOCALES } from '../../app.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@gilsdav/ngx-translate-router";
export const PROJECT_DETAILS_URL_REGEX = /^\/[a-z]{2}\/project\/offset\/\d*\/\d*\/.*/;
export const PROJECT_DETAILS_FR_URL_REGEX = /^\/[a-z]{2}\/projet\/offset\/\d*\/\d*\/.*/;
export class NavigationService {
    constructor(router, locRouterSvc) {
        this.router = router;
        this.locRouterSvc = locRouterSvc;
        this.navigationHistory = [];
        this.skipAddToHistory = false;
        this.regArray = [PROJECT_DETAILS_URL_REGEX, PROJECT_DETAILS_FR_URL_REGEX];
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.handleHistory(event.urlAfterRedirects);
            }
        });
    }
    handleHistory(url) {
        if (this.skipURLTracking(url)) {
            return;
        }
        if (!this.skipAddToHistory) {
            if (!this.navigationHistory.length) {
                this.navigationHistory.push(url);
                this.storedUrl = null;
            }
            else if (this.isSameLang(url) && last(this.navigationHistory) !== url) {
                this.navigationHistory.push(url);
                this.storedUrl = null;
            }
            else if (!this.isSameLang(url)) {
                if (this.storedUrl && this.storedUrl !== url) {
                    this.navigationHistory.push(url);
                    this.storedUrl = null;
                }
                else {
                    this.storedUrl = url;
                }
            }
        }
        else {
            this.navigationHistory[this.navigationHistory.length - 1] = url;
        }
        this.skipAddToHistory = false;
    }
    back(customFallbackPath = null) {
        this.navigationHistory.pop();
        this.skipAddToHistory = true;
        if (this.navigationHistory.length > 0) {
            this.router.navigateByUrl(this.replaceLangPrefix(last(this.navigationHistory)));
        }
        else if (customFallbackPath) {
            this.router.navigate(customFallbackPath);
        }
        else {
            this.router.navigateByUrl('/');
        }
    }
    addUrl(url) {
        this.navigationHistory.push(url);
    }
    removeLast() {
        this.navigationHistory.pop();
    }
    removeSameSectionLocation() {
        const historyLength = this.navigationHistory.length;
        if (historyLength >= 2) {
            const prevIdx = historyLength - 2;
            const prev = this.navigationHistory[prevIdx];
            let last = this.navigationHistory[historyLength - 1];
            last = last.split('/').slice(0, -1).join('/');
            if (last === prev) {
                this.navigationHistory.splice(prevIdx, 1);
            }
        }
    }
    isSameLang(url) {
        return last(this.navigationHistory).split('/')[1] === url.split('/')[1];
    }
    replaceLangPrefix(path) {
        const fragments = path.split('/');
        if (LOCALES.includes(fragments[1])) {
            fragments.splice(1, 1);
        }
        return this.locRouterSvc.translateRoute(`${fragments.join('/')}`);
    }
    /**
     * Skip child tab routes that interfare with tha navigation flow of back button Eg: Project details sub tab routes.
     * @param url
     * @returns
     */
    skipURLTracking(url) {
        return this.regArray.some(regExp => regExp.test(url)) && !history.state.reDirectToSubTab;
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocalizeRouterService)); }, token: NavigationService, providedIn: "root" });
