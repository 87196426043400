import { IApp } from '@core/models/app.interfaces';
import { CarbonIntensityTableFilter } from '@core/models/filter-types.model';


export const CI_CANCEL_PREFIX = 'ciCancel';

export const CARBON_INTENSITY_TABLE_DETAILS = {
    tableListApiUri: '/cfr-service/application/pageSearch',
    tableHeaderType: 'CARBON_INTENSITY_SEARCH',
    tableFilter: CarbonIntensityTableFilter,
    hasViewTemple: false,
    hasActionTemple: true,
};

export const CANCELLATION ={
  action: 'CANCEL',
  uiUri: '/carbon-intensity/cancel-ci',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'CI_CARBON_INTENSITY_CANCELLATION',
  id: 'cancellationId',
  status: 'cancellationStatus',
  prefix: CI_CANCEL_PREFIX
};

export const REQUEST_CI_CANCELLATION_ADMIN ={
  action: 'CANCEL',
  uiUri: '/carbon-intensity/cancel-ci',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'CI_CARBON_INTENSITY_CANCELLATION',
  id: null,
  status: null,
  prefix: CI_CANCEL_PREFIX
};

export const REQUEST_CANCELLATION ={
    action: 'SUBMIT',
    uiUri: '/carbon-intensity/cancel-ci',
    type: 'CI_CARBON_INTENSITY_CANCELLATION',
    uri: '/cancelCarbonIntensity',
    id: null,
    status: null,
    prefix: CI_CANCEL_PREFIX,
    actionLabel: 'requestCancellationFor',
  };

export const VIEW_ASSOCIATED_PATHWAY = {
  uiUri: '/submission-management/wizard/application/${pathwayId}'
}

export const CARBON_INTENSITY_ACTIONS: IApp.ITableAction = {
  VIEW_CI_APPLICATION: {
    uiUri: '/submission-management/wizard/application/${entityId}',
  },
  VIEW_CANCELLATION: CANCELLATION,
  APPROVE_CANCELLATION: CANCELLATION,
  REVIEW_REQUEST_CANCELLATION: CANCELLATION,
  REJECT_REQUEST_CANCELLATION: CANCELLATION,
  REQUEST_CANCELLATION,
  REQUEST_CI_CANCELLATION_ADMIN,
  VIEW_ASSOCIATED_PATHWAY
};
