/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/components/simple-table-wrapper/simple-table-wrapper.component.ngfactory";
import * as i5 from "../../../../shared/components/simple-table-wrapper/simple-table-wrapper.component";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../../core/store/store.service";
import * as i8 from "../../../../core/services/lookup.service";
import * as i9 from "../../../../shared/services/modal.service";
import * as i10 from "@angular/router";
import * as i11 from "./account-history.component";
import * as i12 from "../../../../shared/services/table-template-controls.service";
import * as i13 from "../../../../shared/services/helper.service";
import * as i14 from "@angular/common";
import * as i15 from "../../../../shared/services/navigation.service";
var styles_AccountHistoryComponent = [i0.styles];
var RenderType_AccountHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountHistoryComponent, data: {} });
export { RenderType_AccountHistoryComponent as RenderType_AccountHistoryComponent };
export function View_AccountHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "float-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-simple-table-wrapper", [], null, null, null, i4.View_SimpleTableWrapperComponent_0, i4.RenderType_SimpleTableWrapperComponent)), i1.ɵdid(5, 245760, null, 0, i5.SimpleTableWrapperComponent, [i6.HttpClient, i7.StoreService, i8.LookupService, i9.ModalService, i10.ActivatedRoute, i3.TranslateService], { tableDetails: [0, "tableDetails"], filterParams: [1, "filterParams"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex justify-content-end button-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.returnPreviousLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tableDetails; var currVal_2 = _co.filterParams; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title)); _ck(_v, 1, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("ACCOUNTS_MODULE.accountHistory.close")); _ck(_v, 8, 0, currVal_3); }); }
export function View_AccountHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-history", [], null, null, null, View_AccountHistoryComponent_0, RenderType_AccountHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i11.AccountHistoryComponent, [i10.ActivatedRoute, i10.Router, i12.TableTemplateControls, i13.HelperService, i7.StoreService, i14.Location, i15.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountHistoryComponentNgFactory = i1.ɵccf("app-account-history", i11.AccountHistoryComponent, View_AccountHistoryComponent_Host_0, {}, {}, []);
export { AccountHistoryComponentNgFactory as AccountHistoryComponentNgFactory };
