import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';

@Component({
  selector: 'app-admin-take-action-modal',
  templateUrl: './admin-take-action-modal.component.html',
})
export class AdminTakeActionModalComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  model: any;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminTakeActionModalComponent>,
    private formFactoryService: FormFactoryService,
  ) { }

  ngOnInit() {
    this.model = this.data.model;
    this.title = this.data.title;
    this.fields = this.formFactoryService.configureForm(this.data.formConfig);
  }

  validateForm() {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    return this.form.valid;
  }

  customActionHandler() {
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model,
        customAction: true
      });
    }
  }

  additionalActionHandler() {
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model,
        additionalAction: true
      });
    }
  }

  save() {
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
