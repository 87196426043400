<div class="inner">
  <div class="container" style="margin-bottom:5rem">
    <!--<app-back-btn></app-back-btn>-->

    <app-blurb [message]="'COMMON.documentGuidanceTest.PROJECT_TRANSFER'" class="stick-left"></app-blurb>

    <h2>{{'PROJECTS_MODULE.transferrequest.'+maintitle | translate}}</h2>
    <h2 *ngIf="status !== 'REJECTED' && formType !== 'VIEW_PROJECT_TRANSFER_INIT' && formType !== 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST' && subtitle">{{'PROJECTS_MODULE.transferrequest.'+subtitle | translate}}</h2>
    <h2 *ngIf="formType === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST' ">{{'PROJECTS_MODULE.transferrequest.subTitlemockup8_1'| translate}} {{projectNameID}} {{'PROJECTS_MODULE.transferrequest.subTitlemockup8_2'| translate}}</h2>
    <h2 *ngIf="statusName">{{'PROJECTS_MODULE.transferrequest.field_stat' | translate}} : {{'COMMON.statusList.'+statusName | translate}} </h2>

    <formly-form [model]="model" [fields]="formFields" [form]="form"></formly-form>

    <p-fieldset legend="{{'PROJECTS_MODULE.transferrequest.field_50' | translate }}" *ngIf="additionalInfoRequested && discussions">
      <formly-form [model]="model" [fields]="mockup5_b" [form]="form" ></formly-form>
      <app-previous-discussion *ngIf="discussions" [discussions]="discussions"></app-previous-discussion>
    </p-fieldset>

    <p-fieldset legend="{{'PROJECTS_MODULE.transferrequest.selectUnits' | translate }}" *ngIf="displayUnits && !model.sameAccountGroup" >
      <app-table-view *ngIf="dataList"
      [config]="tableConfig"
      [data]="dataList"
      [relative]="true"
      (checkboxSelected)="selectionChanged($event)"
     >
    </app-table-view>

    </p-fieldset>

    <ng-template #viewColTemplate let-data>
      <div>
        <span tabindex="0" class="link" role="button"
              (click)="viewUnitDetails($event, data)"
              (keyup)="viewUnitDetails($event, data)">
          {{ 'UNITS_MODULE.unitList.view' | translate}}
        </span>
      </div>
    </ng-template>

    <div class="panel-no-background" style="margin-top: .5rem;" *ngIf="loaded" > <!--*ngIf="loaded"-->

      <h2>{{'PROJECTS_MODULE.transferrequest.field_25' | translate }}</h2>

      <app-documents

      [disabled]="disableDocUpload"

       [relative]="true"

       [documentsPage]="documentsPage"

       [documentUploadConfig]="documentUploadConfig"

       [showPlaceholders]="true"

       (documentResult)= "updateDocumentDetails($event)"></app-documents>

    </div>

  </div>

  <div class="container sticky-panel">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary " style=" height:38px; margin-right:10px" [disabled]="documentUploadInProgress.inProgress$ |async" (click)="back()">{{'PROJECTS_MODULE.transferrequest.BACK' | translate}}</button>


      <ng-container *ngFor="let actions of extraEntityActions">
        <button type="button" class="btn btn-primary " style="height:38px; margin-right:10px"  [disabled]="documentUploadInProgress.inProgress$ |async" (click)="exeAction(actions.workflowAction)">{{'PROJECTS_MODULE.transferrequest.'+ actions.workflowAction | translate}}</button>
      </ng-container>
      <ng-container *ngFor="let actions of entityActionList">
        <button type="button" class="btn btn-primary " style="height:38px; margin-right:10px"  [disabled]="documentUploadInProgress.inProgress$ |async" (click)="exeAction(actions.workflowAction)">{{'PROJECTS_MODULE.transferrequest.'+ actions.workflowAction | translate}}</button>
      </ng-container>
    </div>
  </div>
</div>
