import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IRawFormBase } from '@core/models/raw-form.types';
import { CurrentUser } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { IDocument } from '@module/registration/registration.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BUSINESS_TELEPHONE,
  DISPLAY_AO_PROFILE_PUBLIC,
  FIELD_CONFIGURATIONS,
  FULL_NAME,
  TITLE,
} from '@shared/components/add-additional-users/add-additional-users.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { UserService } from '@shared/services/user.service';
import { OTHER_AREA_OF_EXPERTISE_ID, REGISTRATION_ACCOUNT, SUB_ORGANIZATION_ACCOUNT } from '@shared/shared.const';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { PROGRAM_OBPS } from '@core/models/eccc-programs.const';
const PERMISSION_REQUEST_NEW_USERS = 'REQUEST_NEW_USERS';
export const userDetailsPrefix = 'USER_DETAILS_';

const COMPONENT_ID = 'ADD_ADDITIONAL_USERS';
const ADD_ADDITIONAL_FIELDS_VALUE = IApp.NSAccountSubType.StatusEnum.VB;

@Component({
  selector: 'app-add-additional-users',
  templateUrl: './add-additional-users.component.html',
  styleUrls: ['./add-additional-users.component.scss'],
})
export class AddAdditionalUsersComponent implements OnInit, OnDestroy {
  @ViewChild('ngForm', { static: true }) formEl: ElementRef;

  private _destroy$ = new Subject<any>();
  files: IApp.IDocument[] = [];
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    documentType: true,
    entityName: 'USER',
    comment: true,
    title: 'ACCOUNTS_MODULE.uploadDocument.title',
  };

  documentsPage: IApp.IDocumentsPage;
  userData: IApp.ISaveUser = {
    documents: [],
    accounts: [],
    associationsList: [],
  };
  initialUserData: IApp.ISaveUser = {};

  showAdditionalFields = false;
  tags: string[];
  loggedUser: CurrentUser;
  formEnabled: boolean;
  enableSubmit: boolean;
  newUserFields: FormlyFieldConfig[];
  cfrFormFields: FormlyFieldConfig[];
  newUserForm = new FormGroup({});
  apiUser = false;
  addressPrefix: string;
  civicAddress: any;
  config: IApp.IUserFields;
  documentsDisabled: boolean;
  showSubOrganization: boolean;
  areaOfTechnicalExpertise = new BehaviorSubject<IApp.ILookup[]>(null);
  userStatusType: string;
  accounts: number[] = [];
  adminRole = false;
  label: string;
  isMAO: boolean;
  isAAG: boolean;
  maoLabel: string;
  selfUpdate: boolean;
  selfUpdateActive: boolean;
  loaded: boolean;
  submitButtonLabel = 'ACCOUNTS_MODULE.addUser.submitBtn';
  AARAccount: IApp.IAccountData;

  constructor(
    private elRef: ElementRef,
    private translateService: TranslateService,
    private lookupService: LookupService,
    private store: StoreService,
    private formService: FormService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private uploadDocumentService: UploadDocumentService,
    private helperService: HelperService,
    private formFactoryService: FormFactoryService,
    private modalService: ModalService,
    private service: CfrRegistrationService,
    private authService: AuthService,
    private accountManagementService: AccountManagementService,
    private navigation: NavigationService,
  ) {}

  setUserFields() {
    this.config = FIELD_CONFIGURATIONS[this.getFieldKey()];
    this.label = 'ACCOUNTS_MODULE.addUser.' + this.config.linkedAccountsText.label;
    this.documentsDisabled = this.config.uploadDocuments.disabled || !this.formEnabled;
    this.newUserFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-6',
            type: 'input',
            key: TITLE,
            id: userDetailsPrefix.concat(TITLE),
            templateOptions: {
              disabled: this.config.title.disabled,
              required: this.config.title.required,
              label: `ACCOUNTS_MODULE.addUser.${this.config.title.label}`,
            },
          },
          {
            className: 'col-lg-12 col-xl-6',
            type: 'input',
            key: FULL_NAME,
            id: userDetailsPrefix.concat(FULL_NAME),
            templateOptions: {
              disabled: this.config.fullName.disabled || this.isAAG,
              required: this.config.fullName.required,
              label: 'ACCOUNTS_MODULE.addUser.nameLabel',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-6',
            type: 'input',
            key: 'email',
            id: userDetailsPrefix.concat('email'),
            templateOptions: {
              disabled: this.config.email.disabled || this.isAAG,
              required: true,
              label: 'ACCOUNTS_MODULE.addUser.emailLabel',
            },
          },
          {
            className: 'col-lg-12 col-xl-6',
            type: 'input',
            key: 'phoneNumber',
            id: userDetailsPrefix.concat('phoneNumber'),
            templateOptions: {
              disabled: this.config.phoneNumber ? this.config.phoneNumber.disabled : false,
              required: true,
              label: 'ACCOUNTS_MODULE.addUser.phoneLabel',
              placeholder: 'COMMON.messageSection.phonePlaceholder',
            },
            expressionProperties: {
              'templateOptions.required': 'model.deliveryMedium === "SMS"',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-6',
            type: 'readonly-input',
            key: 'status',
            id: userDetailsPrefix.concat('status'),
            templateOptions: {
              translate: true,
              label: 'ACCOUNTS_MODULE.addUser.statusLabel',
              translatePrefix: `COMMON.${this.store.user.getProgramPrefix().toLowerCase()}statusList`,
              translateOptions: true,
            },
          },
          {
            className: 'col-lg-12 col-xl-6',
            type: 'input',
            key: BUSINESS_TELEPHONE,
            id: userDetailsPrefix.concat(BUSINESS_TELEPHONE),
            templateOptions: {
              disabled: this.config.businessTelephone.disabled,
              required: this.config.businessTelephone.required,
              label: 'ACCOUNTS_MODULE.addUser.businessTelephone',
              placeholder: 'COMMON.messageSection.phonePlaceholder',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-6',
            type: 'dict-select',
            wrappers: ['form-field'],
            key: 'deliveryMedium',
            // hideExpression: !this.store.user.hasPermission('MENU_ADMIN'),
            templateOptions: {
              label: 'ACCOUNTS_MODULE.addUser.receiveVerificationCode',
              translatePrefix: 'COMMON.deliveryType',
              translateOptions: true,
              placeholder: 'selectItem',
              source: this.lookupService.getDeliveryTypes(),
              required: true,
              disabled: this.config.deliveryMedium ? this.config.deliveryMedium.disabled : false,
            },
          },
          {
            type: 'dict-select',
            wrappers: ['form-field'],
            className: 'col-lg-12 col-xl-6',
            key: DISPLAY_AO_PROFILE_PUBLIC,
            templateOptions: {
              dataProvided: true,
              items: [
                {id: true, name: 'yes'},
                {id: false, name: 'no'},
              ],
              label: 'ACCOUNTS_MODULE.addUser.principalUserPublic',
              placeholder: 'selectItem',
              translatePrefix: 'COMMON.yesNoList',
              defaultValue: false,
              translateOptions: true,
              clearable: false,
              appendTo: 'self',
            },
            hideExpression: () => !this.AARAccount,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-6',
            type: 'checkbox',
            key: 'enableNotification',
            id: userDetailsPrefix.concat('enableNotification'),
            templateOptions: {
              label: 'ACCOUNTS_MODULE.addUser.enableNotification',
            },
          },
        ],
      },
    ];

    if (!this.config.professionalQualification.hidden || this.showAdditionalFields) {
      this.newUserFields.push(this.getAreaofExpertiseFields())
      this.getExpertiseNames(this.userStatusType || REGISTRATION_ACCOUNT);
      this.userData.subContractingUserType = 'VERIFICATION_BODY';
      if (
        this.userData.associationsList &&
        (this.userData.associationsList.length === 0 || this.userData.associationsList[0].roleId !== 1)
      ) {
        // ACCOUNT_HOLDER_ENHANCED_USER
        //this.newUserFields.push(this.vbStatusOfUserFields());
      }
      this.newUserFields.push(this.formFactoryService.configureForm(this.getSubOrganization())[0]);
    }
    this.setPostalAndCivicAddress();

    this.deferred();
  }

  getAreaofExpertiseFields() {
    return {
      fieldGroupClassName: 'row',
      label: 'AreasOfTechnicalExpertise',
      translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
      fieldGroup: [
        {
          className: 'col-lg-12 col-xl-12',
          key: 'professionalQualificationIds',
          type: 'multi-checkbox',
          id: 'PROFESSIONAL_QUALIFICATIONS',
          templateOptions: {
            source: this.lookupService.getAreaOfExpertiseForVB(),
            label: 'REGISTRATION_PAGE.cfsRegistrationForm.areasOfExpertise',
            className: 'col-6',
            disabled: false,
            required: false,
            inline: false,
          },
        },
        {
          className: 'col-6',
          type: 'input',
          key: `otherProfessionalQualification`,
          templateOptions: {
            label: 'REGISTRATION_PAGE.cfsRegistrationForm.otherProfessionalQualification',
            disabled: false,
            required: true,
          },
          hideExpression: `!model.professionalQualificationIds || !model.professionalQualificationIds.includes(${OTHER_AREA_OF_EXPERTISE_ID})`,
          id: `otherProfessionalQualification`,
          expressionProperties: {
            ['templateOptions.required']: `model.professionalQualificationIds && model.professionalQualificationIds.includes(${OTHER_AREA_OF_EXPERTISE_ID})`
          }
        },
      ],
    };
  }

  ngOnInit() {
    this.newUserForm['id'] = COMPONENT_ID;
    this.loggedUser = this.store.user;
    this.activatedRoute.params.subscribe(params => {
      if (!isNaN(params.id)) {
        forkJoin([this.userService.get(params.id), this.accountManagementService.getAllCreateRoles()]).subscribe(([result, roles]) => {
          this.userData = result;
          if (result.associationsList[0] ){
            this.userData.roleId = result.associationsList[0].roleId;
          }
          if (this.store.user.isCFR()) {
            this.isMAO =
              result.status === 'ACTIVE' &&
              result.associationsList[0] &&
              result.associationsList[0].role === 'ACCOUNT_HOLDER_ENHANCED_USER';
            this.isAAG = result.associationsList[0] && result.associationsList[0].role === 'ACCOUNT_HOLDER_ENHANCED_USER';
            // tslint:disable-next-line: max-line-length
            this.maoLabel = this.translateService.instant('COMMON.messageSection.MAO_DETAILS_CAN_BE_CHANGED_ONLY_FROM_REGISTRATION_REPORT');
            this.maoLabel = this.maoLabel.replace(
              '{{0}}',
              `/${this.translateService.currentLang}${this.userData.registrationLink}?profile=1`,
            );
          }
          this.selfUpdate = this.userService.isSelfUpdate(this.userData);
          this.selfUpdateActive = this.userService.isSelfUpdate(this.userData, true);
          if (this.selfUpdate || !this.userData.entityActionList || this.isMAO) {
            this.userData.entityActionList = [];
          }
          if (this.selfUpdate && this.userData.status !== 'ACTIVE') {
            this.submitButtonLabel = 'COMMON.actionsLabel.PROCEEED_TO_NEXT_STEP';
          }
          const hasEditPermission = this.checkEditable(roles.roleDtos);
          this.formEnabled = this.isFormEnabled(hasEditPermission);
          // tslint:disable-next-line: max-line-length
          this.enableSubmit =
            this.userData.id === this.store.user.id ||
            (hasEditPermission && this.userData.entityActionList.findIndex(t => t.workflowAction.indexOf('APPROVE') > -1) === -1);
          this.setUserType();
          this.userData['_organizationAddress'] = {};
          this.apiUser = this.userData.source === 'API';
          this.documentUploadConfig.id = params.id;
          this.documentUploadConfig.entityDetail = this.userData.fullName;
          this.uploadDocumentService
            .getDocumentsByEntityIdAndType(this.documentUploadConfig.id, this.documentUploadConfig.entityName)
            .subscribe(documents => {
              this.userData.documents = documents.content;
              this.documentsPage = documents;
            });
          this.getOrganizationAddress();
          this.setUserFields();
          this.getOBPSAccount(true);
        });
      } else {
        this.formEnabled = this.loggedUser.hasPermission(PERMISSION_REQUEST_NEW_USERS);
        this.enableSubmit = this.formEnabled;
        this.userData.deliveryMedium = 'SMS';
        this.userData.status = 'DRAFT';
        this.userData.enableNotification = true;
        this.setUserFields();
        this.getOBPSAccount();
      }
    });
  }

  deferred() {
    setTimeout(() => {
      this.loaded = true;
      if (!this.formEnabled && !this.selfUpdateActive) {
        this.newUserForm.disable();
        if (this.isMAO) {
          this.newUserForm.get('deliveryMedium').enable();
          this.newUserForm.get('enableNotification').enable();
        }
      } else {
        this.setPostalAndCivicAddress();
      }

      if(this.apiUser){
        this.newUserForm.get(TITLE).disable();
        this.newUserForm.get(FULL_NAME).disable();
        this.newUserForm.get('email').disable();
        this.newUserForm.get(BUSINESS_TELEPHONE).disable();
      }
    });
  }

  checkEditable(roles) {
    if (this.loggedUser.isCFR()) {
      const admin = !this.loggedUser.checkIfCFRNonAdmin() && this.loggedUser.hasPermission(PERMISSION_REQUEST_NEW_USERS);
      return admin || roles.findIndex(r => this.userData.associationsList[0].roleId === r.id) > -1 || this.selfUpdate;
    } else if (this.loggedUser.isOffset()) {
      const allowed = this.loggedUser.hasPermission(PERMISSION_REQUEST_NEW_USERS) || this.selfUpdate;
      if (allowed) {
        let editable = false;
        this.loggedUser.associationsList.forEach(asst => {
          if (!asst.accountDto || !asst.accountDto.masterAccount) {
            editable = true;
          }
        });

        return editable || this.userData.id === this.store.user.id;
      }
    }
    return this.loggedUser.hasPermission(PERMISSION_REQUEST_NEW_USERS) || this.selfUpdate;
  }

  get program(): string {
    return this.store.user.program;
  }

  getFieldKey() {
    let config = '';

    config = this.program;
    if (config === 'CFR' || config === 'OFFSET') {
      if (this.store.user.checkIfVerificationBody()) {
        config = `${config}_VB`;
      }

      if (this.userData.id) {
        // CATS-2917
        if (this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.store.user.id !== this.userData.id) {
          config = `${config}_NEW`;
        } else {
          config = `${config}_EXISTING`;
        }
      } else {
        config = `${config}_NEW`;
      }
    } else if (this.userData.source === 'API') {
      config = 'API';
    } else if (config === 'OBPS' || config === 'CM') {
      if (this.userData.id) {
        config = `${config}_EXISTING`;
      } else {
        config = `${config}_NEW`;
      }
    }

    return config;
  }

  onListChanged(data: IApp.ILinkedAccountUpdate) {
    if (this.userData.id) {
      if (data.refresh) {
        this.ngOnInit();
      } else {
        this.userData.associationsList = data.items;
      }
    } else {
      this.userData.associationsList = data.items;
      if (this.accounts.length === 0) {
        this.getOrganizationAddress();
      }
    }
  }

  getExpertiseNames(accountType: string) {
    const accountId = this.store.user.associationsList[0].accountId || this.userData.associationsList[0].accountId;
    if (accountId) {
      this.accountManagementService
        .getAccountDetails(accountId)
        .pipe(
          takeUntil(this._destroy$),
          switchMap(data => {
            return data.accountSubTypes === 'VB'
              ? this.lookupService.getAreaOfExpertiseById(`ACCOUNT/${accountId}`)
              : this.lookupService.getAreaOfExpertise(accountType);
          }),
        )
        .subscribe(value => {
          this.areaOfTechnicalExpertise.next(value);
        });
    } else {
      this.lookupService
        .getAreaOfExpertiseById(`${accountType}/${accountId}`)
        .pipe(takeUntil(this._destroy$))
        .subscribe(value => this.areaOfTechnicalExpertise.next(value));
    }
  }

  getAreaofExpertise(): Observable<IApp.ILookup[]> {
    return this.areaOfTechnicalExpertise.pipe(filter(val => val != null));
  }

  updateDocumentDetails(documents: IDocument[]) {
    const documentIds: number[] = [];
    documents.map(doc => documentIds.push(doc.id));
    this.userData.documentIds = documentIds;
  }

  private markFormGroupDirty(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  triggerActionController(value: IApp.IEntityAction): void {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: 'confirmationMessage',
          metaData: this.translateService.instant(`COMMON.actionsLabel.${value.workflowAction}`),
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.executeAction(value);
        }
      });
  }

  executeAction(value: IApp.IEntityAction): void {
    const userPayload: IApp.ISaveUser = {};
    userPayload.id = this.userData.id;
    const email = this.userData.email;
    const accountId = this.userData.accountId;
    const action = value.workflowAction.charAt(0).toUpperCase() + value.workflowAction.slice(1).toLowerCase();
    const prefix = this.store.isProgram('OFFSET') ? 'Offset' : '';

    const taskType = this.activatedRoute.snapshot.params.taskType;
    if (taskType && taskType === 'USER_REVIEW_AO_REPLACEMENT') {
      this.userService
        .doAction(`/do${prefix}${action}`, userPayload)
        .pipe(
          takeUntil(this._destroy$),
          switchMap(() =>  {
            if (action === 'Cfr_reject') {
              return this.service.rejectReplaceMAO({email, accountId});
            } else {
              return this.service.approveReplaceMAO({email});
            }
          })
          )
        .subscribe(() => {
          this._location.back();
        });
    } else {
      this.userService
        .doAction(`/do${prefix}${action}`, userPayload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._location.back();
        });
    }
  }

  saveUser(form: FormGroup) {
    this.markFormGroupDirty(form);
    if (form.valid && this.checkAOReplacement(form)) {
      this.saveUserAfterValidation(form);
    } else {
      this.formService.scrollToFirstInvalidControl(this.formEl, this.elRef.nativeElement.parentElement);
    }
  }

  get saveOBPSAccount(): Observable<any> {
    const publicViewChanged = this.userData[DISPLAY_AO_PROFILE_PUBLIC] !== this.initialUserData[DISPLAY_AO_PROFILE_PUBLIC];
    if (this.AARAccount && publicViewChanged) {
      this.AARAccount[DISPLAY_AO_PROFILE_PUBLIC] = this.userData[DISPLAY_AO_PROFILE_PUBLIC];
      return this.accountManagementService.updateOBPS_NFMP(this.AARAccount, true);
    } else {
      return of(true);
    }
  }

  saveUserAfterValidation(form: FormGroup) {
    this.saveOBPSAccount.pipe(
      switchMap(() => this.userService.saveUser(this.userData)),
    ).subscribe(
      result => {
        this.userData = result.entity;
        if (this.store.user.id === this.userData.id) {
          const user = this.store.user;
          user.title = this.userData.title;
          user.civicAddress = this.userData.civicAddress;
          user.businessTelephone = this.userData.businessTelephone;
          this.store.user = new CurrentUser(user);
        }

        if (this.store.user.status === 'PENDING_KYC') {
          this.router.navigate(this.helperService.getTranslatedPath(''));
        } else {
          this.authService.changeProgram(this.store.user.programId).subscribe(result => {
            this.router.navigate(this.helperService.getTranslatedPath(`/user/list`));
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.formService.parseErrors(form, error.error);
        this.store.storeCurrentUrl();
        if (this.initialUserData) {
          this.userData[DISPLAY_AO_PROFILE_PUBLIC] = this.initialUserData[DISPLAY_AO_PROFILE_PUBLIC];
          this.userData = {...this.userData};
        }
      },
    );
  }

  checkAOReplacement(form: FormGroup) {
    if (
      this.store.user.isOffset() &&
      this.store.user.associationsList &&
      this.userData.associationsList &&
      this.store.user.id !== this.userData.id
    ) {
      const principalUser = this.store.user.associationsList.filter(asctn => {
        return asctn.roleId === 1;
      });

      const newPrincipalUser = this.userData.associationsList.filter(asctn => {
        return asctn.roleId === 1;
      });
      if (principalUser.length > 0 && newPrincipalUser.length > 0) {
        this.modalService
          .open(
            ServiceMessageComponent,
            {
              messages: null,
              message: 'principalUserWarning',
              type: SereviceMessageType.WARNING,
            },
            true,
            DEFAULT_DIALOG_CONFIG,
          )
          .afterClosed()
          .pipe(take(1))
          .subscribe(val => {
            if (val) {
              if(this.store.user.isOffset()) {
                  newPrincipalUser.forEach(item => {
                    item.userStatus= 'PENDING_KYC';
                  });
                }
                this.saveUserAfterValidation(form);
            }
          });
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isCFS() {
    return this.store.user.program === 'CFR';
  }

  returnPreviousLocation(event: Event): void {
    event.preventDefault();

    if (!this.newUserForm.touched) {
      this.navigation.back();
      return;
    }

    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: 'newUserFormUnsavedData',
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(val => {
        if (val) {
          this.navigation.back();
        }
      });
  }

  vbStatusOfUserFields() {
    return {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-lg-3 col-xl-3',
          template: `${this.translateService.instant('ACCOUNTS_MODULE.addUser.StatusofUser')}*`,
        },
        {
          type: 'radio',
          key: 'subContractingUserType',
          className: 'inline col-lg-9 col-xl-9',
          label: this.translateService.instant('ACCOUNTS_MODULE.addUser.StatusofUser'),
          templateOptions: {
            required: true,
            options: [
              {
                value: this.translateService.instant(`COMMON.userSubOrgstatusList.VERIFICATION_BODY`),
                key: 'VERIFICATION_BODY',
              },
              {
                value: this.translateService.instant(`COMMON.userSubOrgstatusList.SUB_CONTRACTING_ORGANIZATION`),
                key: 'ORGANIZATION',
                tooltip: this.translateService.instant(`ACCOUNTS_MODULE.addUser.subContractiongOrganizationInfo`),
              },
              {
                value: this.translateService.instant(`COMMON.userSubOrgstatusList.INVIDUAL`),
                key: 'INVIDUAL',
                tooltip: this.translateService.instant(`ACCOUNTS_MODULE.addUser.userSubOrgIndividualInfo`),
              },
            ],
            change: () => {
              this.onChange();
            },
          },
        },
      ],
    };
  }

  getSubOrganization(): IRawFormBase[] {
    let accountId = -1;
    if (this.store.user.associationsList[0].accountId) {
      accountId = this.store.user.associationsList[0].accountId;
    } else if (this.userData.associationsList && this.userData.associationsList[0].accountId) {
      accountId = this.userData.associationsList[0].accountId;
    }
    return [
      {
        groupFields: false,
        fieldGroup: [
          {
            className: 'col-lg-12 col-xl-12 mt-4',
            type: 'input',
            key: 'subContractingOrganizationName',
            hideExpression: "!(model.subContractingUserType === 'ORGANIZATION')",
            label: 'ACCOUNTS_MODULE.addUser.subContractingOrganizationName',
            disabled: false,
            required: true,
            id: 'subContractingOrganizationName',
          },
        ],
      },
    ];
  }

  onChange(): void {
    this.showSubOrganization = this.userData.subContractingUserType === 'ORGANIZATION';
    this.setUserType();
  }

  setUserType() {
    this.userStatusType = REGISTRATION_ACCOUNT;
    if (this.userData.subContractingUserType === 'ORGANIZATION') {
      this.userStatusType = SUB_ORGANIZATION_ACCOUNT;
    } else if (this.userData.subContractingUserType === 'INVIDUAL') {
      this.userStatusType = this.userData.subContractingUserType;
    }
  }

  onAccountTypesChange(accountTypes) {
    this.showAdditionalFields = accountTypes[0] === ADD_ADDITIONAL_FIELDS_VALUE;
    this.setUserFields();
  }

  showMessage(type: any, yesNoButton: boolean, message: string, noMessage: string = null) {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message,
            },
          ],
          message,
          type,
          yesNoButton,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.getExpertiseNames(this.userStatusType);
        } else {
          this.userData = { ...this.userData, subContractingUserType: null };
          if (noMessage) {
            this.showMessage(SereviceMessageType.ERROR, false, noMessage);
          }
        }
      });
  }

  getOrganizationAddress(): void {
    this.accounts = Array.from(new Set(this.userData.associationsList.filter(f => f.accountId).map(a => a.accountId)));
    if (this.accounts.length > 0) {
      this.getAccountDetails(this.accounts[0]);
    }
  }

  getOBPSAccount(forceReload = false): void {
    if (this.isOBPS) {
      const accounts = Array.from(new Set(this.userData.associationsList
        .filter(f => f.accountId && f.program === PROGRAM_OBPS && f.role === 'ACCOUNT_HOLDER_ENHANCED_USER')
        .map(a => a.accountId)));
      if (accounts.length) {
        this.accountManagementService
          .getAccountDetails(accounts[0], forceReload)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            this.AARAccount = data;
            this.userData[DISPLAY_AO_PROFILE_PUBLIC] = data.displayAoProfilePublicView || false;
            this.initialUserData = {...data, [DISPLAY_AO_PROFILE_PUBLIC]: data.displayAoProfilePublicView || false };
          });
      }
    }
  }

  getAccountDetails(accountId): void {
    this.accountManagementService
      .getAccountDetails(accountId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const orgAddress = data.postalAddress || data.address;
        this.userData['_organizationAddress'] = orgAddress;
        if (this.newUserForm.get('_organizationAddress')) {
          this.newUserForm.get('_organizationAddress').patchValue(orgAddress);
        }
        this.deferred();
      });
  }

  hasAdminRole(adminRole: boolean): void {
    this.adminRole = adminRole;
  }

  isFormEnabled(hasEditPermission) {
    return (!this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.isPendingKYC) ||
      (!this.isMAO && hasEditPermission);
  }

  get canSubmit() {
    return !(this.userData.id && this.userData.associationsList.find(a => a.isNew));
  }

  get isCheckboxHidden(): boolean {
    return (this.accounts && this.accounts.length === 0) || this.adminRole;
  }

  get isPendingKYC(): boolean {
    return this.userData.status === 'PENDING_KYC';
  }

  get isOBPS(): boolean {
    return this.store.isProgram(PROGRAM_OBPS);
  }

  setPostalAndCivicAddress() {
    if (this.config.address.required) {
      const postalAddress = {
        label: this.config.address.postalAddressLabel,
        prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
        key: 'postalAddress',
        changeExpr: null,
        sameAsOrganization: {
          hide: () => this.isCheckboxHidden,
          changeExpr: "this.disableForm($event, field); this.cloneFormGroup($event, field, '_organizationAddress', 'postalAddress')",
        },
        hidePoBox: false,
        optionalPoBox: true,
        provinceStateLabel: this.config.address.provinceStateLabel,
        postalCodeLabel: this.config.address.postalCodeLabel,
        sameAsPostal: {
          hide: true,
        },
        hideLatLong: true,
        optional: this.store.user.isOffset(),
      };

      const civicaddress = {
        label: this.config.address.civicAddressLabel,
        prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
        key: 'civicAddress',
        sameAsOrganization: {
          hide: true,
        },
        hidePoBox: this.config.address.hidePoBoxCivic,
        provinceStateLabel: this.config.address.provinceStateLabel,
        postalCodeLabel: this.config.address.postalCodeLabel,
        sameAsPostalAddressLabel: this.config.address.sameAsPostalLabel,
        sameAsPostal: {
          hide: false,
          changeExpr: "this.disableForm($event, field);  this.cloneFormGroup($event, field, 'postalAddress', 'civicAddress')",
        },
        hideLatLong: true,
        optional: this.store.user.isOffset(),
      };

      this.cfrFormFields = this.formFactoryService.configurFormPostWithCivicAddress(postalAddress, civicaddress, '_organizationAddress');
    }
  }
}
